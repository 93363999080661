import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EmailSentPage from './EmailSentPage';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useForgotPassword, useResendEmailVerificationCode } from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';

const EmailSentPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('auth');
    const { state } = useLocation();
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { mutate, error, isLoading } = useResendEmailVerificationCode(setSnackBarMessage);
    const { mutate: resendEmail } = useForgotPassword();

    const resendCode = () => mutate(state.email);
    const resendEmailForgotPass = () => resendEmail({ email: state.email });

    const childProps = {
        ...props,
        t,
        isSigningUp: state?.isSigningUp ?? false,
        isLoading,
        resendCode: state?.isSigningUp ? resendCode : resendEmailForgotPass,
        error,
        SnackBar,
        email: state.email,
    };

    return <EmailSentPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EmailSentPageContainer.propTypes = propTypes;

export default EmailSentPageContainer;
