import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ErrorModal.styled';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';

const ErrorModal = (props: Props) => {
    const { open, close, t } = props;

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle textAlign={'center'}>{t('error_modal_title')}</DialogTitle>
            <DialogContent>
                <Typography textAlign={'center'}>{t('error_modal_subtitle')}</Typography>
            </DialogContent>
            <St.ButtonContainer>
                <ActionButton variant="contained" sx={{ width: '60%' }} onClick={close}>
                    {t('try_again')}
                </ActionButton>
            </St.ButtonContainer>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorModal.propTypes = propTypes;

export default ErrorModal;
