import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import MarketEquitiesPage from './MarketEquitiesPage';

const MarketEquitiesPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <MarketEquitiesPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketEquitiesPageContainer.propTypes = propTypes;

export default MarketEquitiesPageContainer;
