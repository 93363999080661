import React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomLoader from 'components/common/CustomLoader';
import { GetInstrumentsItem, TickerInfo } from 'types/api/instruments.types';
import { Currency, Tprop } from 'types/common/general.types';
import MarketSearchBar from '../CommonMarketTable/MarketSearchBar';
import St from './GeneralMarketSearchBar.styled';
import { tCommon } from 'constants/appConstants';

const GeneralMarketSearchBar = (props: Props) => {
    const {
        searchBar,
        setSearchBar,
        searchBarData,
        isLoadingSearchBar,
        openSearchBar,
        currency,
        boxRef,
        handleOpenListIfRecordsExist,
        redirectToTickerDetails,
        t,
    } = props;

    return (
        <St.Box ref={boxRef} onClick={handleOpenListIfRecordsExist}>
            <MarketSearchBar
                placeholder={t('search_by_general_instrument')}
                onSearch={query => setSearchBar(query)}
                defaultValue={searchBar}
                blueMode
                extraComponent={
                    openSearchBar && (
                        <St.DropdownContainer>
                            {isLoadingSearchBar ? (
                                <CustomLoader />
                            ) : searchBarData && searchBarData?.length > 0 ? (
                                searchBarData.map((option, index) => (
                                    <St.DropdownItem
                                        key={index}
                                        onClick={() =>
                                            redirectToTickerDetails(option.tickers, option.type)
                                        }>
                                        {`${t(option.type.toLowerCase())} | ${
                                            option.type !== 'FUND'
                                                ? option.tickers.find(
                                                      el => el.currency === currency,
                                                  )?.ticker + ' -'
                                                : ''
                                        } ${option.name}`}
                                    </St.DropdownItem>
                                ))
                            ) : (
                                <St.AlerNoDataWrapper>
                                    <St.AlertNoData title={t('no_data_was_found', tCommon)} />
                                </St.AlerNoDataWrapper>
                            )}
                        </St.DropdownContainer>
                    )
                }
            />
        </St.Box>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    searchBar: string;
    setSearchBar: React.Dispatch<React.SetStateAction<string>>;
    searchBarData: GetInstrumentsItem[] | undefined;
    isLoadingSearchBar: boolean;
    openSearchBar: boolean;
    currency: Currency;
    boxRef: React.RefObject<HTMLDivElement>;
    handleOpenListIfRecordsExist: () => void;
    redirectToTickerDetails: (tickers: TickerInfo[], type: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

GeneralMarketSearchBar.propTypes = propTypes;

export default GeneralMarketSearchBar;
