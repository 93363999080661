import { Box, Typography, styled } from '@mui/material';

export default {
    Wrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '0.8rem',
            padding: '0.8rem',
            margin: 0,
            width: '18rem',
        }),
    ),
    Text: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.primary.main,
            fontSize: '1.2rem',
            lineHeight: '1.4rem',
            fontWeight: 600,
        }),
    ) as typeof Typography,
};
