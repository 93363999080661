import { styled, Box, TextField } from '@mui/material';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    NameWrapper: styled(Box)(() => ({})),
    CustomAutoCompleteWrapper: styled(
        Box,
        transientOptions,
    )<{ $minWidth: string }>(({ $minWidth }) => ({
        input: { width: `${$minWidth} !important` },
    })),
    CustomAutoComplete: styled(CustomAutoComplete)(() => ({})) as typeof CustomAutoComplete,
    TextField: styled(TextField)(({ theme }) => ({})),
};
