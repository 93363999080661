import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ModalTriggerContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    ModalTrigger: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: pxToRem('72px'),
        width: pxToRem('439px'),
        backgroundColor: '#173254',
        borderRadius: pxToRem('81px'),
    })) as typeof Box,

    ModalTitleTrigger: styled(Typography)(({ theme }) => ({
        color: 'white',
    })),

    ButtonTrigger: styled(Typography)(({ theme }) => ({
        width: pxToRem('85px'),
        height: pxToRem('40px'),
        textAlign: 'center',
        alignContent: 'center',
        color: 'white',
        border: 'none',
        backgroundColor: '#307FDD',
        margin: `0 ${pxToRem(14)} 0 ${pxToRem(10)}`,
        borderRadius: pxToRem('81px'),
        cursor: 'pointer',
    })),
};
