import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketExchangePage from './MarketExchangePage';
import { useTranslation } from 'react-i18next';
import { ExchangeOrderItem } from 'types/api/orders.types';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { dateExtraction, formatSingleDate, getCurrentTime } from 'utils/helpers/dateHelper';
import { exchangeCurrencyStartEndOptions, ICON_CELL_SIZE, tCommon } from 'constants/appConstants';
import { Chip } from '@mui/material';
import {
    exchangeStatusOptions,
    formatNumber,
    getColorByStatus,
    getFullName,
} from 'utils/helpers/commonHelper';
import { AuthContext } from 'context/auth.context';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import ExchangeDetail from './ExchangeDetail';
import { MarketContext } from 'context/market.context';
import AddIcon from 'assets/icons/add-line.svg?react';
import ExchangeForm from './ExchangeForm';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';

const MarketExchangePageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');
    const { marketAccounts } = React.useContext(AuthContext);
    const { marketsTimes } = React.useContext(MarketContext);
    const [openCloseModal, setOpenCloseModal] = React.useState<boolean>(false);

    const exchangeTimes = React.useMemo(() => {
        if (!marketsTimes) return null;
        return marketsTimes?.find(m => m.name === 'EXCHANGE') ?? null;
    }, [marketsTimes]);

    const columns: CommonColumn<ExchangeOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('created_at_column_text'),
            selector: row => formatSingleDate(row.createdAt),
            sortable: true,
        },
        {
            id: 'customerCode',
            name: t('customer_code_column_text'),
            sortable: true,
        },
        {
            id: 'client',
            name: t('client', tCommon),
            selector: row => getFullName(row.user),
        },
        {
            id: 'orderType',
            name: t('order_type_column_text'),
            selector: row => `${row.currencyStart} - ${row.currencyEnd}`,
        },
        {
            id: 'isManual',
            name: t('is_manual_column_text'),
            selector: row => (row.isManual ? t('is_manual_text') : t('is_direct_text')),
        },
        {
            id: 'amount',
            name: t('amount', tCommon),
            selector: row => formatNumber(row.amount),
        },
        {
            id: 'ticker',
            name: t('ticker_column_text'),
            selector: row => row.ticker?.ticker,
        },
        {
            id: 'price',
            name: t('price', tCommon),
            selector: row =>
                row.isMarketPrice ? 'MKT' : row.buySell === 'BUY' ? row.buyPrice : row.sellPrice,
        },
        {
            id: 'term',
            name: t('term', tCommon),
            selector: row => (row.term === 0 ? t('term_CI') : '24hs'),
        },
        {
            id: 'declaration',
            name: t('ddjj', tCommon),
            selector: row => <ChannelsDDJJCellInfo uploadFile {...row} />,
        },
        {
            id: 'status',
            name: t('status', tCommon),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status, tCommon)} />
            ),
            sortable: true,
        },
    ];

    const date = new Date();
    const current = dateExtraction(date);
    const nextDay = dateExtraction(new Date(date.setDate(date.getDate() + 1)));
    const nextNextDay = dateExtraction(new Date(date.setDate(date.getDate() + 1)));

    const filters: CommonFilter[] = [
        {
            id: 'currencyStartEnd',
            label: t('order_type_column_text'),
            type: 'select',
            query: 'currencyStartEnd',
            options: exchangeCurrencyStartEndOptions,
        },
        {
            id: 'status',
            label: t('status', tCommon),
            type: 'select',
            query: 'status',
            options: exchangeStatusOptions,
        },
        {
            id: 'customerCode',
            label: t('customer_code_column_text'),
            type: 'select',
            query: 'customerCode',
            options:
                marketAccounts?.map(el => ({ id: el.customerCode, name: el.customerCode })) ?? [],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
            disableFutureDaysFromN: `${nextDay.year}/${nextDay.month}/${nextDay.day}`,
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
            disableFutureDaysFromN: `${nextNextDay.year}/${nextNextDay.month}/${nextNextDay.day}`,
        },
    ];

    const defaultFilters = [
        { query: 'dateFrom', queryValue: `${current.year}-${current.month}-${current.day}` },
        { query: 'dateTo', queryValue: `${nextDay.year}-${nextDay.month}-${nextDay.day}` },
    ];

    const actions: CommonAction<ExchangeOrderItem>[] = [
        {
            id: 'detail',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => <ExchangeDetail {...props} />,
            isHidden: row => row.status === 'PENDING',
        },
    ];

    const isMarketClosed = (openTime: string, closeTime: string) => {
        const openTimeSplitted = openTime.split(':');
        const closeTimeSplitted = closeTime.split(':');
        if (!openTimeSplitted || !closeTimeSplitted) return true;
        const now = getCurrentTime(new Date().toISOString()).split(':');
        for (let index = 0; index < 3; index++) {
            const openElement = openTimeSplitted[index];
            const closeElement = closeTimeSplitted[index];
            const nowElement = now[index];
            if (nowElement < openElement && nowElement > closeElement) return true;
        }
        return false;
    };

    const headAction: CommonAction<ExchangeOrderItem> = {
        id: 'create_exchange',
        type: 'modal',
        Icon: () => <CustomIconCreator icon={AddIcon} sx={{ ...ICON_CELL_SIZE, mt: 0.7 }} />,
        onClick: props => <ExchangeForm {...props} />,
        isHidden: () =>
            exchangeTimes
                ? isMarketClosed(exchangeTimes?.t0OpenTime, exchangeTimes?.t0CloseTime)
                : false,
    };

    const childProps = {
        ...props,
        t,
        columns,
        filters,
        actions,
        openCloseModal,
        setOpenCloseModal,
        exchangeTimes,
        isMarketClosed,
        headAction,
        defaultFilters,
    };

    return <MarketExchangePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExchangePageContainer.propTypes = propTypes;

export default MarketExchangePageContainer;
