import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import CommonTable from '../CommonTable';
import { CommonColumn, CommonData, HeaderStyles } from 'types/common/tableLayout.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';

const FundsTable = (props: Props) => {
    const { columns, rows, handleTabsChange, value, headerStyles, formattedDate, onRowClick } =
        props;

    return (
        <section style={{ color: 'black' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabsChange}>
                        <Tab label="Cuotaparte Persona Física" value="1" />
                        <Tab label="Cuotaparte Persona Jurídica" value="2" />
                    </TabList>
                </Box>
                <CommonTable
                    columns={columns}
                    rows={rows}
                    headerStyles={headerStyles}
                    onRowClick={onRowClick}
                />
                <p>Actualizado al {formattedDate}</p>
            </TabContext>
        </section>
    );
};

const propTypes = {};

interface extraProps {
    columns: CommonColumn<any>[];
    rows: CommonData;
    value: string;
    handleTabsChange: (e: React.SyntheticEvent, newValue: string) => void;
    headerStyles: HeaderStyles;
    formattedDate: string;
    onRowClick: (row: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundsTable.propTypes = propTypes;

export default FundsTable;
