import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useValidateCode } from 'hooks/api/auth.hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import ReplacePasswordForm from './ReplacePasswordForm';
import St from './ReplacePasswordForm.styled';
import { useNavigate } from 'react-router-dom';
import { loggedOutSubroutesPaths } from 'router/routesPaths';

const getInitialValues = () => ({
    username: '',
    newPassword: '',
    newPasswordConfirmation: '',
    referralCode: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            username: Yup.string()
                .min(8, String(i18n.t('username_field_min_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .trim(),
            newPassword: Yup.string()
                .test('password-requirements', function (value) {
                    const errors: string[] = [];
                    if (!value || value.length < 8) {
                        errors.push('minimum_letters');
                    }
                    if (!/[A-Z]/.test(value || '')) {
                        errors.push('one_capital_letter');
                    }
                    if (!/[a-z]/.test(value || '')) {
                        errors.push('one_lowercase_letter');
                    }
                    if (!/[0-9]/.test(value || '')) {
                        errors.push('one_number');
                    }
                    if (!/[!¡;`'[@#$%^&*(),¿.?":{}|+<>=-]/.test(value || '')) {
                        errors.push('one_special_character');
                    }
                    return errors.length > 0
                        ? this.createError({ message: errors.join(', ') })
                        : true;
                })
                .required(tRequiredFieldError),
            newPasswordConfirmation: Yup.string()
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .required(tRequiredFieldError),
            referralCode: Yup.string(),
        }),
    );

const ReplacePasswordFormContainer = (props: Props) => {
    const { isSigningUp, token } = props;
    const { t } = useTranslation('auth');
    const { error, isLoading: isLoadingValidateCode, mutate: validateCode } = useValidateCode();
    const navigate = useNavigate();

    const handleGoBackLogin = () => {
        navigate(loggedOutSubroutesPaths.login);
    };

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const body = {
                code: token ?? '',
                username: values.username,
                password: values.newPassword,
                ...(isSigningUp && { referral: values.referralCode }),
            };

            const options = !isSigningUp && {
                onSuccess: () => {
                    navigate(loggedOutSubroutesPaths.login, {
                        state: { isPasswordChanged: true },
                    });
                },
            };

            validateCode({ body }, options || {});
        },
        [isSigningUp, navigate, token],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const getPasswordErrorClass = (field: string, errors: any) =>
        errors?.newPassword?.includes(field) ? St.error : '';

    const childProps = {
        t,
        isLoading: isLoadingValidateCode,
        formik,
        errorMessage: error || '',
        isSigningUp,
        getPasswordErrorClass,
        navigate,
        handleGoBackLogin,
    };

    return <ReplacePasswordForm {...childProps} />;
};

const propTypes = {
    isSigningUp: PropTypes.bool,
    token: PropTypes.string,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReplacePasswordFormContainer.propTypes = propTypes;

export default ReplacePasswordFormContainer;
