import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import LearnToInvestCards from './LearnToInvestCards';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mainRoutesPaths, onboardingRoutesPaths } from 'router/routesPaths';
import { useGetBlogs } from 'hooks/api/wrapper.hooks';
import { BlogArticle } from 'types/api/wrapper.types';

const LearnToInvestCardsContainer = (props: Props) => {
    const { isOnboarding = false } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: blogNews, isLoading } = useGetBlogs();
    const handleClickSeeMore = () =>
        navigate(isOnboarding ? onboardingRoutesPaths.blog : mainRoutesPaths.blog);

    const isMobile = window.innerWidth <= 600;
    const groupedData =
        blogNews?.reduce((acc: { [key: string]: BlogArticle[] }, article: BlogArticle) => {
            const categoryName = article.category[0].nombre;
            if (!acc[categoryName]) acc[categoryName] = [];
            acc[categoryName].push(article);
            return acc;
        }, {}) || {};
    const firstNewsByCategory = Object.keys(groupedData).map(
        categoryName => groupedData[categoryName][0],
    );
    if (!isMobile && firstNewsByCategory.length > 0) {
        firstNewsByCategory.push(firstNewsByCategory[0]);
    }
    const groupByThree = (articles: BlogArticle[]) => {
        const grouped: BlogArticle[][] = [];
        for (let i = 0; i < articles.length; i += 3) {
            grouped.push(articles.slice(i, i + 3));
        }
        return grouped;
    };
    const groupedNews = !isMobile ? groupByThree(firstNewsByCategory) : [];

    const childProps = {
        ...props,
        t,
        blogNews: firstNewsByCategory,
        groupedNews,
        isLoading,
        handleClickSeeMore,
    };

    return <LearnToInvestCards {...childProps} />;
};

const propTypes = {
    isOnboarding: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LearnToInvestCardsContainer.propTypes = propTypes;

export default LearnToInvestCardsContainer;
