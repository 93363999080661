import { Box, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    RadioButtonContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        opacity: '0.8',
        borderRadius: '50px',
        padding: '4px 8px',
        cursor: 'pointer',
    })),

    RadioButton: styled('input')(({ theme }) => ({
        margin: '0 12px 0 0',
        cursor: 'pointer',
        appearance: 'none',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: `2px solid ${theme.palette.secondary.main}`,
        outline: 'none',
        transition: 'border-color 0.2s',
        '&:checked': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    })),
    RadioButtonText: styled(
        'span',
        transientOptions,
    )<{ $fontSize?: string }>(({ theme, $fontSize }) => ({
        fontSize: $fontSize ?? theme.typography.body1.fontSize,
        color: theme.palette.text.primary,
    })),
};
