import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        m: '0',
    })) as typeof Box,
};
