import { Box, Divider, styled, Typography } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';
import InformationLine from '../../../../assets/icons/information-line.svg?react';

export default {
    Container: styled(Box)(({ theme }) => theme.unstable_sx({})),
    PerformanceContainer: styled(Box)(({ theme }) => theme.unstable_sx({})),
    WalletSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
            marginBottom: pxToRem('20px'),
        }),
    ),
    AccountInformationSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
        }),
    ),
    AumText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.primary.main,
        }),
    ),
    PerformanceLabelContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            gap: pxToRem('10px'),
            marginBottom: pxToRem('20px'),
        }),
    ),
    RadioButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { sm: '211px', xs: '100%' },
            marginBottom: pxToRem('20px'),
        }),
    ),
    PerformancePercentage: styled(Box)(({ theme, color }) =>
        theme.unstable_sx({
            marginTop: pxToRem('25px'),
            marginLeft: pxToRem('18px'),
            color,
            fontSize: pxToRem('24px'),
        }),
    ),
    ModalTextContainer: styled(Box)(({ theme, color }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            gap: pxToRem('5px'),
            marginBottom: '1rem',
        }),
    ),
    ModalText: styled(Typography)(({ theme, color }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
            textAlign: 'center',
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline',
            },
        }),
    ),
    InfoIcon: styled(InformationLine)(({ theme, color }) =>
        theme.unstable_sx({
            fill: theme.palette.custom.darkblue4,
            width: pxToRem('15px'),
        }),
    ),
    DottedDivider: styled(Divider)(({ theme }) => ({
        borderStyle: 'dotted',
        borderColor: theme.palette.custom.darkblue6,
        marginTop: pxToRem('40px'),
        marginBottom: pxToRem('40px'),
    })),
};
