import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ForgotPasswordPage.styled';
import SendMailForm from './SendMailForm';
import consultatioLogo from 'assets/img/general/consultatioInvestmentsLogo.jpg';
import FooterLayout from 'components/layouts/FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';

const ForgotPasswordPage = (props: Props) => {
    // const {} = props;

    return (
        <>
            <St.LogoContainer>
                <St.Logo src={consultatioLogo} alt="Consultatio Plus" />
            </St.LogoContainer>
            <St.ForgotPasswordContainerBox>
                <SendMailForm />
            </St.ForgotPasswordContainerBox>
            <FooterLayout />
            <HelpTooltip />
        </>
    );
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ForgotPasswordPage.propTypes = propTypes;

export default ForgotPasswordPage;
