import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingContractPage.styled';
import TopHeader from 'components/common/TopHeader';
// import CustomRadioButton from 'components/common/CustomRadioButton';
import CustomLoader from 'components/common/CustomLoader';
import FileDownloader from 'components/common/FileDownloader';
import ErrorText from 'components/common/ErrorText';
import PdfReaderModal from 'components/common/PdfReaderModal';
import CustomRadioButton from 'components/common/CustomRadioButton';
import SignatureCanvas from 'react-signature-canvas';
import ActionButton from 'components/common/ActionButton';
import { pxToRem } from 'utils/helpers/stylesHelper';
import { tCommon } from 'constants/appConstants';
import DownloadContract from './DownloadContract';

const OnboardingContractPage = (props: Props) => {
    const {
        t,
        fileUrl,
        setSign,
        isLoading,
        matchesLg,
        contractPath,
        errorMessage,
        openPdfReader,
        acceptContract,
        isLoadingSubmit,
        setOpenPdfReader,
        setAcceptContract,
        handleSubmit,
        handleClear,
        handleOnEnd,
        enableButton,
        isLoadingContext,
    } = props;
    return (
        <St.SectionWrapper>
            {isLoadingContext ? (
                <CustomLoader />
            ) : (
                <>
                    <TopHeader title={t('contract_page_title')} />
                    {isLoading ? (
                        <CustomLoader />
                    ) : (
                        <St.ContentContainer>
                            <St.AcceptTermsConditionsWrapper>
                                <St.CustomText component="span">
                                    {t('accept_the')}{' '}
                                    <St.CustomLink
                                        component="span"
                                        onClick={() => setOpenPdfReader(true)}>
                                        {t('terms_conditions')}
                                    </St.CustomLink>
                                </St.CustomText>
                                <CustomRadioButton
                                    checked={acceptContract}
                                    name={t('accept_contract')}
                                    onChange={() => setAcceptContract(!acceptContract)}
                                    text={t('accept_contract')}
                                    value={String(acceptContract)}
                                    fontSize="16px"
                                />
                            </St.AcceptTermsConditionsWrapper>
                            <DownloadContract
                                fileUrl={contractPath}
                                label={t('download_contract_label')}
                            />
                            {/* <FileDownloader fileUrl={contractPath} label={t('download_contract_label')} /> */}
                            <St.CustomText>{t('signature_label_text')}</St.CustomText>
                            <St.CanvasContainer>
                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{
                                        width: !matchesLg ? '1035' : window.innerWidth - 160,
                                        height: 315,
                                        // className: 'sigCanvas',
                                    }}
                                    ref={(data: any) => {
                                        setSign(data);
                                    }}
                                    onEnd={() => handleOnEnd()}
                                />
                            </St.CanvasContainer>
                            <St.ButtonWrapper>
                                <ActionButton
                                    sx={{ width: `${pxToRem(330)}!important` }}
                                    variant="outlined"
                                    aria-label="export"
                                    onClick={() => handleClear()}
                                    disabled={isLoading}>
                                    {t('restore', tCommon)}
                                </ActionButton>
                                <ActionButton
                                    sx={{ width: `${pxToRem(330)}!important` }}
                                    type="button"
                                    variant="contained"
                                    onClick={() => handleSubmit()}
                                    loading={isLoadingSubmit}
                                    disabled={!enableButton || isLoadingSubmit}>
                                    {t('sent', tCommon)}
                                </ActionButton>
                            </St.ButtonWrapper>
                        </St.ContentContainer>
                    )}
                    {fileUrl && (
                        <PdfReaderModal
                            disableDownload
                            open={openPdfReader}
                            pdfUrl={fileUrl}
                            handleClose={() => setOpenPdfReader(false)}
                        />
                    )}
                    {errorMessage ? <ErrorText text={errorMessage} /> : null}
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    contractPath: PropTypes.string,
    fileUrl: PropTypes.string,
    isLoadingSubmit: PropTypes.bool.isRequired,
    acceptContract: PropTypes.bool.isRequired,
    setAcceptContract: PropTypes.func.isRequired,
    openPdfReader: PropTypes.bool.isRequired,
    matchesLg: PropTypes.bool.isRequired,
    enableButton: PropTypes.bool.isRequired,
    setOpenPdfReader: PropTypes.func.isRequired,
    setSign: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleOnEnd: PropTypes.func.isRequired,
    isLoadingContext: PropTypes.bool.isRequired,
};

interface extraProps {
    errorMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContractPage.propTypes = propTypes;

export default OnboardingContractPage;
