import { Box, styled, Typography } from '@mui/material';

export default {
    Container: styled(Box)(() => ({})) as typeof Box,
    ExchangeQuotationsContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
    })) as typeof Box,
    Caption: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
    })) as typeof Typography,
    AvailableText: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
        fontWeight: 500,
    })) as typeof Typography,
    ButtonsContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
    })) as typeof Box,
    AvailableContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    })) as typeof Box,
    IsManualContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '15px',
        paddingLeft: '1rem',
    })),
};
