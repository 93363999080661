import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './EnableMfaModal.styled';
import CustomModalAuth from '../CustomModalAuth';

const EnableMfaModal = (props: Props) => {
    const {
        t,
        open,
        close,
        isPreferences,
        errorValidate,
        isLoadingValidate,
        sendCodeEnableMfa,
        functionUseEffectEnable,
    } = props;

    return (
        <CustomModalAuth
            title={isPreferences ? t('auth_modal_title') : t('force_mfa_modal_title')}
            handleClose={close}
            open={open}
            sendEmailCode={functionUseEffectEnable}
            handleSubmit={sendCodeEnableMfa}
            apiError={errorValidate}
            isLoadingSubmit={isLoadingValidate}
        />
    );
};

const propTypes = {
    close: PropTypes.func,
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isPreferences: PropTypes.bool,
    isLoadingValidate: PropTypes.bool.isRequired,
    sendCodeEnableMfa: PropTypes.func.isRequired,
    functionUseEffectEnable: PropTypes.func.isRequired,
};

interface extraProps {
    errorValidate: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EnableMfaModal.propTypes = propTypes;

export default EnableMfaModal;
