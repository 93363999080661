import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingValidatePersonPage from './OnboardingValidatePersonPage';
import { useLocation } from 'react-router-dom';
import { OnboardingContext } from 'context/onboarding.context';

const OnboardingValidatePersonPageContainer = (props: Props) => {
    // const {} = props;
    const { state } = useLocation();
    const { isLoadingLegalData, isLoadingSteps } = React.useContext(OnboardingContext);
    const [showNosisQuestions, setShowNosisQuestions] = React.useState<boolean>(
        state?.showNosisQuestions ?? false,
    );

    const childProps = {
        ...props,
        showNosisQuestions,
        setShowNosisQuestions,
        isLoading: isLoadingLegalData || isLoadingSteps,
    };

    return <OnboardingValidatePersonPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonPageContainer.propTypes = propTypes;

export default OnboardingValidatePersonPageContainer;
