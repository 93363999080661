import React from 'react';
import { OnboardingContext } from 'context/onboarding.context';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContactInfoPage from './OnboardingContactInfoPage';

export const OnboardingContactInfoContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const {
        isLoadingLegalData,
        errorMessageLegalData,
        isLoadingSteps,
        userInformationUploaded,
        isLoadingUserInformation,
    } = React.useContext(OnboardingContext);

    const [phoneNumber, setPhoneNumber] = React.useState<string | null>(
        userInformationUploaded?.contactInfo.phoneNumber ?? null,
    );

    React.useEffect(() => {
        if (userInformationUploaded)
            setPhoneNumber(userInformationUploaded.contactInfo.phoneNumber);
    }, [userInformationUploaded]);

    const childProps = {
        t,
        isLoadingLegalData,
        errorMessageLegalData,
        phoneNumber,
        setPhoneNumber,
        isLoadingSteps: isLoadingSteps || isLoadingUserInformation,
        userInformationUploaded,
    };
    return <OnboardingContactInfoPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoContainer.propTypes = propTypes;

export default OnboardingContactInfoContainer;
