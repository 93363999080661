import {
    Box,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    styled,
} from '@mui/material';
import Radio from '@mui/material/Radio';

export default {
    Grid: styled(Grid)(() => ({
        display: 'grid',
        gridTemplateColumns: 'min-content auto',
        gap: '26px',
    })),
    Box: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
    })),
    Title: styled(Typography)(({ theme }) => ({
        minHeight: '2.938rem',
        alignContent: 'space-evenly',
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '28.13px',
        textAlign: 'left',
        color: theme.palette.custom.darkblue2,
    })),
    List: styled(List)(() => ({
        display: 'grid',
        gap: '16px',
        marginTop: '27px',
        padding: 0,
    })),
    ListItemButton: styled(ListItemButton)(({ theme }) => ({
        border: `1px solid ${theme.palette.custom.vibrantblue7}`,
        padding: '2px 7px',
        borderRadius: '20px',
    })) as typeof ListItemButton,
    ListItemIcon: styled(ListItemIcon)(() => ({ minWidth: 'min-content', paddingRight: '6px' })),
    ListItemText: styled(ListItemText)(({ theme }) => ({
        fontFamily: 'Roboto',
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: '21.09px',
        letterSpacing: '0.0015em',
        textAlign: 'left',
        color: theme.palette.custom.darkblue4,
    })),
    Radio: styled(Radio)(() => ({ width: '1.5rem', height: '1.5rem' })),
};
