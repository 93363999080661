import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        flex: 1,
        margin: '0rem 4rem 0rem 0rem',
        gap: '2rem',
        flexDirection: 'column',
        width: 'calc(100% - 4rem)',
        display: 'flex',
    })) as typeof Box,
};
