import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    DniPhotoBox: styled(Box)(() => ({})) as typeof Box,

    DniPhotoInternalBox: styled(Box)(({ theme }) => ({})) as typeof Box,

    DniPhotoTitle: styled(Typography)(() => ({
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28.13px',
    })) as typeof Typography,

    DniPhotoSubTitle: styled(Typography)(({ theme }) => ({
        color: '#307FDD',
        fontWeight: 300,
        fontSize: '30px',
        letterSpacing: '-1.5%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '68px',
            lineHeight: '79.69px',
        },
    })) as typeof Typography,

    DniPhotoInputs: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: pxToRem(50),
        [theme.breakpoints.up('sm')]: {
            marginTop: '50px',
        },
        gap: '20px',
    })) as typeof Box,

    DniPhotoText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue2,
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 'none',
            letterSpacing: '0.25%',
            [theme.breakpoints.up('sm')]: {
                fontSize: '34px',
                lineHeight: '39.84px',
            },
        }),
    ) as typeof Typography,

    DniPhotoButtonsBox: styled(Grid)(({ theme }) => ({
        container: 'true',
        alignItems: 'end',
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        marginTop: '20px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '30px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '50px',
        },
    })) as typeof Grid,

    DniPhotoButtonsBoxInnerGrid: styled(Grid)(({ theme }) => ({
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    })) as typeof Grid,

    DniPhotoLoadingButton: styled(LoadingButton)(({ theme }) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '200px',
        },
        [theme.breakpoints.up('md')]: {
            width: '354px',
        },
    })) as typeof LoadingButton,
};
