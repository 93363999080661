import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import QuoteCard from './QuoteCard';
import { MarketValuesUpdated, Quotation } from 'types/api/instruments.types';
import { useTranslation } from 'react-i18next';

const QuoteCardContainer = (props: Props) => {
    const { t } = useTranslation('home');

    const { quote } = props;

    const mappedQuotes: Quotation[] = [
        {
            title: t('quotation_title_dolar_mep_24hs').toString(),
            buy: quote?.hs24?.mep?.buy,
            sell: quote?.hs24?.mep?.sell,
        },
        {
            title: t('quotation_title_dolar_mep_c_i').toString(),
            buy: quote?.ci?.mep?.buy,
            sell: quote?.ci?.mep?.sell,
        },
        {
            title: t('quotation_title_dolar_cable_24hs').toString(),
            buy: quote?.hs24?.cable?.buy,
            sell: quote?.hs24?.cable?.sell,
        },
        {
            title: t('quotation_title_dolar_cable_c_i').toString(),
            buy: quote?.ci?.cable?.buy,
            sell: quote?.ci?.cable?.sell,
        },
    ];

    const childProps = {
        ...props,
        mappedQuotes,
    };

    return <QuoteCard {...childProps} />;
};

const propTypes = {};

interface extraProps {
    quote: MarketValuesUpdated;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuoteCardContainer.propTypes = propTypes;

export default QuoteCardContainer;
