import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DDJJModal from './DDJJModal';
import { useTranslation } from 'react-i18next';
import { useApproveDeclaration } from 'hooks/api/declarations.hooks';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { useCommonGrid } from 'context/common/commonGrid.context';
import { AuthContext } from 'context/auth.context';

const DDJJModalContainer = (props: Props) => {
    const { close } = props;
    const { t } = useTranslation('home');
    const { matchesXl } = useBreakpoints();
    const [showDJ, setShowDJ] = React.useState<number | null>(null);
    const [checked, setChecked] = React.useState<Record<string, boolean>>({});
    const { setNeedRefresh } = useCommonGrid();
    const { declarations, getUpdatedDeclarations } = React.useContext(AuthContext);

    const { mutate, isLoading } = useApproveDeclaration();

    const isSubmitEnabled = React.useMemo(
        () => Object.values(checked).some(value => value === true),
        [checked],
    );

    const isDDJJOpen = React.useMemo(() => showDJ !== null, [showDJ]);

    const toggleShowDJ = (id: number) => {
        setShowDJ(prevId => (prevId === id ? null : id));
    };

    const handleChangeCheck = (id: number) => {
        setChecked(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const onSubmit = () => {
        const result = Object.entries(checked).reduce((acc: string[], [key, value]) => {
            if (value === true) {
                declarations?.find(dj => dj.id.toString() === key && acc.push(key));
            }
            return acc;
        }, []);

        result.forEach(id => mutate({ id }));

        getUpdatedDeclarations();
        setNeedRefresh(true);

        setTimeout(() => {
            close();
        }, 300);
    };

    const childProps = {
        ...props,
        t,
        onSubmit,
        title: t(`ddjj_modal_title_name`),
        showDJ,
        checked,
        handleChangeCheck,
        toggleShowDJ,
        matchesXl,
        isSubmitEnabled,
        isDDJJOpen,
        isLoading,
        declarations,
    };

    return <DDJJModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isOpen: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DDJJModalContainer.propTypes = propTypes;

export default DDJJModalContainer;
