import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RecommendedWallet from './RecommendedWallet';
import { useTranslation } from 'react-i18next';
import { useGetRecommendedWallet } from 'hooks/api/auth.hooks';

const RecommendedWalletContainer = (props: Props) => {
    // const { } = props;
    const { t } = useTranslation('home');
    const { data, isLoading } = useGetRecommendedWallet();
    const childProps = {
        ...props,
        t,
        values: data,
        isLoading,
    };

    return <RecommendedWallet {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RecommendedWalletContainer.propTypes = propTypes;

export default RecommendedWalletContainer;
