import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CMSCategory, CMSRedirects } from 'types/api/cms.types';
import CommercialLayout from 'components/layouts/CommercialLayout';

const CommercialDetailPage = (props: Props) => {
    const { isLoading, categories, name, redirects } = props;
    return (
        <CommercialLayout
            withGoBackButton
            isLoading={isLoading}
            title={name ?? ''}
            categories={categories}
            redirects={redirects}
        />
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    name?: string;
    categories?: CMSCategory[];
    redirects?: CMSRedirects[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialDetailPage.propTypes = propTypes;

export default CommercialDetailPage;
