import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useGetOrdersByCustomerCode } from 'hooks/api/orders.hooks';
import TableLayout from 'components/layouts/TableLayout';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetMarketOrderItem } from 'types/api/orders.types';

const OperationsReferralOrdersPage = ({ columns, filters, actions, t }: Props) => (
    <TableLayout
        title={t('layout_main_navigation_operations_orders')}
        fetchData={useGetOrdersByCustomerCode}
        columns={columns}
        filters={filters}
        actions={actions}
        defaultSortFieldId="createdAt"
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<GetMarketOrderItem>[];
    filters: CommonFilter[];
    actions: CommonAction<GetMarketOrderItem>[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsReferralOrdersPage.propTypes = propTypes;
OperationsReferralOrdersPage;

export default OperationsReferralOrdersPage;
