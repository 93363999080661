import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ButtonLink from './ButtonLink';
import { useNavigate } from 'react-router-dom';

const ButtonLinkContainer = (props: Props) => {
    const { path, disabled } = props;
    const navigate = useNavigate();

    const childProps = {
        ...props,
        onClick: () => !disabled && navigate(path ?? '/'),
    };

    return <ButtonLink {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    moreClasses: PropTypes.string,
    disabled: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ButtonLinkContainer.propTypes = propTypes;

export default ButtonLinkContainer;
