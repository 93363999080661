import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomCarouselItems from '../CustomCarouselItems/CustomCarouselItems';
import { BlogArticle } from 'types/api/wrapper.types';

const CustomCarouselItemsContainer = (props: Props) => {
    const { article } = props;

    const childProps = { ...props, article };

    return <CustomCarouselItems {...childProps} />;
};

const propTypes = {};

interface extraProps {
    article: BlogArticle;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCarouselItemsContainer.propTypes = propTypes;

export default CustomCarouselItemsContainer;
