import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SubMenu from './SubMenu';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import { redirect } from 'utils/helpers/commonHelper';
import { MarketContext } from 'context/market.context';

const SubMenuContainer = (props: Props) => {
    const { menu, open, link, width, isProfileSubMenu, setOpenProfile } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const { showLockedModal } = React.useContext(MarketContext);
    const prevUrl = window.location.pathname;
    const sectionedUrl = prevUrl.split('/');
    const lastUrlExists = sectionedUrl[sectionedUrl.length - 1].length > 2;

    const [listButtonSelected, setListButtonSelected] = React.useState('');
    const navigate = useNavigate();

    const handleNavigate = ({ path = '' }: SubRouteInfo) => {
        if (showLockedModal(path)) return;
        setListButtonSelected(path?.length > 2 ? `${path}` : '');

        if (menu.path === path) return navigate(path);
        if (sectionedUrl.includes(path) && sectionedUrl.length < 3) return;
        if (!link) navigate(`${path}`);
        else redirect(`${path}`);
    };

    React.useEffect(() => {
        setListButtonSelected(
            lastUrlExists
                ? sectionedUrl[sectionedUrl.length - 1]
                : sectionedUrl[sectionedUrl.length - 2],
        );
    }, [prevUrl]);

    const childProps = {
        ...props,
        handleNavigate,
        listButtonSelected,
        drawerOpened: open,
        menu,
        t,
        navigate,
        isProfileSubMenu,
        width,
        setOpenProfile,
        theme,
    };

    return <SubMenu {...childProps} />;
};

const propTypes = {};

interface extraProps {
    menu: SubRouteInfo | any;
    open: boolean;
    close?: () => void;
    link?: boolean;
    backgroundColor?: string;
    width?: string;
    isProfileSubMenu?: boolean;
    setOpenProfile?: (open: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SubMenuContainer.propTypes = propTypes;

export default SubMenuContainer;
