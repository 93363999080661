import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BankAccountForm from './BankAccountForm';
import { CreateBankAccountRequest, GetBankAccountsItem } from 'types/api/cashflow.types';
import { tRequiredFieldError } from 'constants/appConstants';
import { TFunction } from 'i18next';
import {
    useCreateBankAccount,
    useUpdateBankAccount,
    useVerifyCbvu,
} from 'hooks/api/cahsflow.hooks';
import { AuthContext } from 'context/auth.context';
import { useContext } from 'react';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { AlertColor } from '@mui/material';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = (selectedAccount: any, isEdit?: boolean) => ({
    currency: isEdit && selectedAccount ? selectedAccount.currency || '' : '',
    type: isEdit && selectedAccount ? selectedAccount.type || '' : '',
    accountNumber: isEdit && selectedAccount ? selectedAccount.accountNumber || '' : '',
    description: isEdit && selectedAccount ? selectedAccount.description || '' : '',
    bankName: isEdit && selectedAccount ? selectedAccount.bankName || '' : '',
});
const getValidationSchema = (t: TFunction, invalidCbu: boolean) =>
    Yup.lazy(() =>
        Yup.object().shape({
            currency: Yup.string().required(tRequiredFieldError),
            type: Yup.string().required(tRequiredFieldError),
            accountNumber: Yup.string()
                .required(tRequiredFieldError)
                .matches(/^[0-9]{22}$/, t('bank_account_error_account_number_length') as string)
                .test(
                    'accountNumber',
                    t('bank_account_error_account_number_invalid') as string,
                    () => !invalidCbu,
                ),
            description: Yup.string().required(tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//
const BankAccountFormContainer = (props: Props) => {
    const { close, selectedAccount, isEdit, setSnackBarMessage } = props;
    const { t } = useTranslation('profile');
    const [invalidCbu, setInvalidCbu] = React.useState(false);
    const { mutate, isLoading: isLoadingCbu } = useVerifyCbvu();
    const { selectedCustomer } = useContext(AuthContext);

    const { createBankAccount, createIsLoading } = useCreateBankAccount(setSnackBarMessage, close);
    const { updateBankAccount, updateIsLoading } = useUpdateBankAccount(setSnackBarMessage, close);

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        const body = values;

        if (selectedCustomer?.uuid) body.operableUserUuid = selectedCustomer.uuid;

        isEdit && selectedAccount
            ? updateBankAccount({
                  id: selectedAccount.id,
                  body,
              })
            : createBankAccount(body as CreateBankAccountRequest);
    }, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(selectedAccount, isEdit),
                validateOnChange: false,
                validateOnBlur: true,
                validateOnMount: false,
                validationSchema: getValidationSchema(t, invalidCbu),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, selectedAccount, isEdit, invalidCbu],
    );

    const formik = useFormik(formikInitProps);
    const currencyOptions = [
        { id: 'ARS', name: t('bank_account_currency_ars') },
        { id: 'USD', name: t('bank_account_currency_usd') },
    ];
    const typeOptions = [
        { id: 'SAVINGS', name: t('bank_account_type_savings') },
        { id: 'CHECKING', name: t('bank_account_type_checking') },
    ];

    const accountNumberChange = (e: string) => {
        formik.setFieldValue('accountNumber', e);
        if (e.length === 22) {
            mutate(
                { cbvu: e },
                {
                    onSuccess: ({ data }) => {
                        formik.setFieldValue('bankName', data.bankName);
                        formik.setErrors({ ...formik.errors, accountNumber: undefined });
                        setInvalidCbu(false);
                    },
                    onError: () => {
                        formik.setErrors({
                            ...formik.errors,
                            accountNumber: t('bank_account_error_account_number_invalid') as string,
                        });
                        setInvalidCbu(true);
                    },
                },
            );
        } else {
            formik.setFieldValue('bankName', '');
            formik.setErrors({
                ...formik.errors,
                accountNumber: t('bank_account_error_account_number_length') as string,
            });
        }
        if (e.length === 0) {
            formik.setErrors({
                ...formik.errors,
                accountNumber: tRequiredFieldError,
            });
        }
    };

    const childProps = {
        ...props,
        t,
        formik,
        currencyOptions,
        typeOptions,
        accountNumberChange,
        isLoading: updateIsLoading || createIsLoading,
        disableCbuField: isLoadingCbu,
    };

    return <BankAccountForm {...childProps} />;
};
const propTypes = {};
interface extraProps {
    selectedAccount?: GetBankAccountsItem | null;
    isEdit?: boolean;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountFormContainer.propTypes = propTypes;
export default BankAccountFormContainer;
