import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ButtonLink.styled';

const ButtonLink = (props: Props) => {
    const { title, onClick, moreClasses } = props;

    return (
        <St.Wrapper onClick={onClick} className={`page-link ${moreClasses}`}>
            <St.Text>{title}</St.Text>
        </St.Wrapper>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
    moreClasses: PropTypes.string,
};

interface extraProps {
    onClick: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ButtonLink.propTypes = propTypes;
ButtonLink;

export default ButtonLink;
