import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ExchangeDetail from './ExchangeDetail';
import { useTranslation } from 'react-i18next';
import { ExchangeOrderItem } from 'types/api/orders.types';

const ExchangeDetailContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <ExchangeDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: ExchangeOrderItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeDetailContainer.propTypes = propTypes;

export default ExchangeDetailContainer;
