import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './LearnToInvestCards.styled';
import { BlogArticle } from 'types/api/wrapper.types';
import CustomCarousel from '../CustomCarousel';
import { Box } from '@mui/material';
import CircularSpinner from '../CircularSpinner';
import CustomCarouselItems from '../CustomCarouselItems';

const LearnToInvestCards = (props: Props) => {
    const { t, handleClickSeeMore, blogNews, groupedNews, isLoading } = props;

    return (
        <div>
            <St.SecondSectionWrapper>
                <St.SecondTitle>{t('learn_to_invest_title')}</St.SecondTitle>
                <St.ActionButton onClick={handleClickSeeMore}>{t('btn_see_more')}</St.ActionButton>
            </St.SecondSectionWrapper>
            {isLoading ? (
                <Box sx={{ marginTop: '10px' }}>
                    <CircularSpinner />
                </Box>
            ) : (
                <>
                    <CustomCarousel width="100%" withDots={true}>
                        {groupedNews.length > 0
                            ? groupedNews.map((group, groupIndex) => (
                                  <St.CardWrapper key={groupIndex}>
                                      {group.map((article, index) => (
                                          <CustomCarouselItems key={index} article={article} />
                                      ))}
                                  </St.CardWrapper>
                              ))
                            : blogNews?.map((article, index) => (
                                  <St.CardWrapper key={index}>
                                      <CustomCarouselItems key={index} article={article} />
                                  </St.CardWrapper>
                              ))}
                    </CustomCarousel>
                </>
            )}
        </div>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    blogNews?: Array<BlogArticle>;
    handleClickSeeMore: () => void;
    groupedNews: Array<Array<BlogArticle>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LearnToInvestCards.propTypes = propTypes;

export default LearnToInvestCards;
