import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialDocumentationPage from './CommercialDocumentationPage';
import { useTranslation } from 'react-i18next';
import { useGetCommercialByType } from 'hooks/api/cms.hooks';

const CommercialDocumentationPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { data, isLoading } = useGetCommercialByType('Documentación');

    const childProps = {
        ...props,
        t,
        isLoading,
        categories: data?.subcategories,
        redirects: data?.redirectsForCategory,
    };

    return <CommercialDocumentationPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialDocumentationPageContainer.propTypes = propTypes;

export default CommercialDocumentationPageContainer;
