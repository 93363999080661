import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomAutoComplete from './CustomAutoComplete';

const CustomAutoCompleteContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CustomAutoComplete {...childProps} />;
};

const propTypes = {};
interface extraProps {
    multiple?: boolean;
    isLoading?: boolean;
    disableClearable?: boolean;
    id: string;
    value: any;
    options: any[];
    filterSelectedOptions?: boolean;
    onChange: (e: any, value: any) => void;
    onInputChange?: (e: any, value: any) => void;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: string;
    renderOption: (props: any, option: any, { selected }: any) => React.ReactNode;
    disabled?: boolean;
    getOptionLabel?: (option: any) => string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomAutoCompleteContainer.propTypes = propTypes;

export default CustomAutoCompleteContainer;
