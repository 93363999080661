import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelLayout.styled';
import ActionButton from 'components/common/ActionButton';
import { Skeleton, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';
import SearchClientModal from './SearchClientModal';
import { MarketAccount } from 'types/api/marketAccount.types';

const ChannelLayout = (props: Props) => {
    const {
        t,
        isMobile,
        userFullName,
        customerCode,
        handleGoToWallet,
        isLoadingMarketAccounts,
        isOpen,
        open,
        close,
        account,
    } = props;

    return (
        <St.Container data-testid="ChannelLayout-layout" $isMobile={isMobile}>
            {!isMobile && <St.TitleInfo>{userFullName}</St.TitleInfo>}
            <St.CustomerSelectWrapper>
                {isLoadingMarketAccounts ? (
                    <Skeleton height={'40px'} />
                ) : (
                    <St.CustomerLabel variant="body1" width={'80%'}>
                        {`${account?.name} ${account?.last_name} - N° ${account?.customerCode}`}
                    </St.CustomerLabel>
                )}
            </St.CustomerSelectWrapper>
            <St.ButtonContainer>
                {isMobile && <St.TitleInfo>{userFullName}</St.TitleInfo>}
                <ActionButton
                    onClick={open}
                    variant="contained"
                    sx={{ marginTop: 0, width: pxToRem(200) }}>
                    <Typography marginRight={'0.5rem'}>
                        {t('change_client').toUpperCase()}
                    </Typography>
                </ActionButton>
                <ActionButton
                    onClick={handleGoToWallet}
                    variant="contained"
                    disabled={!customerCode}
                    sx={{ marginTop: 0, width: pxToRem(200) }}>
                    <Typography marginRight={'0.5rem'}>
                        {t('go_to_wallet_button').toUpperCase()}
                    </Typography>
                </ActionButton>
            </St.ButtonContainer>
            <SearchClientModal isOpen={isOpen} close={close} />
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    userFullName: PropTypes.string.isRequired,
    handleGoToWallet: PropTypes.func.isRequired,
    isLoadingMarketAccounts: PropTypes.bool.isRequired,
};

interface extraProps {
    customerCode: string | null;
    isOpen: boolean;
    open(): void;
    close(): void;
    account: MarketAccount | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelLayout.propTypes = propTypes;

export default ChannelLayout;
