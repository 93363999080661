import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TopHeader from 'components/common/TopHeader';
import PropTypes from 'prop-types';
import CustomLoader from 'components/common/CustomLoader';
import RejectionModal from './RejectionModal';
import { BlogNews } from 'types/api/onboarding.types';
import { Step } from 'types/pages/onboardingHome.types';
import LearnToInvestCards from 'components/common/LearnToInvestCards';
import St from './OnboardingHomePage.styled';

const OnboardingHomePage = (props: Props) => {
    const {
        t,
        steps,
        pageLoad,
        isOpenModal,
        setIsOpenModal,
        rejectSteps,
        thereAreRejectedSteps,
        allStepsApproved,
        fullname,
    } = props;

    return (
        <St.SectionWrapper component={'section'} data-testid="OnboardingHomePage-page">
            {pageLoad ? (
                <CustomLoader />
            ) : (
                <>
                    <TopHeader
                        firstElement={t('home_first_text', { fullname }) as string}
                        title={t(
                            `home_title${
                                thereAreRejectedSteps || allStepsApproved
                                    ? `_with_${
                                          thereAreRejectedSteps ? 'rejected' : 'approved'
                                      }_steps`
                                    : ''
                            }`,
                        )}
                        subtitle={
                            thereAreRejectedSteps
                                ? undefined
                                : (t(
                                      `home_subtitle${
                                          allStepsApproved ? '_with_approved_steps' : ''
                                      }`,
                                  ) as string)
                        }
                    />
                    {rejectSteps && rejectSteps.length > 0 && (
                        <St.RejectionMessageWrapper>
                            <St.TriangleWithExclamationMarkIcon />
                            <St.RejectionMessage>{t('rejection_message')}</St.RejectionMessage>
                            <St.ActionButton onClick={() => setIsOpenModal(true)}>
                                {t('btn_rejection')}
                            </St.ActionButton>
                        </St.RejectionMessageWrapper>
                    )}
                    <St.StepsWrapper>
                        {steps?.map(el => (
                            <St.ButtonLink
                                key={el.stepNumber}
                                title={el.stepName}
                                path={el.path}
                                moreClasses={
                                    el.disabled
                                        ? 'disabled'
                                        : `${el.status} ${el.status === 'completed' ? 'disableCursor' : ''}`
                                }
                                disabled={el.disabled || el.status === 'completed'}
                            />
                        ))}
                    </St.StepsWrapper>
                    <LearnToInvestCards isOnboarding />
                </>
            )}
            <RejectionModal {...{ isOpenModal, setIsOpenModal, rejectSteps }} />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    pageLoad: boolean;
    blogNews?: Array<BlogNews>;
    steps?: Array<Step> | null;
    isOpenModal: boolean;
    setIsOpenModal: (x: boolean) => void;
    rejectSteps?: Array<Step>;
    thereAreRejectedSteps: boolean;
    allStepsApproved: boolean;
    fullname: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingHomePage.propTypes = propTypes;

export default OnboardingHomePage;
