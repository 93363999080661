import { Box, styled } from '@mui/material';

export default {
    PDFReaderContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            minWidth: { xs: '100%', sm: '450px', md: '550px' },
            background: '#e4e4e4',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: { lg: '87%', md: '800px', sm: '800px', xs: '800px' },
            width: { lg: '80%', md: '100%', sm: '100%', xs: '100%' },
            marginLeft: { lg: '10%' },
            marginTop: { lg: '2%' },
            borderRadius: { lg: '4px' },
        }),
    ),
};
