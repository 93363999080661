import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AvailableBalanceDetailModal.styled';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { AvailableBalance } from 'types/api/marketAccount.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import { pxToRem } from 'utils/helpers/stylesHelper';

const AvailableBalanceDetailModal = (props: Props) => {
    const { open, title, close, t, availableBalance } = props;

    return (
        <Dialog open={open} onClose={close} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <St.Subtitle>{t('available_to_operate')}</St.Subtitle>
                <TableContainer component={Paper} sx={{ marginBottom: pxToRem(20) }}>
                    <Table aria-label="steps">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t('ars')}</TableCell>
                                <TableCell>{t('usd')}</TableCell>
                                <TableCell>{t('usdc')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('today')}</TableCell>
                                <TableCell>
                                    ${formatNumber(availableBalance?.operate.t0.ars ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.operate.t0.usd ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.operate.t0.usdc ?? 0)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('24hs')}</TableCell>
                                <TableCell>
                                    ${formatNumber(availableBalance?.operate.t1.ars ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.operate.t1.usd ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.operate.t1.usdc ?? 0)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <St.Subtitle>{t('available_to_withdraw')}</St.Subtitle>
                <TableContainer component={Paper}>
                    <Table aria-label="steps">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t('ars')}</TableCell>
                                <TableCell>{t('usd')}</TableCell>
                                <TableCell>{t('usdc')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('today')}</TableCell>
                                <TableCell>
                                    ${formatNumber(availableBalance?.withdraw.ars ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.withdraw.usd ?? 0)}
                                </TableCell>
                                <TableCell>
                                    US${formatNumber(availableBalance?.withdraw.usdc ?? 0)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', marginBottom: '10px' }}>
                <ActionButton variant="contained" onClick={close}>
                    {t('back', tCommon)}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    title: string;
    availableBalance?: AvailableBalance;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AvailableBalanceDetailModal.propTypes = propTypes;

export default AvailableBalanceDetailModal;
