import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ExchangeForm.styled';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import {
    exchangeCurrencyStartEndOptions,
    exchangeTerms,
    tCommon,
    tErrorsContext,
} from 'constants/appConstants';
import { Box, Stack, Theme } from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';
import CustomLoader from 'components/common/CustomLoader';
import ErrorText from 'components/common/ErrorText';
import { CommonSelectType, Currency } from 'types/common/general.types';
import ExchangeQuotation from './ExchangeQuotation';
import { AmountType, QuotationTickerPrices } from 'types/pages/marketExchangePage.types';
import { InstrumentObj } from 'types/common/instrument.types';
import CurrencyInput from 'components/common/CurrencyInput';
import ActionButton from 'components/common/ActionButton';
import { MultiCurrencyObj } from 'types/api/marketAccount.types';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';
import CustomRadioButton from 'components/common/CustomRadioButton';

const ExchangeForm = (props: Props) => {
    const {
        t,
        term,
        theme,
        close,
        amount,
        setTerm,
        isManual,
        setAmount,
        isLoading,
        instrument,
        amountType,
        instruments,
        isQualified,
        maxOperable,
        setIsManual,
        handleSubmit,
        errorMessage,
        tickerPrices,
        setInstrument,
        disableOperate,
        isLoadingSubmit,
        currencyStartEnd,
        errorMessageSubmit,
        availableToOperate,
        hasExteriorAccount,
        setCurrencyStartEnd,
        isLoadingMaxOperable,
        errorMessagePreviewOperable,
        currencyStart,
    } = props;
    return (
        <St.Container>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <Stack spacing={2}>
                    <St.AvailableContainer>
                        <Box width={'50%'}>
                            <InfoLabel
                                fontSize="15px"
                                color={theme.palette.custom.darkblue4}
                                label={t('is_qualified', tCommon)}
                                text={isQualified ? t('yes', tCommon) : 'No'}
                            />
                        </Box>
                        <Box width={'50%'} textAlign={'right'}>
                            <St.AvailableText variant="body2" fontWeight={'700!important'}>
                                {t('exchange_available_text')}
                            </St.AvailableText>
                            <St.AvailableText variant="body2">
                                {t('exchange_form_available_usd', {
                                    available: `US$${formatNumber(availableToOperate?.usd ? availableToOperate?.usd : 0)}`,
                                })}
                            </St.AvailableText>
                            <St.AvailableText variant="body2">
                                {t('exchange_form_available_usdc', {
                                    available: `US$${formatNumber(
                                        availableToOperate?.usdc ? availableToOperate?.usdc : 0,
                                    )}`,
                                })}
                            </St.AvailableText>
                        </Box>
                    </St.AvailableContainer>
                    <CustomAutoComplete
                        id="currency_autocomplete"
                        value={currencyStartEnd}
                        onChange={(e, value) => setCurrencyStartEnd(value)}
                        options={exchangeCurrencyStartEndOptions ?? []}
                        label={t('order_type_column_text') as string}
                        placeholder={t('order_type_text_placeholder') as string}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id} sx={{ width: 'auto' }}>
                                {option.name}
                            </Box>
                        )}
                    />
                    {term?.name === 'MEP - CABLE' && !hasExteriorAccount && (
                        <ErrorText
                            sx={{ marginBottom: '0.5rem' }}
                            text={t('error_has_exterior_account', tErrorsContext)}
                        />
                    )}
                    {currencyStartEnd && (
                        <>
                            <St.ExchangeQuotationsContainer>
                                {tickerPrices?.map((q, index) => (
                                    <ExchangeQuotation
                                        key={index}
                                        quotationPrices={q}
                                        currencyStartEnd={currencyStartEnd}
                                    />
                                ))}
                            </St.ExchangeQuotationsContainer>
                            <St.Caption variant="caption">{t('quotation_disclaimer')}</St.Caption>

                            <CustomAutoComplete
                                id="instrument"
                                value={instrument}
                                onChange={(e, value) => setInstrument(value)}
                                options={instruments ?? []}
                                label={t('instrument_label') as string}
                                placeholder={t('instrument_placeholder') as string}
                                filterSelectedOptions
                                disabled={disableOperate}
                                isLoading={isLoading}
                                renderOption={(props, option) => (
                                    <Box {...props} key={option.id}>
                                        {option.name}
                                    </Box>
                                )}
                            />
                            {instrument && (
                                <>
                                    {isLoadingMaxOperable ? (
                                        <CustomLoader />
                                    ) : (
                                        <>
                                            <Box sx={{ width: '100%' }}>
                                                <CurrencyInput
                                                    value={amount}
                                                    handleChange={value => setAmount(value)}
                                                    currency="USD"
                                                    decimalScale={2}
                                                    disabled={disableOperate}
                                                    label={t('amount', tCommon)}
                                                    sx={{ width: '100%' }}
                                                />
                                                {amountType && (
                                                    <St.Caption variant="caption">
                                                        {t(
                                                            `exchange_form_amount_caption_${amountType?.toLowerCase()}`,
                                                        )}
                                                    </St.Caption>
                                                )}
                                            </Box>
                                            <Box>
                                                <St.AvailableText variant="body2">
                                                    {t('exchange_form_available_operate', {
                                                        available: convertCurrencyFormat(
                                                            maxOperable && maxOperable > 0
                                                                ? maxOperable
                                                                : 0,
                                                            currencyStart ?? 'USD',
                                                        ),
                                                    })}
                                                </St.AvailableText>
                                                {amount > (maxOperable || 0) && (
                                                    <ErrorText
                                                        text={t(
                                                            'not_avaialable_to_operate_error',
                                                            tErrorsContext,
                                                        )}
                                                    />
                                                )}
                                                {errorMessagePreviewOperable && (
                                                    <ErrorText
                                                        sx={{ marginBottom: '0.5rem' }}
                                                        text={errorMessagePreviewOperable}
                                                    />
                                                )}
                                            </Box>
                                            <Box>
                                                <St.AvailableText variant="body2">
                                                    {t('exchange_form_available_ars', {
                                                        available: availableToOperate?.ars
                                                            ? formatNumber(availableToOperate?.ars)
                                                            : '-',
                                                    })}
                                                </St.AvailableText>
                                            </Box>

                                            <CustomAutoComplete
                                                id="term"
                                                value={term}
                                                disabled={
                                                    disableOperate || amountType !== 'HIGH_AMOUNT'
                                                }
                                                onChange={(e, value) => setTerm(value)}
                                                options={exchangeTerms ?? []}
                                                label={
                                                    t('instrument_term', { ns: 'home' }) as string
                                                }
                                                placeholder={
                                                    t('term_placeholder', { ns: 'home' }) as string
                                                }
                                                renderOption={(props, option) => (
                                                    <Box {...props} key={option.id}>
                                                        {option.name}
                                                    </Box>
                                                )}
                                            />
                                            {amountType === 'HIGH_AMOUNT' && (
                                                <St.IsManualContainer>
                                                    <CustomRadioButton
                                                        checked={!isManual}
                                                        name={t('is_direct_text')}
                                                        onChange={() => setIsManual(true)}
                                                        text={t('is_direct_text')}
                                                        value={String(!isManual)}
                                                    />
                                                    <CustomRadioButton
                                                        checked={isManual}
                                                        name={t('is_manual_text')}
                                                        onChange={() => setIsManual(false)}
                                                        text={t('is_manual_text')}
                                                        value={String(isManual)}
                                                    />
                                                </St.IsManualContainer>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Stack>
            )}
            {disableOperate && (
                <ErrorText text={t('not_avaialable_to_operate_error', tErrorsContext)} />
            )}
            {errorMessage && <ErrorText text={errorMessage} />}
            {errorMessageSubmit && <ErrorText text={errorMessageSubmit} />}
            <St.ButtonsContainer>
                <ActionButton disabled={isLoading} variant="outlined" onClick={() => close()}>
                    {t('cancel', tCommon)}
                </ActionButton>
                <ActionButton
                    disabled={
                        isLoading ||
                        amount <= 0 ||
                        disableOperate ||
                        instrument === null ||
                        currencyStartEnd === null ||
                        amount > (maxOperable || 0)
                    }
                    loading={isLoadingSubmit}
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}>
                    {t('sent', tCommon)}
                </ActionButton>
            </St.ButtonsContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isManual: PropTypes.bool.isRequired,
    amount: PropTypes.number.isRequired,
    setAmount: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsManual: PropTypes.func.isRequired,
    isQualified: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    disableOperate: PropTypes.bool.isRequired,
    hasExteriorAccount: PropTypes.bool.isRequired,
    isLoadingSubmit: PropTypes.bool.isRequired,
    isLoadingMaxOperable: PropTypes.bool.isRequired,
};

interface extraProps {
    theme: Theme;
    close: () => void;
    errorMessage: string | null;
    errorMessageSubmit: string | null;
    errorMessagePreviewOperable: string | null;
    term: CommonSelectType | null;
    instrument: InstrumentObj | null;
    instruments: CommonSelectType[] | null;
    currencyStartEnd: CommonSelectType | null;
    availableToOperate: MultiCurrencyObj | null;
    tickerPrices: QuotationTickerPrices[] | null;
    setTerm: (value: CommonSelectType | null) => void;
    setInstrument: (value: InstrumentObj | null) => void;
    setCurrencyStartEnd: (value: CommonSelectType | null) => void;
    amountType: AmountType;
    maxOperable?: number;
    currencyStart: Currency | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeForm.propTypes = propTypes;

export default ExchangeForm;
