import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CircularOptionSelection.styled';

const CircularOptionSelection = (props: Props) => {
    const { field, questionKey, text, userChoice, handleCheck, markContainer = false } = props;
    const activatedMarkContainer = markContainer && userChoice;

    return (
        <St.ListItemButton
            component="li"
            markContainer={activatedMarkContainer}
            onClick={() => handleCheck(field, questionKey)}>
            <St.ListItemIcon>
                <St.Radio $markContainer={activatedMarkContainer} checked={userChoice} />
            </St.ListItemIcon>
            <St.ListItemText primary={text} $markContainer={activatedMarkContainer} />
        </St.ListItemButton>
    );
};

const propTypes = {};

interface extraProps {
    field: string;
    text: string;
    questionKey: string;
    userChoice: boolean;
    handleCheck: (field: string, questionKey: string) => void;
    markContainer?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CircularOptionSelection.propTypes = propTypes;
CircularOptionSelection;

export default CircularOptionSelection;
