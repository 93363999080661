import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { useGetBankAccounts } from 'hooks/api/cahsflow.hooks';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetBankAccountsItem } from 'types/api/cashflow.types';

const TreasuryBankAccountsPage = ({ t, columns, filters, actions, headAction }: Props) => (
    <TableLayout
        title={t('layout_main_navigation_treasury_register_bank_account')}
        fetchData={useGetBankAccounts}
        columns={columns}
        filters={filters}
        actions={actions}
        createAction={headAction}
        defaultSortFieldId="createdAt"
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<GetBankAccountsItem>[];
    filters: CommonFilter[];
    actions: CommonAction<GetBankAccountsItem>[];
    headAction: CommonAction<GetBankAccountsItem>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TreasuryBankAccountsPage.propTypes = propTypes;

export default TreasuryBankAccountsPage;
