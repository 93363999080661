import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DrawerMenuList from './DrawerMenuList';
import { useTheme } from '@emotion/react';
import { useLogout } from 'hooks/api/auth.hooks';
import { logoutItemData } from 'router/routeList';
import { useTranslation } from 'react-i18next';
import { mainRoutes } from 'router/subRoutes/mainLayout';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const DrawerMenuListContainer = (props: Props) => {
    const { open, menusList } = props;
    const theme = useTheme();
    const { mutate: onLogout } = useLogout();
    const { t } = useTranslation();
    const [currentRoutes, logoutItemDataResolved] = React.useMemo(
        () => [menusList ?? [], logoutItemData()],
        [t],
    );
    const childProps = {
        ...props,
        open,
        theme,
        menusList: currentRoutes,
        onLogout,
        t,
        logoutItemData: logoutItemDataResolved,
    };

    return <DrawerMenuList {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

interface extraProps {
    menusList: SubRouteInfo[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DrawerMenuListContainer.propTypes = propTypes;

export default DrawerMenuListContainer;
