import { Box, IconButton, styled } from '@mui/material';

export default {
    Container: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    })) as typeof Box,
    HTMLContainer: styled(Box)(() => ({
        padding: '1rem',
        position: 'relative',
    })) as typeof Box,
    CloseIconButton: styled(IconButton)(({ theme }) => ({
        color: theme.palette.grey[500],
        position: 'absolute',
        right: 8,
        top: 8,
    })),
};
