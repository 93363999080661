import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelAccountInformation from './ChannelAccountInformation';
import { useTranslation } from 'react-i18next';
import { ChannelAccountAum, ChannelAvailableBalance } from 'types/api/marketAccount.types';

const ChannelAccountInformationContainer = (props: Props) => {
    const { aum } = props;
    const { t } = useTranslation('home');
    const [visualizeUSD, setVisualizeUSD] = React.useState<boolean>(false);
    const aumCurrency = React.useMemo(() => {
        if (!aum) return 0;
        return visualizeUSD ? aum.usd : aum.ars;
    }, [aum, visualizeUSD]);

    const childProps = {
        ...props,
        t,
        visualizeUSD,
        setVisualizeUSD,
        aumCurrency,
    };

    return <ChannelAccountInformation {...childProps} />;
};

const propTypes = {};

interface extraProps {
    aum?: ChannelAccountAum;
    availableBalance?: ChannelAvailableBalance;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelAccountInformationContainer.propTypes = propTypes;

export default ChannelAccountInformationContainer;
