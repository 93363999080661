import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Tprop } from 'types/common/general.types';
import { MarketTableFilters } from 'types/context/prices.context.types';
import MarketSearchBar from '../MarketSearchBar';
import Dropdown from '../Dropdown';
import St from './HeaderFilters.styled';
import { TagType } from 'types/api/instruments.types';

const HeaderFilters = (props: Props) => {
    const {
        t,
        listOfCurrencies,
        filters,
        filteredByTypeOptions,
        liquidityList,
        handleChangeState,
        table,
        classList,
        enableFiltersForClassAndPerson,
        isPreferred,
    } = props;
    return (
        <St.Wrapper>
            <Dropdown
                options={filteredByTypeOptions}
                onSelect={value => handleChangeState('filteredByType', value)}
                selectedValue={filters?.filteredByType || null}
                label={t('type_filter')}
                disableClearable
                minWidth="132px"
            />
            <St.RightFiltersWrapper
                $maxW={table === 'funds' && enableFiltersForClassAndPerson ? '960px' : '800px'}>
                <St.FirstWrapper>
                    {filters.typeInstrument !== 'EQUITY' && (
                        <Dropdown
                            options={listOfCurrencies}
                            onSelect={value => handleChangeState('currency', value)}
                            selectedValue={filters?.currency ?? null}
                            label={t('currency_filter')}
                            disableClearable
                            minWidth="48px"
                        />
                    )}
                    {table !== 'funds' ? (
                        <>
                            <St.CustomRadioButton
                                checked={filters.state24OrCI === '24hs'}
                                name={t('filter_24hs')}
                                onChange={() => handleChangeState('state24OrCI', '24hs')}
                                text={t('filter_24hs')}
                                value={filters.state24OrCI}
                            />
                            <St.CustomRadioButton
                                checked={filters.state24OrCI === 'CI'}
                                name={t('filter_CI')}
                                onChange={() => handleChangeState('state24OrCI', 'CI')}
                                text={t('filter_CI')}
                                value={filters.state24OrCI}
                            />
                        </>
                    ) : (
                        <>
                            {enableFiltersForClassAndPerson && (
                                <>
                                    <Dropdown
                                        options={classList}
                                        onSelect={value => handleChangeState('classOfFunds', value)}
                                        selectedValue={filters?.classOfFunds ?? null}
                                        label={t('class_filter')}
                                        disableClearable
                                        minWidth="55px"
                                    />
                                </>
                            )}
                            <Dropdown
                                options={liquidityList}
                                onSelect={value => handleChangeState('liquidity', value)}
                                selectedValue={filters?.liquidity ?? null}
                                label={t('liquidity_filter')}
                                disableClearable
                                minWidth="72px"
                            />
                        </>
                    )}
                </St.FirstWrapper>
                <St.SecondWrapper>
                    <MarketSearchBar
                        placeholder={t(
                            filters.typeInstrument !== 'FUND'
                                ? 'search_by_species'
                                : 'search_by_name',
                        )}
                        onSearch={query =>
                            handleChangeState(
                                filters.typeInstrument !== 'FUND' ? 'searchByTicker' : 'name',
                                query,
                            )
                        }
                        defaultValue={filters.searchByTicker}
                    />
                    {!isPreferred && (
                        <St.CustomRadioButton
                            checked={filters.onlyFavorites}
                            name={t('show_only_favorites')}
                            onChange={() =>
                                handleChangeState('onlyFavorites', !filters.onlyFavorites)
                            }
                            text={t('show_only_favorites')}
                            value={String(filters.onlyFavorites)}
                        />
                    )}
                </St.SecondWrapper>
            </St.RightFiltersWrapper>
        </St.Wrapper>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    filters: MarketTableFilters;
    listOfCurrencies: Array<TagType>;
    filteredByTypeOptions: Array<TagType>;
    handleChangeState: (key: string, value: 'CI' | '24hs' | boolean | string | TagType) => void;
    liquidityList: Array<TagType>;
    classList: Array<TagType>;
    enableFiltersForClassAndPerson: boolean;
    table: 'default' | 'funds';
    isPreferred: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilters.propTypes = propTypes;

export default HeaderFilters;
