import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { RULES_BASE_URL } from 'constants/appConstants';
import { GetLockedFunctionalityResponse } from 'types/api/rules.types';

const apiName = 'declarations';

export default {
    getLockedFunctionilities: () =>
        withFakeData<GetLockedFunctionalityResponse>(
            () => axiosDataInstance.get(`${RULES_BASE_URL}/functionality/?pageSize=30`),
            apiName,
            'declarations',
            false,
            500,
        ),
};
