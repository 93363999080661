import { Box, Chip, TextField, styled } from '@mui/material';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    TextField: styled(TextField)(({ theme }) => ({})),
    Chip: styled(Chip)(({ theme }) => ({
        backgroundColor: '#d3d3d3',
        height: '25px',
        // padding: '0px 4px',
    })),
};
