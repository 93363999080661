import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BuySellSwitch.styled';

const BuySellSwitch = (props: Props) => {
    const {
        t,
        active,
        setActive,
        buyText,
        sellText,
        disableBtn1 = false,
        disableBtn2 = false,
    } = props;
    return (
        <St.BuySellContainer>
            <St.BuySellButton
                onClick={() => (!disableBtn1 ? setActive(false) : null)}
                $selected={!active}
                id="btn1">
                {buyText ?? t('buy_button_text')}
            </St.BuySellButton>
            <St.BuySellButton
                onClick={() => (!disableBtn2 ? setActive(true) : null)}
                $selected={active}
                id="btn2">
                {sellText ?? t('sell_button_text')}
            </St.BuySellButton>
        </St.BuySellContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    buyText: PropTypes.string,
    sellText: PropTypes.string,
};

interface extraProps {
    disableBtn1?: boolean;
    disableBtn2?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellSwitch.propTypes = propTypes;

export default BuySellSwitch;
