import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            maxWidth: { lg: '1172px', md: '100%' },
            margin: '0 auto',
            padding: { lg: '5.938rem 0 7.375rem', md: '5rem', xs: '2rem' },
        }),
    ) as typeof Box,
    InfoWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: { xs: 'block', sm: 'flex' },
            flexDirection: 'row',
            width: '100%',
            margin: '0 auto',
            gap: '10px',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginTop: { lg: '100px', md: '50px' },
        }),
    ) as typeof Box,
    InputWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { sm: '361px', xs: '100%' },
            marginTop: '19px',
        }),
    ) as typeof Box,
};
