import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BuySellPrevisualizeModal.styled';
import { Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import ArrowLeft from 'assets/icons/arrow-left-s-line.svg?react';
import { formatDate } from 'utils/helpers/dateHelper';
import { BUYSELL, GetMarketPreviewResponse } from 'types/api/orders.types';
import ErrorModal from 'components/common/ErrorModal';
import BuySellSucessModal from 'components/common/BuySellSucessModal';
import { InstrumentType } from 'types/api/instruments.types';
import { UserType } from 'types/common/users.types';
import { Currency } from 'types/common/general.types';
import CustomRadioButton from 'components/common/CustomRadioButton';
import CustomModalAuth from 'components/common/CustomModalAuth';
import { formatNumber } from 'utils/helpers/commonHelper';
import { MarketAccount } from 'types/api/marketAccount.types';
import VisualizeDeclaration from './VisualizeDeclaration';

const BuySellPrevisualizeModal = (props: Props) => {
    const {
        t,
        open,
        close,
        apiError,
        isManual,
        buySell,
        userType,
        currency,
        onSubmit,
        isLoading,
        openModal,
        requestOtp,
        setIsManual,
        validateOtp,
        setOpenModal,
        marketPreview,
        instrumentName,
        instrumentType,
        isBond,
        selectedCustomer,
        manualMarketPrice,
        successModalProps,
        isLoadingSubmitMfa,
        setManualMarketPrice,
        acceptDDJJ,
        setAcceptDDJJ,
        requiresDeclaration,
    } = props;

    return (
        <>
            <Dialog open={open} onClose={close} fullWidth>
                <St.Header display={'flex'} justifyContent={'space-between'}>
                    <St.TitleContainer>
                        <ArrowLeft cursor={'pointer'} onClick={close} />
                        <Typography variant="caption">
                            {t(instrumentType?.toLowerCase()).toUpperCase()}
                        </Typography>
                    </St.TitleContainer>
                    <Typography variant="caption" fontWeight={700}>
                        {formatDate(marketPreview.agreementDatetime)}
                    </Typography>
                </St.Header>
                <Divider />
                <DialogContent>
                    <Stack spacing={2}>
                        {(userType === 'ASSISTANT' || userType === 'CHANNEL') &&
                            selectedCustomer && (
                                <St.ContentRowContainer>
                                    <Typography variant="body1">
                                        {t('customer_information', {
                                            customer: `${selectedCustomer.name} ${selectedCustomer.last_name} - N° ${selectedCustomer.customerCode}`,
                                        })}
                                    </Typography>
                                </St.ContentRowContainer>
                            )}
                        <St.ContentRowContainer>
                            <St.BlueTypography variant="h5">
                                {marketPreview.ticker}
                            </St.BlueTypography>
                            <St.BlueTypography variant="h5">
                                {currency === 'ARS' ? '$' : 'US$'}
                                {formatNumber(marketPreview.price)}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <St.BlueTypography variant="caption">
                                {instrumentName}
                            </St.BlueTypography>
                            <St.BlueTypography variant="caption">
                                {t('buy_sell_previsualize_operate_text')}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <Typography variant="body2">{t('quantity_field_text')}</Typography>
                            <St.BlueTypography variant="body2">
                                {marketPreview.quantity}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <Typography variant="body2">{t('gross_field_text')}</Typography>
                            <St.BlueTypography variant="body2">
                                {currency === 'ARS' ? '$' : 'US$'}
                                {formatNumber(marketPreview.gross, isBond ? 3 : 2)}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <Typography variant="body2">{t('currency_field_text')}</Typography>
                            <St.BlueTypography variant="body2">
                                {marketPreview.currency}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <Typography variant="body2">{t('expenses_field_text')}</Typography>
                            <St.BlueTypography variant="body2">
                                {currency === 'ARS' ? '$' : 'US$'}
                                {formatNumber(marketPreview.expenses, isBond ? 3 : 2)}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer marginTop={'3rem'}>
                            <Typography variant="body1">{t('net_field_text')}</Typography>
                            <St.BlueTypography variant="body1">
                                {currency === 'ARS' ? '$' : 'US$'}
                                {formatNumber(marketPreview.net, isBond ? 3 : 2)}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                    </Stack>
                </DialogContent>
                {(userType === 'CHANNEL' || userType === 'ASSISTANT') && (
                    <St.IsManualContainer>
                        <CustomRadioButton
                            checked={!isManual}
                            name={t('is_direct_text')}
                            onChange={() => setIsManual(false)}
                            text={t('is_direct_text')}
                            value={String(!isManual)}
                        />
                        <CustomRadioButton
                            checked={isManual}
                            name={t('is_manual_text')}
                            onChange={() => setIsManual(true)}
                            text={t('is_manual_text')}
                            value={String(!isManual)}
                        />
                    </St.IsManualContainer>
                )}
                {(userType === 'CHANNEL' || userType === 'ASSISTANT') && isManual && (
                    <St.ManualMarketPriceContainer>
                        <CustomRadioButton
                            checked={manualMarketPrice}
                            name={t('market_price')}
                            onChange={() => setManualMarketPrice(true)}
                            text={t('market_price')}
                            value={String(manualMarketPrice)}
                        />
                        <CustomRadioButton
                            checked={!manualMarketPrice}
                            name={t('limit_market_price')}
                            onChange={() => setManualMarketPrice(false)}
                            text={t('limit_market_price')}
                            value={String(!manualMarketPrice)}
                        />
                    </St.ManualMarketPriceContainer>
                )}
                {requiresDeclaration && (
                    <VisualizeDeclaration
                        acceptDDJJ={acceptDDJJ}
                        requiresDeclaration={requiresDeclaration}
                        setAcceptDDJJ={setAcceptDDJJ}
                    />
                )}
                <St.ButtonContainer>
                    <ActionButton
                        disabled={
                            ['ASSISTANT', 'CHANNEL'].includes(userType || '')
                                ? false
                                : requiresDeclaration && !acceptDDJJ
                        }
                        loading={isLoading}
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={onSubmit}>
                        {t(`${buySell.toLowerCase()}_button_text`)}
                    </ActionButton>
                </St.ButtonContainer>
            </Dialog>
            {openModal === 'success' && (
                <BuySellSucessModal
                    {...{ ...successModalProps, instrumentType, open }}
                    close={() => setOpenModal(null)}
                    date={marketPreview.agreementDatetime}
                />
            )}
            {openModal === 'error' && (
                <ErrorModal open={openModal === 'error'} close={() => setOpenModal(null)} />
            )}
            {openModal === 'enableMFA' && (
                <CustomModalAuth
                    handleClose={() => setOpenModal(null)}
                    isLoadingSubmit={isLoadingSubmitMfa}
                    open={openModal === 'enableMFA'}
                    apiError={apiError}
                    sendEmailCode={requestOtp}
                    handleSubmit={validateOtp}
                />
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isManual: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsManual: PropTypes.func.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    instrumentName: PropTypes.string.isRequired,
    isBond: PropTypes.bool.isRequired,
    manualMarketPrice: PropTypes.bool.isRequired,
    isLoadingSubmitMfa: PropTypes.bool.isRequired,
    setManualMarketPrice: PropTypes.func.isRequired,
    acceptDDJJ: PropTypes.bool.isRequired,
    setAcceptDDJJ: PropTypes.func.isRequired,
    requiresDeclaration: PropTypes.bool.isRequired,
};

interface extraProps {
    open: boolean;
    openModal: 'error' | 'success' | 'enableMFA' | null;
    instrumentType?: InstrumentType;
    close: () => void;
    marketPreview: GetMarketPreviewResponse;
    currency: Currency;
    userType: UserType | null;
    selectedCustomer: MarketAccount | null;
    requestOtp: () => void;
    validateOtp: (otp: string) => void;
    apiError: string | null;
    successModalProps: { leftText: string; rightText: string; leftUrl: string; rightUrl: string };
    buySell: BUYSELL;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellPrevisualizeModal.propTypes = propTypes;

export default BuySellPrevisualizeModal;
