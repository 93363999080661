import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonModal from './CommonModal';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';
import { useTranslation } from 'react-i18next';
import { ICommonModal } from 'types/common/tableLayout.types';
import useSnackBar from 'hooks/common/snackbar.hooks';

const CommonModalContainer = (props: Props) => {
    const { isOpen, open, close } = useDialog();
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { t } = useTranslation();

    const childProps = {
        ...props,
        isOpen,
        open,
        close,
        setSnackBarMessage,
        SnackBar,
        t,
    };

    return <CommonModal {...childProps} />;
};

const propTypes = {};

interface extraProps extends ICommonModal {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonModalContainer.propTypes = propTypes;

export default CommonModalContainer;
