import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './NotificationsTool.styled';
import { Badge, Box, Popover, Typography } from '@mui/material';
import NotificationBell from 'assets/svg/notificationBell.svg?react';
import { NotificationObj, ParsedNotificationBody } from 'types/api/alerts.types';
import CircularSpinner from '../CircularSpinner';
import NotificationItem from './NotificationItem';
import DeleteIcon from 'assets/icons/delete-bin-line.svg?react';
import DoubleCheckIcon from 'assets/icons/check-double-line.svg?react';
import AlertNoData from '../AlertNoData';
import ActionButton from '../ActionButton';

const NotificationsTool = (props: Props) => {
    const {
        t,
        id,
        open,
        counter,
        anchorEl,
        unreadCount,
        handleClick,
        handleClose,
        handleSeeMore,
        notifications,
        isLoadingNotifications,
        selectedNotifications,
        handleReadNotifications,
        setSelectedNotifications,
        handleDeleteNotifications,
        isLoadingSubmitNotification,
    } = props;

    return (
        <St.Container>
            <St.Tooltip title={t('toggle_notifications_panel')}>
                <St.IconButton onClick={handleClick} size="large">
                    <Badge badgeContent={unreadCount} color="error">
                        <NotificationBell />
                    </Badge>
                </St.IconButton>
            </St.Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Box p={1.5}>
                    <Typography variant="h5" color="primary.main">
                        {t('notifications_title')}
                    </Typography>
                    {selectedNotifications && (
                        <St.IconsContainer>
                            <St.IconText
                                $disabled={isLoadingSubmitNotification}
                                aria-label="mark as read"
                                onClick={() =>
                                    isLoadingSubmitNotification ? null : handleReadNotifications()
                                }>
                                <Typography variant="caption">{t('read_selected')}</Typography>
                                <DoubleCheckIcon />
                            </St.IconText>
                            <St.IconText
                                $disabled={isLoadingSubmitNotification}
                                aria-label="delete"
                                onClick={() =>
                                    isLoadingSubmitNotification ? null : handleDeleteNotifications()
                                }>
                                <Typography variant="caption">{t('delete_selected')}</Typography>
                                <DeleteIcon />
                            </St.IconText>
                        </St.IconsContainer>
                    )}
                    {isLoadingNotifications ? (
                        <CircularSpinner />
                    ) : (
                        <Box>
                            <St.NotificationList>
                                {notifications && notifications.length ? (
                                    <>
                                        {notifications
                                            .filter((_, index) => index < counter)
                                            .map(n => {
                                                const { body, NotificationID, date, read } = n;
                                                const parsedBody = JSON.parse(
                                                    body,
                                                ) as ParsedNotificationBody;
                                                return (
                                                    <NotificationItem
                                                        id={NotificationID}
                                                        key={NotificationID}
                                                        date={date}
                                                        read={read}
                                                        body={parsedBody}
                                                        selectedNotifications={
                                                            selectedNotifications
                                                        }
                                                        setSelectedNotifications={
                                                            setSelectedNotifications
                                                        }
                                                    />
                                                );
                                            })}
                                        {counter < notifications.length && (
                                            <ActionButton
                                                sx={{ width: '100%' }}
                                                onClick={handleSeeMore}>
                                                {t('see_more')}
                                            </ActionButton>
                                        )}
                                    </>
                                ) : (
                                    <AlertNoData title={t('no_notification_was_found')} />
                                )}
                            </St.NotificationList>
                        </Box>
                    )}
                </Box>
            </Popover>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    counter: PropTypes.number.isRequired,
    unreadCount: PropTypes.number.isRequired,
    handleSeeMore: PropTypes.func.isRequired,
    handleDeleteNotifications: PropTypes.func.isRequired,
    handleReadNotifications: PropTypes.func.isRequired,
    isLoadingSubmitNotification: PropTypes.bool.isRequired,
};

interface extraProps {
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    open: boolean;
    id: string | undefined;
    anchorEl: HTMLButtonElement | null;
    notifications?: NotificationObj[] | null;
    isLoadingNotifications: boolean;
    selectedNotifications: string[] | null;
    setSelectedNotifications: React.Dispatch<React.SetStateAction<string[] | null>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NotificationsTool.propTypes = propTypes;

export default NotificationsTool;
