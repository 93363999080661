import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CircleWithNumber from 'components/common/CircleWithNumber';
import St from './QuestionsAnswers.styled';

const QuestionsAnswers = (props: Props) => {
    const { number, text, questionOptions, handleCheck, questionKey } = props;
    return (
        <St.Grid>
            <CircleWithNumber number={number} />
            <St.Box>
                <St.Title>{text}</St.Title>
                <St.List>
                    {Object.entries(questionOptions).map(([key, el]) => (
                        <St.ListItemButton
                            key={`${questionKey}-${key}`}
                            component="li"
                            onClick={() => handleCheck(key, questionKey)}>
                            <St.ListItemIcon>
                                <St.Radio checked={el.userChoice} />
                            </St.ListItemIcon>
                            <St.ListItemText primary={el.text} />
                        </St.ListItemButton>
                    ))}
                </St.List>
            </St.Box>
        </St.Grid>
    );
};

const propTypes = {};

interface extraProps {
    questionKey: string;
    number: number;
    handleCheck: (field: string, questionKey: string) => void;
    questionId: string;
    text: string;
    questionOptions: {
        [key: string]: { id: string; text: string; userChoice: boolean };
    };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuestionsAnswers.propTypes = propTypes;

export default QuestionsAnswers;
