import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CircleWithNumber from './CircleWithNumber';

const CircleWithNumberContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CircleWithNumber {...childProps} />;
};

const propTypes = {};

interface extraProps {
    number: number;
    activated?: boolean;
    className?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CircleWithNumberContainer.propTypes = propTypes;

export default CircleWithNumberContainer;
