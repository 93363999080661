import React from 'react';
// import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'router/AppRoutes';
// import { checkLogin } from 'store/authSlice/authSlice.slice';

import { closeSnackbar, SnackbarProvider } from 'notistack';
import AuthContextProvider from 'context/auth.context';
import { useCheckLogin } from 'hooks/api/auth.hooks';
import AppTheme from 'utils/theme/AppTheme';
import PricesProvider from 'context/prices.context';
import NotificationsContextProvider from 'context/notifications.context';
import { IconButton } from '@mui/material';
import CloseIcon from 'assets/icons/close-line.svg?react';
import { CommonGridProvider } from 'context/common/commonGrid.context';

const App = () => {
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCheckLogin();
    }, []);

    // if (isAuthenticated == null) return null;

    return (
        <SnackbarProvider
            maxSnack={5}
            action={snackbarId => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <CloseIcon />
                </IconButton>
            )}>
            <AppTheme>
                <BrowserRouter>
                    <AuthContextProvider>
                        <NotificationsContextProvider>
                            <PricesProvider>
                                <CommonGridProvider>
                                    <AppRoutes />
                                </CommonGridProvider>
                            </PricesProvider>
                        </NotificationsContextProvider>
                    </AuthContextProvider>
                </BrowserRouter>
            </AppTheme>
        </SnackbarProvider>
    );
};

export default App;
