import { Box, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        flex: 1,
        margin: '0rem 2rem',
        marginRight: '0!important',
    })) as typeof Box,
    HeaderContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1.6rem',
    })) as typeof Box,
    ButtonsContainer: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        gap: pxToRem(20),
    })) as typeof Box,
    PageWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            marginTop: '2rem',
            display: 'grid',
            gap: '2rem',
            width: '100%',
            gridTemplateColumns: { xs: '1fr', lg: '1fr 400px' },
        }),
    ),
};
