import endpoints from 'api/utils/utils.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useTranslation } from 'react-i18next';
import { download } from 'utils/helpers/files.helper';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { PresignedUrlData } from 'types/utils/utils.api.types';
import axios from 'axios';

export const useCreateDocument = (config: any) => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.createDocument, {
        ...config,
    });
    return {
        mutate,
        isLoading,
        error: (error as any)?.response?.data?.error_messages?.[0] ?? '',
        isError,
    };
};
/** Analizar si se pueden unificar ambos en uno */

export const useCreateSignature = (
    file: File | null,
    setFieldValue: (field: string, value: any) => void,
    setEnableButton: (value: boolean) => void,
) => {
    const [presignedUrls, setPresignedUrls] = React.useState<{
        [key: string]: PresignedUrlData;
    } | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleUploadFile = (
        file: File,
        presignedUrls: {
            [key: string]: PresignedUrlData;
        },
    ) => {
        Object.keys(presignedUrls).forEach(async presignedUrl => {
            const { url, fields }: PresignedUrlData = presignedUrls[presignedUrl];
            const formData = new FormData();
            formData.append('key', fields.key);
            formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
            formData.append('x-amz-security-token', fields['x-amz-security-token']);
            formData.append('policy', fields.policy);
            formData.append('signature', fields.signature);
            file && formData.append('file', file);
            setFieldValue('filePath', fields.key);
            await axios.post(url, formData);
            setIsLoading(false);
        });
    };

    React.useEffect(() => {
        if (presignedUrls && file) handleUploadFile(file, presignedUrls);
    }, [file, presignedUrls]);

    const {
        mutate,
        isLoading: isLoadingReq,
        error,
        isError,
    } = useMutation(endpoints.createDocument, {
        onSuccess: async (response: any) => {
            const { presignedUrls } = response.data;
            setIsLoading(true);
            setPresignedUrls(presignedUrls);
        },
        onError: () => {
            setEnableButton(false);
        },
    });
    return {
        mutate,
        isLoading: isLoading || isLoadingReq,
        error: error ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetFiles = (enabledCondition?: boolean, filePath?: string) => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['get-files', filePath],
        () => endpoints.downloadFile(filePath ?? ''),
        {
            enabled: enabledCondition,
            select: response => {
                const downloadPresignedUrl = response.data.downloadPresignedUrl;
                const res: string[] = [];
                Object.keys(downloadPresignedUrl).forEach(element => {
                    res.push(downloadPresignedUrl[element]);
                });
                return res[0]; // Analizar si devolver el primer elemento o un array completo
            },
        },
    );

    return {
        fileUrl: data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useDownloadFile = () => {
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { t } = useTranslation('commonGrid');

    const { mutate, isLoading, error } = useMutation(endpoints.downloadFile, {
        onSuccess: res => {
            setSnackBarMessage(t('file_download_success'));
            const downloadPresignedUrl = res.data.downloadPresignedUrl;
            Object.keys(downloadPresignedUrl).forEach(element => {
                download(downloadPresignedUrl[element]);
            });
        },
        onError: () => {
            setSnackBarMessage(t('file_download_error'), 'error');
        },
    });

    return {
        downloadFile: mutate,
        isLoading,
        SnackBar,
        error: error ? errorResponseHelper(error) : null,
    };
};

export const useGetAutocompleteInfo = () => {
    const { mutate, isLoading, isError } = useMutation(endpoints.getAutocompleteInfo);

    return {
        mutate,
        isLoading,
        isError,
    };
};
