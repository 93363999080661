import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ForgotPasswordPage from './ForgotPasswordPage';

const ForgotPasswordPageContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <ForgotPasswordPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ForgotPasswordPageContainer.propTypes = propTypes;

export default ForgotPasswordPageContainer;
