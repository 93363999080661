import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CompanyModal.styled';
import PropTypes from 'prop-types';
import ActionButton from 'components/common/ActionButton';
import { pxToRem } from 'utils/helpers/stylesHelper';
import BriefCaseIcon from 'assets/icons/briefcase-3-line.svg?react';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomIconCreator from 'components/common/CustomIconCreator';

const CompanyModal = (props: Props) => {
    const { t, openModal, handleClose, setOpenModal } = props;

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}>
            <DialogTitle id="alert-dialog-title" sx={{ pt: 2, pb: 1 }}>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenModal(false)}
                    sx={{
                        color: theme => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ py: 1, px: 3 }}>
                <DialogContentText id="alert-dialog-description">
                    <Box display="flex" flexDirection="column">
                        <St.ModalTitle>
                            <CustomIconCreator
                                icon={BriefCaseIcon}
                                alt="brief-case-icon"
                                sx={{
                                    fontSize: '200px',
                                    alignSelf: 'center',
                                    color: '#307FE2',
                                }}
                            />
                            <St.ModalTitleText>{t('company_modal_title')}</St.ModalTitleText>
                        </St.ModalTitle>
                        <Box display="flex" flexDirection="column" alignContent={'center'}>
                            <St.ModalText>{t('company_modal_first_paragraph')}</St.ModalText>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2, justifyContent: 'center' }}>
                <ActionButton
                    type="button"
                    variant="contained"
                    sx={{ width: pxToRem(354) }}
                    onClick={handleClose}>
                    {t('accept')}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    handleClose: () => void;
    setOpenModal: (state: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CompanyModal.prototype = propTypes;

export default CompanyModal;
