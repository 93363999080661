import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfileBankAccountPage from './ProfileBankAccountPage';
import { useTranslation } from 'react-i18next';
import { useDeleteBankAccount, useGetBankAccounts } from 'hooks/api/cahsflow.hooks';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';
import { useCommonGrid } from 'context/common/commonGrid.context';

const ProfileBankAccountPageContainer = (props: Props) => {
    const { t } = useTranslation('profile');
    const {
        data: bankAccounts,
        isLoading,
        refetch,
    } = useGetBankAccounts({ filterQueryString: 'sortField=status&sort=DESC' } as GridHookParams);
    const [selectedAccount, setSelectedAccount] = React.useState<GetBankAccountsItem | null>(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const { isOpen, open, close } = useDialog();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { needRefresh, setNeedRefresh } = useCommonGrid();

    const { mutate: deleteBankAccount, isLoading: deleteIsLoading } = useDeleteBankAccount(
        setSnackBarMessage,
        () => setOpenDeleteModal(false),
    );

    const accountTypeOptions = {
        SAVINGS: t('bank_account_type_savings'),
        CHECKING: t('bank_account_type_checking'),
        EXTERIOR: t('bank_account_type_exterior'),
        BROKER: t('bank_account_type_broker'),
    };
    const childProps = {
        ...props,
        t,
        bankAccounts,
        selectedAccount,
        setSelectedAccount,
        isEdit,
        setIsEdit,
        deleteBankAccount,
        openDeleteModal,
        setOpenDeleteModal,
        accountTypeOptions,
        SnackBar,
        isLoading,
        deleteIsLoading,
        isOpen,
        open,
        close,
        setSnackBarMessage,
    };

    React.useEffect(() => {
        if (needRefresh) {
            setNeedRefresh(false);
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needRefresh]);

    return <ProfileBankAccountPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileBankAccountPageContainer.propTypes = propTypes;

export default ProfileBankAccountPageContainer;
