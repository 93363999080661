import { Box, styled, Typography } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        flex: 1,
        margin: '8rem 2rem',
    })) as typeof Box,
    ActionButtonWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '100%', sm: 'fit-content' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '1rem', sm: '3.188rem' },
            margin: '6rem auto 0',
            button: {
                fontSize: '1.25rem',
                fontWeight: '500',
                lineHeight: '23.44px',
                letterSpacing: '0.0015em',
                padding: '8.5px 0',
                borderRadius: '44px',
                textTransform: 'uppercase',
                width: { xs: '100%', sm: '260px', md: '324px' },
                m: 0,
            },
        }),
    ),
    Subtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '1.5rem',
            fontWeight: '500',
            lineHeight: '28.13px',
            margin: '3.438rem 0 2.438rem',
            color: theme.palette.custom.darkblue2,
        }),
    ),
    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            margin: '0',
            padding: '0',
            color: theme.palette.custom.darkblue2,
        }),
    ),
    Li: styled('li')(() => ({
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: '39.84px',
        letterSpacing: '0.0025em',
        listStyleType: 'disc',
        marginLeft: '1.5rem',
    })),
};
