import { Box, styled, Typography } from '@mui/material';

export default {
    TitleContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        fontWeight: 700,
    })),
    Header: styled(Box)(({ theme }) => ({
        paddingTop: '1rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.8rem',
        paddingBottom: '0.5rem',
    })),
    ContentRowContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
    })),
    BlueTypography: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.vibrantblue4,
    })),
    ButtonContainer: styled(Box)(({ theme }) => ({
        padding: '1rem',
    })),
    ManualMarketPriceContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '15px',
        paddingLeft: '1rem',
    })),
    IsManualContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        marginLeft: '1rem',
        marginBottom: '1rem',
    })),
};
