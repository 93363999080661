import { Theme, useMediaQuery } from '@mui/material';

export default {
    FirstText: (theme: Theme) => {
        const isXs = useMediaQuery(theme.breakpoints.only('xs'));
        const isSm = useMediaQuery(theme.breakpoints.only('sm'));
        const fontSize = isXs ? '0.75rem' : isSm ? '2rem' : '1.5rem';
        const textAlign = isXs || isSm ? 'center' : 'left';
        return {
            h5: (
                <h5
                    style={{
                        fontSize,
                        fontWeight: '500',
                        lineHeight: '28.13px',
                        letterSpacing: '0.0025em',
                        textAlign,
                        color: theme.palette.custom.darkblue2,
                        margin: '0',
                    }}
                />
            ),
        };
    },

    Title: (theme: Theme) => {
        const isXs = useMediaQuery(theme.breakpoints.only('xs'));
        const isSm = useMediaQuery(theme.breakpoints.only('sm'));
        const fontSize = isXs ? '2.25rem' : isSm ? '4rem' : '4.25rem';
        const textAlign = isXs || isSm ? 'center' : 'left';
        return {
            h2: (
                <h2
                    style={{
                        fontSize,
                        fontWeight: '300',
                        lineHeight: '59.69px',
                        letterSpacing: '-0.015em',
                        textAlign,
                        color: theme.palette.primary.main,
                        margin: '.6rem 0 1rem',
                    }}
                />
            ),
            h3: (
                <h3
                    style={{
                        fontSize: '2.25rem',
                        fontWeight: '600',
                        lineHeight: '50px',
                        textAlign,
                        color: '#020202',
                        margin: '1rem 0 0.2rem',
                    }}
                />
            ),
        };
    },

    Subtitle: (theme: Theme) => {
        const isXs = useMediaQuery(theme.breakpoints.only('xs'));
        const isSm = useMediaQuery(theme.breakpoints.only('sm'));
        const fontSize = isXs ? '1.125rem' : isSm ? '3rem' : '2.125rem';
        const textAlign = isXs || isSm ? 'center' : 'left';
        return {
            h4: (
                <h4
                    style={{
                        fontSize,
                        fontWeight: '400',
                        lineHeight: '39.84px',
                        letterSpacing: '0.0025em',
                        textAlign,
                        color: theme.palette.custom.darkblue2,
                        margin: '0',
                        marginTop: '40px!important',
                    }}
                />
            ),
        };
    },
};
