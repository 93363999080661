import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomInput from './CustomInput';
import { FormikErrors, FormikValues } from 'formik';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/material';
const CustomInputContainer = (props: Props) => {
    const { handleChange, setFieldValue, field, regex } = props;

    const onChangeInput = (value: string) => {
        if (regex) {
            if (regex.test(value)) {
                handleChange
                    ? handleChange(value)
                    : setFieldValue && setFieldValue(`${field}`, value);
            }
        } else {
            handleChange ? handleChange(value) : setFieldValue && setFieldValue(`${field}`, value);
        }
    };

    const childProps = {
        ...props,
        onChangeInput,
    };

    return <CustomInput {...childProps} />;
};

const propTypes = {
    field: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    style: PropTypes.any,
    inputProps: PropTypes.any,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
};

interface extraProps {
    disabled?: boolean;
    autoFocus?: boolean;
    noError?: boolean;
    values: FormikValues;
    value?: string | number;
    regex?: RegExp;
    errors?: FormikErrors<FormikValues>;
    size?: OverridableStringUnion<'small' | 'medium', ''>;
    multiline?: boolean;
    rows?: number;
    sx?: SxProps;
    onBlur?: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomInputContainer.propTypes = propTypes;

export default CustomInputContainer;
