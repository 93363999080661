import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import mainLogo from 'assets/img/general/generic_logo.svg';
import SendMailPage from './SendMailForm';
import { useForgotPassword } from 'hooks/api/auth.hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tRequiredFieldError } from 'constants/appConstants';
import { useNavigate } from 'react-router-dom';
import { loggedOutSubroutesPaths } from 'router/routesPaths';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    email: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            email: Yup.string()
                .email(i18n.t('required_email', { ns: 'errors' }) as string)
                .required(tRequiredFieldError)
                .trim(),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const SendMailFormContainer = (props: Props) => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading, errorMessage } = useForgotPassword();
    const navigate = useNavigate();

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        mutate(
            { email: values.email.trim() },
            {
                onSuccess: () => {
                    navigate(loggedOutSubroutesPaths.emailSent, {
                        state: { isSigningUp: false, email: values.email.trim() },
                    });
                },
            },
        );
    }, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        mainLogo,
        isLoading,
        formik,
        errorMessage,
    };

    return <SendMailPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SendMailFormContainer.propTypes = propTypes;

export default SendMailFormContainer;
