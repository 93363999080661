import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { IconButton } from '@mui/material';
import St from './AppBar.styled';
import UserInfo from 'components/common/UserInfo';
import NotificationsTool from 'components/common/NotificationsTool';

const AppBar = (props: Props) => {
    const { handleDrawerOpen } = props;
    return (
        <St.FixedAppBar component={'div'}>
            <St.Toolbar>
                <UserInfo />
                <St.ToolsContainer>
                    {/** TODO agregar boton de darkMode cuando se encare la funcionalidad */}
                    <NotificationsTool />
                    <St.MenuContainer onClick={handleDrawerOpen}>
                        <IconButton onClick={handleDrawerOpen}>
                            <St.MenuLine />
                        </IconButton>
                    </St.MenuContainer>
                </St.ToolsContainer>
            </St.Toolbar>
        </St.FixedAppBar>
    );
};

const propTypes = {
    handleDrawerOpen: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AppBar.propTypes = propTypes;

export default AppBar;
