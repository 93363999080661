import React, { useContext } from 'react';
import endpoints from 'api/marketAccount/marketAccount.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AuthContext } from 'context/auth.context';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { MarketAccount } from 'types/api/marketAccount.types';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import {
    channelCurrencyPosition,
    channelDailyOperations,
    reportTicketAdapter,
    reportTransactionsAdapter,
} from 'adapters/api/marketAccount.adapter';

export const useGetAccountStatus = (byLiquidation = false) => {
    const { customerCode, userType, setSelectedCustomer } = React.useContext(AuthContext);
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-account-status', customerCode, byLiquidation],
        () => endpoints.getAccountStatus(customerCode ?? '', byLiquidation),
        {
            select: data => data.data,
            enabled: !!customerCode,
            onSuccess(data) {
                if (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL')
                    setSelectedCustomer(prev => ({
                        ...(prev as MarketAccount),
                        aum_ars: data.aum.total.ars,
                        available_operate_ars_term_0: data.availableBalance.operate.t0.ars,
                        available_operate_ars_term_1: data.availableBalance.operate.t1.ars,
                        available_operate_usd_term_0: data.availableBalance.operate.t0.usd,
                        available_operate_usd_term_1: data.availableBalance.operate.t1.usd,
                        available_operate_usdc_term_0: data.availableBalance.operate.t0.usdc,
                        available_operate_usdc_term_1: data.availableBalance.operate.t1.usdc,
                    }));
            },
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetChannelAccountStatus = () => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-channel-account-status'],
        () => endpoints.getChannelAccountStatus(),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error as any) : null,
    };
};

export const useGetChannelMarketAccounts = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.getChannelMarketAccounts);
    return {
        mutate,
        isLoading,
        errorMessage: error ? errorResponseHelper(error as any) : null,
    };
};

export const useGetChannelClients = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, refetch, isRefetching } = useQuery(
        ['channel-client', filterQueryString],
        () => endpoints.getChannelClients(filterQueryString),
        {
            select: data => data?.data,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
    };
};

export const useGetReportTickets = ({ filterQueryString }: GridHookParams) => {
    const { selectedCustomer } = React.useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-report-tickets', filterQueryString, selectedCustomer?.customerCode],
        () => endpoints.getReportTickets(filterQueryString, selectedCustomer?.customerCode ?? ''),
        { select: data => reportTicketAdapter(data.data) },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetReportTransactions = ({ filterQueryString }: GridHookParams) => {
    const { customerCode } = useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-report-transactions', filterQueryString, customerCode],
        () => endpoints.getReportTransactions(filterQueryString, customerCode ?? ''),
        {
            select: data => reportTransactionsAdapter(data.data),
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetReportHolding = ({ filterQueryString }: GridHookParams) => {
    const { customerCode } = useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-report-holding', filterQueryString, customerCode],
        () => endpoints.getReportHolding(filterQueryString, customerCode ?? ''),
        {
            select: data => {
                const transformedData = data.data.flatMap(item =>
                    item.instruments.map(instrument => ({
                        instrumentType: item.instrumentType,
                        ticker: instrument.ticker,
                        description: instrument.description,
                        price: {
                            value: instrument.price.value,
                            currency: instrument.price.currency,
                        },
                        quantity: instrument.quantity,
                        percentageInWallet: instrument.percentageInWallet,
                        pppc: {
                            value: instrument.pppc?.value,
                            percentage: instrument.pppc?.percentage,
                        },
                        returns: instrument.returns,
                        instrumentDate: instrument.instrumentDate,
                    })),
                );
                return { items: transformedData };
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetCurrencyPosition = ({ filterQueryString }: GridHookParams) => {
    const { customerCode } = useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-channel-currency-position', filterQueryString, customerCode],
        () => endpoints.getChannelCurrencyPosition(filterQueryString),
        {
            select: data => {
                const transformedData = channelCurrencyPosition(data.data);
                return transformedData;
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetDailyOperations = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-report-transactions', filterQueryString],
        () => endpoints.getChannelDailyOperations(filterQueryString),
        {
            select: data => channelDailyOperations(data.data),
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
