import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CloseMarketModal from './CloseMarketModal';
import { useTranslation } from 'react-i18next';

const CloseMarketModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
        title: t(`${'closeMarketModal'.replace(/([A-Z])/g, '_$1').toLowerCase()}_title_name`),
    };

    return <CloseMarketModal {...childProps} />;
};

const propTypes = {
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CloseMarketModalContainer.propTypes = propTypes;

export default CloseMarketModalContainer;
