import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeaderFilterText from './HeaderFilterText';

const HeaderFilterTextContainer = (props: Props) => {
    const { filterstring: filterString, query, setfilterstring: setFilterString, regex } = props;

    const value = React.useMemo(() => {
        const queries = filterString.split('&');

        for (const element of queries) {
            const [eQuery, value] = element.split('=');

            if (eQuery === query) {
                return value;
            }
        }
        return '';
    }, [filterString, query]);

    const onChange = (field: string, value: string) => {
        if ((regex && regex.test(value)) || !regex) {
            setFilterString((prev: string) => {
                if (value.trim() === '') {
                    return prev.replace(new RegExp(`&${field}=[^&]*`, 'g'), '');
                }

                const selectedValue = `&${field}=${value}`;
                if (prev.includes(field)) {
                    const variables = prev.split('&');
                    variables.shift();
                    const updatedVariables = variables.map((i: any) =>
                        i.includes(field) ? selectedValue : `&${i}`,
                    );

                    return updatedVariables.join('');
                }
                return prev.concat(selectedValue);
            });
        }
    };

    const childProps = {
        ...props,
        value,
        onChange,
    };

    return <HeaderFilterText {...childProps} />;
};

const propTypes = {
    query: PropTypes.string.isRequired,
    filterstring: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
};

interface extraProps {
    setfilterstring: React.Dispatch<React.SetStateAction<string>>;
    regex?: RegExp;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilterTextContainer.propTypes = propTypes;

export default HeaderFilterTextContainer;
