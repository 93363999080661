import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingContactInfoContactSection.styled';
import CustomLabel from 'components/common/CustomLabel';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import OnboardingContactInfoModal from './OnboardingContactInfoModal';
import { LegalData } from 'types/api/onboarding.types';
import ErrorText from 'components/common/ErrorText';
import { pxToRem } from 'utils/helpers/stylesHelper';

const OnboardingContactInfoContactSection = (props: Props) => {
    const { t, legalData, errorSubmit, isLoading, handleContactInfo, openModal, setOpenModal } =
        props;

    return (
        <>
            <St.ContactInfoValidationInternalBox>
                <St.Subtitle variant="h5">{t('contact_info_subtitle')}</St.Subtitle>
                <St.CustomLabelWrapper>
                    <CustomLabel
                        id="streetAddress"
                        value={legalData?.streetAddress ?? ''}
                        label={t('streetName')}
                    />
                    <CustomLabel
                        id="streetNumber"
                        value={legalData?.streetNumber ?? ''}
                        label={t('streetNum')}
                    />
                    <CustomLabel id="floor" value={legalData?.floor || '-'} label={t('floor')} />
                    <CustomLabel
                        id="apartment"
                        value={legalData?.apartment || '-'}
                        label={t('apartment')}
                    />
                    <CustomLabel id="location" value={legalData?.city ?? '-'} label={t('city')} />
                    <CustomLabel
                        id="postalCode"
                        value={legalData?.zipCode ?? '-'}
                        label={t('zipCode')}
                    />
                    <CustomLabel id="state" value={legalData?.state ?? '-'} label={t('state')} />
                    {errorSubmit && <ErrorText text={errorSubmit} />}
                </St.CustomLabelWrapper>
            </St.ContactInfoValidationInternalBox>
            <St.ButtonWrapper>
                <ActionButton
                    sx={{ width: `${pxToRem(330)}!important` }}
                    variant="outlined"
                    aria-label="export"
                    onClick={() => setOpenModal(!openModal)}
                    disabled={isLoading}>
                    {t('validation_page_edit_button_text')}
                </ActionButton>
                <ActionButton
                    sx={{ width: `${pxToRem(330)}!important` }}
                    type="button"
                    variant="contained"
                    onClick={() => handleContactInfo(legalData)}
                    loading={isLoading}>
                    {t('confirm', tCommon)}
                </ActionButton>
            </St.ButtonWrapper>
            <OnboardingContactInfoModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                formValues={legalData}
                handleContactInfo={handleContactInfo}
                errorSubmit={errorSubmit}
                isLoading={isLoading}
            />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleContactInfo: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    openModal: PropTypes.bool.isRequired,
    setOpenModal: PropTypes.func.isRequired,
};

interface extraProps {
    legalData: LegalData | null;
    errorSubmit: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoContactSection.propTypes = propTypes;

export default OnboardingContactInfoContactSection;
