import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentCurrenciesTable.styled';
import { CurrencyFormatted } from 'types/api/instruments.types';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { tCommon } from 'constants/appConstants';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import AlertNoData from 'components/common/AlertNoData';
import { formatNumber, truncateDecimals } from 'utils/helpers/commonHelper';

const InstrumentCurrenciesTable = (props: Props) => {
    const { t, currencies, handleInstrumentClick } = props;
    return currencies?.length > 0 ? (
        <TableContainer component={Paper}>
            <Table aria-label="wallet-instrument-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('ticker_column')}</TableCell>
                        <TableCell>{t('description_column')}</TableCell>
                        <TableCell>{t('quantity', tCommon)}</TableCell>
                        <TableCell>{t('quantity_available_column')}</TableCell>
                        <TableCell>{t('available_in_wallet')}</TableCell>
                        <TableCell>{t('instrument_valuation')}</TableCell>
                        <St.ActionCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currencies.map((currency, index: number) => {
                        const {
                            available,
                            holdingPercentage,
                            quantity,
                            valuation,
                            currency: name,
                            description,
                        } = currency as CurrencyFormatted;

                        return (
                            <TableRow key={index}>
                                <St.TickerCell
                                    onClick={() =>
                                        handleInstrumentClick({
                                            ...currency,
                                            type: 'CURRENCY',
                                        })
                                    }>
                                    {name}
                                </St.TickerCell>
                                <TableCell>{description ?? '-'}</TableCell>
                                <TableCell>{formatNumber(truncateDecimals(quantity, 2))}</TableCell>
                                <TableCell>
                                    {formatNumber(truncateDecimals(available, 2))}
                                </TableCell>
                                <TableCell>{holdingPercentage}</TableCell>
                                <TableCell>{valuation}</TableCell>
                                <St.ActionCell>
                                    <Tooltip title={t('detail', tCommon)}>
                                        <IconButton
                                            onClick={() =>
                                                handleInstrumentClick({
                                                    ...currency,
                                                    type: 'CURRENCY',
                                                })
                                            }>
                                            <CustomIconCreator icon={EyeIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </St.ActionCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <AlertNoData title={t('no_data_was_found')} />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    currencies: CurrencyFormatted[];
    handleInstrumentClick: (instrument: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentCurrenciesTable.propTypes = propTypes;

export default InstrumentCurrenciesTable;
