import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContactInfoPhoneNumberSection from './OnboardingContactInfoPhoneNumberSection';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { VALID_PHONE_NUMBER_REGEX } from 'utils/helpers/constants';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    phoneNumber: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(i18n.t(tRequiredFieldError) as string)
            .matches(
                VALID_PHONE_NUMBER_REGEX,
                i18n.t('phone_invalid_number', tErrorsContext) as string,
            ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const OnboardingContactInfoPhoneNumberSectionContainer = (props: Props) => {
    const { setPhoneNumber } = props;
    const { t } = useTranslation('onboarding');

    const handleSubmit = React.useCallback(async (data: any) => {
        setPhoneNumber(`+54${data.phoneNumber}`);
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        enableButton: !!formik.values.phoneNumber,
    };

    return <OnboardingContactInfoPhoneNumberSection {...childProps} />;
};

const propTypes = {
    setPhoneNumber: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoPhoneNumberSectionContainer.propTypes = propTypes;

export default OnboardingContactInfoPhoneNumberSectionContainer;
