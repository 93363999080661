import {
    Box,
    Modal,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import DownArrow from 'assets/icons/downArrow.svg?react';
import UpArrow from 'assets/icons/upArrow.svg?react';
import Neutral from 'assets/icons/neutral.svg?react';
import StarWithBackground from 'assets/icons/star-with-background.svg?react';
import BottomlessStar from 'assets/icons/bottomless-star.svg?react';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';
import AlertNoData from 'components/common/AlertNoData';

export default {
    TableContainerWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: 'fit-content',
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '55vh',
            overflowY: 'auto',
        }),
    ),
    TableContainer: styled(
        TableContainer,
        transientOptions,
    )<{ $funds: boolean }>(({ $funds, theme }) =>
        theme.unstable_sx({
            background: `linear-gradient(to bottom, transparent ${$funds ? '2.5rem' : '3.65rem'}, ${theme.palette.custom.vibrantblue10} ${$funds ? '1rem' : '3.5125rem'})`,
            px: '0.75rem',
            borderTop: '1px solid #E0E0E0',
            width: 'calc(100% - 1.5rem)',
            maxWidth: '100%',
            backgroundAttachment: 'local',
            mx: 'auto',
        }),
    ),
    Table: styled(Table)(({ theme }) => ({
        background: theme.palette.custom.vibrantblue10,
        // width: pxToRem('1286px'),
    })),
    TableHead: styled(TableHead)(({ theme }) =>
        theme.unstable_sx({
            background: theme.palette.background.default,
            span: { position: 'relative' },
            th: {
                fontSize: '1rem',
                fontWeight: '700',
                lineHeight: pxToRem('18.75px'),
                letterSpacing: '0.0015em',
                p: '0.688rem 1rem 0.688rem 0.5rem',
                minWidth: 'calc(86px - 1.25rem)',
                border: 'none',
                color: theme.palette.custom.darkblue,
                verticalAlign: 'bottom',
                transition: '0.2s',
                /*   '&:hover': {
                    background: theme.palette.custom.vibrantblue11,
                }, */
            },
            '& th:nth-of-type(2)': {
                fontWeight: 700,
                letterSpacing: '0.0015em',
                textAlign: 'left',
                color: theme.palette.custom.vibrantblue2,
            },
        }),
    ),
    TableRow: styled(TableRow)(() => ({})),
    TableCell: styled(
        TableCell,
        transientOptions,
    )<{ $color?: string; $bg?: string }>(({ $color, $bg, theme }) => ({
        transition: '0.2s',
        color: $color === 'default' ? theme.palette.custom.darkblue : $color,
        backgroundColor: $bg === 'none' ? 'inherit' : `${$bg} !important`,
    })),
    TableCellFavorite: styled(TableCell)(({ theme }) => ({
        minWidth: 'calc(24px - 1rem) !important',
        width: 'calc(25px - 0.5rem) !important',
        padding: '0 0.375rem !important',
    })),
    StarWithBackground: styled(StarWithBackground)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue,
            verticalAlign: 'bottom',
        }),
    ),
    BottomlessStar: styled(BottomlessStar)(({ theme }) =>
        theme.unstable_sx({
            verticalAlign: 'bottom',
        }),
    ),
    TableBody: styled(TableBody)(({ theme }) =>
        theme.unstable_sx({
            '& td': {
                width: '5.375rem',
                borderCollapse: 'collapse',
                border: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                lineHeight: pxToRem('18.75px'),
                letterSpacing: '-0.0125em',
                paddingX: '0.5rem',
                paddingY: '0.664rem',
            },
            '& td:nth-of-type(2)': {
                fontWeight: 700,
                letterSpacing: '0.0015em',
                textAlign: 'left',
                color: theme.palette.custom.vibrantblue2,
            },
            '& tr:nth-of-type(even)': {
                backgroundColor: theme.palette.custom.vibrantblue11,
                td: { backgroundColor: theme.palette.custom.vibrantblue11 },
                '& td:nth-of-type(1)': {
                    borderRadius: '1.25rem 0 0 1.25rem',
                },
                '& td:last-of-type': {
                    borderRadius: '0 1.25rem 1.25rem 0',
                },
                '& td:nth-of-type(n+6):nth-of-type(-n+7)': {
                    backgroundColor: 'rgb(234 242 252 / 50%)',
                },
            },
            '& td:nth-of-type(n+4):nth-of-type(-n+5)': {
                backgroundColor: 'rgb(172 204 241 / 50%)',
            },
        }),
    ),
    DownArrow: styled(DownArrow)(() => ({ marginLeft: '.5rem', verticalAlign: 'middle' })),
    UpArrow: styled(UpArrow)(() => ({ marginLeft: '.5rem', verticalAlign: 'middle' })),
    Neutral: styled(Neutral)(() => ({
        marginLeft: '.5rem',
        width: '0.875rem',
        height: '0.875rem',
        verticalAlign: 'middle',
    })),
    AlertNoData: styled(AlertNoData)(() => ({})),
    AlerNoDataWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            div: { margin: 'auto', width: 'fit-content' },
        }),
    ),
    Modal: styled(Modal)(() => ({})),
    ModalBox: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '1.25rem',
            borderRadius: '0.5rem',
            outline: 'none',
        }),
    ),
};
