import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, CircularProgress } from '@mui/material';
import CommonTable from 'components/common/CommonTable';
import { ITableLayoutView } from 'types/common/tableLayout.types';
import TableLayoutHeader from 'components/common/TableLayoutHeader';

const TableLayout = ({
    data,
    pageLoading,
    columns,
    title,
    actions,
    filters,
    sortInfo,
    createAction,
    filterHeaderString,
    setFilterHeaderString,
    handlePageChange,
    handleSort,
    SnackBar,
    fetchData,
    exportTitle,
    canExport,
    customFilterValidation,
}: Props) => (
    <Box display="flex" flexDirection="column" sx={{ minWidth: '100%', minHeight: '100vh' }}>
        <TableLayoutHeader
            title={title}
            filters={filters}
            filterHeaderString={filterHeaderString}
            setFilterHeaderString={setFilterHeaderString}
            createAction={createAction}
            columns={columns}
            fetchData={fetchData}
            exportTitle={exportTitle}
            canExport={canExport}
            customFilterValidation={customFilterValidation}
            total={data.total}
        />
        {pageLoading ? (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5,
                }}>
                <CircularProgress color="primary" size="7rem" />
            </Box>
        ) : (
            <CommonTable
                rows={data}
                columns={columns}
                actions={actions}
                sortInfo={sortInfo}
                handlePageChange={handlePageChange}
                handleSort={handleSort}
            />
        )}
        <SnackBar />
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps extends ITableLayoutView {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableLayout.propTypes = propTypes;

export default TableLayout;
