import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DisagreeModal from './DisagreeModal';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { Nationality } from 'types/api/onboarding.types';

const getInitialValues = () => ({
    rejectPersonalReason: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            rejectPersonalReason: Yup.string().required(tRequiredFieldError),
        }),
    );

const DisagreeModalContainer = (props: Props) => {
    const { t, setOpenModal, handlePersonalData, nationality } = props;
    const handleClose = React.useCallback(() => {
        setOpenModal(false);
    }, [setOpenModal]);

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            handlePersonalData(nationality.name, true, values.rejectPersonalReason);
        },
        [handlePersonalData, nationality],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const enableButton = React.useMemo(() => !!formik.values.rejectPersonalReason, [formik.values]);

    const childProps = {
        ...props,
        t,
        handleClose,
        setOpenModal,
        formik,
        enableButton,
        handlePersonalData,
    };

    return <DisagreeModal {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handlePersonalData: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    nationality: Nationality;
    isLoadingSubmit: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DisagreeModalContainer.prototype = propTypes;

export default DisagreeModalContainer;
