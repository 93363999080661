import * as XLSX from 'xlsx';
import moment from 'moment';
import {
    ACTIVE,
    ARS,
    ARS_LOCALE,
    ASSIGNED,
    BROKER,
    CANCELLED,
    CANCELLED_PARTIALLY,
    COMPLETED,
    DESTINATION,
    EXPIRED,
    INTERMEDIARY,
    CONFIRMED,
    ERROR,
    EXPIRED_PARTIALLY,
    PARTIALLY_FILLED,
    PENDING,
    PENDING_ACCEPT,
    PENDING_DELETE,
    PENDING_CANCELLATION,
    REJECTED,
    SENT,
    tCommonGrid,
    tErrorsContext,
    USD,
    SCHEDULED,
    UNASSIGNED,
} from 'constants/appConstants';
import i18n from 'utils/i18n';
import { t } from 'i18next';
import { InvestmentTestValues } from 'types/common/users.types';
import { ExteriorBankAccount, SortedBankAccount } from 'types/api/cashflow.types';

export const later = (delay: number, value?: any) =>
    new Promise(resolve => setTimeout(resolve, delay, value));

export const laterCancellable = (delay: number, value?: any) => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let reject: ((reason?: any) => void) | null = null;
    const promise = new Promise((resolve, _reject) => {
        reject = _reject;
        timer = setTimeout(resolve, delay, value);
    });
    return {
        get promise() {
            return promise;
        },
        cancel() {
            if (timer) {
                clearTimeout(timer);
                timer = null;
                if (reject) reject();
                reject = null;
            }
        },
    };
};

export const getAmericanStringNumber = (stringNumber: string | null) => {
    return stringNumber
        ? stringNumber.replace(/,/g, 'temp').replace(/\./g, ',').replace(/temp/g, '.')
        : stringNumber;
};

export const formatDateToAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes().toString();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12;
    minutes = Number(minutes) < 10 ? '0' + minutes : minutes;
    const strTime = (Number(hours) < 10 ? '0' + hours : hours) + ':' + minutes + ' ' + ampm;
    return strTime;
};

export const getCurrentTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    // Return the time in "HH:mm:ss" format
    return `${hours}:${minutes}:${seconds}`;
};

export const toCurrencyString = (
    num: number,
    decimalPositions = 2,
    currency: null | string = null,
    spacedCurrency = true,
) => {
    if (num == null || isNaN(num))
        return `${currency ?? ''}${currency != null && spacedCurrency ? ' ' : ''}${(0)
            .toFixed(decimalPositions)
            .replace('.', ',')}`;
    return `${currency ?? ''}${currency != null && spacedCurrency ? ' ' : ''}${num
        .toFixed(decimalPositions)
        .replace('.', ',')
        .replace(
            decimalPositions == 0 ? /(\d)(?=(\d{3})+(?!\d))/g : /(\d)(?=(\d{3})+(?!\d)?,)/g,
            '$1.',
        )}`;
};
export const lowerFirstLetter = (string: string) =>
    `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

export const formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberSplitted = phoneNumber.split(' ');
    const country = phoneNumberSplitted[0];
    const area = phoneNumberSplitted[1];
    const number = phoneNumberSplitted[2];
    switch (country) {
        case '+1':
            return `${country} (${area}) ${number.slice(0, 4)}-${number.slice(4)}`;
        case '+549':
            return `${country.slice(0, 3)} ${country.charAt(3)} ${area} ${number.slice(
                0,
                4,
            )} ${number.slice(4)}`;
        default:
            return phoneNumber;
    }
};
const fitToColumn = (data: Array<any>) => {
    const columnWidths = [];
    for (const property in data[0]) {
        if (property) {
            columnWidths.push({
                wch: Math.max(
                    property ? property.toString().length : 0,
                    ...data.map(obj => (obj[property] ? obj[property].toString().length : 0)),
                ),
            });
        }
    }

    return columnWidths;
};
export const exportFromJson = (data: Array<any>, columns: Array<string>, name: string) => {
    const ws = XLSX.utils.json_to_sheet(data, {
        header: columns,
    });

    const wscols = fitToColumn(data);
    ws['!cols'] = wscols;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name + moment().format('YYYY-MM-DD'));
    XLSX.writeFile(wb, `${name + moment().format(' YYYY-MM-DD')}.xlsx`);
};

export const messagesToDeleteRegistration = (props: { resource: string; error: any }) => {
    const { resource, error } = props;
    return {
        errorMessage: i18n.t(
            error?.response?.data?.error_messages?.[0].code ?? 'error_deletion_snackbar_text',
            { ...tErrorsContext, complement: i18n.t('use_delete_error_plugin', { ns: resource }) },
        ),
        successfulMessage: i18n.t('successful_deletion_snackbar_text', {
            ...tCommonGrid,
            complement: i18n.t('use_delete_success_plugin', { ns: resource }),
        }),
    };
};

export const replaceSpecialCharacters = (string: string = '') => {
    return string.replaceAll(/[¿?]/g, '');
};

export const redirect = (link: string) => {
    const element = document.createElement('a');
    element.href = link;
    element.download = link.split('/').reverse()[0];
    element.target = '_blank';
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
export const disableCurrentDate = (date: any) => date < new Date();

export const truncateDecimals = (number: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.trunc(number * factor) / factor;
};

export const formatNumber = (
    number: number,
    decimalPlaces?: number,
    showDecimals: boolean = true,
) => {
    // Convertir el número a un string para la manipulación
    let numberString = number.toString();

    // Si se especifica decimalPlaces, redondear el número a esa cantidad de decimales
    if (decimalPlaces !== undefined) {
        numberString = number.toFixed(decimalPlaces);
    }

    // Dividir la parte entera y decimal
    const parts = numberString.split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1] || '00'; // Si no hay parte decimal, inicializar a '00'

    // Añadir separadores de miles a la parte entera
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Manejar la parte decimal dependiendo de showDecimals y decimalPlaces
    if (!showDecimals) {
        decimalPart = '';
    } else {
        decimalPart = ',' + decimalPart;
    }

    // Unir las partes formateadas
    return formattedIntegerPart + decimalPart;
};

export function formatNumberMilis(num: number) {
    if (num >= 1e9) {
        return `$ ${(num / 1e9).toFixed(2)} B`;
    } else if (num >= 1e6) {
        return `$ ${(num / 1e6).toFixed(2)} M`;
    } else if (num >= 1e3) {
        return `$ ${(num / 1e3).toFixed(2)} K`;
    } else {
        return `$ ${num.toFixed(2)}`;
    }
}

export const convertToNumber = (str: string) => {
    const withoutMiles = str.replace(/\./g, '');
    const validNumber = withoutMiles.replace(',', '.');
    return parseFloat(validNumber);
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const getFullName = (user?: { name: string; lastName: string } | null) =>
    user ? `${user?.name} ${user?.lastName}` : '-';

export const getFormalName = (user?: { name: string; lastName: string } | null) =>
    user ? `${user?.lastName} ${user?.name}` : '-';

export const getIntegerWithFirstTwoAfterComma = (parameter: string | number) =>
    formatNumber(Number(parameter), 2);

export const getStylesOfVariation = (parameter: string) => {
    const isNeutral = parameter === '0';
    const isNegative = parameter.includes('-');
    return (isNeutral ? 'n' : isNegative ? 'b' : 't') as 'n' | 'b' | 't';
};

export const convertCurrencyFormat = (price: number, format = ARS, minDecimals = 2) =>
    new Intl.NumberFormat(ARS_LOCALE, {
        style: 'currency',
        minimumFractionDigits: minDecimals,
        currency: format.includes('USD') ? USD : ARS,
    }).format(price);

export const getColorByStatus = (status: string) => {
    switch (status) {
        case EXPIRED:
        case REJECTED:
        case CANCELLED:
        case CANCELLED_PARTIALLY:
        case EXPIRED_PARTIALLY:
        case ERROR:
            return {
                backgroundColor: '#ffc09f',
            };
        case PENDING:
        case PENDING_ACCEPT:
        case PENDING_DELETE:
        case ASSIGNED:
        case PARTIALLY_FILLED:
        case SENT:
        case PENDING_CANCELLATION:
        case SCHEDULED:
            return {
                backgroundColor: '#ffee93',
            };
        case COMPLETED:
        case ACTIVE:
        case CONFIRMED:
            return {
                backgroundColor: '#adf7b6',
            };
        default:
            return {};
    }
};

export const fundDirectaOptions = [
    { id: PENDING, name: t(PENDING) },
    { id: CANCELLED, name: t(CANCELLED) },
    { id: COMPLETED, name: t(COMPLETED) },
];

export const fundMesaOptions = [
    { id: PENDING, name: t(UNASSIGNED) },
    { id: ASSIGNED, name: t(ASSIGNED) },
    { id: REJECTED, name: t(REJECTED) },
    { id: COMPLETED, name: t(COMPLETED) },
];

export const fundsStatusOptions = [
    { id: PENDING, name: t(PENDING) },
    { id: CANCELLED, name: t(CANCELLED) },
    { id: COMPLETED, name: t(COMPLETED) },
];

export const orderMesaOptions = [
    {
        id: ASSIGNED,
        name: t(ASSIGNED),
    },
    {
        id: PENDING,
        name: t(UNASSIGNED),
    },
    { id: COMPLETED, name: t(COMPLETED) },
    { id: REJECTED, name: t(REJECTED) },
    { id: EXPIRED, name: t(EXPIRED) },
];

export const exchangeStatusOptions = [
    { id: PENDING, name: t(PENDING) },
    { id: CANCELLED, name: t(CANCELLED) },
    { id: REJECTED, name: t(REJECTED) },
    { id: COMPLETED, name: t(COMPLETED) },
];

export const orderStatusOptions = [
    { id: PENDING, name: t(PENDING) },
    { id: SENT, name: t(SENT) },
    { id: PARTIALLY_FILLED, name: t(PARTIALLY_FILLED) },
    { id: COMPLETED, name: t(COMPLETED) },
    { id: EXPIRED, name: t(EXPIRED) },
    { id: EXPIRED_PARTIALLY, name: t(EXPIRED_PARTIALLY) },
    { id: CANCELLED, name: t(CANCELLED) },
    { id: CANCELLED_PARTIALLY, name: t(CANCELLED_PARTIALLY) },
    { id: REJECTED, name: t(REJECTED) },
];

export const orderWithdrawalsOptions = [
    { id: PENDING, name: t(PENDING) },
    { id: REJECTED, name: t(REJECTED) },
    { id: COMPLETED, name: t(COMPLETED) },
];

export const statusToShowDelete = [SENT, PARTIALLY_FILLED];

export const calculateDifference = (total: number, percentage: number) => {
    const decimal = percentage / 100;
    const percentageValue = total * decimal;
    return percentageValue;
};

export const formatPercentage = (percentage: number, minDigits: number = 2) =>
    typeof percentage === 'string' ? percentage : `${percentage.toFixed(minDigits)}%`;

export const isRecommendedForYourProfile = (
    profileToCheck?: InvestmentTestValues,
    currentProfile?: InvestmentTestValues,
) => {
    const profileNumber = { CONSERVATIVE: 1, MODERATE: 2, AGGRESSIVE: 3 };

    return t(
        `market_fund_details${!currentProfile || !profileToCheck ? '_not' : profileNumber[profileToCheck] >= profileNumber[currentProfile] ? '' : '_not'}_recommended`,
        { ns: 'market' },
    );
};

// export const isMobile = () => {
//     console.log(REGEX_IS_MOBILE.test(navigator.userAgent));
//     return REGEX_IS_MOBILE.test(navigator.userAgent);
// };

export const isMobile = () => {
    const userAgent = navigator.userAgent;
    // alert(`${navigator.userAgent}\n${userAgent.includes('Mobile')}`);
    return userAgent.includes('Mobile');
};

export const sortBankAccounts = (bankAccountExtraData?: ExteriorBankAccount[]) =>
    bankAccountExtraData?.reduce((accum: SortedBankAccount, account) => {
        switch (account.extraType) {
            case DESTINATION:
            case BROKER:
                return (accum = { ...accum, [account.extraType]: account });
            case INTERMEDIARY:
                return (accum = {
                    ...accum,
                    INTERMEDIARY: accum?.INTERMEDIARY?.length
                        ? accum.INTERMEDIARY.concat(account)
                        : [account],
                });
            default:
                return accum;
        }
    }, {} as SortedBankAccount);
