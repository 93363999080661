import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';
import './assets/fonts/Montserrat-VariableFont_wght.ttf';
import './assets/fonts/Montserrat-Italic-VariableFont_wght.ttf';
import './assets/fonts/MonumentExtended-Regular.otf';
import './assets/fonts/MonumentExtended-Ultrabold.otf';
import { QueryClientProvider } from '@tanstack/react-query';
import { getQueryClient } from 'utils/helpers/queryClientHelper';
import * as Sentry from '@sentry/react';
import { V_ENVIRONMENT_NAME } from 'constants/envVarConstants';

Sentry.init({
    dsn: 'https://c02756cf01f57da3eb9781ca97612abd@o4506707223117824.ingest.us.sentry.io/4508098706014208',
    integrations: [
        // Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://api.dev.consultatioinvestments.com'],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: V_ENVIRONMENT_NAME,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <QueryClientProvider client={getQueryClient()}>
        <App />
    </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
