// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import NavbarItem from './NavbarItem';
import St from './NavBarLayout.styled';
import ToolsList from './ToolsList';
import UserInfo from '../../common/UserInfo';

const NavBarLayout = (props: Props) => {
    const { handleHover, openSubmenu, routes } = props;
    return (
        <St.AppBarContainer position="sticky">
            <St.CommonToolbarContainer id="common-toolbar-container">
                <St.CommonToolbar>
                    <UserInfo />
                    <St.DesktopNavigatorContainer component={'nav'}>
                        {routes
                            ?.filter(route => route.name !== 'profile')
                            .map(route => (
                                <NavbarItem
                                    key={route.name}
                                    handleHover={handleHover}
                                    openSubmenu={openSubmenu}
                                    route={route}
                                />
                            ))}
                        <ToolsList />
                    </St.DesktopNavigatorContainer>
                </St.CommonToolbar>
            </St.CommonToolbarContainer>
        </St.AppBarContainer>
    );
};

const propTypes = {};

interface extraProps {
    handleHover: (menu: SubRouteInfo | null, hasSubroutes?: boolean) => void;
    routes: Array<SubRouteInfo>;
    openSubmenu: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NavBarLayout.propTypes = propTypes;

export default NavBarLayout;
