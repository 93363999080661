import { Box, styled, Typography } from '@mui/material';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    DialogTitle: styled(Typography)(({ theme, fontSize }) =>
        theme.unstable_sx({
            textAlign: 'center',
            color: theme.palette.custom.vibrantblue,
            margin: '20px',
        }),
    ),
};
