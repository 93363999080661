import { Box, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';
import QuestionMarkRadiusIcon from 'assets/svg/questionMarkRadius.svg?react';

export default {
    Container: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    })) as typeof Box,
    Dot: styled(
        'li',
        transientOptions,
    )<{ $isMarketClosed: boolean }>(({ theme, $isMarketClosed }) => ({
        width: 16,
        height: 16,
        borderRadius: '16px',
        margin: '0 5px',
        backgroundColor: $isMarketClosed ? theme.palette.custom.red2 : theme.palette.custom.green,
        cursor: 'pointer',
        border: `2px solid ${$isMarketClosed ? theme.palette.custom.red2 : theme.palette.custom.green}`,
        display: 'inline-block',
    })),
    QuestionMarkRadiusIcon: styled(QuestionMarkRadiusIcon)(() => ({
        width: 16,
        height: 16,
    })),
};
