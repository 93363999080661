import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingValidatePersonPage.styled';
import OnboardingValidatePersonQuestionsSection from './OnboardingValidatePersonQuestionsSection';
import OnboardingValidatePersonDniSection from './OnboardingValidatePersonDniSection';
import CustomLoader from 'components/common/CustomLoader';

const OnboardingValidatePersonPage = (props: Props) => {
    const { setShowNosisQuestions, showNosisQuestions, isLoading } = props;

    return (
        <St.SectionWrapper>
            {isLoading ? (
                <CustomLoader />
            ) : showNosisQuestions ? (
                <OnboardingValidatePersonQuestionsSection />
            ) : (
                <OnboardingValidatePersonDniSection setShowNosisQuestions={setShowNosisQuestions} />
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    isLoading: boolean;
    showNosisQuestions: boolean;
    setShowNosisQuestions: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonPage.propTypes = propTypes;

export default OnboardingValidatePersonPage;
