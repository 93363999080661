export const download = (link: string) => {
    const element = document.createElement('a');
    element.href = link;
    element.download = link.split('/').reverse()[0];
    element.target = '_blank';
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const getFileNameFromFileUrl = (fileUrl: string) => {
    const route = fileUrl?.split('?')[0];
    return route.split('/').pop() ?? '';
};

export function base64ToFile(base64String: string, fileName: string): File {
    // Extraer la parte base64
    const base64ContentArray: string[] = base64String.split(',');
    const base64Data: string =
        base64ContentArray.length > 1 ? base64ContentArray[1] : base64ContentArray[0];

    // Convertir a un array de bytes
    const byteCharacters: string = atob(base64Data);
    const byteNumbers: number[] = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray: Uint8Array = new Uint8Array(byteNumbers);

    // Crear un objeto Blob con el array de bytes y un tipo MIME apropiado
    const fileType: string = base64ContentArray[0].split(';')[0].split(':')[1] || '';
    const blob: Blob = new Blob([byteArray], { type: fileType });

    // Convertir el Blob a un File
    return new File([blob], fileName, { type: fileType });
}
