import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { useGetReportTransactions } from 'hooks/api/marketAccount.hooks';
import { tCommon } from 'constants/appConstants';
import { ReportTransaction } from 'types/pages/reports.types';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';

const ReportsConsolidatedAccountPage = (props: Props) => {
    const { columns, filters, t, defaultFilters, filterDateValidation } = props;

    return (
        <TableLayout
            title={t('reports_consolidate_account', tCommon)}
            fetchData={useGetReportTransactions}
            columns={columns}
            filters={filters}
            canExport
            exportTitle={t('account_doc_title')}
            defaultFilters={defaultFilters}
            customFilterValidation={filterDateValidation}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<ReportTransaction>[];
    filters: CommonFilter[];
    defaultFilters: ExtraQueryFiltersProps[];
    filterDateValidation: (value: string) => { result: boolean; error: string | undefined };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsConsolidatedAccountPage.propTypes = propTypes;

export default ReportsConsolidatedAccountPage;
