import { TextField, styled } from '@mui/material';

export default {
    TextField: styled(TextField)<{ ownerState: { disabled: boolean } }>(({ theme, ownerState }) => {
        const { disabled } = ownerState;
        return theme.unstable_sx({
            input: {
                WebkitTextFillColor: disabled ? 'rgba(0,0,0,0.38) !important' : '',
                color: disabled ? 'rgba(0,0,0,0.7) !important' : '',
                opacity: disabled ? 0.7 : '',
                // backgroundColor: disabled ? 'rgba(0,0,0,0.05) ' : '',
            },
            label: {
                color: disabled ? 'rgba(0,0,0,0.38) !important' : '',
            },
        });
    }),
};
