import { Box, DialogContent, DialogTitle, Modal, Typography, styled } from '@mui/material';

export default {
    Modal: styled(Modal)(({ theme }) =>
        theme.unstable_sx({
            overflowY: 'auto',
            mt: '10px',
            mb: '10px',
        }),
    ),
    ModalContentWrapper: styled(Box)(({ theme }) => {
        return theme.unstable_sx({
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: { xs: 'calc(280px - 1rem)', sm: 'calc(380px - 1rem)' },
            height: 'max-content',
            maxHeight: { xs: '98%', sm: '95%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '5px',
            p: 2,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
        });
    }),

    DialogContent: styled(DialogContent)(() => ({
        padding: 0,
        textAlign: 'center',
        marginTop: '.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    })),
    DialogTitle: styled(DialogTitle)(() => ({
        padding: 0,
    })),
    Header: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '0.4rem',
    })),
    Typography: styled(Typography)(() => ({})),
    ButtonsContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '1rem',
        button: { width: '100%', marginTop: 0 },
    })),
    Img: styled('img')(({ theme }) =>
        theme.unstable_sx({
            height: { sm: '150px', xs: '100px' },
        }),
    ),
};
