import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomIconCreator from '../CustomIconCreator';
import FileUploader from '../FileUploader';
import { Form, FormikContextType, FormikProvider } from 'formik';
import ActionButton from '../ActionButton';

const UploadDdjj = ({ formik, isOpen, isLoading, SnackBar, close, t }: Props) => {
    const { errors, values, setFieldValue } = formik;
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={isOpen} onClose={close}>
                <DialogTitle id="dialog-title" sx={{ pt: 2, pb: 1 }}>
                    {t('upload_ddjj_title')}
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            color: theme => theme.palette.grey[500],
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}>
                        <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form>
                            <FileUploader
                                field="ddjj"
                                label={t('ddjj_input_label')}
                                values={values}
                                setFieldValue={setFieldValue as any}
                                errors={errors}
                            />
                            <Box display="flex" justifyContent="end" alignItems="center" gap={2}>
                                <ActionButton
                                    type="button"
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={close}>
                                    {t('cancel')}
                                </ActionButton>
                                <ActionButton
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    loading={isLoading}
                                    disabled={isLoading || !values.ddjj}>
                                    {t('sent')}
                                </ActionButton>
                            </Box>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    isOpen: boolean;
    isLoading: boolean;
    SnackBar: any;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UploadDdjj.propTypes = propTypes;

export default UploadDdjj;
