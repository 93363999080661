import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketPreferredPage from './MarketPreferredPage';
import { useTranslation } from 'react-i18next';

const MarketPreferredPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <MarketPreferredPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketPreferredPageContainer.propTypes = propTypes;

export default MarketPreferredPageContainer;
