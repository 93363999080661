import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomHistogramChart.styled';

const CustomHistogramChart = (props: Props) => {
    const { title, chartContainerRef } = props;

    return (
        <St.SectionWrapper>
            <St.GraphicContainer ref={chartContainerRef} />
            <St.Title>{title}</St.Title>
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    chartContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    title: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomHistogramChart.propTypes = propTypes;

export default CustomHistogramChart;
