import { styled, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

export default {
    CustomListItem: styled(ListItem)(({ theme }) => ({
        display: 'block',
        padding: '2px 8px',
        '.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'rgba(145, 158, 171, 0.08)',
            '& .icon': { color: theme.palette.primary.main },
        },
    })),
    ListWrapper: styled(List)<{ ownerState: { link?: boolean }; width: string }>(
        ({ theme, ownerState, width = '234px' }) => ({
            width,
            border: '1px solid #D9D9D9',
            ...(ownerState.link && {
                borderTop: '4px solid #F8981D',
                width: '9.108rem',
                rowGap: '0.5rem',
            }),
            background: '#fff',
            '& .MuiButtonBase-root:last-of-type': {
                borderBottom: 'none',
            },
            zIndex: 101,
        }),
    ),
    DrawerSubmenuButton: styled(ListItemButton)<{ ownerState: { link?: boolean } }>(
        ({ theme, selected, ownerState }) => ({
            fontFamily: 'Inter',
            lineHeight: '239.5%',
            color: '#454545',
            padding: '0 1rem',
            background: selected && !ownerState.link ? '#fcd6a5' : 'transparent',
            textTransform: 'none',
            borderBottom: '2px solid #00000012',
            '&:hover': {
                background: '#E9E9E9',
            },
            transform: 'none',
        }),
    ),
    ListItemText: styled(ListItemText)(({ theme }) => ({
        '& span': {
            fontSize: '0.75rem',
            color: theme.palette.custom.darkGrey,
        },
    })),
};
