import { Box, Table, TableCell, TableRow, Typography, styled } from '@mui/material';

export default {
    Container: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '6rem 2rem',
    })) as typeof Box,

    Grid: styled(Box)(({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    })),

    Section: styled(Box)(({ theme }) => ({
        marginBottom: theme.spacing(4),
        color: 'black',
    })),

    SectionTitle: styled(Typography)(({ theme }) => ({
        fontSize: '1.1rem',
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
    })),

    Table: styled(Table)(() => ({
        width: '100%',
    })),

    TableRow: styled(TableRow)(() => ({
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    })),

    TableCell: styled(TableCell)(({ theme }) => ({
        padding: theme.spacing(1, 0),
        '&:last-child': {
            textAlign: 'right',
        },
    })),

    Label: styled(Typography)(({ theme }) => ({
        color: theme.palette.text.secondary,
    })),

    RatioGrid: styled(Box)(() => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1rem',
        textAlign: 'center',
    })),

    RatioValue: styled(Typography)(({ theme }) => ({
        fontSize: '1.5rem',
        fontWeight: 700,
        color: theme.palette.text.secondary,
    })),

    RatioLabel: styled(Typography)(({ theme }) => ({
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
    })),

    InfoContainer: styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    })),

    InfoGrid: styled(Box)(({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    })),

    InfoSection: styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    })),

    InfoTitle: styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    })),
    InfoSubtitle: styled(Typography)(({ theme }) => ({
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
    })),

    InfoContent: styled(Typography)(({ theme }) => ({
        color: theme.palette.text.secondary,
    })),
};
