import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        width: pxToRem('550px'),
        margin: '0 auto',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    Title: styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: pxToRem('18px'),
        marginTop: pxToRem('10px'),
    })) as typeof Typography,
    Subtitle: styled(Typography)(() => ({
        fontSize: pxToRem('18px'),
        marginTop: pxToRem('10px'),
    })) as typeof Typography,
    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            textAlign: 'left',
            alignSelf: 'flex-start',
            color: theme.palette.custom.vibrantblue2,
        }),
    ),
    Li: styled('li')(() => ({
        fontSize: pxToRem('20px'),
        fontWeight: 500,
        margin: pxToRem('8px'),
        lineHeight: pxToRem('19px'),
        letterSpacing: '0.0015em',
    })),
    Img: styled('img')(() => ({ width: pxToRem('100px') })),
};
