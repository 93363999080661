import * as React from 'react';
import defaultTheme from 'utils/theme/defaultTheme';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { SvgIcon } from '@mui/material';

const CustomIconCreator = (props: Props) => {
    const { sx, icon } = props;

    const IconComponent = <SvgIcon component={icon} sx={sx} />;

    return React.cloneElement(IconComponent, {
        fill: sx?.color || defaultTheme.palette.primary.main,
    });
};

const propTypes = {};

interface extraProps {
    sx?: any;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomIconCreator.propTypes = propTypes;

export default CustomIconCreator;
