import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PersonalDataItem from './PersonalDataItem';
import { useTranslation } from 'react-i18next';

const PersonalDataItemContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <PersonalDataItem {...childProps} />;
};

const propTypes = {};

interface extraProps {
    title: string;
    description?: string;
    breakPoints?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PersonalDataItemContainer.propTypes = propTypes;

export default PersonalDataItemContainer;
