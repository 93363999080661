import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ActionButton from 'components/common/ActionButton';
import { formatDate } from 'utils/helpers/dateHelper';
import { InstrumentType } from 'types/api/instruments.types';
import St from './BuySellSucessModal.styled';
import orderCompletedImage from '../../../assets/img/general/orderCompleted.png';
import { Divider } from '@mui/material';

const BuySellSucessModal = (props: Props) => {
    const {
        open,
        date,
        t,
        handleGotoBtnLeft,
        handleGotoBtnRight,
        instrumentType,
        leftText,
        rightText,
    } = props;

    return (
        <St.Modal open={open} onClose={close}>
            <St.ModalContentWrapper>
                <St.DialogTitle>
                    <St.Header>
                        <St.Typography variant="caption">
                            {t(instrumentType?.toLowerCase()).toUpperCase()}
                        </St.Typography>
                        <St.Typography variant="caption" fontWeight={700}>
                            {formatDate(date)}
                        </St.Typography>
                    </St.Header>
                    <Divider />
                </St.DialogTitle>
                <St.DialogContent>
                    <St.Img src={orderCompletedImage} />
                    {t(`modal_text_buy_sell${instrumentType === 'FUND' ? '_for_funds' : ''}`)}
                </St.DialogContent>
                <St.ButtonsContainer>
                    <ActionButton variant="contained" onClick={handleGotoBtnLeft}>
                        {leftText}
                    </ActionButton>
                    <ActionButton variant="outlined" onClick={handleGotoBtnRight}>
                        {rightText}
                    </ActionButton>
                </St.ButtonsContainer>
            </St.ModalContentWrapper>
        </St.Modal>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    handleGotoBtnLeft: PropTypes.func.isRequired,
    handleGotoBtnRight: PropTypes.func.isRequired,
};

interface extraProps {
    instrumentType?: InstrumentType;
    leftText: string;
    rightText: string;
    open: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellSucessModal.propTypes = propTypes;

export default BuySellSucessModal;
