import { Box, styled } from '@mui/material';

export default {
    ButtonContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem',
        paddingTop: 0,
        button: {
            marginTop: 0,
        },
    })),
};
