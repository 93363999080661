import { Box, Dialog, SvgIcon, Theme, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled as styledMui, CSSObject } from '@mui/material/styles';
import React from 'react';

const RoundIconSvg = ({
    children,
    onClick,
}: {
    children: string | JSX.Element | JSX.Element[];
    onClick: React.MouseEventHandler<SVGSVGElement>;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-tour="toggle-icon"
        style={{
            cursor: 'pointer',
        }}
        onClick={onClick}
        className="text-primary toggle-icon d-none d-xl-block">
        {children}
    </svg>
);

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export default {
    Drawer: styledMui(MuiDrawer)<{ ownerState: { drawerWidth: number } }>(
        ({ theme, open, ownerState }) => {
            const { drawerWidth } = ownerState;
            return {
                boxSizing: 'border-box',
                flexShrink: 0,
                whiteSpace: 'nowrap',
                width: drawerWidth,
                ...(open
                    ? {
                          ...openedMixin(theme, drawerWidth),
                          '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
                      }
                    : {
                          ...closedMixin(theme),
                          '& .MuiDrawer-paper': closedMixin(theme),
                      }),
            };
        },
    ),
    DrawerHeader: styledMui(Box)(({ theme }) => ({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 1),
        textAlign: 'center',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        height: '60px',
    })),
    DrawerLogo: styledMui(SvgIcon)(({ theme }) =>
        theme.unstable_sx({
            height: '60%',
            width: '100%',
            objectFit: 'contain',
            color: theme.palette.primary.main,
        }),
    ) as unknown as typeof SvgIcon,
    DrawerHolderBox: styledMui(Box)(() => ({
        height: '100%',
        width: '95%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingBottom: '1rem',
    })),
    RoundIconSvgWrapper: RoundIconSvg,
    CircleSvg: styledMui('circle')(({ r }: { r: number }) => ({
        cx: '12',
        cy: '12',
        r,
    })),
    FlexedDialog: styledMui(Dialog)(() => ({
        display: 'flex',
        justifyContent: 'flex-end',
    })),
    MenuTextWrapper: styledMui(Box)(() => ({
        transition: 'opacity 0.3s ease-in-out',
        padding: '8px 16px',
    })),
    MenuText: styledMui(Typography)(() => ({
        fontWeight: 600,
        letterSpacing: '0.15rem',
    })),
    LogoContainer: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            height: 'inherit',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }),
    ) as typeof Box,
};
