import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import NavBarLayout from './NavBarLayout';

const NavBarLayoutContainer = (props: Props) => {
    // const {} = props;
    const [openSubmenu, setOpenSubmenu] = React.useState<string | null>('');
    const location = useLocation();

    const handleOpenSubmenu = (menu: string | null) =>
        setOpenSubmenu(openSubmenu !== menu ? menu : null);

    const handleHover = (menu: SubRouteInfo | null, hasSubroutes?: boolean) => {
        hasSubroutes && menu ? handleOpenSubmenu(menu.name) : handleOpenSubmenu(null);
    };

    React.useEffect(() => {
        const currentPage = location.pathname?.slice(1) ?? '';
        setOpenSubmenu(currentPage);
    }, [location]);

    const childProps = {
        ...props,
        openSubmenu,
        handleHover,
    };

    return <NavBarLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {
    routes: Array<SubRouteInfo>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NavBarLayoutContainer.propTypes = propTypes;

export default NavBarLayoutContainer;
