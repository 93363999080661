import { createTheme, ThemeProvider, Theme } from '@mui/material';
import React from 'react';
import themeV2 from './client/clientTheme_v2';
import themeV1 from './client/clientTheme_v1';
import GlobalStyle from './GlobalStyles';
import defaultTheme from './defaultTheme';
import { AuthContext } from 'context/auth.context';

const AppTheme = ({ children }: any) => {
    const { userType } = React.useContext(AuthContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themesMapper: { [key: string]: Theme } = {
        default: defaultTheme,
    };

    const theme = React.useMemo(() => {
        const resultTheme = themesMapper.default;
        return createTheme(resultTheme);
    }, [themesMapper, userType]);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    );
};

export default AppTheme;
