import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CurrencyQuotes.styled';
import QuoteCard from 'components/common/QuoteCard';
import { Skeleton } from '@mui/material';
import { MarketValuesUpdated, QuotationItem } from 'types/api/instruments.types';

const CurrencyQuotes = (props: Props) => {
    const { t, quotations, isLoadingQuotations, marketValuesUpdated } = props;

    return (
        <St.PrimarySection component={'section'} data-testid="CurrencyQuotesSection-default">
            <St.SecondTitle>{t('currency_quotes_title')}</St.SecondTitle>
            {isLoadingQuotations ? (
                <St.SecondSection>
                    <Skeleton variant="rectangular" height={18} width={'70%'} />
                    <St.ContainerCardsQuotes>
                        {Array(4)
                            .fill(null)
                            .map((_, index) => (
                                <St.SkeletonQuoteCard variant="rounded" key={index} />
                            ))}
                    </St.ContainerCardsQuotes>
                </St.SecondSection>
            ) : (
                quotations?.map((quotation, index) => {
                    const baseTicker = quotation.instrument.tickers.find(
                        ticker => ticker.currency === 'ARS',
                    )?.ticker;

                    const currentMarketValue = marketValuesUpdated.find(
                        marketValue => marketValue.ticker === baseTicker,
                    );

                    return (
                        <St.SecondSection key={index}>
                            <St.TitleSectionQuotes>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: quotation.title || '',
                                    }}
                                />
                            </St.TitleSectionQuotes>
                            <St.ContainerCardsQuotes>
                                <QuoteCard quote={currentMarketValue!} />
                            </St.ContainerCardsQuotes>
                        </St.SecondSection>
                    );
                })
            )}
        </St.PrimarySection>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    quotations: QuotationItem[];
    isLoadingQuotations: boolean;
    marketValuesUpdated: MarketValuesUpdated[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyQuotes.propTypes = propTypes;

export default CurrencyQuotes;
