import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DeleteAccountForm.styled';
import { Form, FormikProvider } from 'formik';
import { InputLabel } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import { pxToRem } from 'utils/helpers/stylesHelper';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

const DeleteAccountForm = (props: Props) => {
    const { formik, options, hasPendingRequest, t } = props;

    return (
        <FormikProvider value={formik} data-testid="DeleteAccountForm-form">
            <Form>
                <InputLabel id="delete-reason-select-label">
                    {t('delete_account_form_select_placeholder')}
                </InputLabel>
                <CustomAutoComplete
                    options={options}
                    id="selectedOption"
                    value={formik.values.selectedOption}
                    onChange={(e, value) => formik.setFieldValue('selectedOption', value)}
                    error={formik.errors.selectedOption ?? false}
                    renderOption={(props, option) => (
                        <St.SelectOptions {...props} key={option.id}>
                            {option.name}
                        </St.SelectOptions>
                    )}
                    disabled={hasPendingRequest}
                />
                <St.TextAreaLabel>{t('delete_account_form_input_label')}</St.TextAreaLabel>
                <CustomInput
                    style={{
                        width: '100%',
                        height: pxToRem('150px'),
                        resize: 'none',
                        boxSizing: 'border-box',
                        borderColor: 'grey',
                    }}
                    multiline
                    values={formik?.values}
                    setFieldValue={formik.setFieldValue}
                    field="comment"
                    maxLength={60}
                    placeholder={t('delete_account_form_input_placeholder')}
                    disabled={hasPendingRequest}
                />
                <St.ActionContainer>
                    {hasPendingRequest ? (
                        <St.RequestSendText>
                            {t('delete_account_form_request_send_text')}
                        </St.RequestSendText>
                    ) : (
                        <St.InfoText>{t('delete_account_form_info_text')}</St.InfoText>
                    )}
                    <ActionButton
                        sx={{ width: '60%', marginTop: 0 }}
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={hasPendingRequest}>
                        {t('delete_account_form_submit_button')}
                    </ActionButton>
                </St.ActionContainer>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: any;
    hasPendingRequest: boolean;
    options: {
        id: number;
        name: string;
    }[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteAccountForm.propTypes = propTypes;

export default DeleteAccountForm;
