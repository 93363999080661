import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1172px',
        margin: '0 auto',
        padding: '5.938rem 0 7.375rem',
    })) as typeof Box,
    QuestionsAnswersWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '43px',
        margin: '5.813rem 0 3.25rem',
    })) as typeof Box,
    ActionButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        button: {
            margin: '0 auto',
            fontFamily: 'Roboto',
            fontSize: '1.25rem',
            fontWeight: '500',
            lineHeight: '23.44px',
            letterSpacing: '0.0015em',
            padding: '8.5px 112px',
            borderRadius: '44px',
        },
    })),
    ErrorTextWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        marginTop: '3.5rem',
        p: {
            backgroundColor: theme.palette.custom.rederror,
            color: theme.palette.custom.darkblue2,
            width: '100%',
            fontSize: '1.125rem',
            fontWeight: '400',
            textAlign: 'center',
            padding: '8px 20px',
            margin: 'auto',
            letterSpacing: '0.016em',
        },
    })),
};
