import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from './TableLayout';
import { useTranslation } from 'react-i18next';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import { ITableLayout } from 'types/common/tableLayout.types';
import useSnackBar from 'hooks/common/snackbar.hooks';

const TableLayoutContainer = (props: Props) => {
    const { fetchData, defaultSortFieldId, defaultFilters } = props;
    const { SnackBar } = useSnackBar();
    const { t } = useTranslation();

    const {
        data,
        loading: pageLoading,
        sortInfo,
        handleSort,
        handlePageChange,
        filterHeaderString,
        setFilterHeaderString,
    } = useCustomFetch({ useGetData: fetchData, defaultSortFieldId, defaultFilters });

    const childProps = {
        ...props,
        data,
        pageLoading,
        sortInfo,
        filterHeaderString,
        setFilterHeaderString,
        handlePageChange,
        handleSort,
        SnackBar,
        fetchData,
        t,
    };

    return <TableLayout {...childProps} />;
};

const propTypes = {};

interface extraProps extends ITableLayout {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableLayoutContainer.propTypes = propTypes;

export default TableLayoutContainer;
