import { Box, Divider, Link, styled } from '@mui/material';

export default {
    PrimarySection: styled(Box)(() => ({
        backgroundColor: '#ffffff',
        color: '#999999',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    })),
    BoxHeaderFooter: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
            alignItems: 'center',
            gap: '10%',
            padding: '40px',
        }),
    ),
    BoxAppsImages: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '10px',
            flexDirection: { xs: 'column', sm: 'row' },
        }),
    ),
    BoxScrollToTop: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
    })),
    BoxInfoFooter: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'space-between' },
            gap: '20px',
            padding: '20px 40px',
            alignItems: 'start',
            flexWrap: 'wrap',
        }),
    ),
    ChildrenInfoBox: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            width: { xs: '100%', sm: '20%' },
            minWidth: '200px',
            justifyContent: 'center',
        }),
    ),
    DisclaimerBox: styled(Box)(() => ({
        display: 'flex',
        padding: '40px',
        flexDirection: 'column',
        gap: '10px',
    })),
    DividerFooter: styled(Divider)(() => ({
        margin: '0 40px',
    })),
    SpanLink: styled('span')(({ theme }) => ({
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        fontWeight: 'bold',
    })),
    TextLink: styled(Link)<{ color?: string }>(({ color }) => ({
        cursor: 'pointer',
        textDecoration: 'none',
        color: color || '#999999',
        fontSize: '14px',
    })),
};
