import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import VisualizeDeclaration from './VisualizeDeclaration';
import { useTranslation } from 'react-i18next';
import { useGetUserPreviewDDJJ } from 'hooks/api/declarations.hooks';

const VisualizeDeclarationContainer = (props: Props) => {
    const { acceptDDJJ, setAcceptDDJJ, requiresDeclaration } = props;
    const { t } = useTranslation('home');
    const [showDDJJ, setShowDDJJ] = React.useState<boolean>(false);
    const {
        declarationHtml,
        error: errorGetDeclaration,
        isLoading: isLoadingDeclaration,
    } = useGetUserPreviewDDJJ(requiresDeclaration);

    const childProps = {
        ...props,
        t,
        showDDJJ,
        acceptDDJJ,
        setShowDDJJ,
        setAcceptDDJJ,
        declarationHtml,
        errorGetDeclaration,
        isLoadingDeclaration,
    };

    return <VisualizeDeclaration {...childProps} />;
};

const propTypes = {
    acceptDDJJ: PropTypes.bool.isRequired,
    setAcceptDDJJ: PropTypes.func.isRequired,
    requiresDeclaration: PropTypes.bool.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
VisualizeDeclarationContainer.propTypes = propTypes;

export default VisualizeDeclarationContainer;
