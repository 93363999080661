import { Box, Link, styled, Typography } from '@mui/material';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    IconWrapper: styled('div')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    })),
    StyledImage: styled('img')({
        width: pxToRem('100px'),
    }),
    StyledLink: styled(Link)(({ theme }) => ({
        marginTop: theme.spacing(2),
        display: 'block',
        maxWidth: 'fit-content',
        cursor: 'pointer',
    })),
    BodyText: styled(Typography)({
        marginBottom: '15px',
    }),
    CustomAutoComplete: styled(CustomAutoComplete)(() => ({})) as typeof CustomAutoComplete,
    InputContainer: styled(Box)(() => ({
        alignItems: 'center',
        gap: '10px',
    })),
};
