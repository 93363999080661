import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketInstrumentTable from './MarketInstrumentTable';
import {
    FundsTable,
    MarketTableFilters,
    WSFundTableKey,
    WSTable,
    WSTableKey,
} from 'types/context/prices.context.types';
import { Tprop } from 'types/common/general.types';
import { useAddFavorites, useRemoveFavorites } from 'hooks/api/instruments.hooks';
import useColumns from 'hooks/common/CommonMarketTable/columns.hooks';
import useRows, { TableRow } from 'hooks/common/CommonMarketTable/rows.hooks';
import St from './MarketInstrumentTable.styled';

const MarketInstrumentTableContainer = (props: Props) => {
    const { changeValues, table, refetch } = props;
    const { mutate: mutateAddFavorite, isLoading: isLoadingAddFavorite } = useAddFavorites();
    const { mutate: mutateRemoveFavorite, isLoading: isLoadingRemoveFavorite } =
        useRemoveFavorites();
    const marketTableColumns = useColumns();
    const rows = useRows(St);

    const handleFavorite = (ticker: string, isFavorite: boolean) => {
        const config = {
            onSuccess: () => refetch(),
        };
        const formData = { tickers: [ticker] };
        if (isFavorite) return mutateRemoveFavorite(formData, config);
        mutateAddFavorite(formData, config);
    };

    const changeBackground = (ticker: string, cell: WSTableKey | WSFundTableKey) => {
        const onlyCell = cell as WSTableKey;
        const val = changeValues?.[ticker]?.[onlyCell];
        const blueReturn = ['buyQuantity', 'sellQuantity', 'nominalVolume'];
        if (!val) return 'none';
        if (blueReturn.includes(cell)) return '#409fe4';
        return val === '+' ? '#a0d6a0' : '#ff8ca5';
    };

    const childProps = {
        ...props,
        columns: marketTableColumns[table],
        changeBackground,
        handleFavorite,
        rows: rows[table] as Array<TableRow<WSTable | FundsTable>>,
        isLoadingFavorite: isLoadingAddFavorite || isLoadingRemoveFavorite,
    };

    return <MarketInstrumentTable {...childProps} />;
};

const propTypes = {};

interface extraProps extends Tprop {
    tableData?: Array<WSTable | FundsTable>;
    isLoading: boolean;
    changeValues?: {
        [ticker: string]: Partial<Record<keyof WSTable, string>>;
    };
    filters: MarketTableFilters;
    table: 'default' | 'funds';
    refetch: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentTableContainer.propTypes = propTypes;

export default MarketInstrumentTableContainer;
