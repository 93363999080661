/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CurrencyQuotes from './CurrencyQuotes';
import { useTranslation } from 'react-i18next';
import { useGetHomeQuotations } from 'hooks/ws/prices.hooks';

const CurrencyQuotesContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');

    const { homeQuotations, quotations, isLoadingQuotations } = useGetHomeQuotations();

    const childProps = {
        ...props,
        t,
        quotations: quotations || [],
        isLoadingQuotations,
        marketValuesUpdated: homeQuotations || [],
    };

    return <CurrencyQuotes {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyQuotesContainer.propTypes = propTypes;

export default CurrencyQuotesContainer;
