import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import NotificationItem from './NotificationItem';
import { useTranslation } from 'react-i18next';
import { ParsedNotificationBody } from 'types/api/alerts.types';
import { NotificationsContext } from 'context/notifications.context';

const NotificationItemContainer = (props: Props) => {
    const { id, setSelectedNotifications, selectedNotifications } = props;
    const { t } = useTranslation();
    const { handleDeleteNotifications, handleReadNotifications, isLoadingSubmitNotification } =
        React.useContext(NotificationsContext);

    const handleDeleteNotification = () => {
        handleDeleteNotifications({ notificationIds: [id] });
    };
    const handleReadNotification = () => {
        handleReadNotifications({ notificationIds: [id] });
    };

    const handleCheckNotification = (id: string) => {
        setSelectedNotifications(prev => {
            if (!prev) return [id];
            return [...prev, id];
        });
    };
    const handleUnCheckNotification = (id: string) => {
        setSelectedNotifications(prev => {
            if (prev?.length === 1) return null;
            return prev?.filter(p => p != id) ?? null;
        });
    };

    const childProps = {
        ...props,
        t,
        handleReadNotification,
        handleDeleteNotification,
        handleChangeNotification: selectedNotifications?.includes(id)
            ? handleUnCheckNotification
            : handleCheckNotification,
        isLoadingSubmitNotification,
    };

    return <NotificationItem {...childProps} />;
};

const propTypes = {
    date: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
};

interface extraProps {
    body: ParsedNotificationBody;
    selectedNotifications: string[] | null;
    setSelectedNotifications: React.Dispatch<React.SetStateAction<string[] | null>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NotificationItemContainer.propTypes = propTypes;

export default NotificationItemContainer;
