import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            button: {
                marginTop: 0,
                textTransform: 'uppercase',
                height: 'fit-content',
                width: { xs: '100% !important', sm: '200px', md: '354px !important' },
            },
        }),
    ) as typeof Box,
};
