import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentMarketStatus.styled';
import { IconButton, Tooltip, Typography } from '@mui/material';

const InstrumentMarketStatus = (props: Props) => {
    const { t, marketClosingTime, isMarketClosed } = props;
    return (
        <St.Container>
            <St.Dot $isMarketClosed={isMarketClosed} />
            <Typography variant="subtitle2">
                {t(
                    `market_${isMarketClosed ? 'fund' : 'instrument'}_${isMarketClosed ? 'close' : 'open'}_text`,
                )}
            </Typography>
            <Tooltip
                placement="bottom"
                title={t('tooltip_market_fund', {
                    closeTime: marketClosingTime,
                })}>
                <IconButton>
                    <St.QuestionMarkRadiusIcon />
                </IconButton>
            </Tooltip>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    marketClosingTime: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentMarketStatus.propTypes = propTypes;

export default InstrumentMarketStatus;
