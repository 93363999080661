import LoginPage from 'components/pages/LoginPage';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import i18n from 'utils/i18n';
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage';
import CreateAccountPage from 'components/pages/CreateAccountPage';
import OpenMyAccountPage from 'components/pages/OpenMyAccountPage';
import ReplacePasswordPage from 'components/pages/ReplacePasswordPage';
import { loggedOutSubroutesPaths } from 'router/routesPaths';
import EmailSentPage from 'components/pages/EmailSentPage';

/* {
        name: 'contactInfo',
        path: '/contact-info',
        title: i18n.t('layout_main_navigation_accounts') as string,
        component: ContactInfo,
    }, */
export const loggedOutSubroutes: Array<SubRouteInfo> = [
    {
        name: 'login',
        path: '/',
        title: i18n.t('layout_main_navigation_login') as string,
        component: LoginPage,
    },
    {
        name: 'login',
        path: loggedOutSubroutesPaths.login,
        title: i18n.t('layout_main_navigation_login') as string,
        component: LoginPage,
    },
    {
        name: 'sign_up',
        path: loggedOutSubroutesPaths.createAccount,
        title: i18n.t('layout_main_navigation_create_account') as string,
        component: CreateAccountPage,
    },
    {
        name: 'open_my_account',
        path: loggedOutSubroutesPaths.openMyAccount,
        title: i18n.t('layout_main_navigation_open_my_account') as string,
        component: OpenMyAccountPage,
    },
    {
        name: 'email_sent',
        path: loggedOutSubroutesPaths.emailSent,
        title: i18n.t('layout_main_navigation_email_verification') as string,
        component: EmailSentPage,
    },
    {
        name: 'forgot_password',
        path: loggedOutSubroutesPaths.forgotPassword,
        title: i18n.t('layout_main_navigation_forgot_password') as string,
        component: ForgotPasswordPage,
    },
    {
        name: 'change-password',
        path: loggedOutSubroutesPaths.replacePassword,
        title: i18n.t('layout_main_navigation_change_password') as string,
        component: ReplacePasswordPage,
    },
    {
        name: 'change-password',
        path: loggedOutSubroutesPaths.generatePassword,
        title: i18n.t('layout_main_navigation_change_password') as string,
        component: () => <ReplacePasswordPage isSigningUp />,
    },
];
