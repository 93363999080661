import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialLayout from './CommercialLayout';
import { CMSCategory, CMSRedirects } from 'types/api/cms.types';
import { useTranslation } from 'react-i18next';

const CommercialLayoutContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <CommercialLayout {...childProps} />;
};

const propTypes = {
    withGoBackButton: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    categories?: CMSCategory[];
    redirects?: CMSRedirects[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialLayoutContainer.propTypes = propTypes;

export default CommercialLayoutContainer;
