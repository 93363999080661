import { Box, styled } from '@mui/material';
import { Form } from 'formik';
import { Link } from 'react-router-dom';

export default {
    ContainerHeader: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            margin: { md: '1rem 12rem', xs: '1rem' },
        }),
    ),
    ContainerItems: styled(Box)(({ theme }) => ({
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    })),
    Form: styled(Form)(({ theme }) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
    })),
    Link: styled(Link)(({ theme }) => ({
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: '1rem',
        textAlign: 'center',
        textDecoration: 'none',
        maxWidth: 'fit-content',
        alignSelf: 'center',
    })),
    ContainerTexts: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '5px',
    })),
};
