import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PersonalDataItem from '../ProfilePersonalDataPage/PersonalDataItem';
import { UserData } from 'types/api/auth.types';
import St from './ChannelProfilePersonalDataPage.styled';

const ProfilePersonalDataPage = (props: Props) => {
    const { userData, t, referralCodes, referralCodeTitle } = props;

    return (
        <St.SectionWrapper component={'section'} data-testid="ChannelProfilePersonalDataPage-page">
            <St.SectionInfo>
                <St.TitleSection>{t('personal_data_title')}</St.TitleSection>
                <St.GridContainer container>
                    <PersonalDataItem
                        title={t('personal_data_name')}
                        description={userData?.name}
                    />
                    <PersonalDataItem
                        title={t('personal_data_last_name')}
                        description={userData?.lastName}
                    />
                    <PersonalDataItem
                        title={t('personal_data_email')}
                        description={userData?.email}
                    />
                    <PersonalDataItem
                        title={t('personal_data_user_name')}
                        description={userData?.username}
                    />
                    <PersonalDataItem title={referralCodeTitle} description={referralCodes} />
                </St.GridContainer>
            </St.SectionInfo>
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    userData: UserData | null;
    referralCodes: string;
    referralCodeTitle: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePersonalDataPage.propTypes = propTypes;

export default ProfilePersonalDataPage;
