import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

export default {
    ActionButton: styled(LoadingButton)(({ theme }) => ({
        marginTop: '1.5em', // Analizar sacar esta prop
        /* Por el Momento para mantener un estandar en los estilos hay un width fijo, a fin de que todos los Buttons sean iguales. */
        minWidth: '130px',
    })),
};
