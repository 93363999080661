import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HeadLogo.styled';
import consultatioLogo from 'assets/img/general/consultatioInvestmentsLogo.jpg';

const HeadLogo = (props: Props) => {
    // const { } = props;

    return (
        <St.LogoContainer>
            <St.Logo src={consultatioLogo} alt="Consultatio Plus" />
        </St.LogoContainer>
    );
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeadLogo.propTypes = propTypes;

export default HeadLogo;
