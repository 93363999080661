import { tCommon } from 'constants/appConstants';
import { MarketContext } from 'context/market.context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketTableInstrumentType, TagType } from 'types/api/instruments.types';

const tBase = 'market_table_';
/* {
                        id: 'FINANCIAL&tags=MATERIALS&tags=ENERGY&tags=INDUSTRY&tags=COMSNTEC&tags=CONSUMER&tags=HEALT&tags=ETF&tags=OTHER',
                        name: t(`${tBase}all`),
                    }, */
const useFilters = () => {
    const { t } = useTranslation('market');
    const { tagsForInstruments, tagsForInstrumentsIsLoading } = React.useContext(MarketContext);
    const { filteredByTypeOptions, listOfCurrencies, liquidityList, classList } =
        React.useMemo(() => {
            const filteredByTypeOptions: {
                [key in MarketTableInstrumentType]: Array<TagType>;
            } = {
                EQUITY: [...(tagsForInstruments?.EQUITY || [])],
                BOND: [...(tagsForInstruments?.BOND || [])],
                CERTIFICATE: [
                    ...(tagsForInstruments?.CERTIFICATE
                        ? [
                              {
                                  name: tagsForInstruments?.CERTIFICATE.map(el => el.name).join(
                                      '&tags=',
                                  ),
                                  description: t(`${tBase}all`),
                                  order: 1,
                              },
                          ]
                        : []),
                    ...(tagsForInstruments?.CERTIFICATE || []),
                ],
                CURRENCY: [],
                EXTERIOR: [],
                EXTERIOR_BOND: [],
                EXTERIOR_FUND: [],
                EXTERIOR_ON: [],
                ADR: [],
                FUND: [...(tagsForInstruments?.FUND || [])],
                LETTER: [],
                OTHER: [],
                PREFERRED: [
                    {
                        name: 'CERTIFICATE&type=BOND&type=EQUITY',
                        description: t(`${tBase}all`),
                        order: 1,
                    },
                    { name: 'EQUITY', description: t('EQUITY', tCommon), order: 2 },
                    { name: 'CERTIFICATE', description: t('CERTIFICATE', tCommon), order: 3 },
                    { name: 'BOND', description: t('BOND', tCommon), order: 4 },
                ],
            };
            const listOfCurrencies = [
                { name: 'ALL', description: t(`${tBase}all_currency`), order: 1 },
                { name: 'ARS', description: 'ARS', order: 2 },
                { name: 'USD', description: 'USD', order: 3 },
                { name: 'USD-C', description: 'USD-C', order: 4 },
            ];
            const liquidityList = [
                { name: 'all', description: t(`${tBase}all`), order: 1 },
                { name: 'Inmediato', description: 'Inmediato', order: 2 },
                { name: '24 horas', description: '24 horas', order: 3 },
                { name: '48 horas', description: '48 horas', order: 4 },
                { name: '72 horas', description: '72 horas', order: 5 },
            ];
            const classList = [
                { name: 'CLASS_A', description: 'Clase A', order: 1 },
                { name: 'CLASS_B', description: 'Clase B', order: 2 },
                { name: 'CLASS_C', description: 'Clase C', order: 3 },
            ];

            return {
                filteredByTypeOptions,
                listOfCurrencies,
                liquidityList,
                classList,
            };
        }, [t, tagsForInstruments]);

    return {
        filteredByTypeOptions,
        listOfCurrencies,
        liquidityList,
        classList,
        tagsForInstrumentsIsLoading,
    };
};

export default useFilters;
