import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentMarketStatus from './InstrumentMarketStatus';
import { useTranslation } from 'react-i18next';

const InstrumentMarketStatusContainer = (props: Props) => {
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <InstrumentMarketStatus {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isMarketClosed: boolean;
    marketClosingTime: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentMarketStatusContainer.propTypes = propTypes;

export default InstrumentMarketStatusContainer;
