import { useContext, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EditBankAccount from './EditBankAccount';
import { useTranslation } from 'react-i18next';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import { AlertColor } from '@mui/material';
import { useUpdateBankAccount } from 'hooks/api/cahsflow.hooks';
import { AuthContext } from 'context/auth.context';

const EditBankAccountContainer = (props: Props) => {
    const { row, setSnackBarMessage, close } = props;
    const { t } = useTranslation();
    const [description, setDescription] = useState(row.description);
    const { updateBankAccount, updateIsLoading } = useUpdateBankAccount(setSnackBarMessage, close);
    const { selectedCustomer } = useContext(AuthContext);

    const handleSubmit = () =>
        updateBankAccount({
            id: row.id,
            body: { description, operableUserUuid: selectedCustomer?.uuid },
        });

    const childProps = {
        ...props,
        status: row.status,
        description,
        isLoading: updateIsLoading,
        setDescription,
        handleSubmit,
        updateBankAccount,
        t,
    };

    return <EditBankAccount {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetBankAccountsItem;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditBankAccountContainer.propTypes = propTypes;

export default EditBankAccountContainer;
