import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomLabel.styled';

const CustomLabel = (props: Props) => {
    const { id, value, label, required } = props;

    return (
        <St.Wrapper>
            <St.Typography id={`${id}`} variant="body2">
                {label}
                {required && ' *'}
            </St.Typography>
            <St.Value>{value}</St.Value>
        </St.Wrapper>
    );
};

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

interface extraProps {
    value: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomLabel.propTypes = propTypes;

export default CustomLabel;
