import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './GoBackButton.styled';

const GoBackButton = (props: Props) => {
    const { goBack } = props;

    return <St.GoBackButton onClick={goBack} />;
};

const propTypes = {
    goBack: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
GoBackButton.propTypes = propTypes;

export default GoBackButton;
