import { Box, styled, Typography } from '@mui/material';

export default {
    Container: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            padding: { lg: '1rem', xs: '0' },
        }),
    ),
    AvailableContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '10px',
    })),
    InputContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
    })),
    EstimatedPriceText: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
    })),
    EstimatedPriceValue: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.lightGreen,
        fontWeight: 700,
    })),
    Typography: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
    })),
};
