import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './NavbarItem.styled';
import SubMenu from 'components/common/SubMenu';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import ArrowDown from 'assets/icons/arrow-down-s-line.svg?react';
import { replaceSpecialCharacters } from 'utils/helpers/commonHelper';
import { NavigateFunction } from 'react-router-dom';
import { Theme } from '@mui/material';

const NavbarItem = (props: Props) => {
    const { t, route, path, navigate, openSubmenu, theme, handleHover } = props;
    return (
        <St.ButtonMUI
            $activated={path.split('/')[1] === route.name}
            // $submenu={!(route.subRoutes && route.subRoutes.length > 0)}
            onMouseLeave={() => handleHover(null)}
            onMouseEnter={() => handleHover(route, !!route.subRoutes)}
            aria-label={t('menu_button', { menu: route.title })}
            onClick={() =>
                !(route.subRoutes && route.subRoutes.length > 0)
                    ? navigate(route?.path as string)
                    : null
            }>
            <span className="sp_nav" style={{ display: 'flex', alignItems: 'center' }}>
                {replaceSpecialCharacters(route?.title)}
                {route.subRoutes && (
                    <CustomIconCreator
                        icon={ArrowDown}
                        sx={{
                            marginLeft: '0.3rem',
                            width: '0.8rem',
                            height: '0.8rem',
                            fill: theme.palette.custom.vibrantblue,
                        }}
                    />
                )}
            </span>
            {route.subRoutes && (
                <St.SubMenuWrapper>
                    <SubMenu open={openSubmenu === route?.name} menu={{ ...route }} />
                </St.SubMenuWrapper>
            )}
        </St.ButtonMUI>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
};

interface extraProps {
    route: SubRouteInfo;
    navigate: NavigateFunction;
    theme: Theme;
    handleHover: (menu: SubRouteInfo | null, handleMouseLeave?: boolean) => void;
    openSubmenu: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NavbarItem.propTypes = propTypes;

export default NavbarItem;
