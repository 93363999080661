import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingLayout from './OnboardingLayout';
import { onboardingRoutesPaths } from 'router/routesPaths';
import { useLocation } from 'react-router-dom';
import { onboardingRoutes } from 'router/subRoutes/onboardingLayout';
import useScreenWidth from 'hooks/common/screenWidth';

const OnboardingLayoutContainer = (props: Props) => {
    // const {} = props;
    const { home, blog } = onboardingRoutesPaths;
    const enableNavbar = [home, blog].includes(useLocation().pathname);
    const screenWidth = useScreenWidth();

    const childProps = {
        ...props,
        enableNavbar,
        routes: onboardingRoutes.slice(0, 2),
        isMobile: !(screenWidth > 950),
    };

    return <OnboardingLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingLayoutContainer.propTypes = propTypes;

export default OnboardingLayoutContainer;
