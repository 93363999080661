import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingInvestmentTestResultPage from './OnboardingInvestmentTestResultPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetInvestmentProfileFeatures } from 'hooks/common/users.hooks';
import { InvestmentTestValues } from 'types/common/users.types';

const OnboardingInvestmentTestResultPageContainer = (props: Props) => {
    // const { prevTest } = props;
    const { t } = useTranslation('onboarding');
    const { userData } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const { investorFeatures } = useGetInvestmentProfileFeatures(userData?.info?.investmentProfile);

    const childProps = {
        ...props,
        t,
        userData,
        navigate,
        isOnboarding: location?.pathname.includes('onboarding'),
        investorFeatures,
        investmentProfile:
            location?.state?.investmentProfile.toLowerCase() ??
            (userData?.info?.investmentProfile?.toLowerCase() as InvestmentTestValues) ??
            null,
    };

    return <OnboardingInvestmentTestResultPage {...childProps} />;
};

const propTypes = {
    prevTest: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestResultPageContainer.propTypes = propTypes;

export default OnboardingInvestmentTestResultPageContainer;
