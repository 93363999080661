import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfileTestExpiredPage.styled';
import { Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import TopHeader from 'components/common/TopHeader';
import HeadLogo from 'components/common/HeadLogo';
import { formatDate } from 'utils/helpers/dateHelper';

const ProfileTestExpiredPage = (props: Props) => {
    const { t, navigateToInvestmentTest, updatedAt } = props;
    return (
        <St.SectionWrapper>
            <HeadLogo />
            <St.HeaderExpiredWrapper>
                <TopHeader
                    firstElement={t('investment_test_page_title_expired') as string}
                    title={t('investment_test_page_subtitle_expired')}
                />
                <St.ActionButtonWrapper>
                    <ActionButton
                        variant="contained"
                        type="button"
                        onClick={() => navigateToInvestmentTest()}>
                        {t('investment_test_page_do_another_test')}
                    </ActionButton>
                </St.ActionButtonWrapper>
                <Typography textAlign={'center'} marginTop={'2rem'}>
                    {
                        t('last_test_date', {
                            date: formatDate(updatedAt),
                        }) as string
                    }
                </Typography>
            </St.HeaderExpiredWrapper>
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    navigateToInvestmentTest: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileTestExpiredPage.propTypes = propTypes;

export default ProfileTestExpiredPage;
