import { Box, Divider, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(() => ({})),
    AvailableBalanceContainer: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem('5px'),
        marginBottom: pxToRem('20px'),
    })),
    WalletSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
            marginBottom: pxToRem('20px'),
        }),
    ),
    AccountInformationSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
        }),
    ),
    DottedDivider: styled(Divider)(({ theme }) => ({
        borderStyle: 'dotted',
        borderColor: theme.palette.custom.darkblue6,
        marginTop: pxToRem('40px'),
        marginBottom: pxToRem('40px'),
    })),
    AumText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.primary.main,
        }),
    ),
    MoneyLabelContainer: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: pxToRem('15px'),
    })),
    RadioButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { sm: '211px', xs: '100%' },
            marginBottom: pxToRem('20px'),
        }),
    ),
};
