import { Box, Grid, Typography, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '0rem 4rem',
        justifyItems: 'center',
        maxWidth: '90%',
        aspectRatio: '4/3',
    })) as typeof Box,
    LatestSection: styled(Box)(() => ({
        width: '100%',
        position: 'relative',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        marginBottom: '4rem',
        '& .carousel .control-dots': {
            bottom: '0px',
        },
        '& .carousel .slide img': {
            height: '70vh',
            objectFit: 'cover',
            width: '100%',
        },
        '@media (max-width: 768px)': {
            marginRight: 0,
            width: '100%',
            '& .carousel .slide img': {
                height: '40vh',
            },
        },
    })),
    CarouselItemContainer: styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px',
        position: 'relative',
        width: '100%',
    }),
    LatestImageContainer: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
        height: '60vh',
        '@media (max-width: 1024px)': {
            height: '50vh',
        },
        '@media (max-width: 768px)': {
            height: '40vh',
        },
    })),
    ImageContainer: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
        height: 'auto',
        marginBottom: '40px',
    })),
    Image: styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }),
    TitleOverlay: styled(Box)(() => ({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
    })),
    LatestTitle: styled(Typography)(() => ({
        color: 'white',
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'left',
        '@media (max-width: 1024px)': {
            fontSize: '1.5rem',
        },
        '@media (max-width: 768px)': {
            fontSize: '1.2rem',
        },
    })),
    CardWrapper: styled(Grid)(({ theme }) =>
        theme.unstable_sx({
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: 'repeat(4,1fr)' },
            gridTemplateRows: '190px',
            m: '2rem 0 5rem',
        }),
    ) as typeof Grid,
    CategoriesSection: styled(Box)<{ $matchessm?: boolean; $matchesmd?: boolean }>(
        ({ theme, $matchessm, $matchesmd }) =>
            theme.unstable_sx({
                '& .carousel .control-dots': {
                    display: $matchessm || $matchesmd ? 'none' : 'inline-block',
                },
                '& .carousel-root': {
                    marginTop: '-30px',
                    marginBottom: '-30px',
                },
            }),
    ),
};
