import { Box, IconButton, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        maxWidth: '700px',
        width: '80%',
        display: 'flex',
    })),
    Container: styled(Box)(() => ({
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        width: '100%',
    })),
    CreateCardContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        maxWidth: '700px',
        width: '80%',
        backgroundColor: '#F5F5F5',
        justifyContent: 'space-between',
        padding: '10px 20px',
        borderRadius: '10px',
        alignItems: 'center',
    })),
    CustomIconButton: styled(IconButton)(({ theme }) => ({
        border: '1px solid #307FDD',
        padding: '15px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    })),
    BoxModal: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '40px',
            backgroundColor: 'white',
            borderRadius: '10px',
            width: { xs: '70%', sm: '80%' },
            maxWidth: '400px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }),
    ),
    CardContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        maxWidth: '700px',
        width: '90%',
        backgroundColor: '#F5F5F5',
        justifyContent: 'space-between',
        padding: '20px',
        borderRadius: '10px',
    })),
    ContainerInfo: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
    })),
};
