import { Box, styled } from '@mui/material';
import CustomRadioButton from 'components/common/CustomRadioButton';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Wrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '0.5rem',
            justifyContent: 'space-between',
            mb: '1rem',
            '.radioButton': {
                py: '0.6145rem',
                height: 'calc(40px - 1.328rem)',
                input: {
                    marginRight: '.4rem',
                },
                span: {
                    fontWeight: '500',
                    lineHeight: pxToRem('18.75px'),
                    letterSpacing: '0.0015em',
                    color: theme.palette.custom.darkblue4,
                },
            },
        }),
    ),
    RightFiltersWrapper: styled(
        Box,
        transientOptions,
    )<{ $maxW: string }>(({ theme, $maxW }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            justifyContent: 'space-between',
            maxWidth: pxToRem($maxW),
            width: '100%',
            gap: '0.5rem',
        }),
    ),
    FirstWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '0.5rem',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
        }),
    ),
    CustomRadioButton: styled(CustomRadioButton)(() => ({})),
    SecondWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            maxWidth: { xs: 'fit-content', sm: pxToRem('485px') },
            width: '100%',
            gap: { xs: '0.5rem', lg: '0' },
            '.radioButton': {
                minWidth: 'calc(200.29px - 9.832px)',
                maxWidth: 'calc(200.29px - 9.832px)',
                width: '100%',
            },
            '.MarketSearchBar': {
                height: pxToRem('38.4px'),
            },
        }),
    ),
};
