import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContactInfoContactSection from './OnboardingContactInfoContactSection';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'context/onboarding.context';
import { useUploadContactInfoStep } from 'hooks/api/onboarding.hooks';
import { GetUserInformationForOnboardingResponse, LegalData } from 'types/api/onboarding.types';
import { useNavigate } from 'react-router-dom';
import { ContactInfoExtraData } from 'types/pages/onboardingContactInfo.types';

const OnboardingContactInfoContactSectionContainer = (props: Props) => {
    const { phoneNumber } = props;
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate();
    const { legalData, userInformationUploaded } = React.useContext(OnboardingContext);

    const prevData = {
        floor: userInformationUploaded?.contactInfo.floor,
        apartment: userInformationUploaded?.contactInfo.apartment,
        city: userInformationUploaded?.contactInfo.city,
        lastName: userInformationUploaded?.personalInfo.lastName,
        name: userInformationUploaded?.personalInfo.name,
        state: userInformationUploaded?.contactInfo.state,
        streetAddress: userInformationUploaded?.contactInfo.streetName,
        streetNumber: userInformationUploaded?.contactInfo.streetNum,
        taxDocNumber: userInformationUploaded?.personalInfo.taxDocNumber,
        zipCode: userInformationUploaded?.contactInfo.zipCode,
        contactInfoExtra: {
            documentPath: userInformationUploaded?.contactInfo.documentPath,
            tower: userInformationUploaded?.contactInfo.tower,
            sector: userInformationUploaded?.contactInfo.sector,
            square: userInformationUploaded?.contactInfo.square,
        },
    };
    const { mutate, errorMessage: errorSubmit, isLoading } = useUploadContactInfoStep();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handleContactInfo = (values: LegalData, extraData?: ContactInfoExtraData) => {
        if (legalData) {
            // console.log(values, extraData);
            mutate(
                {
                    phoneNumber,
                    mobileNumber: phoneNumber,
                    contactType: 'REAL',
                    floor: values.floor,
                    apartment: values.apartment,
                    state: values.state,
                    city: values.city,
                    country: 'Argentina',
                    zipCode: values.zipCode,
                    streetNum: values.streetNumber,
                    streetName: values.streetAddress,
                    documentPath: extraData?.documentPath ?? null,
                    sector: extraData?.sector ?? '',
                    square: extraData?.square ?? '',
                    tower: extraData?.tower ?? '',
                },
                {
                    onSuccess() {
                        setOpenModal(false);
                        navigate('/');
                    },
                },
            );
        }
    };

    const childProps = {
        ...props,
        t,
        legalData: (userInformationUploaded ? prevData : legalData) as any,
        errorSubmit,
        isLoading,
        openModal,
        setOpenModal,
        handleContactInfo,
    };

    return <OnboardingContactInfoContactSection {...childProps} />;
};

const propTypes = {};

interface extraProps {
    phoneNumber: string;
    userInformationUploaded: GetUserInformationForOnboardingResponse | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoContactSectionContainer.propTypes = propTypes;

export default OnboardingContactInfoContactSectionContainer;
