import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketOthersPage from './MarketOthersPage';
import { useTranslation } from 'react-i18next';

const MarketOthersPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <MarketOthersPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketOthersPageContainer.propTypes = propTypes;

export default MarketOthersPageContainer;
