import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

interface ImageButtonProps {
    internal?: boolean;
}

export default {
    ImageButton: styled(LoadingButton)<ImageButtonProps>(({ theme, internal }) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: 'inherit',
        width: pxToRem('322px'),
        height: pxToRem('299px'),
        border: internal ? 'none' : `${pxToRem('1px')} solid #A2ADBB`,
        borderRadius: `${pxToRem('24px')} !important`,
        overflow: 'hidden',
        position: 'relative',
        '&:hover': {
            border: internal && 'none',
            transform: 'scale(0.99)',
        },
    })),
    Image: styled('img')<ImageButtonProps>(({ theme, internal }) =>
        theme.unstable_sx({
            height: internal ? '100%' : '90%',
            width: internal ? '100%' : '100%',
            // objectFit: internal ? 'cover' : 'contain',
        }),
    ),
    TextBox: styled(Box)<ImageButtonProps>(({ internal }) => ({
        width: '100%',
        fontSize: pxToRem('25px'),
        position: internal ? 'absolute' : 'static',
        bottom: internal ? '0' : 'auto',
        backgroundColor: 'transparent',
        color: internal ? '#FFFFFF' : 'inherit',
        padding: internal ? pxToRem('15px') : '0',
        marginBottom: internal ? '0' : pxToRem('20px'),
        textAlign: 'center',
    })),
};
