import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EnableMfaModal from './EnableMfaModal';
import { useEnableMfaEmail, useValidateOtp } from 'hooks/api/auth.hooks';
import { AuthContext } from 'context/auth.context';
import { setUserDataStorage } from 'storage/auth.storage';
import { useTranslation } from 'react-i18next';

const EnableMfaModalContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { setUserData, userData, isTermsAccepted } = React.useContext(AuthContext);
    const { mutate: sendEmailToEnableMfa } = useEnableMfaEmail();
    const {
        mutate: enableMfa,
        error: errorValidate,
        isLoading: isLoadingValidate,
    } = useValidateOtp();

    const sendCodeEnableMfa = (otp: string) => {
        enableMfa(
            { otp },
            {
                onSuccess: () => {
                    if (userData) {
                        setUserData({ ...userData, mfaType: 'EMAIL' });
                        setUserDataStorage({ ...userData, mfaType: 'EMAIL' });
                    }
                    close && close();
                    setSnackBarMessage && setSnackBarMessage(t('mfa_enable_snackbar'), 'success');
                },
            },
        );
    };

    const functionUseEffectEnable = () => {
        if (!isTermsAccepted) {
            return;
        }
        sendEmailToEnableMfa(
            {},
            {
                onSuccess() {
                    setSnackBarMessage &&
                        setSnackBarMessage(
                            t('preferences_mfa_send_enable_email_success'),
                            'success',
                        );
                },
                onError: () => {
                    setSnackBarMessage &&
                        setSnackBarMessage(t('preferences_mfa_send_enable_email_error'), 'error');
                },
            },
        );
    };

    const childProps = {
        ...props,
        t,
        errorValidate,
        isLoadingValidate,
        sendCodeEnableMfa,
        functionUseEffectEnable,
    };

    return <EnableMfaModal {...childProps} />;
};

const propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool.isRequired,
    setSnackBarMessage: PropTypes.func,
    isPreferences: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EnableMfaModalContainer.propTypes = propTypes;

export default EnableMfaModalContainer;
