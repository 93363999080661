import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DDJJModal.styled';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { DeclarationItem } from 'types/api/declarations.types';
import { formatDate } from 'utils/helpers/dateHelper';
import { pxToRem } from 'utils/helpers/stylesHelper';

const HtmlRenderer = ({ htmlContent }: { htmlContent: string | undefined }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent || '' }} />;
};

const DDJJModal = (props: Props) => {
    const {
        isOpen,
        title,
        t,
        onSubmit,
        checked,
        handleChangeCheck,
        showDJ,
        close,
        declarations,
        toggleShowDJ,
        isDDJJOpen,
        isSubmitEnabled,
        isLoading,
    } = props;

    return (
        <Dialog open={isOpen} onClose={close} maxWidth={'md'}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent
                sx={{
                    width: isDDJJOpen ? pxToRem(850) : pxToRem(550),
                    height: 'auto',
                    overflowY: 'auto',
                }}>
                {declarations &&
                    declarations.map((declaration: DeclarationItem, index: number) => (
                        <St.ModalContainer key={declaration.id}>
                            <St.ModalTitle>
                                {t('ddjj_modal_dj_title', {
                                    number: index + 1,
                                    date: formatDate(declaration.createdAt),
                                })}
                            </St.ModalTitle>

                            <FormControlLabel
                                sx={{ marginTop: pxToRem(5) }}
                                label={<St.CheckLabel>{t('ddjj_modal_check')}</St.CheckLabel>}
                                control={
                                    <Checkbox
                                        checked={!!checked[declaration.id]}
                                        onChange={() => handleChangeCheck(declaration.id)}
                                    />
                                }
                            />

                            <ActionButton
                                size="large"
                                type="button"
                                sx={{
                                    width: pxToRem(10),
                                    marginTop: pxToRem(-8),
                                }}
                                disabled={isDDJJOpen && showDJ !== declaration.id}
                                onClick={() => toggleShowDJ(declaration.id)}>
                                {showDJ === declaration.id
                                    ? t('ddjj_modal_close_dj')
                                    : t('ddjj_modal_open_dj')}
                            </ActionButton>

                            {showDJ === declaration.id && (
                                <St.DDJJContainer>
                                    <HtmlRenderer htmlContent={declaration.version?.content} />
                                </St.DDJJContainer>
                            )}
                        </St.ModalContainer>
                    ))}
            </DialogContent>
            <St.DialogActions>
                <ActionButton fullWidth onClick={close} color="error" variant="outlined">
                    {t('cancel', tCommon)}
                </ActionButton>
                <ActionButton
                    fullWidth
                    onClick={onSubmit}
                    disabled={!isSubmitEnabled || isLoading}
                    loading={isLoading}
                    variant="contained">
                    {t('confirm', tCommon)}
                </ActionButton>
            </St.DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

interface extraProps {
    isOpen: boolean;
    title: string;
    showDJ: number | null;
    close(): void;
    checked: Record<string, boolean>;
    handleChangeCheck: (id: number) => void;
    declarations: DeclarationItem[] | undefined;
    toggleShowDJ: (id: number) => void;
    matchesXl: boolean;
    isSubmitEnabled: boolean;
    isDDJJOpen: boolean;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DDJJModal.propTypes = propTypes;

export default DDJJModal;
