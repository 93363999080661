import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ExchangeDetail.styled';
import { ExchangeOrderItem } from 'types/api/orders.types';
import InfoLabel from 'components/common/InfoLabel';
import { REJECTED, tCommon, USD } from 'constants/appConstants';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';

const ExchangeDetail = (props: Props) => {
    const { t, row } = props;
    return (
        <St.Container>
            {row.status === REJECTED && row.reason && (
                <InfoLabel
                    fontSize={'14px'}
                    label={t('rejected_reason', tCommon)}
                    text={row.reason}
                />
            )}
            {row.operator && (
                <InfoLabel
                    fontSize={'14px'}
                    label={t('operator', tCommon)}
                    text={getFullName(row.operator)}
                />
            )}
            <InfoLabel
                fontSize={'14px'}
                label={t('exchange_amount_label')}
                text={row.amount ? convertCurrencyFormat(row.amount, USD) : '-'}
            />
            <InfoLabel
                fontSize={'14px'}
                label={t('buy_price_label')}
                text={row.operatedBuyPrice ? convertCurrencyFormat(row.operatedBuyPrice, USD) : '-'}
            />
            <InfoLabel
                fontSize={'14px'}
                label={t('sell_price_label')}
                text={
                    row.operatedSellPrice ? convertCurrencyFormat(row.operatedSellPrice, USD) : '-'
                }
            />
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: ExchangeOrderItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeDetail.propTypes = propTypes;

export default ExchangeDetail;
