import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HelpPage.styled';

import FooterLayout from 'components/layouts/FooterLayout';
import HeadLogo from 'components/common/HeadLogo';
import { Link } from 'react-router-dom';
import {
    HELP_EDUCATION_ANCHOR,
    HELP_FAQ_ANCHOR,
    HELP_GLOSSARY_ANCHOR,
    HELP_SEND_EMAIL_ANCHOR,
    HELP_CHAT_WITH_US_ANCHOR,
} from '../../../constants/appConstants';

import TopHeader from 'components/common/TopHeader';
import NextIcon from '../../../assets/icons/arrow-next.svg';
const HelpPage = (props: Props) => {
    const { t } = props;

    const arrayLinks = [
        {
            title: t('help_education'),
            link: HELP_EDUCATION_ANCHOR,
        },
        {
            title: t('help_faq'),
            link: HELP_FAQ_ANCHOR,
        },
        {
            title: t('help_glossary'),
            link: HELP_GLOSSARY_ANCHOR,
        },
        {
            title: t('help_send_email'),
            link: HELP_SEND_EMAIL_ANCHOR,
        },
        {
            title: t('help_chat_with_us'),
            link: HELP_CHAT_WITH_US_ANCHOR,
        },
    ];
    return (
        <St.ContainerPage>
            <Link to="/">
                <HeadLogo />
            </Link>
            <St.ContainerTopHeader>
                <TopHeader title={t('help_title')} firstElement={t('help_first_title')} />
            </St.ContainerTopHeader>

            <St.SectionWrapper component={'section'} data-testid="HelpPage-page">
                {arrayLinks.map((item, index) => (
                    <St.Link key={index} href={item.link} target="_blank">
                        <St.TitleLink>{item.title}</St.TitleLink>
                        <St.BoxImage>
                            <img src={NextIcon} />
                        </St.BoxImage>
                    </St.Link>
                ))}
            </St.SectionWrapper>
            <FooterLayout />
        </St.ContainerPage>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HelpPage.propTypes = propTypes;

export default HelpPage;
