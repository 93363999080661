import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialDetailPage from './CommercialDetailPage';
import { useLocation } from 'react-router-dom';
import { useGetCommercialDetail } from 'hooks/api/cms.hooks';

const CommercialDetailPageContainer = (props: Props) => {
    // const {} = props;
    const { pathname } = useLocation();

    const id = pathname.split('/').pop();
    const { data, isLoading } = useGetCommercialDetail(Number(id));

    const childProps = {
        ...props,
        isLoading,
        name: data?.name,
        categories: data?.subcategories,
        redirects: data?.redirectsForCategory,
    };

    return <CommercialDetailPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialDetailPageContainer.propTypes = propTypes;

export default CommercialDetailPageContainer;
