import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingDniPhotoPage from './OnboardingDniPhotoPage';
import * as Yup from 'yup';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUploadDocument } from 'hooks/api/onboarding.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useNavigate } from 'react-router-dom';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { OnboardingContext } from 'context/onboarding.context';
import { UploadDocumentRequest } from 'types/api/onboarding.types';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = (uploadedFiles: UploadDocumentRequest[]) => ({
    dniPhoto: uploadedFiles[0]?.documentPath ?? null,
    dniSecondPhoto: uploadedFiles[1]?.documentPath ?? null,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            dniPhoto: Yup.string().required(tRequiredFieldError),
            dniSecondPhoto: Yup.string().nullable(),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

export const OnboardingDniPhotoPageContainer = (props: Props) => {
    const { t } = useTranslation('onboarding');
    const { isLoadingLegalData, isLoadingSteps, userInformationUploaded } =
        React.useContext(OnboardingContext);
    const [loadingFile, setLoadingFile] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadDocumentRequest[]>([]);
    const { mutate, isLoading: isLoadingSubmit, errorMessage } = useUploadDocument();
    const { setSnackBarMessage } = useSnackBar();
    const navigate = useNavigate();

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            mutate(
                {
                    documentPath: values?.dniPhoto,
                    documentName: t('dni_photo_file_1'),
                },
                {
                    onSuccess: () => {
                        setSnackBarMessage(t('success'));
                        if (values?.dniSecondPhoto === null) navigate('home'); // siguiente paso
                    },
                    onError: error => {
                        setSnackBarMessage(
                            t(errorResponseHelper(error as any), tErrorsContext),
                            'error',
                        );
                    },
                },
            );
            values?.dniSecondPhoto &&
                mutate(
                    {
                        documentPath: values?.dniSecondPhoto,
                        documentName: t('dni_photo_file_2'),
                    },
                    {
                        onSuccess: () => {
                            setSnackBarMessage(t('success'));
                            // si ambas mutaciones funcionan, navegar a...
                            navigate('home'); // siguiente paso
                        },
                        onError: error => {
                            setSnackBarMessage(
                                t(errorResponseHelper(error as any), tErrorsContext),
                                'error',
                            );
                        },
                    },
                );
        },
        [mutate, setSnackBarMessage, t],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(uploadedFiles),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, uploadedFiles],
    );

    const formik = useFormik(formikInitProps);

    const enableButton = React.useMemo(() => {
        return !!formik.values.dniPhoto || !!formik.values.dniSecondPhoto;
    }, [formik.values]);

    React.useEffect(() => {
        if (userInformationUploaded?.documentsInfo) {
            const namesToFilter = [t('dni_photo_file_1'), t('dni_photo_file_2')];
            const filteredDocs: UploadDocumentRequest[] = [];
            namesToFilter.forEach(name => {
                const firstDoc = userInformationUploaded.documentsInfo.find(
                    doc => doc.documentName === name,
                );
                if (firstDoc) filteredDocs.push(firstDoc);
            });
            if (filteredDocs.length > 0) {
                setLoadingFile(true);
                setUploadedFiles(filteredDocs);
            }
        }
    }, [userInformationUploaded, t]);

    const childProps = {
        t,
        formik,
        enableButton: enableButton && !loadingFile,
        isLoadingSubmit,
        errorMessage,
        isLoading: isLoadingLegalData || isLoadingSteps,
        setLoadingFile,
        preloadedData: !!userInformationUploaded,
    };
    return <OnboardingDniPhotoPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingDniPhotoPageContainer.propTypes = propTypes;

export default OnboardingDniPhotoPageContainer;
