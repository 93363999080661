import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';
const GlobalStyle = () => {
    const theme = useTheme();

    return (
        <GlobalStyles
            styles={{
                html: {
                    height: '100%',
                    msOverflowStyle: 'scrollbar',
                    scrollbarWidth: 'none',
                    overflowY: 'auto',
                    scrollbarGutter: 'stable',
                    /// ////
                    fontSize: '16px',
                    lineHeight: 1.5,
                    boxSizing: 'border-box',
                },
                body: {
                    width: '100%',
                    backgroundColor: theme.palette.background.default,
                    margin: 0,
                    fontFamily: 'Roboto',
                    color: '#333',
                    display: 'flex',
                    '.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
                        borderRadius: '20px',
                    },
                    '.MuiButton-root': {
                        textTransform: 'none',
                        boxShadow: 'none',
                        borderRadius: '50px',
                    },
                },
                '#root': {
                    width: '100%',
                    height: '100%',
                },
                ':root': {
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    // Revisar si va
                    // font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
                    // line-height: 1.5;
                    // font-weight: 400;

                    // color-scheme: light dark;
                    // color: rgba(255, 255, 255, 0.87);
                    // background-color: #242424;

                    // font-synthesis: none;
                    // text-rendering: optimizeLegibility;
                    // -webkit-font-smoothing: antialiased;
                    // -moz-osx-font-smoothing: grayscale;
                    // -webkit-text-size-adjust: 100%;
                },
                'html::-webkit-scrollbar': {
                    width: '5px',
                },
                'html::-webkit-scrollbar-track': {
                    backgroundColor: theme.palette.background.default,
                },
                'html::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '1rem',
                },
                // '.logo' {
                //     height: '6em'
                //     padding: '1.5em',
                //     willChange: 'filter,
                //     transition: filter 300ms;
                //   }

                // h1 {
                //     font-size: 3.2em;
                //     line-height: 1.1;
                //   }

                // @media (prefers-color-scheme: light) {
                //     :root {
                //       color: #213547;
                //       background-color: #ffffff;
                //     }
                //     a:hover {
                //       color: #747bff;
                //     }
                //     button {
                //       background-color: #f9f9f9;
                //     }
                //   }
            }}
        />
    );
};

export default GlobalStyle;
