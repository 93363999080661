import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomPieChart from './CustomPieChart';
import { ApexOptions } from 'apexcharts';
import { CHART_MAIN_COLORS } from 'constants/color.constants';
import { formatNumber } from 'utils/helpers/commonHelper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import './CustomPieChart.css';
import { mappedInstrumentTypesValues } from 'constants/appConstants';

const CustomPieChartContainer = (props: Props) => {
    const { labels, total, extraOptions, colors, setInstrumentType, id = 'big-chart' } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const { matchesMd } = useBreakpoints();

    const options: ApexOptions = {
        labels,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 0,
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            show: true,
            fontSize: '16px',
            formatter: function (val, opts) {
                return `<div class="legend-text" data-series-index="${opts.seriesIndex}">
                        <span class="legend-title">${val}</span>
                        <span class="legend-value">
                            ${opts.w.globals.series[opts.seriesIndex].toFixed(2)}%
                        </span>
                    </div>`;
            },
            position: matchesMd ? 'bottom' : 'right',
        },
        colors: colors ?? CHART_MAIN_COLORS,
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        show: !!total,
                        name: {
                            show: true,
                            fontWeight: '400',
                            offsetY: 30,
                        },
                        value: {
                            show: true,
                            fontWeight: '400',
                            fontSize: matchesMd ? '16px' : '26px',
                            color: theme.palette.primary.main,
                            formatter: function (opts: any) {
                                return `${Number(opts).toFixed(2)}%`;
                            },
                            offsetY: -20,
                        },
                        total: {
                            show: !!total,
                            color: theme.palette.primary.main,
                            fontWeight: '400',
                            fontSize: '20px',
                            label: t('total') as string,
                            formatter: function (w) {
                                if (total) return `${total}`;
                                return `${formatNumber(
                                    w.globals.seriesTotals.reduce(
                                        (a: number, b: number) => a + b,
                                        0,
                                    ),
                                    0,
                                    false,
                                )}%`;
                            },
                        },
                    },
                },
            },
        },
        chart: {
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    const selectedLabel = chartContext.opts.labels[config.dataPointIndex];
                    if (setInstrumentType) {
                        const normalizedLabel = selectedLabel.toUpperCase();

                        setInstrumentType((prev: string) =>
                            prev === mappedInstrumentTypesValues[normalizedLabel]
                                ? ''
                                : mappedInstrumentTypesValues[normalizedLabel],
                        );
                    }
                },
            },
        },
        ...extraOptions,
    };

    const childProps = {
        ...props,
        options,
        matchesMd,
        id,
    };

    return <CustomPieChart {...childProps} />;
};

const propTypes = {
    total: PropTypes.string,
    id: PropTypes.string.isRequired,
};

interface extraProps {
    series: number[];
    labels: string[];
    colors?: string[];
    width?: string | number;
    extraOptions?: ApexOptions;
    height?: string | number;
    setInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomPieChartContainer.propTypes = propTypes;

export default CustomPieChartContainer;
