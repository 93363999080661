import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import DepositStepThree from './DepositStepThree';
import { scrollToTop } from 'utils/helpers/commonHelper';
import { useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';

const DepositStepThreeContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');
    const navigate = useNavigate();

    const handleBack = async () => {
        scrollToTop();
        navigate(mainRoutesPaths.home);
    };

    const childProps = {
        t,
        handleBack,
    };

    return <DepositStepThree {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepThreeContainer.propTypes = propTypes;

export default DepositStepThreeContainer;
