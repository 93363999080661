import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReportsHistoricalHoldingPage from './ReportsHistoricalHoldingPage';
import { useTranslation } from 'react-i18next';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { Holding } from 'types/api/marketAccount.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import { formatSingleDate } from 'utils/helpers/dateHelper';

const ReportsHistoricalHoldingPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_historical_holding_page_';

    const columns: CommonColumn<Holding>[] = [
        {
            id: 'ticker',
            name: 'Ticker',
            selector: row => row.ticker ?? '-',
            exportOption: row => row.ticker ?? '-',
        },
        {
            id: 'description',
            name: t(`${tBase}description`),
            selector: row => row.description ?? '-',
            exportOption: row => row.description ?? '-',
        },
        {
            id: 'price',
            name: t(`${tBase}price`),
            selector: row =>
                row.price != null ? `${row.price.currency} ${formatNumber(row.price.value)}` : '-',
            exportOption: row => `${row.price.currency} ${formatNumber(row.price.value) ?? '-'}`,
        },
        {
            id: 'quantity',
            name: t(`${tBase}quantity`),
            selector: row => (row.quantity != null ? formatNumber(row.quantity) : '-'),
            exportOption: row => (row.quantity ? formatNumber(row.quantity) : '-'),
        },
        {
            id: 'quantityAvailable',
            name: t(`${tBase}quantity_available`),
            selector: row =>
                row.quantityAvailable != null ? formatNumber(row.quantityAvailable) : '-',
            exportOption: row =>
                row.quantityAvailable ? formatNumber(row.quantityAvailable) : '-',
        },
        {
            id: 'quantityNotAvailable',
            name: t(`${tBase}quantity_not_available`),
            selector: row =>
                row.quantityNotAvailable != null ? formatNumber(row.quantityNotAvailable) : '-',
            exportOption: row =>
                row.quantityNotAvailable ? formatNumber(row.quantityNotAvailable) : '-',
        },
        {
            id: 'percentageInWallet',
            name: t(`${tBase}percentage_in_wallet`),
            selector: row =>
                row.percentageInWallet != null ? formatNumber(row.percentageInWallet) : '-',
            exportOption: row =>
                row.percentageInWallet ? formatNumber(row.percentageInWallet) : '-',
        },
        {
            id: 'value',
            name: t(`${tBase}value`),
            selector: row => (row.value != null ? formatNumber(row.value) : '-'),
            exportOption: row => (row.value ? formatNumber(row.value) : '-'),
        },
        {
            id: 'pppc',
            name: t(`${tBase}ppc`),
            selector: row => (row.pppc?.value != null ? formatNumber(row.pppc?.value) : '-'),
            exportOption: row => (row.pppc?.value ? formatNumber(row.pppc?.value) : '-'),
        },
        {
            id: 'pppcPercentage',
            name: t(`${tBase}ppcPercentage`),
            selector: row =>
                row.pppc?.percentage != null ? formatNumber(row.pppc?.percentage) : '-',
            exportOption: row => (row.pppc?.percentage ? formatNumber(row.pppc?.percentage) : '-'),
        },
        {
            id: 'returns',
            name: t(`${tBase}returns`),
            selector: row => (row.returns != null ? formatNumber(row.returns) : '-'),
            exportOption: row => (row.returns ? formatNumber(row.returns) : '-'),
        },
        {
            id: 'instrumentDate ',
            name: t(`${tBase}instrument_date`),
            selector: row => formatSingleDate(row.instrumentDate) ?? '-',
            exportOption: row => formatSingleDate(row.instrumentDate) ?? '-',
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateType',
            label: t(`reports_tickets_page_date_type`, tCommon),
            type: 'select',
            query: 'byLiquidation',
            options: [
                { id: 'true', name: t('reports_tickets_page_for_liquidation') },
                { id: 'false', name: t('reports_tickets_page_for_concertation') },
            ],
        },
        {
            id: 'date',
            label: t('date', tCommon),
            type: 'date',
            query: 'filterDate',
            disableFutureDaysFromN: true,
        },
    ];

    const defaultFilters = [{ query: 'byLiquidation', queryValue: 'false' }];

    const filterDateValidation = (
        filters: string,
    ): { result: boolean; error: string | undefined } => {
        if (
            ['', undefined].includes(filters) ||
            !filters.includes('filterDate') ||
            !filters.includes('byLiquidation')
        )
            return {
                result: false,
                error: t('enter_filter', tErrorsContext) as string,
            };

        return { result: true, error: undefined };
    };

    const childProps = {
        ...props,
        columns,
        filters,
        defaultFilters,
        filterDateValidation,
        t,
    };

    return <ReportsHistoricalHoldingPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsHistoricalHoldingPageContainer.propTypes = propTypes;

export default ReportsHistoricalHoldingPageContainer;
