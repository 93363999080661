import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ActionButton from './ImageButton';

const ImageButtonContainer = (props: Props) => {
    const {
        size = 'small',
        color = 'primary',
        variant = 'contained',
        loading = false,
        loadingPosition = 'center',
        disabled = false,
        type = 'button',
        internal = false,
    } = props;

    const childProps = {
        ...props,
        size,
        color,
        variant,
        loading,
        loadingPosition,
        disabled,
        type,
        internal,
    };

    return <ActionButton {...childProps} />;
};

const propTypes = {};

interface extraProps {
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    type?: 'button' | 'submit' | 'reset';
    internal?: boolean;
    onClick?: () => void;
    // title: string;
    loading?: boolean;
    loadingPosition?: 'start' | 'center' | 'end';
    children: React.ReactNode;
    disabled?: boolean;
    sx?: Object;
    src: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ImageButtonContainer.propTypes = propTypes;

export default ImageButtonContainer;
