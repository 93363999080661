import { Box, styled } from '@mui/material';
import ArrowLeft from 'assets/icons/arrow-left-s-line.svg?react';

export default {
    GoBackButton: styled(ArrowLeft)(({ theme }) => ({
        // border: `2px solid ${theme.palette.custom.vibrantblue}`,
        padding: '5px',
        borderRadius: '25px',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.98)',
        cursor: 'pointer',
    })),
};
