import { Box, Grid, styled, Typography } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '20px 4rem',
    })) as typeof Box,
    SectionInfo: styled(Box)(() => ({ color: 'black', marginBottom: '3rem' })),
    TitleSection: styled(Typography)(({ theme }) => ({
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        opacity: 0.8,
        color: theme.palette.primary.main,
    })),
    GridContainer: styled(Grid)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    })),
};
