import { Box, styled } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    BuySellContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            marginBottom: '1.5rem',
            border: `1px solid ${theme.palette.custom.vibrantblue8}`,
            padding: '4px',
            height: { xl: pxToRem(54), xs: pxToRem(40) },
            borderRadius: pxToRem(35),
            gap: '10px',
        }),
    ),
    BuySellButton: styled(
        Box,
        transientOptions,
    )<{ $selected: boolean }>(({ theme, $selected }) =>
        theme.unstable_sx({
            height: '100%',
            width: '100%',
            display: 'flex',
            borderRadius: pxToRem(28),
            border: `1px solid ${theme.palette.custom.vibrantblue4}`,
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
            fontSize: { xl: '24px', xs: '20px' },
            lineHeight: '28.13px',
            color: theme.palette.custom.vibrantblue4,
            ...($selected && {
                color: 'white',
                backgroundColor: theme.palette.primary.main,
            }),
        }),
    ),
};
