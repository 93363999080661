import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(({ theme }) => ({})),
    Tooltip: styled(Tooltip)(({ theme }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.custom.darkblue10}`,
            borderRadius: '25px',
            maxWidth: 'fit-content',
            maxHeight: '40px',
            padding: '8px',
            position: 'relative',
        }),
    ),
    IconButton: styled(IconButton)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.vibrantblue,
            svg: {
                width: '24px',
                height: '24px',
                marginLeft: '0',
            },
        }),
    ),
    PopoverContainer: styled(Box)(({ theme }) => ({
        width: pxToRem(200),
    })),
    NotificationList: styled(Box)(({ theme }) => ({
        width: '100%',
        maxWidth: 360,
        maxHeight: 300,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    })),
    IconsContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '1rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0.5rem',
    })),
    IconText: styled(
        Box,
        transientOptions,
    )<{ $disabled: boolean }>(({ theme, $disabled }) => ({
        display: 'flex',
        alignItems: 'center',
        cursor: $disabled ? 'default' : 'pointer',
        padding: '8px',
        ':hover': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '25px',
        },
    })),
};
