import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SearchClientModal.styled';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Form, FormikContextType, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { MarketAccount } from 'types/api/marketAccount.types';

const SearchClientModal = (props: Props) => {
    const {
        t,
        handleConfirm,
        handleSearch,
        formik,
        isOpen,
        close,
        isLoadingMarketAccounts,
        marketAccounts,
        errorMarketAccounts,
    } = props;
    const { values, setFieldValue } = formik;

    return (
        <Dialog maxWidth="md" fullWidth open={isOpen} onClose={close}>
            <DialogTitle id="dialog-title" sx={{ pt: 2, pb: 1 }}>
                {t('search_client')}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        color: theme => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormikProvider value={formik}>
                    <Form>
                        <St.BodyText variant="body2" paragraph>
                            {t('search_client_placeholder')}
                        </St.BodyText>
                        <CustomAutoComplete
                            id="client-search"
                            value={values.selectedClient}
                            onChange={(event, value) => {
                                setFieldValue('selectedClient', value);
                            }}
                            options={marketAccounts ?? []}
                            label={t('search_client_label') as string}
                            placeholder={t('search_client_placeholder') as string}
                            filterSelectedOptions
                            isLoading={isLoadingMarketAccounts}
                            getOptionLabel={option =>
                                `${option.name} ${option.last_name} - N° ${option.customerCode}`
                            }
                            disabled={isLoadingMarketAccounts}
                            error={errorMarketAccounts as string}
                            onInputChange={(event, value) => {
                                handleSearch(value);
                            }}
                            renderOption={(props, option) => (
                                <Box {...props} key={option.id}>
                                    {`${option.name} ${option.last_name} - N° ${option.customerCode}`}
                                </Box>
                            )}
                        />
                    </Form>
                    <Box display="flex" justifyContent={'flex-end'} gap={2}>
                        <ActionButton
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={close}>
                            {t('cancel')}
                        </ActionButton>
                        <ActionButton
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            disabled={!values.selectedClient}>
                            {t('confirm')}
                        </ActionButton>
                    </Box>
                </FormikProvider>
            </DialogContent>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    isLoadingMarketAccounts: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    isOpen: boolean;
    close(): void;
    marketAccounts: MarketAccount[] | null;
    errorMarketAccounts: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchClientModal.propTypes = propTypes;

export default SearchClientModal;
