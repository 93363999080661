import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OperationsFCIRequestsPage from './OperationsFCIRequestsPage';
import { useTranslation } from 'react-i18next';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetFundOrderItem } from 'types/api/orders.types';
import { formatDateWithHour, formatSingleDate } from 'utils/helpers/dateHelper';
import { Chip, Typography } from '@mui/material';
import {
    convertCurrencyFormat,
    fundsStatusOptions,
    getColorByStatus,
} from 'utils/helpers/commonHelper';
import { INSTRUMENTS_BASE_URL } from 'constants/appConstants';

const OperationsFCIRequestsPageContainer = (props: Props) => {
    const { t } = useTranslation();

    const columns: CommonColumn<GetFundOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('entry_date'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'code',
            name: t('code'),
            selector: row => row.code || '-',
            sortable: true,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => (
                <Chip color="secondary" variant="outlined" label={t(`funds_${row.buySell}`)} />
            ),
            sortable: true,
        },
        {
            id: 'isSimple',
            name: t('type'),
            selector: row => (row.isSimple ? 'SIMPLE' : 'ACDY'),
        },
        {
            id: 'instrument',
            name: t('fund_instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row?.instrument.name || '-'}
                </Typography>
            ),
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            selector: row => row.quantity || '-',
            sortable: true,
        },
        {
            id: 'amount',
            name: t('fund_price'),
            selector: row => convertCurrencyFormat(row.amount, row.ticker.currency),
            sortable: true,
        },
        {
            id: 'isTotal',
            name: t('is_total'),
            selector: row => (row.isTotal ? 'SI' : 'NO'),
        },
        {
            id: 'agreementDatetime',
            name: t('agreement_date'),
            selector: row => formatSingleDate(row.agreementDatetime),
            sortable: true,
        },
        {
            id: 'liquidationDatetime',
            name: t('liquidation_date'),
            selector: row => formatSingleDate(row.liquidationDatetime),
            sortable: true,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateFrom',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'buySell',
            label: t('type_filter'),
            type: 'select',
            query: 'buySell',
            options: [
                { id: 'BUY', name: t('funds_BUY') },
                { id: 'SELL', name: t('funds_SELL') },
            ],
        },
        {
            id: 'ticker',
            label: t('instrument'),
            type: 'requestAutocomplete',
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/instruments/`,
                param: 'ticker',
                optionLabel: 'name',
            },
        },
        {
            id: 'status',
            label: t('status_filter'),
            type: 'select',
            query: 'status',
            options: fundsStatusOptions,
        },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        t,
    };

    return <OperationsFCIRequestsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsFCIRequestsPageContainer.propTypes = propTypes;

export default OperationsFCIRequestsPageContainer;
