import { Box, styled } from '@mui/material';

export default {
    ImageReaderContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            margin: { md: '40px auto', xs: '50% auto' },
            // minWidth: { xs: '100%', sm: '450px', md: '550px' },
            background: '#e4e4e4',
            width: { md: 'fit-content', sm: '300px', xs: '200px' },
            maxHeight: '80%',
            height: { md: 'fit-content', sm: '300px', xs: '200px' },
            // width: { lg: '80%', md: '100%', sm: '100%', xs: '100%' },
            borderRadius: '15px',
            position: 'relative',
            overflow: 'hidden',
        }),
    ),
    CloseContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            right: '5px',
            borderRadius: '50px',
            svg: {
                WebkitFilter: 'drop-shadow(0px 0px 1px grey) drop-shadow(-1px -1px 6px grey)',
                filter: 'drop-shadow(0px 0px 10px grey)  drop-shadow(-0px -0px 3px grey)',
                fill: 'black!important',
            },
        }),
    ),
    Image: styled('img')(({ theme }) =>
        theme.unstable_sx({
            maxHeight: '100%',
            height: { md: '700px', sm: '100%' },
        }),
    ),
};
