import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    ForgotPasswordContainerBox: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '20px 0px',
            padding: '1.2rem',
        }),
    ),
    ConfirmContainerBox: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }),
    ),
    LoadingButton: styled(LoadingButton)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            margin: 'auto',
            alignSelf: 'center',
            fontFamily: 'Roboto',
            fontSize: '20px',
            lineHeight: '14.06px',
            textAlign: 'center',
            padding: '12px 24px 12px 24px',
        }),
    ) as typeof LoadingButton,
    LogoContainer: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
            },
        }),
    ),
    Logo: styledMui('img')(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '280px',
            },
        }),
    ),
};
