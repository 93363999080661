import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ErrorText.styled';
import { SxProps } from '@mui/material';

const ErrorText = (props: Props) => {
    const { text, sx } = props;

    return (
        <St.Text sx={sx} data-testid="ErrorText-default">
            <span>{text}</span>
        </St.Text>
    );
};

const propTypes = {
    text: PropTypes.string,
};

interface extraProps {
    sx?: SxProps;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorText.propTypes = propTypes;

export default ErrorText;
