import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './WithdrawalSuccessModal.styled';
import { IconButton, Modal, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import CloseIcon from 'assets/icons/close-line.svg?react';
import withdrawalSuccessIcon from 'assets/img/general/stork.png';

const WithdrawalSuccessModal = (props: Props) => {
    const { open, close, t, resetForm } = props;
    return (
        <Modal open={open} onClose={close}>
            <St.BoxModal>
                <IconButton
                    onClick={() => close()}
                    sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <CloseIcon fontSize={'15px'} color="#307FDD" />
                </IconButton>
                <St.ContainerImage>
                    <img
                        src={withdrawalSuccessIcon}
                        alt="withdrawalSuccessIcon"
                        style={{
                            width: '200px',
                        }}
                    />
                </St.ContainerImage>
                <Typography variant="h5" color="#2e4665">
                    {t('withdrawal_success_title')}
                </Typography>
                <Typography variant="h6" color="#2e4665">
                    {t('withdrawal_success_subtitle')}
                </Typography>
                <ActionButton
                    variant="contained"
                    color="primary"
                    sx={{
                        width: '100%',
                    }}
                    onClick={() => {
                        close();
                        resetForm();
                    }}>
                    {t('withdrawal_button_ok')}
                </ActionButton>
            </St.BoxModal>
        </Modal>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WithdrawalSuccessModal.propTypes = propTypes;

export default WithdrawalSuccessModal;
