import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HomeInvestmentProfile from './HomeInvestmentProfile';
import { useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { useGetInvestmentProfileFeatures } from 'hooks/common/users.hooks';
import { scrollToTop } from 'utils/helpers/commonHelper';

const HomeInvestmentProfileContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');
    const { userData } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const goToInvestmentTest = () => {
        scrollToTop();
        navigate(mainRoutesPaths.investmentTest);
    };
    const { investorFeatures } = useGetInvestmentProfileFeatures(userData?.info?.investmentProfile);

    const childProps = {
        ...props,
        t,
        goToInvestmentTest,
        investmentProfile: userData?.info?.investmentProfile,
        investorFeatures,
    };

    return <HomeInvestmentProfile {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomeInvestmentProfileContainer.propTypes = propTypes;

export default HomeInvestmentProfileContainer;
