import { AppBar, Box, SvgIcon, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuLine from 'assets/icons/menu-line.svg?react';

export default {
    FixedAppBar: styled(AppBar)(() => ({
        position: 'fixed',
        background: 'white',
    })) as typeof AppBar,
    Toolbar: styled(Toolbar)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    })),
    Logo: styled(SvgIcon)(({ theme }) =>
        theme.unstable_sx({
            objectFit: 'contain',
            width: 'auto',
            height: '46px',
            color: theme.palette.primary.main,
        }),
    ) as unknown as typeof SvgIcon,
    MenuContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.custom.darkblue10}`,
            borderRadius: '100px',
            width: '40px',
            height: '40px',
            padding: 0,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }),
    ),
    MenuLine: styled(MenuLine)(({ theme }) =>
        theme.unstable_sx({
            fill: theme.palette.primary.main,
        }),
    ),
    ToolsContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
        }),
    ),
};
