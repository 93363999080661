import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Form } from 'formik';

const formFieldWidth = '250px';

const errorStyles = {
    color: 'red',
};

export default {
    Form: styled(Form)(({ theme }) =>
        theme.unstable_sx({
            margin: 'auto',
            padding: { sm: 0, md: '1em' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
        }),
    ),
    FieldsContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& > *': {
            flex: 1,
            minWidth: formFieldWidth,
            margin: '0.5rem 0',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            '& > *': {
                margin: '0 0.5rem',
            },
        },
    })),
    FieldWrapper: styled(Box)(({ theme }) => ({
        position: 'relative',
        margin: '0.5rem 0.5rem',
        '& .field-legend': {
            position: 'absolute',
            bottom: '-1.5rem',
            fontSize: '12px',
            color: theme.palette.text.secondary,
            alignSelf: 'center',
        },
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    })),
    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            display: 'grid',
            gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, max-content)',
                md: 'repeat(3, max-content)',
                xl: 'repeat(5, max-content)',
            },
            columnGap: '2rem',
        }),
    ),
    error: errorStyles,
    NumberBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        gap: '2em',
    })) as typeof Box,
};
