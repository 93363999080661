import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '8rem 4rem',
    })) as typeof Box,
    AcceptTermsConditionsWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingY: pxToRem(50),
            borderBottom: `1px dashed ${theme.palette.custom.darkblue7}`,
        }),
    ) as typeof Box,

    CustomText: styled(Typography)(({ theme }) => ({
        fontSize: '18px',
        fontWeight: '400',
        letterSpacing: '0.0025em',
        color: theme.palette.custom.darkblue2,
        margin: '0',
    })) as typeof Typography,
    CustomLink: styled(Typography)(({ theme }) => ({
        fontSize: '18px',
        fontWeight: '400',
        letterSpacing: '0.0025em',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        margin: '0',
        ':hover': {
            textDecoration: 'underline',
        },
    })) as typeof Typography,

    ContentContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem(40),
        }),
    ) as typeof Box,
    CanvasContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            background: theme.palette.custom.darkblue10,
            borderRadius: '20px',
            width: '100%',
            padding: pxToRem(20),
            border: `1px dashed ${theme.palette.custom.darkblue7}`,
        }),
    ),
    ButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        gap: '1.5rem',
        marginTop: '2rem',
        justifyContent: 'center',
    })),
};
