import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomRadioButton from './CustomRadioButton';

const CustomRadioButtonContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <CustomRadioButton {...childProps} />;
};

const propTypes = {};

interface extraProps {
    name: string;
    value: boolean;
    handleClick: () => void;
    text: string;
    disabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomRadioButtonContainer.propTypes = propTypes;

export default CustomRadioButtonContainer;
