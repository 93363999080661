import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FooterLayout from './FooterLayout';
import { useTranslation } from 'react-i18next';

import {
    FACEBOOK_ANCHOR,
    INSTAGRAM_ANCHOR,
    LINKEDIN_ANCHOR,
    WHATSAPP_ANCHOR,
    FATCA_ANCHOR,
    PEP_ANCHOR,
    OBLIGATED_SUBJECT_ANCHOR,
    FEE_ANCHOR,
    CODE_OF_CONDUCT_ANCHOR,
    TERMS_AND_CONDITIONS_ANCHOR,
    PRIVACY_POLICY_ANCHOR,
    X_ANCHOR,
} from '../../../constants/appConstants';

const FooterLayoutContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const businessInformation = [
        { id: 1, text: 'business_name' },
        { id: 2, text: 'business_cuit' },
        { id: 3, text: 'business_first_address' },
        { id: 4, text: 'business_second_address' },
        { id: 5, text: 'business_location' },
    ];

    const businessSocialMedia = [
        { id: 1, text: 'Facebook', anchor: FACEBOOK_ANCHOR },
        { id: 2, text: 'X', anchor: X_ANCHOR },
        { id: 3, text: 'Linkedin', anchor: LINKEDIN_ANCHOR },
        { id: 4, text: 'Whatsapp', anchor: WHATSAPP_ANCHOR },
        { id: 5, text: 'Instagram', anchor: INSTAGRAM_ANCHOR },
    ];

    const businessLinks = [
        { id: 1, text: 'FATCA_text', anchor: FATCA_ANCHOR },
        { id: 2, text: 'PEP_text', anchor: PEP_ANCHOR },
        { id: 3, text: 'obligated_subject_text', anchor: OBLIGATED_SUBJECT_ANCHOR },
        { id: 4, text: 'fee_text', anchor: FEE_ANCHOR },
        { id: 5, text: 'code_of_conduct_text', anchor: CODE_OF_CONDUCT_ANCHOR },
        { id: 6, text: 'terms_and_conditions_text', anchor: TERMS_AND_CONDITIONS_ANCHOR },
        { id: 7, text: 'privacy_policy_text', anchor: PRIVACY_POLICY_ANCHOR },
    ];

    const childProps = {
        ...props,
        t,
        businessInformation,
        businessSocialMedia,
        businessLinks,
    };

    return <FooterLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FooterLayoutContainer.propTypes = propTypes;

export default FooterLayoutContainer;
