import { Box, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        flex: 1,
        margin: '8rem 2rem',
    })) as typeof Box,
    HeaderExpiredWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: pxToRem(1172),
            marginLeft: '7rem',
            height: '60vh',
        }),
    ),
    ActionButtonWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '100%', sm: 'fit-content' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '1rem', sm: '3.188rem' },
            margin: '6rem auto 0',
            button: {
                fontSize: '1.25rem',
                fontWeight: '500',
                lineHeight: '23.44px',
                letterSpacing: '0.0015em',
                padding: '8.5px 0',
                borderRadius: '44px',
                textTransform: 'uppercase',
                width: { xs: '100%', sm: '260px', md: '324px' },
                m: 0,
            },
        }),
    ),
};
