import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled('div')(() => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })) as typeof Box,
    Title: styled(Typography)(({ theme }) => ({
        writingMode: 'vertical-rl',
        margin: 0,
        padding: '0 10px',
        color: theme.palette.text.primary,
        fontSize: pxToRem(25),
    })),
    GraphicContainer: styled('div')(() => ({
        flexGrow: 1,
        width: '100%',
    })) as typeof Box,
};
