import { useCallback, useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CreateExteriorBankAccount from './CreateExteriorBankAccount';
import { useTranslation } from 'react-i18next';
import { BROKER, EXTERIOR, INTERMEDIARY, tRequiredFieldError } from 'constants/appConstants';
import { useFormik } from 'formik';
import { BankAccountsTypes, ExteriorBankAccount } from 'types/api/cashflow.types';
import { AuthContext } from 'context/auth.context';
import { useCreateBankAccount } from 'hooks/api/cahsflow.hooks';
import { AlertColor } from '@mui/material';
import { t } from 'i18next';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (type: any) => ({
    extraType: type,
    appellation: '',
    aba: '',
    swift: '',
    iban: '',
    broker: '',
    brokerCustomerCode: '',
});

const getValidationSchema = () =>
    Yup.object().shape(
        {
            appellation: Yup.string().required(tRequiredFieldError),
            aba: Yup.string().when(['swift', 'iban'], {
                is: (swift: string, iban: string) => !swift && !iban,
                then: Yup.string().required(t('exterior_bank_account_required') as string),
            }),
            swift: Yup.string().when(['aba', 'iban'], {
                is: (aba: string, iban: string) => !aba && !iban,
                then: Yup.string().required(t('exterior_bank_account_required') as string),
            }),
            iban: Yup.string().when(['swift', 'aba'], {
                is: (swift: string, aba: string) => !swift && !aba,
                then: Yup.string().required(t('exterior_bank_account_required') as string),
            }),
            extraType: Yup.string(),
            broker: Yup.string().when('extraType', {
                is: (extraType: string) => extraType === 'BROKER',
                then: Yup.string().required(tRequiredFieldError),
            }),
            brokerCustomerCode: Yup.string().when('extraType', {
                is: (extraType: string) => extraType === 'BROKER',
                then: Yup.string().required(tRequiredFieldError),
            }),
        },
        [
            ['swift', 'iban'],
            ['aba', 'iban'],
            ['swift', 'aba'],
        ],
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const CreateExteriorBankAccountContainer = (props: Props) => {
    const { type, setSnackBarMessage, close } = props;
    const { t } = useTranslation();
    const { selectedCustomer } = useContext(AuthContext);
    const [showIntermediary, setShowIntermediary] = useState(false);
    const [intermediary, setIntermediary] = useState({} as ExteriorBankAccount);
    const { createBankAccount, createIsLoading } = useCreateBankAccount(setSnackBarMessage, close);

    const handleIntermediary = (name: string, value: string) =>
        setIntermediary({ ...intermediary, [name]: value });

    const handleSubmit = useCallback(
        async (data: ExteriorBankAccount) => {
            createBankAccount({
                type: type === BROKER ? BROKER : EXTERIOR,
                bankName: data.appellation,
                accountNumber: data.aba || data.swift || data.iban,
                currency: 'USD-C',
                extraData: intermediary
                    ? [data, { ...intermediary, extraType: INTERMEDIARY }]
                    : [data],
                operableUserUuid: selectedCustomer?.uuid,
            });
        },
        [intermediary],
    );

    const formikInitProps = useMemo(
        () => ({
            initialValues: getInitialValues(type as string),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        isLoading: createIsLoading,
        type,
        showIntermediary,
        setShowIntermediary,
        intermediary,
        handleIntermediary,
        t,
    };

    return <CreateExteriorBankAccount {...childProps} />;
};

const propTypes = {};

interface extraProps {
    type: BankAccountsTypes;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateExteriorBankAccountContainer.propTypes = propTypes;

export default CreateExteriorBankAccountContainer;
