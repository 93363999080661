import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './AumDetailModal.styled';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { MultiCurrencyObj } from 'types/api/marketAccount.types';
import CurrencyLabel from 'components/common/CurrencyLabel';

const AumDetailModal = (props: Props) => {
    const { open, title, close, t, aum } = props;

    return (
        <Dialog open={open} onClose={close} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <CurrencyLabel currency="ARS" value={aum?.ars ?? 0} />
                <CurrencyLabel currency="USD" value={aum?.usd ?? 0} />
                <CurrencyLabel currency="USD-C" value={aum?.usdc ?? 0} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', marginBottom: '10px' }}>
                <ActionButton onClick={close} variant="contained">
                    {t('back', tCommon)}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    title: string;
    close: () => void;
    aum?: MultiCurrencyObj;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AumDetailModal.propTypes = propTypes;

export default AumDetailModal;
