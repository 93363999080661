import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    ButtonMUI: styled(
        LoadingButton,
        transientOptions,
    )<{ $activated: boolean }>(({ theme, $activated }) =>
        theme.unstable_sx({
            transition:
                'color 0.1s ease-in-out, background-color 0.1s ease-in-out opacity 0.1s ease-in-out',
            textDecoration: 'none',
            cursor: 'pointer',
            backgroundColor: $activated ? theme.palette.custom.darkblue7 : 'transparent',
            borderBottom: '2px solid transparent',
            borderRadius: '28px',
            padding: '0 0.8rem',
            height: '40px',
            position: 'relative',
            '.sp_nav': { display: 'flex', lineHeight: 'normal' },
            ' && a, && .sp_nav': {
                color: $activated
                    ? theme.palette.background.default
                    : theme.palette.custom.darkblue2,
                fontSize: pxToRem(12),
                fontWeight: '400',
                letterSpacing: '0.016em',
                textTransform: 'uppercase',
                transition:
                    'color 0.1s ease-in-out, background-color 0.1s ease-in-out opacity 0.1s ease-in-out',
                textDecoration: 'none',
                cursor: 'pointer',
                overflow: 'hidden',
            },
            ':hover': {
                backgroundColor: theme.palette.custom.darkblue7,
            },
        }),
    ),
    SubMenuWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            left: '0.8rem',
            top: '2.5rem',
        }),
    ),
};
