import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketFundDetailPage from './MarketFundDetailPage';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGetFundDetails } from 'hooks/api/instruments.hooks';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import { getCurrentTime } from 'utils/helpers/commonHelper';

const MarketFundDetailPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const location = useLocation();
    const ticker = location.pathname.split('/')[3];
    const { data: fundDetail, isLoading } = useGetFundDetails({ id: ticker });

    const [term, setTerm] = React.useState(
        location?.state?.term ?? localStorage.getItem('term') ?? 'CI',
    );

    const { data, isLoading: isLoadingAccountStatus } = useGetAccountStatus();

    const [currentTime, setCurrentTime] = React.useState(getCurrentTime(new Date()));
    React.useEffect(() => {
        const interval = setInterval(() => {
            const newTime = getCurrentTime(new Date());
            setCurrentTime(newTime);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const isMarketClosed = React.useMemo(() => {
        const closeTime = fundDetail?.fund_closing_time;
        if (!closeTime) return true;

        const [currentHour, currentMinute, currentSecond] = currentTime.split(':').map(Number);
        const [closeHour, closeMinute, closeSecond] = closeTime.split(':').map(Number);

        const currentTimeInSeconds = currentHour * 3600 + currentMinute * 60 + currentSecond;
        const closeTimeInSeconds = closeHour * 3600 + closeMinute * 60 + closeSecond;
        return currentTimeInSeconds >= closeTimeInSeconds;
    }, [fundDetail?.fund_closing_time, currentTime]);

    const childProps = {
        ...props,
        t,
        ticker: ticker || '',
        isLoading: isLoading || isLoadingAccountStatus,
        accountStatus: data,
        term,
        setTerm,
        fundDetail: fundDetail as GetFundDetailResponse,
        isMarketClosed,
    };

    return <MarketFundDetailPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketFundDetailPageContainer.propTypes = propTypes;

export default MarketFundDetailPageContainer;
