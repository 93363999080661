import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonMarketTable from 'components/common/CommonMarketTable';
import { useGetTableQuotations } from 'hooks/ws/prices.hooks';
import St from './MarketCedearsPage.styled';

const MarketCedearsPage = (props: Props) => {
    const { t } = props;

    return (
        <St.SectionWrapper>
            <CommonMarketTable
                typeInstrument="CERTIFICATE"
                title={t('market_table_cedears_title')}
                useGetData={useGetTableQuotations}
            />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};
interface extraProps {}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketCedearsPage.propTypes = propTypes;

export default MarketCedearsPage;
