import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BidsOffersTable from './BidsOffersTable';
import { WSPricesData } from 'types/context/prices.context.types';
import { useTranslation } from 'react-i18next';
import {
    BidsOffersChangeValues,
    BidsOffersChangeValuesGroups,
    LimitPriceStateInfo,
} from 'types/pages/marketInstrumentDetail.types';
import { BUYSELL } from 'types/api/orders.types';

const BidsOffersTableContainer = (props: Props) => {
    const { setLimitIndex, changeValues } = props;
    const { t } = useTranslation('market');

    const changeBackground = (index: number, group: BidsOffersChangeValuesGroups) => {
        const selectedGroup = changeValues[group];
        if (!selectedGroup) return '';
        const value = selectedGroup[index];
        if (value === 'x') return '';
        if (group === 'bidsSizes' || group === 'offersSizes') return '#409fe4';
        return value === '+' ? '#a0d6a0' : '#ff8ca5';
    };

    const childProps = {
        ...props,
        t,
        setLimitIndex,
        changeBackground,
    };

    return <BidsOffersTable {...childProps} />;
};

const propTypes = {
    isBond: PropTypes.bool.isRequired,
};

interface extraProps {
    bids?: WSPricesData[] | null;
    setLimitIndex: (value: LimitPriceStateInfo | null) => void;
    offers?: WSPricesData[] | null;
    buySell: BUYSELL;
    changeValues: BidsOffersChangeValues;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BidsOffersTableContainer.propTypes = propTypes;

export default BidsOffersTableContainer;
