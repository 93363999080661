import { TableCell, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ActionCell: styled(TableCell)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: pxToRem(30),
        }),
    ),
    TickerCell: styled(TableCell)(({ theme }) => ({
        color: theme.palette.primary.main,
        cursor: 'pointer',
    })),
};
