import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialCorporatePage from './CommercialCorporatePage';
import { useTranslation } from 'react-i18next';
import { useGetCommercialByType } from 'hooks/api/cms.hooks';

const CommercialCorporatePageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { data, isLoading } = useGetCommercialByType('Material Corporativo');

    const childProps = {
        ...props,
        t,
        isLoading,
        categories: data?.subcategories,
        redirects: data?.redirectsForCategory,
    };

    return <CommercialCorporatePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialCorporatePageContainer.propTypes = propTypes;

export default CommercialCorporatePageContainer;
