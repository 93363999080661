import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CompanyModal from './CompanyModal';
import PropTypes from 'prop-types';

const CompanyModalContainer = (props: Props) => {
    const { t, setOpenModal } = props;

    const handleClose = () => {
        setOpenModal(false);
    };

    const childProps = { ...props, t, handleClose, setOpenModal };

    return <CompanyModal {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CompanyModalContainer.prototype = propTypes;

export default CompanyModalContainer;
