import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AccountInformation.styled';
import CurrencyLabel from 'components/common/CurrencyLabel';
import { AccountPerformance } from 'types/api/marketAccount.types';
import CustomRadioButton from 'components/common/CustomRadioButton';
import { Theme } from '@mui/material';
import CustomIconCreator from 'components/common/CustomIconCreator';
import PerformanceIcon from 'assets/icons/rentabilidad.svg?react';
const AccountInformation = (props: Props) => {
    const {
        t,
        theme,
        isWallet,
        performance,
        aumCurrency,
        visualizeUSD,
        setVisualizeUSD,
        performanceCurrency,
        setOpenAumDetailModal,
        isUserApAc,
    } = props;

    return (
        <St.Container>
            {!isUserApAc ? null : (
                <St.WalletSubtitle>{t('account_information_wallet_subtitle')}</St.WalletSubtitle>
            )}
            <St.RadioButtonContainer>
                <CustomRadioButton
                    checked={visualizeUSD}
                    name={t('visualize_usd')}
                    onChange={() => setVisualizeUSD(!visualizeUSD)}
                    text={t('visualize_usd')}
                    value={String(visualizeUSD)}
                />
            </St.RadioButtonContainer>
            <CurrencyLabel
                variant="h4"
                currency={visualizeUSD ? 'USD' : 'ARS'}
                value={aumCurrency}
            />
            <St.AumText typography="subtitle1">{t('aum_text')}</St.AumText>
            {isWallet && (
                <St.ModalTextContainer onClick={() => setOpenAumDetailModal(true)}>
                    <St.InfoIcon />
                    <St.ModalText variant="caption">{t('see_aum_detailed_text')}</St.ModalText>
                </St.ModalTextContainer>
            )}
            {isUserApAc ? null : (
                <St.PerformanceContainer>
                    <St.AccountInformationSubtitle variant="subtitle1">
                        {t('account_information_performance_title')}
                    </St.AccountInformationSubtitle>
                    <St.PerformanceLabelContainer>
                        <CustomIconCreator
                            icon={PerformanceIcon}
                            sx={{
                                width: '1.7rem',
                                height: '1.7rem',
                                marginRight: '0.8rem',
                                overflow: 'visible',
                            }}
                        />
                        <CurrencyLabel
                            variant="h5"
                            currency={visualizeUSD ? 'USD' : 'ARS'}
                            value={performanceCurrency}
                        />
                        {!!performance?.percentage && (
                            <St.PerformancePercentage
                                color={
                                    performance?.percentage >= 0
                                        ? theme.palette.custom.lightGreen
                                        : theme.palette.custom.lightRed
                                }>
                                {performance?.percentage.toFixed(2)}%
                            </St.PerformancePercentage>
                        )}
                    </St.PerformanceLabelContainer>
                </St.PerformanceContainer>
            )}
            <St.DottedDivider />
        </St.Container>
    );
};

const propTypes = {
    isWallet: PropTypes.bool,
    t: PropTypes.func.isRequired,
    visualizeUSD: PropTypes.bool.isRequired,
    aumCurrency: PropTypes.number.isRequired,
    performanceCurrency: PropTypes.number.isRequired,
    isUserApAc: PropTypes.bool.isRequired,
};

interface extraProps {
    theme: Theme;
    performance?: AccountPerformance;
    setVisualizeUSD: (value: boolean) => void;
    setOpenAumDetailModal: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountInformation.propTypes = propTypes;

export default AccountInformation;
