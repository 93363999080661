import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstumentGraphicCard from './InstumentGraphicCard';
import { useTranslation } from 'react-i18next';
import { useGetHistoricalPrices } from 'hooks/api/instruments.hooks';
import { GetHistoricalPricesResponse } from 'types/api/instruments.types';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import moment from 'moment';
import { formatSingleDate } from 'utils/helpers/dateHelper';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = () => ({
    dateFrom: null,
    dateTo: null,
});
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const InstumentGraphicCardContainer = (props: Props) => {
    const { ticker } = props;

    const { t } = useTranslation('market');

    const dateRanges = ['1M', '3M', '6M', 'YTD', '1Y', 'ALL'];

    const [showGraphic, setShowGraphic] = React.useState(false);
    const [currentFilter, setCurrentFilter] = React.useState('ALL');

    const handleSubmit = React.useCallback(async (values: FormikValues) => {}, []);
    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: true,
                validateOnMount: false,
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const handleSwitchGraphicVisibility = () => setShowGraphic(!showGraphic);

    const calculateDateRange = (range: string) => {
        let dateFrom;
        let dateTo = moment().format('YYYY-MM-DD');

        switch (range) {
            case '1M':
                dateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');
                break;
            case '3M':
                dateFrom = moment().subtract(3, 'months').format('YYYY-MM-DD');
                break;
            case '6M':
                dateFrom = moment().subtract(6, 'months').format('YYYY-MM-DD');
                break;
            case 'YTD':
                dateFrom = moment().startOf('year').format('YYYY-MM-DD');
                break;
            case '1Y':
                dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD');
                break;
            case 'ALL':
            default:
                dateFrom = '';
                dateTo = '';
                break;
        }

        return { dateFrom, dateTo };
    };

    const onFilterChange = (range: string) => {
        const { dateFrom, dateTo } = calculateDateRange(range);
        formik.setFieldValue('dateFrom', dateFrom);
        formik.setFieldValue('dateTo', dateTo);
        setCurrentFilter(range);
    };

    const { data: historicalPricesData, isLoading: isLoadingHistoricalPrices } =
        useGetHistoricalPrices({
            id: ticker,
            term: '0',
            timeSpan: currentFilter,
            dateFrom: formatSingleDate(formik.values.dateFrom, 'YYYY/MM/DD'),
            dateTo: formatSingleDate(formik.values.dateTo, 'YYYY/MM/DD'),
        });

    const pricesArray = Array.isArray(historicalPricesData) ? historicalPricesData : [];

    const lineData = pricesArray
        .filter(
            (data: GetHistoricalPricesResponse) => data.close !== null && data.priceDate != null,
        )
        .map((data: GetHistoricalPricesResponse) => ({
            time: data.priceDate,
            value: data.close,
        }));

    const histogramData = pricesArray
        .filter(
            (data: GetHistoricalPricesResponse) => data.volume !== null && data.priceDate != null,
        )
        .map((data: GetHistoricalPricesResponse) => ({
            time: data.priceDate,
            value: data.volume,
            color: '#2962FF',
        }));

    const childProps = {
        ...props,
        showGraphic,
        dateRanges,
        handleSwitchGraphicVisibility,
        t,
        isLoadingHistoricalPrices,
        lineData,
        histogramData,
        onFilterChange,
        currentFilter,
        formik,
    };

    return <InstumentGraphicCard {...childProps} />;
};

const propTypes = {
    ticker: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstumentGraphicCardContainer.propTypes = propTypes;

export default InstumentGraphicCardContainer;
