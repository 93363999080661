import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
    })) as typeof Box,
    HeaderDesktopLogo: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            height: 'inherit',
            cursor: 'pointer',
            paddingRight: '20px',
            display: 'flex',
            alignItems: 'center',
        }),
    ) as typeof Box,
    AccountInfo: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: '700',
            fontSize: '1rem',
            maxWidth: {
                xs: pxToRem(150),
                sm: pxToRem(300),
                md: pxToRem(200),
                lg: pxToRem(400),
                xl: pxToRem(500),
            },
        }),
    ),
    AccountInfoSubtitile: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: '400',
            fontSize: '13px',
        }),
    ),
};
