import { Box, styled } from '@mui/material';
import { pageContentMeshStyle } from 'utils/helpers/stylesHelper';

export default {
    MainWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    })),
    ContentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        }),
    ) as typeof Box,
    PageWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'calc(100% - 2rem)',
            padding: '0 1rem',
            ...pageContentMeshStyle,
            mt: '0',
        }),
    ) as typeof Box,
};
