import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ReplacePasswordPage.styled';
import ReplacePasswordForm from './ReplacePasswordForm';
import HeadLogo from 'components/common/HeadLogo';
import FooterLayout from 'components/layouts/FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';

const ReplacePasswordPage = (props: Props) => {
    const { isSigningUp, token } = props;
    return (
        <>
            <HeadLogo />
            <St.LoginFormWrapper>
                <ReplacePasswordForm token={token} isSigningUp={isSigningUp} />
            </St.LoginFormWrapper>
            <FooterLayout />
            <HelpTooltip />
        </>
    );
};

const propTypes = {
    isSigningUp: PropTypes.bool,
    token: PropTypes.string,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReplacePasswordPage.propTypes = propTypes;

export default ReplacePasswordPage;
