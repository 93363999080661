import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DepositStepTwo from './DepositStepTwo';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useCreateDeposit, useGetBankAccounts } from 'hooks/api/cahsflow.hooks';
import { TFunction } from 'i18next';
import { AuthContext } from 'context/auth.context';
import { scrollToTop } from 'utils/helpers/commonHelper';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { CreateDepositRequest } from 'types/api/cashflow.types';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import i18n from 'utils/i18n';

const getInitialValues = () => ({
    amount: 0,
    document: null,
    sourceAccount: null,
    destinationAccount: null,
    currencyMatch: null,
});

const normalizeName = (name: string | undefined) => name?.trim().replace(/\s+/g, ' ') ?? '';

const getValidationSchema = (t: TFunction) =>
    Yup.object().shape({
        amount: Yup.number()
            .typeError(tRequiredFieldError)
            .required(tRequiredFieldError)
            .moreThan(0, tRequiredFieldError),
        document: Yup.string().required(tRequiredFieldError).nullable(),
        sourceAccount: Yup.object().required(tRequiredFieldError),
        destinationAccount: Yup.object().required(tRequiredFieldError),
        currencyMatch: Yup.mixed().test(
            'currency-match',
            String(i18n.t('error_currency_mismatch', tErrorsContext)),
            function () {
                const { sourceAccount, destinationAccount } = this.parent;
                const sourceCurrencyMatch = normalizeName(sourceAccount?.name).match(
                    /\((ARS|USD)\)$/,
                );
                const destinationCurrencyMatch = normalizeName(destinationAccount?.name).match(
                    /\((ARS|USD)\)$/,
                );
                const sourceCurrency = sourceCurrencyMatch ? sourceCurrencyMatch[1] : null;
                const destinationCurrency = destinationCurrencyMatch
                    ? destinationCurrencyMatch[1]
                    : null;
                if (
                    !destinationCurrency ||
                    !sourceCurrency ||
                    sourceCurrency !== destinationCurrency
                ) {
                    return this.createError({
                        message: String(i18n.t('error_currency_mismatch', tErrorsContext)),
                    });
                }

                return true;
            },
        ),
    });

const DepositStepTwoContainer = (props: Props) => {
    const { activeStep, setActiveStep } = props;
    const { t } = useTranslation('home');
    const filterQueryString = `&status=ACTIVE`;
    const { data: bankAccounts, isLoading } = useGetBankAccounts({
        filterQueryString,
    } as GridHookParams);
    const [sourceAccount, setSourceAccount] = useState();
    const { customerCode } = React.useContext(AuthContext);
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { createDeposit, createIsLoading } = useCreateDeposit();

    const handleSubmit = React.useCallback(async (data: any) => {}, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(t),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [],
    );

    const formik = useFormik(formikInitProps);

    const handleSourceAccount = (newValue: any) => {
        setSourceAccount(newValue?.id);
        formik.setFieldValue('sourceAccount', newValue);
    };

    const handleNext = async () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                scrollToTop();
                const today = new Date().toISOString();
                const depositData = {
                    transferDate: today,
                    customerCode,
                    amount: parseFloat(formik.values.amount),
                    fileUrl: formik.values.document,
                    accountUuid: sourceAccount ?? '',
                };

                createDeposit(depositData as CreateDepositRequest, {
                    onSuccess() {
                        formik.resetForm();
                    },
                    onError: () => {
                        setSnackBarMessage(t('deposit_create_error'), 'error');
                    },
                });
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            } else {
                formik.setTouched({
                    amount: true,
                    document: true,
                });
            }
        });
    };

    const handleBack = () => {
        scrollToTop();
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        formik.resetForm();
    };

    const childProps = {
        t,
        handleSourceAccount,
        bankAccounts,
        formik,
        isLoading,
        createIsLoading,
        handleNext,
        handleBack,
        SnackBar,
        activeStep,
        setActiveStep,
    };

    return <DepositStepTwo {...childProps} />;
};

const propTypes = {};

interface extraProps {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepTwoContainer.propTypes = propTypes;

export default DepositStepTwoContainer;
