import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BuySellPrevisualizeModal from './BuySellPrevisualizeModal';
import { useTranslation } from 'react-i18next';
import { CreateFundOrderRequest } from 'types/api/orders.types';
import { useCreateFundOrder } from 'hooks/api/orders.hooks';
import { AuthContext } from 'context/auth.context';
import { Currency } from 'types/common/general.types';
import { useRequestOtp } from 'hooks/api/auth.hooks';
import { channelRoutesPaths, mainRoutesPaths } from 'router/routesPaths';
import { isRecommendedForYourProfile } from 'utils/helpers/commonHelper';
import { InvestmentTestValues } from 'types/common/users.types';

const BuySellPrevisualizeModalContainer = (props: Props) => {
    const { bodyToCreateOrder, fundPreview } = props;
    const { t } = useTranslation('market');
    const { userData, userType, selectedCustomer } = React.useContext(AuthContext);
    const isAPOrAC = ['ASSISTANT', 'CHANNEL'].includes(userType || '');
    const recommendationText = isRecommendedForYourProfile(
        fundPreview.profile,
        userData?.info?.investmentProfile,
    );
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    const {
        mutate,
        isLoading,
        errorMessage,
        data: orderCreationResponse,
        reset,
    } = useCreateFundOrder();
    const { mutate: requestOtp, isLoading: isLoadingRequestOtp } = useRequestOtp();

    const [openModal, setOpenModal] = React.useState<'error' | 'success' | 'enableMFA' | null>(
        null,
    );

    const handleCreateOrder = (body: CreateFundOrderRequest) => {
        mutate(body, {
            onSuccess() {
                setOpenModal('success');
            },
            onError: ({ response }: any) => {
                setOpenModal(response.data.errorCode === 'Invalid_OTP' ? 'enableMFA' : 'error');
            },
        });
    };
    const onSubmit = () => {
        if (userData?.mfaType !== 'DISABLED' && !isAPOrAC) {
            setOpenModal('enableMFA');
        } else {
            handleCreateOrder(bodyToCreateOrder);
        }
    };

    const handleRequestOtp = () => {
        if (errorMessage) reset();
        requestOtp(
            {},
            {
                onError() {
                    setOpenModal('error');
                },
            },
        );
    };

    const handleValidateOtp = (otp: string) => handleCreateOrder({ ...bodyToCreateOrder, otp });

    const successModalProps = {
        leftText: t('fund_buy_sell_btn_left'),
        leftUrl: isAPOrAC
            ? channelRoutesPaths.referralFciRequest
            : mainRoutesPaths.referralFciRequest,
        rightText: t(`fund_buy_sell_btn_right_${bodyToCreateOrder.buySell === 'BUY' ? 1 : 2}`),
        rightUrl:
            bodyToCreateOrder.buySell === 'BUY'
                ? isAPOrAC
                    ? channelRoutesPaths.preferred
                    : mainRoutesPaths.preferred
                : isAPOrAC
                  ? channelRoutesPaths.wallet
                  : mainRoutesPaths.briefcase,
    };

    const childProps = {
        ...props,
        t,
        onSubmit,
        isLoading: isLoading || isLoadingRequestOtp,
        openModal,
        setOpenModal,
        mfaType: userData?.mfaType,
        isLoadingSubmitMfa: isLoadingRequestOtp,
        requestOtp: handleRequestOtp,
        validateOtp: handleValidateOtp,
        apiError: errorMessage,
        selectedCustomer,
        successModalProps,
        isAPOrAC,
        orderCreationResponse,
        recommendationText,
        currentDate,
    };

    return <BuySellPrevisualizeModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
    close: () => void;
    fundPreview: {
        ticker: string;
        name: string;
        class: string;
        currency: Currency;
        priceToOperate: number;
        cuotaparte: string;
        profile?: InvestmentTestValues;
    };
    bodyToCreateOrder: CreateFundOrderRequest;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellPrevisualizeModalContainer.propTypes = propTypes;

export default BuySellPrevisualizeModalContainer;
