import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './Drawer.styled';
import DrawerMenuList from './DrawerMenuList';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import desktopLogo from 'assets/img/general/main_logo.svg';

const Drawer = (props: Props) => {
    const { open, selectedMenu, handleMobileNavigate, handleDrawerClose, routes } = props;

    return (
        <St.FlexedDialog open={open} onClose={handleDrawerClose} fullScreen>
            <St.MenuTextWrapper>
                <St.LogoContainer>
                    <img src={desktopLogo} alt="logo" />
                </St.LogoContainer>
                {/* <St.MenuText>{t("")}</St.MenuText> */}
                <DrawerMenuList
                    open={open}
                    selectedMenu={selectedMenu}
                    menusList={routes}
                    handleNavigate={handleMobileNavigate}
                />
            </St.MenuTextWrapper>
        </St.FlexedDialog>
    );
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    handleMobileNavigate: PropTypes.func.isRequired,
};

interface extraProps {
    routes: Array<SubRouteInfo>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Drawer.propTypes = propTypes;

export default Drawer;
