import { Box } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    LoginFormWrapper: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            marginTop: '1rem',
            paddingLeft: '1rem',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
                paddingLeft: '2rem',
            },
        }),
    ),
};
