import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfileTestExpiredPage from './ProfileTestExpiredPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';

const ProfileTestExpiredPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const { userData } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const navigateToInvestmentTest = () => {
        navigate(mainRoutesPaths.investmentTestQuestions);
    };

    const childProps = {
        ...props,
        t,
        navigateToInvestmentTest,
        updatedAt: userData?.info?.investorProfileUpdatedAt || '',
    };

    return <ProfileTestExpiredPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileTestExpiredPageContainer.propTypes = propTypes;

export default ProfileTestExpiredPageContainer;
