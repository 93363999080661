import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WalletDepositPage from './WalletDepositPage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const WalletDepositPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Datos para depositar', 'Datos del depósito', 'Confirmación'];

    const childProps = {
        t,
        activeStep,
        steps,
        setActiveStep,
    };

    return <WalletDepositPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletDepositPageContainer.propTypes = propTypes;

export default WalletDepositPageContainer;
