import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        width: '100%',
    })) as typeof Box,
    PageTitle: styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: pxToRem('20px'),
        marginBottom: pxToRem('20px'),
    })) as typeof Typography,
};
