import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HomeWelcome.styled';
import { Typography } from '@mui/material';
import { tCommon } from 'constants/appConstants';

const HomeWelcome = (props: Props) => {
    const { steps, t } = props;
    return (
        <St.Container>
            <Typography variant="h4" color="primary" sx={{ marginTop: '1.5rem' }}>
                {t('welcome_title')}
            </Typography>
            <St.Subtitle>{t('welcome_start_steps')}</St.Subtitle>
            <St.StepsContainer>
                {steps.map(step => (
                    <St.StepCard key={step.number}>
                        <St.StepNumber>{step.number}</St.StepNumber>
                        <St.StepTitle>{step.title}</St.StepTitle>
                        <St.StepButton onClick={step.action}>
                            {t('btn_see_more', tCommon)}
                        </St.StepButton>
                    </St.StepCard>
                ))}
            </St.StepsContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    steps: {
        number: number;
        title: string;
        action: () => void;
    }[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomeWelcome.propTypes = propTypes;

export default HomeWelcome;
