import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonMarketTable from 'components/common/CommonMarketTable';
import { useGetInstrumentsForFund } from 'hooks/api/instruments.hooks';
import St from './MarketFundsPage.styled';

const MarketFundsPage = (props: Props) => {
    const { t } = props;
    return (
        <St.SectionWrapper>
            <CommonMarketTable
                typeInstrument="FUND"
                title={t('funds_page_title')}
                useGetData={useGetInstrumentsForFund}
                table="funds"
            />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketFundsPage.propTypes = propTypes;

export default MarketFundsPage;
