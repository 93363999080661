import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CircularOptionSelection from 'components/common/CircularOptionSelection/CircularOptionSelection';
import St from './QuestionsAnswers.styled';
import { Option } from 'types/pages/onboardingValidatePerson.types';

const QuestionsAnswers = (props: Props) => {
    const { text, questionOptions, handleCheck, questionKey } = props;
    return (
        <St.Box>
            <St.Title>{text}</St.Title>
            <St.List>
                {Object.entries(questionOptions ?? []).map(([key, el]) => (
                    <CircularOptionSelection
                        key={`${questionKey}-${key}`}
                        {...{
                            handleCheck,
                            questionKey,
                            field: key,
                            text: el.text,
                            userChoice: el.userChoice,
                            markContainer: true,
                        }}
                    />
                ))}
            </St.List>
        </St.Box>
    );
};

const propTypes = {};

interface extraProps {
    questionKey: string;
    handleCheck: (field: string, questionKey: string) => void;
    questionId: string;
    text: string;
    current: boolean;
    selectedOption: boolean;
    questionOptions: Option;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuestionsAnswers.propTypes = propTypes;

export default QuestionsAnswers;
