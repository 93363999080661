import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ClientsWalletPage.styled';
import AccountStatus from 'components/common/AccountStatus';
import WalletInstrumentContainer from './WalletInstrument/WalletInstrument.container';

const ClientsWalletPage = (props: Props) => {
    const { selectedInstrumentType, setSelectedInstrumentType } = props;
    return (
        <St.SectionWrapper>
            <AccountStatus isWallet setSelectedInstrumentType={setSelectedInstrumentType} />
            <WalletInstrumentContainer selectedInstrumentType={selectedInstrumentType} />
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    setSelectedInstrumentType: React.Dispatch<React.SetStateAction<string>>;
    selectedInstrumentType: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsWalletPage.propTypes = propTypes;

export default ClientsWalletPage;
