import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TermsConditionsModal from './TermsConditionsModal';
import { useTranslation } from 'react-i18next';
import { useAcceptTermsConditions, useGetTermsConditions } from 'hooks/api/auth.hooks';

const TermsConditionsModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('common');

    const { previewTerms } = useGetTermsConditions();
    const { mutate: acceptTerms, errorMessage, isLoading } = useAcceptTermsConditions();

    const handleAcceptTerms = () => {
        acceptTerms();
    };

    const childProps = {
        ...props,
        t,
        terms: previewTerms?.content,
        handleAcceptTerms,
        errorMessage,
        previewTerms,
        isLoading,
    };

    return <TermsConditionsModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TermsConditionsModalContainer.propTypes = propTypes;

export default TermsConditionsModalContainer;
