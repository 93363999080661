import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Drawer from './Drawer';
import { useNavigate } from 'react-router-dom';
import { RouteInfo, SubRouteInfo } from 'utils/helpers/routesHelper';

const DrawerContainer = (props: Props) => {
    const { setIsOpen, setSelectedMenu, open } = props;
    const navigate = useNavigate();

    const handleDrawerClose = () => {
        if (open) setIsOpen(false);
    };
    const handleNavigate = (listItem: RouteInfo) => {
        setSelectedMenu(listItem.name);
        navigate(listItem.path ?? '/');
    };
    const handleMobileNavigate = (listItem: RouteInfo) => {
        handleNavigate(listItem);
        handleDrawerClose();
    };

    const childProps = {
        ...props,
        handleDrawerClose,
        handleNavigate,
        handleMobileNavigate,
    };

    return <Drawer {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    setSelectedMenu: PropTypes.func.isRequired,
};

interface extraProps {
    routes: Array<SubRouteInfo>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DrawerContainer.propTypes = propTypes;

export default DrawerContainer;
