import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentMarketStatus.styled';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MarketStatusTimes } from 'types/pages/marketInstrumentDetail.types';

const InstrumentMarketStatus = (props: Props) => {
    const { t, currentMarket, term, isMarketClosed } = props;
    return (
        <St.Container>
            <St.Dot $isMarketClosed={isMarketClosed} />
            <Typography variant="subtitle2">
                {t(`market_instrument_${isMarketClosed ? 'close' : 'open'}_text`)}
            </Typography>
            <Tooltip
                placement="bottom"
                title={t('tooltip_market_times', {
                    term: term === 'CI' ? t('term_ci_tooltip_text') : term,
                    openTime: currentMarket?.openTime,
                    closeTime: currentMarket?.closeTime,
                })}>
                <IconButton>
                    <St.QuestionMarkRadiusIcon />
                </IconButton>
            </Tooltip>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    currentMarket: MarketStatusTimes | null;
    term: '24hs' | 'CI';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentMarketStatus.propTypes = propTypes;

export default InstrumentMarketStatus;
