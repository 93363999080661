import { Box, styled, Typography } from '@mui/material';

export default {
    WalletInstrumentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            width: 'calc(100% + 4rem)',
        }),
    ),
    WalletInstrumentContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: '1rem',
                borderRadius: 0,
                overflow: 'auto',
            },
        }),
    ),
    Subtitle: styled(Typography)(({ theme }) => ({
        fontSize: '1.5rem',
        color: theme.palette.primary.dark,
    })),
};
