import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { tCommon } from 'constants/appConstants';
import St from './DeleteModal.styled';

const DeleteModal = (props: Props) => {
    const { t, isLoading, close, handleDelete } = props;

    return (
        <>
            <St.DialogContent>
                <St.DialogContentText id="alert-dialog-description">
                    {t('delete_order_message')}
                </St.DialogContentText>
            </St.DialogContent>
            <St.DialogActions>
                <St.ActionButton variant="outlined" color="secondary" size="small" onClick={close}>
                    {t('delete_dialog_cancel_button_text', tCommon)}
                </St.ActionButton>
                <St.ActionButton
                    size="small"
                    variant="contained"
                    loading={isLoading}
                    onClick={handleDelete}>
                    {t('delete_dialog_submit_button_text', tCommon)}
                </St.ActionButton>
            </St.DialogActions>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteModal.propTypes = propTypes;

export default DeleteModal;
