import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentMarketStatus from './InstrumentMarketStatus';
import { useTranslation } from 'react-i18next';
import { MarketStatusTimes } from 'types/pages/marketInstrumentDetail.types';

const InstrumentMarketStatusContainer = (props: Props) => {
    // const { market, term } = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <InstrumentMarketStatus {...childProps} />;
};

const propTypes = {
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    currentMarket: MarketStatusTimes | null;
    term: '24hs' | 'CI';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentMarketStatusContainer.propTypes = propTypes;

export default InstrumentMarketStatusContainer;
