import { Box, IconButton, Tooltip, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';
import MenuLine from 'assets/icons/menu-line.svg?react';

export default {
    ToolsListWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            marginLeft: '6px',
            gap: '3px',
        }),
    ),
    Tooltip: styled(
        Tooltip,
        transientOptions,
    )<{ $radiusSmall: boolean }>(({ theme, $radiusSmall }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.custom.darkblue10}`,
            borderRadius: $radiusSmall ? '25px' : '50%',
            maxWidth: $radiusSmall ? 'fit-content' : '40px',
            maxHeight: '40px',
            padding: $radiusSmall ? '0' : '8px',
            position: 'relative',
        }),
    ),
    IconButton: styled(IconButton)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.vibrantblue,
            svg: {
                width: '24px',
                height: '24px',
                marginLeft: '0',
            },
        }),
    ),
    ProfileTool: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.custom.darkblue10}`,
            borderRadius: '100px',
            width: '40px',
            height: '40px',
            padding: 0,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }),
    ),
    SubMenuWrapperProfile: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            // left: '-2rem',
            top: '3rem',
            right: '-1rem',
        }),
    ),
    MenuLine: styled(MenuLine)(({ theme }) =>
        theme.unstable_sx({
            fill: theme.palette.primary.main,
        }),
    ),
};
