import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { FormikContextType, Form, FormikProvider } from 'formik';
import { Box } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import CustomInput from 'components/common/CustomInput';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { tCommon, tRequiredFieldError } from 'constants/appConstants';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import St from './BankAccountForm.styled';

const BankAccountForm = (props: Props) => {
    const {
        t,
        formik,
        isEdit,
        currencyOptions,
        typeOptions,
        accountNumberChange,
        selectedAccount,
        isLoading,
        disableCbuField,
        close,
    } = props;
    const { errors, values, setFieldValue, setErrors } = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <St.BoxForm>
                    <CustomAutoComplete
                        disabled={isEdit && selectedAccount?.status !== 'REJECTED'}
                        id="currency"
                        value={
                            currencyOptions.find(option => option.id === values.currency) ?? null
                        }
                        onChange={(e, value) => {
                            setFieldValue('currency', value?.id);
                        }}
                        error={errors.currency && errors.currency.toString()}
                        options={currencyOptions ?? []}
                        label={t('bank_account_currency')}
                        placeholder={t('bank_account_currency')}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.name}
                            </Box>
                        )}
                        helperText={errors.currency && errors.currency.toString()}
                    />
                    <CustomAutoComplete
                        id="type"
                        disabled={isEdit && selectedAccount?.status !== 'REJECTED'}
                        value={typeOptions.find(option => option.id === values.type) ?? null}
                        onChange={(e, value) => {
                            setFieldValue('type', value?.id);
                        }}
                        error={errors.type && errors.type.toString()}
                        options={typeOptions ?? []}
                        label={t('bank_account_type')}
                        placeholder={t('bank_account_type')}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.name}
                            </Box>
                        )}
                        helperText={errors.type && errors.type.toString()}
                    />

                    <CustomInput
                        label={t('bank_account_account_number_title')}
                        onBlur={() => {
                            if (values.accountNumber.length === 0) {
                                setErrors({
                                    ...errors,
                                    accountNumber: tRequiredFieldError,
                                });
                            }
                        }}
                        values={values}
                        disabled={
                            (isEdit && selectedAccount?.status !== 'REJECTED') || disableCbuField
                        }
                        field="accountNumber"
                        handleChange={e => accountNumberChange(e)}
                        errors={errors}
                        helperText={errors.accountNumber && errors.accountNumber.toString()}
                        inputType={'number'}
                    />
                    {values.bankName && (
                        <CustomInput
                            label={t('bank_account')}
                            values={values}
                            disabled={true}
                            field="bankName"
                        />
                    )}

                    <CustomInput
                        onBlur={() => {
                            if (values.description.length === 0) {
                                setErrors({ ...errors, description: tRequiredFieldError });
                            }
                        }}
                        label={t('bank_account_description')}
                        values={values}
                        field="description"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        helperText={errors.description && errors.description.toString()}
                    />
                    <Box display="flex" gap="10px">
                        <ActionButton
                            fullWidth
                            variant="outlined"
                            onClick={close}
                            disabled={isLoading}>
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <ActionButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            loading={isLoading}
                            disabled={isLoading}>
                            {isEdit
                                ? t('bank_account_edit_button')
                                : t('bank_account_create_button')}
                        </ActionButton>
                    </Box>
                </St.BoxForm>
            </Form>
        </FormikProvider>
    );
};
const propTypes = {
    t: PropTypes.any.isRequired,
};
interface extraProps {
    formik: FormikContextType<any>;
    isEdit?: boolean;
    currencyOptions: { id: string; name: string }[];
    typeOptions: { id: string; name: string }[];
    accountNumberChange: (e: string) => void;
    selectedAccount?: GetBankAccountsItem | null;
    isLoading: boolean;
    disableCbuField: boolean;
    close(): void;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountForm.propTypes = propTypes;
export default BankAccountForm;
