import * as React from 'react';
import * as Yup from 'yup';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfileChangePasswordPage from './ProfileChangePasswordPage';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { FormikValues, useFormik } from 'formik';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useChangePassword } from 'hooks/api/auth.hooks';
import { useNavigate } from 'react-router-dom';
import {
    REGEX_ONE_CAPITAL_LETTER,
    REGEX_ONE_LOWER_LETTER,
    REGEX_ONE_NUMBER,
    REGEX_SPECIAL_CHARACTER,
    VALID_PASSWORD_REGEX,
} from 'utils/helpers/constants';

const getInitialValues = () => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            oldPassword: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            newPassword: Yup.string()
                .test('password-requirements', function (value) {
                    const errors: string[] = [];
                    if (!value || value.length < 8) {
                        errors.push(
                            i18n.t('password_requirements_minimum_letters', { ns: 'auth' }),
                        );
                    }
                    if (!REGEX_ONE_CAPITAL_LETTER.test(value || '')) {
                        errors.push(
                            i18n.t('password_requirements_one_capital_letter', { ns: 'auth' }),
                        );
                    }
                    if (!REGEX_ONE_LOWER_LETTER.test(value || '')) {
                        errors.push(
                            i18n.t('password_requirements_one_lowercase_letter', { ns: 'auth' }),
                        );
                    }
                    if (!REGEX_ONE_NUMBER.test(value || '')) {
                        errors.push(i18n.t('password_requirements_one_number', { ns: 'auth' }));
                    }
                    if (!REGEX_SPECIAL_CHARACTER.test(value || '')) {
                        errors.push(
                            i18n.t('password_requirements_one_special_character', { ns: 'auth' }),
                        );
                    }
                    return errors.length > 0
                        ? this.createError({ message: errors.join(', ') })
                        : true;
                })
                .required(tRequiredFieldError),
            newPasswordConfirmation: Yup.string()
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .required(tRequiredFieldError),
        }),
    );

const ProfileChangePasswordPageContainer = (props: Props) => {
    const { t } = useTranslation('auth');
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const navigate = useNavigate();
    const handleGoHome = () => navigate('/home');

    const { mutate, isLoading } = useChangePassword(setSnackBarMessage, handleGoHome);

    const handleSubmit = React.useCallback(
        (values: FormikValues) => {
            const { oldPassword, newPassword } = values;
            mutate({ body: { oldPassword, newPassword } });
        },
        [mutate],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        t,
        formik,
        isLoading,
        SnackBar,
        handleSubmit,
        handleGoHome,
    };

    return <ProfileChangePasswordPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileChangePasswordPageContainer.propTypes = propTypes;

export default ProfileChangePasswordPageContainer;
