import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ComponentLi from './ComponentLi';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { Nationality } from 'types/api/onboarding.types';

const ComponentLiContainer = (props: Props) => {
    const { values, formKey, setFieldValue } = props;
    const { t } = useTranslation('onboarding');
    const handleClick = () => setFieldValue(formKey, !values[formKey]);

    const childProps = {
        ...props,
        handleClick,
        enableInput: values[formKey],
        t,
    };

    return <ComponentLi {...childProps} />;
};

const propTypes = {};

interface extraProps {
    text: string;
    tooltipText: string;
    formKey: string;
    values: { [key: string]: boolean };
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<any>>;
    inputKey?: string;
    inputType?: string;
    countriesList?: Nationality[];
    errors: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ComponentLiContainer.propTypes = propTypes;

export default ComponentLiContainer;
