import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Dropdown from './Dropdown';
import { TagType } from 'types/api/instruments.types';

const DropdownContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <Dropdown {...childProps} />;
};

const propTypes = {};

interface extraProps {
    options: Array<TagType>;
    selectedValue: TagType | null;
    onSelect: (value: TagType) => void;
    disableClearable?: boolean;
    label: string;
    minWidth: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DropdownContainer.propTypes = propTypes;

export default DropdownContainer;
