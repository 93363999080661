import { Box, Typography, styled } from '@mui/material';
export default {
    ModalTitle: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
    })) as typeof Box,
    ModalTitleText: styled(Typography)(({ theme }) => ({
        color: '#2E4665',
        fontSize: '2em',
        alignSelf: 'center',
    })) as typeof Typography,
    ModalText: styled(Typography)(({ theme }) => ({
        marginTop: '1em',
        color: '#2E4665',
        fontSize: '1em',
        textAlign: 'center',
    })) as typeof Typography,
};
