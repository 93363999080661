import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelHomePage.styled';
import CurrencyQuotes from '../../common/CurrencyQuotes';
import CollateralModal from './CollateralModal';
import FundsTable from 'components/common/FundsTable';
import ChannelAccountStatus from './ChannelAccountStatus';

const ChannelHomePage = (props: Props) => {
    // const {  } = props;

    return (
        <St.SectionWrapper>
            <CollateralModal />
            <ChannelAccountStatus />
            <CurrencyQuotes />
            <FundsTable />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelHomePage.propTypes = propTypes;

export default ChannelHomePage;
