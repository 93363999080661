import { InferPropsExtended } from 'utils/helpers/proptypesHelper.js';
import St from './CreateAccountPage.styled';
import PropTypes from 'prop-types';
import ActionButton from 'components/common/ActionButton';
import ImageButton from 'components/common/ImageButton';
import forMeImage from 'assets/img/general/forme.png';
import forCompanyImage from 'assets/img/general/forcompany.png';
import CompanyModal from './CompanyModal';
import HeadLogo from 'components/common/HeadLogo';
import FooterLayout from 'components/layouts/FooterLayout';

const CreateAccountPage = (props: Props) => {
    const { t, getInto, openCompanyModal, setOpenCompanyModal, createMyAccount } = props;

    return (
        <St.CreateAccountContainerBox>
            <HeadLogo />
            <St.CreateAccountBox>
                <St.CreateAccountTitle>{t('create_account_title')}</St.CreateAccountTitle>
                <St.CreateAccountInformationBox>
                    <St.CreateAccountInformationFirstTab>
                        <St.CreateAccountSubTitle>
                            {t('create_account_sub_title')}
                        </St.CreateAccountSubTitle>
                        <St.ComeBackToLoginBox>
                            <St.CreateAccountText>
                                {t('create_account_already_has_account_text')}
                            </St.CreateAccountText>
                            <ActionButton variant="outlined" aria-label="export" onClick={getInto}>
                                {t('create_account_button_get_into')}
                            </ActionButton>
                        </St.ComeBackToLoginBox>
                    </St.CreateAccountInformationFirstTab>
                    <St.CreateAccountInformationSecondTab>
                        <ImageButton
                            variant="outlined"
                            internal
                            aria-label="export"
                            src={forMeImage}
                            onClick={createMyAccount}>
                            {t('create_account_button_for_me')}
                        </ImageButton>
                        <ImageButton
                            variant="outlined"
                            internal
                            aria-label="export"
                            src={forCompanyImage}
                            onClick={() => setOpenCompanyModal(true)}>
                            {t('create_account_button_for_company')}
                        </ImageButton>
                    </St.CreateAccountInformationSecondTab>
                </St.CreateAccountInformationBox>
            </St.CreateAccountBox>
            <CompanyModal t={t} openModal={openCompanyModal} setOpenModal={setOpenCompanyModal} />
            <FooterLayout />
        </St.CreateAccountContainerBox>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    getInto: () => void;
    openCompanyModal: boolean;
    setOpenCompanyModal: (value: boolean) => void;
    createMyAccount: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateAccountPage.propTypes = propTypes;

export default CreateAccountPage;
