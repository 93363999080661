import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './BankAccountExtraDataModal.styled';
import { Dialog, DialogActions, DialogTitle, Grid, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { BROKER, DESTINATION, EXTERIOR, INTERMEDIARY, tCommon } from 'constants/appConstants';
import { BankAccountsTypes, SortedBankAccount } from 'types/api/cashflow.types';
import InfoLabel from 'components/common/InfoLabel';

const BankAccountExtraDataModal = (props: Props) => {
    const { open, title, close, t, accountType, sortedBankAccount } = props;

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
            <DialogTitle>{title}</DialogTitle>
            <Grid container paddingX={'1.5rem'}>
                {accountType === BROKER && (
                    <Grid item xs={12}>
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_broker_title')}
                            text={sortedBankAccount[BROKER]?.broker}
                        />
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_broker_customer_code_title')}
                            text={sortedBankAccount[BROKER]?.brokerCustomerCode}
                        />
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_appellation_title')}
                            text={sortedBankAccount[BROKER]?.appellation}
                        />
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_aba_title')}
                            text={sortedBankAccount[BROKER]?.aba}
                        />
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_account_swift_title')}
                            text={sortedBankAccount[BROKER]?.swift}
                        />
                        <InfoLabel
                            withoutSeparation
                            label={t('bank_account_account_iban_title')}
                            text={sortedBankAccount[BROKER]?.iban}
                        />
                    </Grid>
                )}
                {accountType === EXTERIOR && (
                    <>
                        {sortedBankAccount[DESTINATION] && (
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="h6"
                                    color="primary.main"
                                    marginBottom={'0.5rem'}>
                                    {t('bank_account_extra_type_exterior')}
                                </Typography>
                                <InfoLabel
                                    withoutSeparation
                                    label={t('bank_account_aba_title')}
                                    text={sortedBankAccount[DESTINATION]?.aba}
                                />
                                <InfoLabel
                                    withoutSeparation
                                    label={t('bank_account_account_swift_title')}
                                    text={sortedBankAccount[DESTINATION]?.swift}
                                />
                                <InfoLabel
                                    withoutSeparation
                                    label={t('bank_account_account_iban_title')}
                                    text={sortedBankAccount[DESTINATION]?.iban}
                                />
                            </Grid>
                        )}
                        {sortedBankAccount[INTERMEDIARY] &&
                            sortedBankAccount[INTERMEDIARY].map((intermediary, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <Typography
                                        variant="h6"
                                        color="primary.main"
                                        marginBottom={'0.5rem'}>
                                        {t('bank_account_extra_type_intermediary')}
                                    </Typography>
                                    <InfoLabel
                                        withoutSeparation
                                        label={t('bank_account_aba_title')}
                                        text={intermediary?.aba}
                                    />
                                    <InfoLabel
                                        withoutSeparation
                                        label={t('bank_account_account_swift_title')}
                                        text={intermediary?.swift}
                                    />
                                    <InfoLabel
                                        withoutSeparation
                                        label={t('bank_account_account_iban_title')}
                                        text={intermediary?.iban}
                                    />
                                </Grid>
                            ))}
                    </>
                )}
            </Grid>
            <DialogActions>
                <ActionButton variant="contained" onClick={close}>
                    {t('close', tCommon)}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    title: string;
    close: () => void;
    accountType: BankAccountsTypes;
    sortedBankAccount: SortedBankAccount;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountExtraDataModal.propTypes = propTypes;

export default BankAccountExtraDataModal;
