import { useEffect, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BankAccountDetail from './BankAccountDetail';
import { useTranslation } from 'react-i18next';
import { GetBankAccountsItem, SortedBankAccount } from 'types/api/cashflow.types';
import { LOCAL_ACCOUNTS } from 'constants/appConstants';
import { sortBankAccounts } from 'utils/helpers/commonHelper';

const BankAccountDetailContainer = (props: Props) => {
    const { row } = props;
    const { t } = useTranslation();
    const [accountsInfo, setAccountInfo] = useState<SortedBankAccount | undefined>(
        {} as SortedBankAccount,
    );

    const childProps = {
        ...props,
        accountsInfo,
        t,
    };

    useEffect(() => {
        if (!LOCAL_ACCOUNTS.includes(row.type)) {
            // Si la cuenta es del exterior, ordenar las cuentas destino, broker o intermediarios
            const accounts = sortBankAccounts(row?.bankAccountExtraData);
            setAccountInfo(accounts);
        }
    }, []);

    return <BankAccountDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetBankAccountsItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetailContainer.propTypes = propTypes;

export default BankAccountDetailContainer;
