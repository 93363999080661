import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonTableHeader from './CommonTableHeader';
import { ICommonHeader, SortInformation, SortType } from 'types/common/tableLayout.types';
import { useState } from 'react';

const CommonTableHeaderContainer = (props: Props) => {
    const { handleSort, sortInfo } = props;
    const [sort, setSort] = useState(sortInfo as SortInformation);

    const onSortChange = (columnId: string) => {
        let newSort: SortType = 'asc';
        if (columnId === sort.column) newSort = sort.sortDirection === 'desc' ? 'asc' : 'desc';

        handleSort && handleSort({ column: columnId, sortDirection: newSort });
        setSort({ column: columnId, sortDirection: newSort });
    };

    const childProps = {
        ...props,
        sort,
        onSortChange,
    };

    return <CommonTableHeader {...childProps} />;
};

const propTypes = {};

interface extraProps extends ICommonHeader {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTableHeaderContainer.propTypes = propTypes;

export default CommonTableHeaderContainer;
