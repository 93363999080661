import * as React from 'react';
import St from './ImageButton.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';

const ActionButton = (props: Props) => {
    const {
        onClick,
        children,
        sx,
        src,
        size = 'small',
        color = 'primary',
        variant = 'contained',
        loading = false,
        loadingPosition = 'center',
        disabled = false,
        type = 'button',
        internal = false,
    } = props;

    return (
        <St.ImageButton
            variant={variant}
            loading={loading}
            loadingPosition={loadingPosition}
            size={size}
            color={color}
            type={type}
            disabled={disabled}
            sx={sx}
            onClick={onClick}
            internal={internal} // Pasamos la prop al styled component
        >
            <St.Image internal={internal} src={src ?? ''} />
            <St.TextBox internal={internal}>{children}</St.TextBox>
        </St.ImageButton>
    );
};

const propTypes = {};

interface extraProps {
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    type?: 'button' | 'submit' | 'reset';
    internal?: boolean;
    onClick?: () => void;
    loading?: boolean;
    loadingPosition?: 'start' | 'center' | 'end';
    children: React.ReactNode;
    disabled?: boolean;
    sx?: Object;
    src: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionButton.propTypes = propTypes;

export default ActionButton;
