import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BankAccountCard from './BankAccountCard';
import { useTranslation } from 'react-i18next';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import { useTheme } from '@mui/material';
import { sortBankAccounts } from 'utils/helpers/commonHelper';

const BankAccountCardContainer = (props: Props) => {
    const { account } = props;
    const { t } = useTranslation('profile');
    const theme = useTheme();
    const [openExtraDataModal, setOpenExtraDataModal] = React.useState(false);

    const statusOptions = {
        PENDING_ACCEPT: { color: '#f6cd6f', text: t('bank_account_statuts_pending_approval') },
        PENDING_DELETE: { color: '#f6cd6f', text: t('bank_account_statuts_pending_delete') },
        ACTIVE: { color: '#55cf0f', text: t('bank_account_statuts_active') },
        DELETED: { color: '#d0011b', text: t('bank_account_statuts_deleted') },
        REJECTED: { color: '#d0011b', text: t('bank_account_statuts_rejected') },
    };
    const accountTypeOptions = {
        SAVINGS: t('bank_account_type_savings'),
        CHECKING: t('bank_account_type_checking'),
        EXTERIOR: t('bank_account_type_exterior'),
        BROKER: t('bank_account_type_broker'),
    };

    const childProps = {
        ...props,
        account,
        t,
        statusOptions,
        accountTypeOptions,
        theme,
        openExtraDataModal,
        setOpenExtraDataModal,
        sortedBankAccount: sortBankAccounts(account?.bankAccountExtraData),
    };

    return <BankAccountCard {...childProps} />;
};

const propTypes = {};

interface extraProps {
    account: GetBankAccountsItem;
    setSelectedAccount: React.Dispatch<React.SetStateAction<GetBankAccountsItem | null>>;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    open(): void;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountCardContainer.propTypes = propTypes;

export default BankAccountCardContainer;
