import i18next from 'i18next';
import moment from 'moment';
import { LoginUserResponse } from 'types/api/auth.types';
import { MarketAccount } from 'types/api/marketAccount.types';
import { languages } from 'types/common/general.types';

export const getAuthToken = () => {
    return localStorage.getItem('IdToken');
};

export const setAuthToken = (token: string) => {
    return localStorage.setItem('IdToken', token);
};

export const getIsAuthenticated = () => {
    return !!getAuthToken();
};

export const getSelectedCustomerStorage = () => {
    return JSON.parse(localStorage.getItem('selectedCustomer') as string);
};
export const setSelectedCustomerStorage = (selectedCustomer: MarketAccount) => {
    localStorage.setItem('selectedCustomer', JSON.stringify(selectedCustomer));
};

export const setUserDataStorage = (userData: LoginUserResponse) => {
    localStorage.setItem('userData', JSON.stringify(userData));
};

export const getAuthData = async () => {
    const AccessToken = await localStorage.getItem('AccessToken');
    const ExpiresInString = await localStorage.getItem('ExpiresIn');
    const ExpiresIn =
        ExpiresInString != null && ExpiresInString != '' ? parseInt(ExpiresInString) : -1;
    const TokenType = await localStorage.getItem('TokenType');
    const RefreshToken = await localStorage.getItem('RefreshToken');
    const IdToken = await localStorage.getItem('IdToken');
    const timestamp = await localStorage.getItem('timestamp');
    return {
        AccessToken,
        ExpiresIn,
        TokenType,
        RefreshToken,
        IdToken,
        timestamp,
    };
};

export const setAuthData = async (
    AccessToken: string,
    ExpiresIn: number,
    TokenType: string,
    RefreshToken: string,
    IdToken: string,
) => {
    localStorage.setItem('AccessToken', AccessToken);
    localStorage.setItem('ExpiresIn', ExpiresIn.toString());
    localStorage.setItem('TokenType', TokenType);
    localStorage.setItem('RefreshToken', RefreshToken);
    localStorage.setItem('IdToken', IdToken);
    localStorage.setItem('timestamp', moment().toISOString());
};

export const removeAuthdata = async () => {
    localStorage.setItem('AccessToken', '');
    localStorage.setItem('ExpiresIn', '');
    localStorage.setItem('TokenType', '');
    localStorage.setItem('RefreshToken', '');
    localStorage.setItem('IdToken', '');
    localStorage.setItem('timestamp', '');
};

export const getLanguage = () => languages[i18next.language];

export const cleanStorage = () => localStorage.clear();

export const getUserData = () => {
    const userData = localStorage.getItem('userData');
    if (userData) return JSON.parse(userData) as LoginUserResponse;
    return null;
};

export const getIsOnboarding = () => {
    return !!getUserData()?.onboardingPending;
};

export const getMarketAccounts = () => {
    const marketAccounts = localStorage.getItem('marketAccounts');
    if (marketAccounts) return JSON.parse(marketAccounts) as MarketAccount[];
    return null;
};

export const setMarketAccountsStorage = (marketAccounts: MarketAccount[]) => {
    localStorage.setItem('marketAccounts', JSON.stringify(marketAccounts));
};
