import { InferPropsExtended } from 'utils/helpers/proptypesHelper.js';
import { useNavigate } from 'react-router-dom';
import CreateAccountPage from './CreateAccountPage';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { loggedOutSubroutesPaths } from 'router/routesPaths';

const CreateAccountPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('auth');
    const [openCompanyModal, setOpenCompanyModal] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const getInto = () => {
        navigate(loggedOutSubroutesPaths.login);
    };

    const createMyAccount = () => {
        navigate(loggedOutSubroutesPaths.openMyAccount);
    };
    const childProps = {
        t,
        getInto,
        openCompanyModal,
        setOpenCompanyModal,
        createMyAccount,
    };
    return <CreateAccountPage {...childProps} />;
};
const propTypes = {};
interface extraProps {}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateAccountPageContainer.propTypes = propTypes;
export default CreateAccountPageContainer;
