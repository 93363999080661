import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Worker, Viewer, Plugin } from '@react-pdf-viewer/core';
import { Modal, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import St from './PdfReaderModal.styled';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CustomIconCreator from '../CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import PropTypes from 'prop-types';

const PdfReaderModal = (props: Props) => {
    const { open, handleClose, pdfUrl, matchesXl, defaultLayoutPluginInstance, disableDownload } =
        props;

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="PdfReaderModal-default">
            <St.PDFReaderContainer>
                <Box sx={{ display: 'flex', justifyContent: 'right', pt: 5, width: '100%' }}>
                    <IconButton onClick={handleClose}>
                        <CustomIconCreator icon={CloseIcon} />
                    </IconButton>
                </Box>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={pdfUrl ?? ''}
                        defaultScale={matchesXl ? 0 : 1}
                        plugins={disableDownload ? [] : [defaultLayoutPluginInstance as Plugin]}
                    />
                </Worker>
            </St.PDFReaderContainer>
        </Modal>
    );
};

const propTypes = {
    disableDownload: PropTypes.bool,
};

interface extraProps {
    open: boolean;
    handleClose: () => void;
    pdfUrl: string | null;
    matchesXl: boolean;
    defaultLayoutPluginInstance: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PdfReaderModal.propTypes = propTypes;

export default PdfReaderModal;
