import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeaderFilters from './HeaderFilters';
import { useTranslation } from 'react-i18next';
import { CommonFilter } from 'types/common/tableLayout.types';

const HeaderFiltersContainer = (props: Props) => {
    const { filterHeaderString, setFilterHeaderString, customFilterValidation } = props;
    const { t } = useTranslation();
    const [filterString, setFilterString] = React.useState<string>(filterHeaderString ?? '');
    const [validationError, setValidationError] = React.useState<string | undefined>(undefined);

    const onSubmit = () => {
        if (customFilterValidation) {
            const { result, error } = customFilterValidation(filterString);
            result
                ? setFilterHeaderString(filterString) && setValidationError(error)
                : setValidationError(error);
        } else {
            setFilterHeaderString(filterString);
        }
    };

    const onClear = () => {
        setFilterHeaderString('');
        setFilterString('');
    };

    const childProps = {
        ...props,
        t,
        filterString,
        onSubmit,
        onClear,
        setFilterString,
        validationError,
    };

    return <HeaderFilters {...childProps} />;
};

const propTypes = {
    filterHeaderString: PropTypes.string.isRequired,
    setFilterHeaderString: PropTypes.func.isRequired,
};

interface extraProps {
    filters: CommonFilter[];
    customFilterValidation?: (value: string) => { result: boolean; error: string | undefined };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFiltersContainer.propTypes = propTypes;

export default HeaderFiltersContainer;
