import {
    GetInvestmentTestQuestionsResponse,
    GetNosisQuestionsResponse,
    GetOnboardingStepsResponse,
} from 'types/api/onboarding.types';
import { Step } from 'types/pages/onboardingHome.types';
import {
    Option,
    Question,
    QuestionsInvestment,
    QuestionsInvestmentById,
    QuestionsNosisById,
} from 'types/pages/onboardingValidatePerson.types';
import i18n from 'utils/i18n';

export const onboardingHomeStepsDataAdapter = (data: GetOnboardingStepsResponse) => {
    let disabled = false;
    const order = [
        'VALIDATE_PERSON',
        'PERSONAL_DATA',
        'CONTACT_INFO',
        'COMPLEMENTARY_DATA',
        'UPLOAD_DOCUMENT',
        'INVESTMENT_TEST',
        'PROOF_OF_INCOME',
        'CONTRACT',
    ];
    const highRiskProfile =
        data.riskProfile === 'HIGH' || data.riskProfile === 'MEDIUM' || data.incomeProofRequired;
    const disableContract =
        data.steps
            .filter(el => {
                if (!highRiskProfile) {
                    return ![
                        'CONTRACT',
                        'FINALIZE',
                        'ONBOARDING_STARTED',
                        'PROOF_OF_INCOME',
                    ].includes(el.stepName);
                }
                return !['CONTRACT', 'FINALIZE', 'ONBOARDING_STARTED'].includes(el.stepName);
            })
            .filter(el => el.status === 'PENDING').length !== 0;

    const filteringAndOrderingOfSteps = data.steps
        .filter(el => !['FINALIZE', 'ONBOARDING_STARTED'].includes(el.stepName))
        .sort((a, b) => order.indexOf(a.stepName) - order.indexOf(b.stepName))
        .map(step => {
            const stepName = step.stepName.toLowerCase();
            const subpath =
                stepName === 'investment_test'
                    ? 'investment-test/questions'
                    : stepName.toLowerCase();
            const formStep = {
                ...step,
                status: step.status.toLowerCase(),
                stepName: i18n.t(`btn_text_${stepName}`, {
                    ns: 'onboarding',
                }),
                path: `/onboarding/${subpath.replaceAll('_', '-')}`,
                disabled: false,
            } as Step;
            if (['REJECTED', 'COMPLETED'].includes(step.status)) disabled = true;
            if (step.stepName === 'CONTRACT' && disableContract) formStep.disabled = true;
            return formStep;
        });
    if (!highRiskProfile) {
        return filteringAndOrderingOfSteps.filter(
            el =>
                ![
                    i18n.t(`btn_text_proof_of_income`, {
                        ns: 'onboarding',
                    }),
                ].includes(el.stepName),
        );
    }

    return filteringAndOrderingOfSteps;
};

export const questionsNosisDataAdapter = (data: GetNosisQuestionsResponse) =>
    ({
        requestId: data.requestId,
        retry: data.retry,
        questions: data.questions.reduce<{ [key: string]: Question }>((acc, el, index: number) => {
            const questionId = `${el.questionId}`;
            acc[questionId] = {
                questionId,
                text: el.question,
                questionOptions: el.answers.reduce<Option>((acc2, el2, index2: number) => {
                    const optionId = `${el2.answerId}`;
                    acc2[optionId] = {
                        text: el2.answer,
                        userChoice: false,
                        id: optionId,
                    };
                    return acc2;
                }, {}),
            };
            return acc;
        }, {}),
    }) as QuestionsNosisById;

export const questionAdapterToValidateAAnswers = (questions: { [key: string]: Question }) => {
    let totalQuestions = 0;
    const answers = Object.values(questions).map(question => {
        totalQuestions = ++totalQuestions;

        const optionsArray = Object.values(question.questionOptions);
        const finalObject: { questionId: number; answerId: number } = {
            questionId: parseInt(question.questionId),
            answerId: 0,
        };
        for (let i = 0; i < optionsArray.length; i++) {
            const current = optionsArray[i];
            if (current.userChoice) finalObject.answerId = parseInt(current.id);
        }

        return finalObject;
    });

    return { answers };
};

export const questionsInvestmentDataAdapter = (data: GetInvestmentTestQuestionsResponse) =>
    ({
        questions: data.items.reduce<{ [key: string]: QuestionsInvestment }>(
            (acc, el, index: number) => {
                const questionId = `${el.id}`;
                acc[questionId] = {
                    questionId,
                    text: el.question,
                    current: el.id === 1,
                    selectedOption: false,
                    questionOptions: el.answers.reduce<Option>((acc2, el2, index2: number) => {
                        const optionId = `${el2.id}`;
                        acc2[optionId] = {
                            text: el2.answer,
                            userChoice: false,
                            id: optionId,
                        };
                        return acc2;
                    }, {}),
                };
                return acc;
            },
            {},
        ),
    }) as QuestionsInvestmentById;

export const questionsInvestmentAdapterToValidateAnswers = (questions: {
    [key: string]: Question;
}) =>
    Object.values(questions).map(({ questionId, questionOptions }) => {
        const [{ id: answerId }] = Object.values(questionOptions).filter(
            ({ userChoice }) => userChoice,
        );
        return { questionId: Number(questionId), answerId: Number(answerId) };
    });
