import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid, Typography } from '@mui/material';
import { Form, FormikContextType, FormikProvider } from 'formik';
import PasswordField from 'components/common/PasswordField';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const ProfileChangePasswordPage = ({ t, formik, isLoading, SnackBar, handleGoHome }: Props) => {
    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik} data-testid="changePassword-form">
            <Form>
                <Grid container gap={2} sx={{ margin: '0rem 4rem 8rem 4rem' }}>
                    <Grid item xs={12}>
                        <Typography fontSize={24} my={1.5}>
                            {t('change_password_title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PasswordField
                            fullWidth
                            name="oldPassword"
                            error={errors?.oldPassword as string}
                            label={t('old_password')}
                            placeholder={t('old_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('oldPassword', target.value);
                            }}
                            value={values?.oldPassword || ''}
                            helperText={errors?.oldPassword as string}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PasswordField
                            fullWidth
                            name="newPassword"
                            error={errors?.newPassword as string}
                            label={t('new_password')}
                            placeholder={t('enter_new_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('newPassword', target.value);
                            }}
                            value={values?.newPassword || ''}
                            helperText={errors?.newPassword as string}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PasswordField
                            fullWidth
                            name="newPasswordConfirmation"
                            error={errors?.newPasswordConfirmation as string}
                            helperText={errors?.newPasswordConfirmation as string}
                            label={t('change_password_repeat')}
                            placeholder={t('enter_new_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('newPasswordConfirmation', target.value);
                            }}
                            value={values?.newPasswordConfirmation || ''}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        gap={2}>
                        <Grid item>
                            <ActionButton
                                fullWidth
                                variant="outlined"
                                color="primary"
                                disabled={isLoading}
                                onClick={handleGoHome}>
                                {t('back', tCommon)}
                            </ActionButton>
                        </Grid>
                        <Grid item>
                            <ActionButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                loading={isLoading}>
                                {t('save', tCommon)}
                            </ActionButton>
                        </Grid>
                    </Grid>
                </Grid>
                <SnackBar />
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    isLoading: boolean;
    SnackBar: any;
    handleSubmit(values: any): void;
    handleGoHome(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileChangePasswordPage.propTypes = propTypes;

export default ProfileChangePasswordPage;
