import { Box, Link, styled, Typography } from '@mui/material';

export default {
    ContainerPage: styled(Box)(() => ({
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        gap: '20px',
    })),
    ContainerTopHeader: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { lg: '80%' },
            padding: '0 40px',
        }),
    ),
    SectionWrapper: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        padding: '0 40px',
    })),
    Link: styled(Link)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '2rem 0.875rem 2rem 0',
            borderBottom: `1px solid ${theme.palette.custom.darkblue7}`,
            gap: '1rem',
            textDecoration: 'none',
        }),
    ),
    ContainerTitle: styled(Box)(() => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    })),
    TitleLink: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue2,
        fontSize: '1.5rem',
    })),
    BoxImage: styled(Box)(() => ({
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: '2px solid rgb(246, 245, 245)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })),
};
