import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from 'api/orders/orders.api';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import { AuthContext } from 'context/auth.context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { AlertColor } from '@mui/material';
import { useCommonGrid } from 'context/common/commonGrid.context';

export const useGetOrders = ({ filterQueryString }: GridHookParams) => {
    const { t } = useTranslation();
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['orders', filterQueryString],
        () => endpoints.getMarketOrders(filterQueryString),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useGetOrdersByCustomerCode = ({ filterQueryString }: GridHookParams) => {
    const { selectedCustomer } = useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch, isError } = useQuery(
        ['orders', filterQueryString, selectedCustomer],
        () =>
            endpoints.getMarketOrdersByCustomerCode(
                filterQueryString,
                selectedCustomer?.customerCode,
            ),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetMarketPreview = (setOpenPrevisualizeModal: (value: boolean) => void) => {
    const { data, mutate, isLoading, error, isError, reset } = useMutation(
        endpoints.getMarketPreview,
        {
            onSuccess() {
                setOpenPrevisualizeModal(true);
            },
        },
    );
    return {
        getMarketPreview: mutate,
        marketPreview: data?.data,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
        reset,
    };
};

export const useCreateMarketOrder = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.createMarketOrder);
    return {
        createMarketOrder: mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useCreateFundOrder = () => {
    const { mutate, isLoading, error, isError, data, reset } = useMutation(
        endpoints.createFundOrder,
    );
    return {
        mutate,
        isLoading,
        data: data?.data,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
        reset,
    };
};

export const useGetFundsOrders = ({ filterQueryString }: GridHookParams) => {
    const { t } = useTranslation();
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['funds', filterQueryString],
        () => endpoints.getFundOrders(filterQueryString),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useGetFundsOrdersByCustomerCode = ({ filterQueryString }: GridHookParams) => {
    const { selectedCustomer } = useContext(AuthContext);
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['funds', filterQueryString],
        () =>
            endpoints.getFundOrdersByCustomerCode(
                filterQueryString,
                selectedCustomer?.customerCode,
            ),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetExchangeOrders = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['funds', filterQueryString],
        () => endpoints.getExchangeOrders(filterQueryString),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useDeleteOrder = (setSnackBarMessage: Function, close: Function) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.deleteMarketOrder, {
        onSuccess: () => {
            setSnackBarMessage(t('delete_order_success'));
            setNeedRefresh(true);
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error), 'error');
            close();
        },
    });

    return { mutate, isLoading };
};

export const useGetCollateralExpiration = () => {
    const { data, isLoading, error, isError } = useQuery(
        ['collateral-expiration'],
        () => endpoints.getCollateralExpiration(),
        {
            select: data => data?.data,
        },
    );

    return {
        data,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetCollateralOrders = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, isError, isFetching, isRefetching, refetch } = useQuery(
        ['collateralOrders', filterQueryString],
        () => endpoints.getCollateralOrders(filterQueryString),
        { select: data => data?.data },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useCreateCollateralOrder = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation();
    const { mutate, isLoading, error, isError, data, reset } = useMutation(
        endpoints.createCollateralOrder,
        {
            onSuccess: () => {
                setSnackBarMessage(t('action_successfully'));
                setNeedRefresh(true);
                close();
            },
        },
    );
    return {
        mutate,
        isLoading,
        data: data?.data,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
        reset,
    };
};
export const useDeleteCollateralOrder = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation();
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteCollateralOrder, {
        onSuccess: () => {
            setSnackBarMessage(t('collateral_modal_delete_success'));
            setNeedRefresh(true);
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error), 'error');
        },
    });
    return {
        mutate,
        isLoading,
        successfulMessage: t('action_successfully', tCommon),
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useCreateExchangeOrder = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation('market');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.createExcahngeOrder, {
        onSuccess: () => {
            setSnackBarMessage(t('exchange_created_success_message'));
            setNeedRefresh(true);
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error), 'error');
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetCollateralsAvailable = () => {
    const { selectedCustomer } = useContext(AuthContext);
    const customer = selectedCustomer?.customerCode || '';
    const { data, isLoading, error, isFetching, isRefetching, isError, refetch } = useQuery(
        ['get-collaterals-available', customer],
        () => endpoints.getCollateralsAvailable(customer),
        { select: data => data.data },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetPreviewMaxOperable = () => {
    const { data, mutate, isLoading, error, isError, reset } = useMutation(
        endpoints.getMarketPreviewMaxOperable,
    );
    return {
        getMaxOperable: mutate,
        data: data?.data,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
        reset,
    };
};

export const useGetReportCollateralsByDate = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, isFetching, isRefetching, refetch } = useQuery(
        ['get-report-collaterals-by-date', filterQueryString],
        () => endpoints.getReportCollaterals(filterQueryString),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
