import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CurrencyLabel.styled';
import { Currency } from 'types/common/general.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

const CurrencyLabel = (props: Props) => {
    const { currency, value, variant } = props;

    return (
        <St.Container>
            <Typography variant="caption" color="primary.main">
                {currency}
            </Typography>
            <Typography variant={variant ?? 'h4'} color="primary.main">
                {formatNumber(value)}
            </Typography>
        </St.Container>
    );
};

const propTypes = {
    value: PropTypes.number.isRequired,
};

interface extraProps {
    currency: Currency;
    variant?: Variant;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyLabel.propTypes = propTypes;

export default CurrencyLabel;
