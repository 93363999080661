import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './LockedFunctionalityModal.styled';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { LockedFunctionalityItem } from 'types/api/rules.types';
import CustomIconCreator from '../CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';

const LockedFunctionalityModal = (props: Props) => {
    const { open, title, close, t, lockInfo, forceHome } = props;

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={() => (forceHome ? undefined : close())}>
            <St.DialogTitle>
                <Box>{title}</Box>
                {!forceHome && (
                    <St.CloseIconButton aria-label="close" onClick={close}>
                        <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                    </St.CloseIconButton>
                )}
            </St.DialogTitle>
            <DialogContent>
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                        __html: lockInfo?.details[0]?.lockReason || '',
                    }}
                />
            </DialogContent>
            <DialogActions>
                <ActionButton sx={{ margin: 0 }} variant="contained" onClick={close}>
                    {t('close', tCommon)}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    forceHome: PropTypes.bool.isRequired,
};

interface extraProps {
    open: boolean;
    title: string;
    close: () => void;
    lockInfo: LockedFunctionalityItem | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LockedFunctionalityModal.propTypes = propTypes;

export default LockedFunctionalityModal;
