import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SignUpHeaderNumbers from './SignUpHeaderNumbers';

const SignUpHeaderNumbersContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <SignUpHeaderNumbers {...childProps} />;
};

const propTypes = {
    activated: PropTypes.number.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SignUpHeaderNumbersContainer.propTypes = propTypes;

export default SignUpHeaderNumbersContainer;
