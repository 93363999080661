import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SearchClientModal from './SearchClientModal';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { MarketAccount } from 'types/api/marketAccount.types';

const getInitialValues = (account: MarketAccount | null) => ({
    selectedClient: account || null,
});

const getValidationSchema = () =>
    Yup.object().shape({
        selectedClient: Yup.object().nullable(),
    });

const SearchClientModalContainer = (props: Props) => {
    const { isOpen, close } = props;
    const { t } = useTranslation();

    const handleSubmit = React.useCallback(async (values: FormikValues) => {}, []);

    const {
        setSelectedCustomer,
        selectedCustomer,
        marketAccounts,
        isLoadingMarketAccounts,
        errorMarketAccounts,
    } = React.useContext(AuthContext);
    const [account, setAccount] = React.useState<MarketAccount | null>(selectedCustomer);

    const onChange = (value: MarketAccount) => setSelectedCustomer(value);

    const [filterQueryString, setFilterQueryString] = React.useState<string>('');

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(account),
            validateOnChange: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    React.useEffect(() => {
        setAccount(selectedCustomer);
        if (isOpen) {
            formik.resetForm({
                values: getInitialValues(null),
            });
        }
    }, [selectedCustomer, isOpen]);

    const handleConfirm = () => {
        if (formik.values.selectedClient) {
            onChange(formik.values.selectedClient);
        }
        close();
    };

    const handleSearch = React.useCallback(
        (query: string) => {
            const filteredAccounts = marketAccounts?.filter(account =>
                `${account.name} ${account.last_name} ${account.customerCode}`
                    .toLowerCase()
                    .includes(query.toLowerCase()),
            );
            setFilterQueryString(query);
        },
        [marketAccounts],
    );

    const childProps = {
        ...props,
        t,
        formik,
        handleConfirm,
        handleSearch,
        isOpen,
        close,
        isLoadingMarketAccounts,
        errorMarketAccounts,
        marketAccounts,
        setFilterQueryString,
        onChange,
    };

    return <SearchClientModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isOpen: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchClientModalContainer.propTypes = propTypes;

export default SearchClientModalContainer;
