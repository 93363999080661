import { tErrorsContext } from 'constants/appConstants';
import { t } from 'i18next';

export const errorResponseHelper = (err: any) => {
    const errorCode = err?.response?.data?.errorCode;
    if (errorCode === 'InternalError' || !errorCode) {
        return t('Internal_Error', tErrorsContext);
    }
    return t(errorCode, tErrorsContext);
};
