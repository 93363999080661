import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContractPage from './OnboardingContractPage';
import { useTranslation } from 'react-i18next';
import { useGetContract, useUploadContractStep } from 'hooks/api/onboarding.hooks';
import { useCreateSignature, useGetFiles } from 'hooks/api/utils.hooks';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { base64ToFile } from 'utils/helpers/files.helper';
import { useNavigate } from 'react-router-dom';
import { onboardingRoutesPaths } from 'router/routesPaths';
import { tCommon } from 'constants/appConstants';
import { OnboardingContext } from 'context/onboarding.context';

const OnboardingContractPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const { isLoadingLegalData, isLoadingSteps } = React.useContext(OnboardingContext);
    const navigate = useNavigate();
    const { matchesLg } = useBreakpoints();
    const [acceptContract, setAcceptContract] = React.useState(false);
    const [openPdfReader, setOpenPdfReader] = React.useState(false);
    const [enableButton, setEnableButton] = React.useState(false);
    const [sign, setSign] = React.useState<any>();
    const [file, setFile] = React.useState<File | null>(null);
    const [filePath, setFilePath] = React.useState<string>('');

    const { data, isLoading, errorMessage } = useGetContract(isLoadingLegalData || isLoadingSteps);
    const { fileUrl, isLoading: isLoadingFile } = useGetFiles(!!data?.tycPath, data?.tycPath);
    const {
        error,
        isLoading: isLoadingSignature,
        mutate: createSignature,
    } = useCreateSignature(file, (field, value) => setFilePath(value), setEnableButton);

    const {
        mutate,
        isLoading: isLoadingSubmit,
        errorMessage: errorMessageSubmit,
    } = useUploadContractStep();

    const handleClear = () => {
        sign.clear();
        setEnableButton(false);
    };
    const handleSubmit = () => {
        if (sign) {
            setFile(
                base64ToFile(
                    sign.getTrimmedCanvas().toDataURL('image/png'),
                    `${t('signature', tCommon)}.png`,
                ),
            );
            createSignature({ uploadFilesNames: [`${t('signature', tCommon)}.png`] });
        }
    };
    const handleOnEnd = () => {
        if (sign) {
            setEnableButton(true);
            return;
        }
        setEnableButton(false);
    };

    React.useEffect(() => {
        if (filePath) {
            mutate(
                {
                    acceptContract,
                    documentPath: filePath,
                },
                {
                    onError: () => {
                        setEnableButton(false);
                    },
                    onSuccess: () => {
                        navigate(onboardingRoutesPaths.home);
                    },
                },
            );
        }

        return () => {
            setFilePath('');
        };
    }, [filePath]);

    const childProps = {
        ...props,
        t,
        errorMessage: errorMessage ?? errorMessageSubmit ?? error,
        isLoading: isLoading || isLoadingFile,
        contractPath: data?.contractPath,
        isLoadingSubmit: isLoadingSignature || isLoadingSubmit,
        acceptContract,
        setAcceptContract,
        openPdfReader,
        setOpenPdfReader,
        fileUrl,
        matchesLg,
        setSign,
        handleSubmit,
        handleClear,
        enableButton: enableButton && acceptContract,
        setEnableButton,
        handleOnEnd,
        isLoadingContext: isLoadingLegalData || isLoadingSteps,
    };

    return <OnboardingContractPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContractPageContainer.propTypes = propTypes;

export default OnboardingContractPageContainer;
