import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { CreateDocumentResponse, DownloadFileResponse } from 'types/utils/utils.api.types';

const apiName = 'utils';

export default {
    createDocument: (req: any) =>
        withFakeData<CreateDocumentResponse>(
            () => axiosDataInstance.post('/files/v1_0/', req),
            apiName,
            'createNewDocument',
            false,
            500,
        ),
    downloadFile: (downloadFileName: string) =>
        withFakeData<DownloadFileResponse>(
            () => axiosDataInstance.get(`/files/v1_0/?downloadFileName=${downloadFileName}`),
            apiName,
            'download',
            false,
            500,
        ),
    getAutocompleteInfo: ({ url, param }: { url: string; param: string }) =>
        withFakeData<any>(
            () => axiosDataInstance.get(`${url}?${param}`),
            apiName,
            'requestAutocompleteCall',
            false,
            2000,
        ),
};
