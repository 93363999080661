import { Grid, styled, Typography } from '@mui/material';

export default {
    GriItem: styled(Grid)(() => ({
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0',
        borderBottom: '1px solid #ccc',
        width: '100%',
        gap: '10px',
        marginTop: '20px',
    })),

    Title: styled(Typography)(() => ({
        fontSize: '1.2rem',
        fontWeight: 'bold',
    })),
    Description: styled(Typography)(() => ({
        fontSize: '1rem',
        color: 'grey',
    })),
};
