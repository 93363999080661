import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BankAccountExtraDataModal from './BankAccountExtraDataModal';
import { useTranslation } from 'react-i18next';
import { BankAccountsTypes, SortedBankAccount } from 'types/api/cashflow.types';

const BankAccountExtraDataModalContainer = (props: Props) => {
    const { accountType } = props;
    const { t } = useTranslation('profile');

    const childProps = {
        ...props,
        t,
        title: t(
            `${'bankAccountExtraDataModal'.replace(/([A-Z])/g, '_$1').toLowerCase()}_${accountType.toLowerCase()}_title_name`,
        ),
    };

    return <BankAccountExtraDataModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
    close: () => void;
    accountType: BankAccountsTypes;
    sortedBankAccount: SortedBankAccount;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountExtraDataModalContainer.propTypes = propTypes;

export default BankAccountExtraDataModalContainer;
