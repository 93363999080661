import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HomeInvestmentProfile.styled';
import { InvestmentTestValues } from 'types/common/users.types';
import { Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/helpers/stringsHelper';
import ActionButton from 'components/common/ActionButton';
import investmentProfileImg from 'assets/img/general/investmentProfile.png';

const HomeInvestmentProfile = (props: Props) => {
    const { t, goToInvestmentTest, investmentProfile, investorFeatures } = props;

    return (
        <St.Container>
            <Typography variant="h4" color="primary">
                {t('investment_profile_subtitle', {
                    profile: capitalizeFirstLetter(
                        t(investmentProfile?.toLowerCase(), { ns: 'onboarding' }),
                    ),
                })}
            </Typography>
            <St.ImageContainer>
                <St.Img src={investmentProfileImg} />
                <St.UnionIcon $investmentProfile={investmentProfile} />
            </St.ImageContainer>
            <St.Ul>
                {investorFeatures.map((el: string, index: number) => (
                    <St.Li key={index}>{el}</St.Li>
                ))}
            </St.Ul>
            <St.ActionButtonContainer>
                <ActionButton
                    sx={{ width: { lg: '95%', sm: '418px', xs: '100%' } }}
                    variant="contained"
                    onClick={goToInvestmentTest}>
                    {t('resimulate_button_text')}
                </ActionButton>
            </St.ActionButtonContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    goToInvestmentTest: PropTypes.func.isRequired,
};

interface extraProps {
    investmentProfile?: InvestmentTestValues;
    investorFeatures: string[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomeInvestmentProfile.propTypes = propTypes;

export default HomeInvestmentProfile;
