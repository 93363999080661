import { useQuery } from '@tanstack/react-query';
import endpoints from 'api/rules/rules.api';
import { AuthContext } from 'context/auth.context';
import { useContext } from 'react';
import { errorResponseHelper } from 'utils/helpers/errorHelper';

export const useGetLockedFunctionalities = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const { data, isLoading, error, isError } = useQuery(
        ['locked-functionality', isAuthenticated],
        () => endpoints.getLockedFunctionilities(),
        {
            select: data => data?.data?.items,
            enabled: isAuthenticated,
        },
    );

    return {
        data,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};
