import { BlogArticle, BlogArticleResponse } from 'types/api/wrapper.types';

export const blogArticleAdapter = (data: BlogArticleResponse): BlogArticle => {
    return {
        id: data.id,
        mobTitulo: data.mob_titulo,
        category: data.category,
        mobImagenGrandeHeader: data.mob_imagen_grande_header,
        articleUrl: data.article_url,
        mobUrl: data.mob_url,
    };
};

export const blogArticlesAdapter = (data: BlogArticleResponse[]): BlogArticle[] => {
    return data?.map(blogArticleAdapter);
};

export const blogLatestsArticlesAdapter = (data: BlogArticleResponse): BlogArticle => {
    return {
        id: data.id,
        mobTitulo: data.mob_titulo,
        category: data.category,
        mobImagenGrandeHeader: data.mob_imagen_grande_header,
        articleUrl: data.article_url,
        mobUrl: data.mob_url,
    };
};

export const blogLatestArticlesAdapter = (
    data: Record<string, { categoryName: string; data: BlogArticleResponse[] }>,
): BlogArticle[] => {
    return Object.values(data).flatMap(category => category.data.map(blogArticleAdapter));
};
