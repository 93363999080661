import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingHomePage from './OnboardingHomePage';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'context/onboarding.context';
import { getFullName } from 'utils/helpers/commonHelper';
import { useGetProfileInfo } from 'hooks/api/auth.hooks';

const OnboardingHomePageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const [isOpenModal, setIsOpenModal] = React.useState(false);
    const { isLoadingSteps, steps, legalData, isLoadingLegalData } =
        React.useContext(OnboardingContext);
    const { isLoading: isLoadingProfileInfo } = useGetProfileInfo();
    const rejectSteps = steps?.filter(step => step?.status === 'rejected');

    const childProps = {
        ...props,
        pageLoad: isLoadingSteps || isLoadingLegalData || isLoadingProfileInfo,
        steps,
        isOpenModal,
        setIsOpenModal,
        rejectSteps,
        thereAreRejectedSteps: !!(rejectSteps && rejectSteps.length > 0),
        allStepsApproved: !steps?.find(el => ['pending', 'rejected'].includes(el.status)),
        fullname: getFullName(legalData),
        t,
    };

    return <OnboardingHomePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingHomePageContainer.propTypes = propTypes;

export default OnboardingHomePageContainer;
