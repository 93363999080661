import { AlertColor, useTheme } from '@mui/material';
import { exchangeTerms } from 'constants/appConstants';
import { AuthContext } from 'context/auth.context';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ExchangeForm from './ExchangeForm';
import { useGetExchangePrices } from 'hooks/ws/prices.hooks';
import { CommonSelectType, Currency } from 'types/common/general.types';
import { AmountType } from 'types/pages/marketExchangePage.types';
import { useCreateExchangeOrder } from 'hooks/api/orders.hooks';

const ExchangeFormContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation('market');
    const { customerCode, selectedCustomer } = React.useContext(AuthContext);
    const [term, setTerm] = React.useState<CommonSelectType | null>(
        exchangeTerms.find(t => t.name === 'Inmediato') ?? null,
    );
    const [currencyStartEnd, setCurrencyStartEnd] = React.useState<CommonSelectType | null>(null);
    const [amount, setAmount] = React.useState(0);
    const [isManual, setIsManual] = React.useState<boolean>(false);
    const theme = useTheme();

    const getCurrencyValue = (currency?: string) => {
        switch (currency) {
            case 'MEP':
                return 'USD';
            case 'CABLE':
                return 'USD-C';
            case 'ARS':
                return 'ARS';
            default:
                return 'ARS';
        }
    };

    const currencyStart: Currency | null = React.useMemo(() => {
        if (!currencyStartEnd) return null;
        const currencySplitted = currencyStartEnd.name.split('-');
        return getCurrencyValue(currencySplitted[0].trim());
    }, [currencyStartEnd]);

    const {
        mutate,
        isLoading: isLoadingSubmit,
        errorMessage: errorMessageSubmit,
    } = useCreateExchangeOrder(setSnackBarMessage, close);

    const {
        instruments,
        isLoading: isLoadingPrices,
        errorMessage: errorMessagePrices,
        tickerPrices,
        instrument,
        setInstrument,
        accountStatus,
        errorAccountStatus,
        isLoadingAccountStatus,
        maxOperable,
        isLoadingMaxOperable,
        errorMessagePreviewOperable,
    } = useGetExchangePrices(term?.name as 'Inmediato' | '24hs', currencyStart);

    const amountType: AmountType = React.useMemo(() => {
        if (!amount) return null;
        if (amount <= 50000) return 'LOW_AMOUNT';
        if (!selectedCustomer?.is_qualified) return 'HIGH_NOT_QUALIFIED';
        return 'HIGH_AMOUNT';
    }, [amount, selectedCustomer?.is_qualified]);

    const handleSubmit = () => {
        const currencySplitted = currencyStartEnd?.name.split('-');
        mutate({
            amount,
            buySell: 'BUY',
            currencyEnd: getCurrencyValue(currencySplitted ? currencySplitted[1].trim() : ''),
            currencyStart: currencyStart ?? 'ARS',
            customerCode: customerCode ?? '',
            isManual,
            isMarketPrice: true,
            otp: null,
            term: term?.id ?? 0,
            tickerId: String(instrument?.tickerId ?? instrument?.id) ?? '',
            userUuid: selectedCustomer?.uuid ?? '',
        });
    };

    const disableOperate = React.useMemo(() => {
        if (!currencyStart) return false;
        const selectedCurrency =
            currencyStart === 'USD-C'
                ? 'usdc'
                : (currencyStart?.toLowerCase() as 'ars' | 'usd' | 'usdc');
        return accountStatus?.availableBalance?.operate?.t0[selectedCurrency] === 0;
    }, [accountStatus, currencyStart]);

    const childProps = {
        ...props,
        t,
        isQualified: selectedCustomer?.is_qualified ?? false,
        hasExteriorAccount: selectedCustomer?.hasExteriorAccount ?? false,
        isLoading: isLoadingAccountStatus || isLoadingPrices,
        errorMessage: errorAccountStatus || errorMessagePrices,
        term,
        setTerm,
        currencyStartEnd,
        setCurrencyStartEnd,
        instrument,
        setInstrument,
        instruments,
        tickerPrices,
        amount,
        setAmount,
        handleSubmit,
        theme,
        availableToOperate: accountStatus?.availableBalance?.operate?.t0 ?? null,
        amountType,
        isManual,
        setIsManual,
        disableOperate,
        isLoadingSubmit,
        errorMessageSubmit,
        maxOperable,
        isLoadingMaxOperable,
        errorMessagePreviewOperable,
        currencyStart,
    };

    return <ExchangeForm {...childProps} />;
};

const propTypes = {};

interface extraProps {
    close: () => void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeFormContainer.propTypes = propTypes;

export default ExchangeFormContainer;
