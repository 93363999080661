import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomLoader from './CustomLoader';

const CustomLoaderContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CustomLoader {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomLoaderContainer.propTypes = propTypes;

export default CustomLoaderContainer;
