import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import QuestionsAnswers from './QuestionsAnswers';
import { Option } from 'types/pages/onboardingValidatePerson.types';

const QuestionsAnswersContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <QuestionsAnswers {...childProps} />;
};

const propTypes = {};

interface extraProps {
    questionKey: string;
    handleCheck: (field: string, questionKey: string) => void;
    questionId: string;
    text: string;
    current: boolean;
    selectedOption: boolean;
    questionOptions: Option;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuestionsAnswersContainer.propTypes = propTypes;

export default QuestionsAnswersContainer;
