import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import { tCommon } from 'constants/appConstants';
import { Form, FormikProvider } from 'formik';
import FileUploader from 'components/common/FileUploader';
import ErrorText from 'components/common/ErrorText';
import ActionButton from 'components/common/ActionButton';
import { pxToRem } from 'utils/helpers/stylesHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { State } from 'types/api/onboarding.types';
import DeleteIcon from 'assets/icons/delete-bin-7-line.svg?react';
import CustomInput from 'components/common/CustomInput';

const OnboardingContactInfoModal = (props: Props) => {
    const {
        t,
        openModal,
        handleClose,
        setOpenModal,
        formik,
        errorSubmit,
        isLoading,
        formInputs,
        states,
        cleanForm,
        isLoadingStates,
        fileLoading,
        setFileLoading,
    } = props;

    const { errors, values, setFieldValue } = formik;

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            fullWidth>
            <FormikProvider value={formik}>
                <Form>
                    <DialogTitle id="alert-dialog-title" sx={{ pt: 2, pb: 1 }}>
                        {t('contact_info_modal_title')}
                        <Tooltip placement="bottom" title={t('clean', tCommon)}>
                            <IconButton
                                aria-label="clean"
                                onClick={() => cleanForm()}
                                sx={{ color: theme => theme.palette.grey[500] }}>
                                <CustomIconCreator icon={DeleteIcon} alt="clean-icon" />
                            </IconButton>
                        </Tooltip>

                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenModal(false)}
                            sx={{
                                color: theme => theme.palette.grey[500],
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                            <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container rowGap="10px" columnGap="20px" marginBottom={pxToRem(30)}>
                            {formInputs.map(field => (
                                <Grid item xs={5.5} key={field.id}>
                                    <CustomInput
                                        field={field.id}
                                        errors={errors}
                                        regex={field.regex}
                                        placeholder={t(field.label)}
                                        label={t(field.label)}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        sx={{ mt: '1em' }}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={5.5} marginTop="16px">
                                <CustomAutoComplete
                                    id="state"
                                    value={values.state}
                                    onChange={(e, value) => {
                                        setFieldValue('state', value);
                                    }}
                                    error={errors?.state ?? false}
                                    options={states ?? []}
                                    isLoading={isLoadingStates}
                                    label={t('state') as string}
                                    placeholder={t('state_placeholder') as string}
                                    renderOption={(props, option) => (
                                        <Box {...props} key={option.id}>
                                            {option.name}
                                        </Box>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="body2">{t('valid_service_file')}</Typography>
                        <FileUploader
                            {...{
                                values,
                                errors,
                                setFieldValue,
                                label: t(`valid_service_proof`),
                                field: 'documentPath',
                                setDisableButton: setFileLoading,
                                isEdit: !!values.documentPath,
                            }}
                        />
                        {errorSubmit && <ErrorText text={errorSubmit} />}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            px: 2,
                            pb: 2,
                            paddingBottom: pxToRem(8),
                            paddingLeft: pxToRem(40),
                            paddingRight: pxToRem(40),
                        }}>
                        <Grid container display={'flex'} flexDirection={'column'}>
                            <Grid
                                container
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'end'}
                                justifyContent={'space-around'}
                                marginBottom={'16px'}>
                                <Grid item xs={5}>
                                    <ActionButton
                                        variant="outlined"
                                        color="secondary"
                                        disabled={isLoading || fileLoading}
                                        sx={{ width: '100%' }}
                                        onClick={() => setOpenModal(false)}>
                                        {t('cancel', tCommon)}
                                    </ActionButton>
                                </Grid>
                                <Grid item xs={5}>
                                    <ActionButton
                                        type="submit"
                                        disabled={fileLoading}
                                        loading={isLoading}
                                        variant="contained"
                                        sx={{ width: '100%' }}>
                                        {t('accept', tCommon)}
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    cleanForm: PropTypes.func.isRequired,
    isLoadingStates: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    openModal: boolean;
    handleClose: () => void;
    setOpenModal: (state: boolean) => void;
    formik: any;
    // handleClearAllInputs: () => void;
    formInputs: {
        label: string;
        id: string;
        regex?: RegExp;
    }[];
    errorSubmit: string | null;
    states?: State[];
    fileLoading: boolean;
    setFileLoading: (x: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoModal.propTypes = propTypes;

export default OnboardingContactInfoModal;
