import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { DECLARATIONS_BASE_URL } from 'constants/appConstants';
import {
    GetApproveDeclarationsResponse,
    GetDeclarationPreviewResponse,
    GetDeclarationsResponse,
} from 'types/api/declarations.types';

const apiName = 'declarations';

export default {
    getDeclarations: (status: string) =>
        withFakeData<GetDeclarationsResponse>(
            () => axiosDataInstance.get(`${DECLARATIONS_BASE_URL}?status=${status}`),
            apiName,
            'declarations',
            false,
            500,
        ),

    approveDeclaration: ({ id }: { id: string }) =>
        withFakeData<GetApproveDeclarationsResponse>(
            () => axiosDataInstance.put(`${DECLARATIONS_BASE_URL}/${id}/approve`),
            apiName,
            'approve_declaration',
            false,
            500,
        ),
    approveDeclarationChannel: ({ id, filePath }: { id: string; filePath?: string }) =>
        withFakeData<GetApproveDeclarationsResponse>(
            () => axiosDataInstance.put(`${DECLARATIONS_BASE_URL}/${id}/approve`, { filePath }),
            apiName,
            'approve_declaration',
            false,
            500,
        ),
    getPreviewDDJJ: () =>
        withFakeData<GetDeclarationPreviewResponse>(
            () => axiosDataInstance.get(`${DECLARATIONS_BASE_URL}/preview`),
            apiName,
            'declarations',
            false,
            500,
        ),
};
