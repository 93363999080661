import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import ArrowLeft from 'assets/icons/arrow-left-s-line.svg?react';
import { CreateFundOrderRequest, CreateFundOrderResponse } from 'types/api/orders.types';
import ErrorModal from 'components/common/ErrorModal';
import { Currency } from 'types/common/general.types';
import CustomModalAuth from 'components/common/CustomModalAuth';
import BuySellSucessModal from 'components/common/BuySellSucessModal';
import St from './BuySellPrevisualizeModal.styled';
import { MarketAccount } from 'types/api/marketAccount.types';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';

const BuySellPrevisualizeModal = (props: Props) => {
    const {
        t,
        open,
        close,
        onSubmit,
        isLoading,
        openModal,
        setOpenModal,
        fundPreview,
        validateOtp,
        requestOtp,
        isLoadingSubmitMfa,
        apiError,
        bodyToCreateOrder,
        selectedCustomer,
        successModalProps,
        orderCreationResponse,
        isAPOrAC,
        recommendationText,
        currentDate,
    } = props;

    return (
        <>
            <Dialog open={open} onClose={close} fullWidth>
                <St.Header display={'flex'} justifyContent={'space-between'}>
                    <St.TitleContainer>
                        <ArrowLeft cursor={'pointer'} onClick={close} />
                        <Typography variant="caption">{t('fund')}</Typography>
                    </St.TitleContainer>
                    <Typography variant="caption" fontWeight={700}>
                        {formatSingleDate(currentDate, 'DD/MM/YYYY')}
                    </Typography>
                </St.Header>
                <Divider />
                <DialogContent>
                    <Stack spacing={2}>
                        {isAPOrAC && selectedCustomer && (
                            <St.ContentRowContainer>
                                <Typography variant="body1">
                                    {t('customer_information', {
                                        customer: `${selectedCustomer.name} ${selectedCustomer.last_name} - N° ${selectedCustomer.customerCode}`,
                                    })}
                                </Typography>
                            </St.ContentRowContainer>
                        )}
                        <St.ContentRowContainer>
                            <St.BlueTypography variant="h5">{fundPreview.name}</St.BlueTypography>
                            <St.BlueTypography variant="h5">
                                {convertCurrencyFormat(
                                    fundPreview.priceToOperate,
                                    fundPreview.currency,
                                )}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        <St.ContentRowContainer>
                            <St.BlueTypography variant="body2">
                                {fundPreview.class}
                            </St.BlueTypography>
                            <St.BlueTypography variant="body2">
                                {t('fund_buy_sell_previsualize_operate_text')}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        {bodyToCreateOrder.buySell === 'SELL' && (
                            <St.ContentRowContainer>
                                <Typography variant="body2">{t('liquidity_term')}</Typography>
                                <St.BlueTypography variant="body2">
                                    {bodyToCreateOrder.term === 0 ? t('operate_immediate') : '24hs'}
                                </St.BlueTypography>
                            </St.ContentRowContainer>
                        )}
                        <St.ContentRowContainer>
                            <Typography variant="body2">
                                {t('fund_buy_sell_legend_text_1')}
                            </Typography>
                        </St.ContentRowContainer>
                        {bodyToCreateOrder.buySell === 'SELL' && (
                            <St.ContentRowContainer>
                                <Typography variant="body2">
                                    {t(
                                        `${bodyToCreateOrder.isTotal ? 'total' : 'estimated'}_cuotapartes`,
                                    )}
                                </Typography>
                                <St.BlueTypography variant="body2">
                                    {fundPreview.cuotaparte}
                                </St.BlueTypography>
                            </St.ContentRowContainer>
                        )}
                        <St.ContentRowContainer>
                            <Typography variant="body2">
                                {t(
                                    `${bodyToCreateOrder.isTotal && bodyToCreateOrder.buySell === 'SELL' ? 'approximate' : 'net'}_field_text`,
                                )}
                            </Typography>
                            <St.BlueTypography variant="body2">
                                {convertCurrencyFormat(
                                    bodyToCreateOrder.amount,
                                    fundPreview.currency,
                                )}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        {isAPOrAC && (
                            <St.CustomRadioButtonWrapper>
                                <St.CustomRadioButton
                                    checked={true}
                                    name={t(
                                        `is_${bodyToCreateOrder.isManual ? 'manual' : 'direct'}_text`,
                                    )}
                                    onChange={() => null}
                                    text={t(
                                        `is_${bodyToCreateOrder.isManual ? 'manual' : 'direct'}_text`,
                                    )}
                                    value={t(
                                        `is_${bodyToCreateOrder.isManual ? 'manual' : 'direct'}_text`,
                                    )}
                                />
                            </St.CustomRadioButtonWrapper>
                        )}
                        <St.ContentRowContainer>
                            <St.BlueTypography variant="body2">
                                {recommendationText}
                            </St.BlueTypography>
                        </St.ContentRowContainer>
                        {apiError && <St.ErrorText text={apiError} />}
                    </Stack>
                </DialogContent>
                <St.ButtonContainer>
                    <ActionButton
                        loading={isLoading}
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={onSubmit}>
                        {t(`${bodyToCreateOrder.buySell === 'BUY' ? 'subscribe' : 'rescue'}_text`)}
                        {isAPOrAC && selectedCustomer && ` - N° ${selectedCustomer.customerCode}`}
                    </ActionButton>
                </St.ButtonContainer>
            </Dialog>
            {openModal === 'success' && (
                <BuySellSucessModal
                    {...{ ...successModalProps, instrumentType: 'FUND', open }}
                    close={() => setOpenModal(null)}
                    date={orderCreationResponse?.agreementDatetime ?? currentDate}
                />
            )}
            {openModal === 'error' && (
                <ErrorModal open={openModal === 'error'} close={() => setOpenModal(null)} />
            )}
            {openModal === 'enableMFA' && (
                <CustomModalAuth
                    handleClose={() => setOpenModal(null)}
                    isLoadingSubmit={isLoadingSubmitMfa}
                    open={openModal === 'enableMFA'}
                    apiError={apiError}
                    sendEmailCode={requestOtp}
                    handleSubmit={validateOtp}
                />
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    isLoadingSubmitMfa: PropTypes.bool.isRequired,
};

interface extraProps {
    open: boolean;
    isAPOrAC: boolean;
    openModal: 'error' | 'success' | 'enableMFA' | null;
    close: () => void;
    fundPreview: {
        ticker: string;
        name: string;
        class: string;
        currency: Currency;
        priceToOperate: number;
        cuotaparte: string;
    };
    requestOtp: () => void;
    validateOtp: (otp: string) => void;
    apiError: string | null;
    bodyToCreateOrder: CreateFundOrderRequest;
    selectedCustomer: MarketAccount | null;
    successModalProps: { leftText: string; rightText: string; leftUrl: string; rightUrl: string };
    orderCreationResponse?: CreateFundOrderResponse;
    recommendationText: string;
    currentDate: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellPrevisualizeModal.propTypes = propTypes;

export default BuySellPrevisualizeModal;
