import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfilePersonalDataPage.styled';
import PersonalDataItem from './PersonalDataItem';
import { UserData } from 'types/api/auth.types';
import { download } from 'utils/helpers/files.helper';
import CircularSpinner from 'components/common/CircularSpinner';
import ErrorText from 'components/common/ErrorText';

const ProfilePersonalDataPage = (props: Props) => {
    const { t, userData, fileUrl, customerCode, errorMessage, isLoading } = props;

    return (
        <St.SectionWrapper component={'section'} data-testid="ProfilePersonalDataPage-page">
            <St.SectionInfo>
                <St.TitleSection>{t('personal_data_title')}</St.TitleSection>
                <St.GridContainer container>
                    <PersonalDataItem
                        title={t('personal_data_customer_code')}
                        description={customerCode || ''}
                    />
                    {userData?.userType === 'APP_PHYSICAL' && (
                        <>
                            <PersonalDataItem
                                title={t('personal_data_dni')}
                                description={userData?.info?.docNumber}
                            />
                            <PersonalDataItem
                                title={t('personal_data_name')}
                                description={userData?.name}
                            />
                            <PersonalDataItem
                                title={t('personal_data_last_name')}
                                description={userData?.lastName}
                            />
                            <PersonalDataItem
                                title={t('personal_data_birthay')}
                                description={
                                    userData?.info?.birthdate &&
                                    userData?.info?.birthdate.split('-').reverse().join('/')
                                }
                            />
                        </>
                    )}
                    {userData?.userType === 'APP_LEGAL' && (
                        <>
                            <PersonalDataItem
                                title={t('personal_data_cuit')}
                                description={userData?.info?.docNumber}
                            />
                            <PersonalDataItem
                                title={t('personal_data_legal_name')}
                                description={userData?.name}
                            />
                        </>
                    )}
                </St.GridContainer>
            </St.SectionInfo>

            <St.SectionInfo>
                <St.TitleSection>{t('personal_data_contact_info')}</St.TitleSection>
                <St.GridContainer container>
                    <PersonalDataItem
                        title={t('personal_data_email')}
                        description={userData?.email}
                    />
                    <PersonalDataItem
                        title={t('personal_data_phone_number')}
                        description={
                            (userData?.contacts && userData?.contacts[0]?.phoneNumber) || ''
                        }
                    />
                </St.GridContainer>
            </St.SectionInfo>

            <St.SectionInfo>
                <St.TitleSection>{t('personal_data_address')}</St.TitleSection>
                <St.GridContainer container>
                    <PersonalDataItem
                        title={t('personal_data_street')}
                        description={
                            (userData?.contacts && userData?.contacts[0]?.streetName) || ''
                        }
                        breakPoints={{ xs: 5, md: 3 }}
                    />
                    <PersonalDataItem
                        title="N°"
                        description={(userData?.contacts && userData?.contacts[0]?.streetNum) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_zip_code')}
                        description={(userData?.contacts && userData?.contacts[0]?.zipCode) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_city')}
                        description={(userData?.contacts && userData?.contacts[0]?.city) || ''}
                        breakPoints={{ xs: 5, md: 4 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_floor')}
                        description={(userData?.contacts && userData?.contacts[0]?.floor) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_department')}
                        description={(userData?.contacts && userData?.contacts[0]?.apartment) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_sector')}
                        description={(userData?.contacts && userData?.contacts[0]?.sector) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_tower')}
                        description={(userData?.contacts && userData?.contacts[0]?.tower) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_square')}
                        description={(userData?.contacts && userData?.contacts[0]?.square) || ''}
                        breakPoints={{ xs: 5, md: 2 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_province')}
                        description={(userData?.contacts && userData?.contacts[0]?.state) || ''}
                        breakPoints={{ xs: 5 }}
                    />
                    <PersonalDataItem
                        title={t('personal_data_country')}
                        description={userData?.info?.countryTaxResidence}
                        breakPoints={{ xs: 5 }}
                    />
                </St.GridContainer>
            </St.SectionInfo>
            <div>
                {isLoading ? (
                    <CircularSpinner />
                ) : (
                    <>
                        {fileUrl && (
                            <St.ContainerBtn>
                                <St.LoadingButton onClick={() => download(fileUrl)}>
                                    {t('download_contract')}
                                </St.LoadingButton>
                            </St.ContainerBtn>
                        )}
                        {errorMessage && <ErrorText text={errorMessage} />}
                    </>
                )}
            </div>
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    userData: UserData | null;
    fileUrl?: string;
    customerCode: string | null;
    errorMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePersonalDataPage.propTypes = propTypes;

export default ProfilePersonalDataPage;
