import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientsWalletPage from './ClientsWalletPage';

const ClientsWalletPageContainer = (props: Props) => {
    // const {} = props;

    const [selectedInstrumentType, setSelectedInstrumentType] = React.useState<string>('');

    const childProps = {
        ...props,
        setSelectedInstrumentType,
        selectedInstrumentType,
    };

    return <ClientsWalletPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsWalletPageContainer.propTypes = propTypes;

export default ClientsWalletPageContainer;
