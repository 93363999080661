import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonMarketTable from 'components/common/CommonMarketTable';
import { useGetTableQuotations } from 'hooks/ws/prices.hooks';
import St from './MarketPreferredPage.styled';

const MarketPreferredPage = (props: Props) => {
    const { t } = props;

    return (
        <St.SectionWrapper>
            <CommonMarketTable
                typeInstrument="PREFERRED"
                title={t('market_table_preferred_title')}
                useGetData={useGetTableQuotations}
            />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketPreferredPage.propTypes = propTypes;
MarketPreferredPage;

export default MarketPreferredPage;
