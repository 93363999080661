import { Box, styled } from '@mui/material';
import ErrorText from '../ErrorText';
import CustomLoader from '../CustomLoader';

export default {
    ModalBox: styled(Box)(({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow:
            '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
        borderRadius: '10px',
        padding: '20px',
    })),
    ContainerBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        button: { marginTop: 0 },
    })),
    CustomLoaderWrapper: styled(Box)(({ theme }) => ({
        '& > div:first-of-type': { minHeight: '40px', margin: '1rem 0 1.5rem' },
    })),
    CustomLoader: styled(CustomLoader)(({ theme }) => ({})),
    ErrorText: styled(ErrorText)(() => ({})),
};
