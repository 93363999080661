import { Box, styled } from '@mui/material';

export default {
    Container: styled(Box)(({ theme }) => ({})),
    LogoContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
            },
        }),
    ),
    Logo: styled('img')(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '280px',
            },
        }),
    ),
};
