import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';
import CustomLoader from '../CustomLoader';

export default {
    CommonMarketTableWrapper: styled(Box)(() => ({
        maxWidth: '100%', //  '89rem',
        width: 'fit-content',
        margin: 'auto',
        minWidth: '100%',
    })),
    BoxWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '0.5rem', md: '12.25rem' },
            marginBottom: { xs: '0.5rem', md: '1.438rem' },
        }),
    ),
    CustomLoaderWrapper: styled(Box)(() => ({
        width: '100%',
    })),
    CustomLoader: styled(CustomLoader)(() => ({})),
    Title: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '2.125rem',
            fontWeight: '400',
            lineHeight: pxToRem('39.84px'),
            letterSpacing: '0.0025em',
            color: theme.palette.custom.vibrantblue2,
            m: '0',
        }),
    ),
};
