import {
    Alert,
    AlertTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import ActionButton from 'components/common/ActionButton';

export default {
    Dialog: styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    })),
    DialogTitle: styled(DialogTitle)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.5rem',
            m: 0,
            p: 2,
            pr: 1,
        }),
    ),
    IconButton: styled(IconButton)(({ theme }) => ({
        color: theme.palette.grey[500],
    })),
    DialogContent: styled(DialogContent)(() => ({
        paddingLeft: '1.5rem !important',
    })),
    Stack: styled(Stack)(() => ({ width: '100%' })),
    Alert: styled(Alert)(({ theme }) => ({})),
    AlertTitle: styled(AlertTitle)(() => ({})),
    RejectedStep: styled(DialogContent)(() => ({})),
    DialogActions: styled(DialogActions)(() => ({
        marginBottom: '.5rem',
        button: { margin: '0' },
    })),
    ActionButton: styled(ActionButton)(() => ({})),
};
