import { CommercialType, CMSCategory, CMSRedirects } from 'types/api/cms.types';

export const cmsCommercialAdapter = (
    categories: CMSCategory[],
    redirects: CMSRedirects[],
    type: CommercialType,
) => {
    const subcategories = categories.filter(c => c.parent && c.parent.name === type);

    const redirectsForCategory = redirects.filter((r, index) => {
        const redirectForCategory = subcategories.find(sc => sc?.name === r?.category?.name);
        return !!redirectForCategory;
    });

    return {
        subcategories,
        redirectsForCategory,
    };
};
export const cmsCommercialDetailAdapter = (
    categories: CMSCategory[],
    redirects: CMSRedirects[],
    id: number,
) => {
    const subcategories = categories.filter(c => c.parent && c.parent.id === id);
    const name = categories.find(c => c.id === id)?.name;

    const redirectsForCategory = redirects.filter((r, index) => {
        const redirectForCategory = subcategories.find(sc => sc?.name === r?.category?.name);
        return !!redirectForCategory;
    });

    return {
        subcategories,
        redirectsForCategory,
        name,
    };
};
