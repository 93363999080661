import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            backgroundColor: theme.palette.custom.vibrantblue10,
            borderRadius: '25px',
            width: pxToRem(299),
        }),
    ) as typeof Box,
    TopComponent: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            color: 'black',
            textAlign: 'center',
            fontWeight: 500,
        }),
    ) as typeof Box,
    BottomComponent: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue3,
            textAlign: 'center',
        }),
    ) as typeof Box,
};
