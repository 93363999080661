import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './NotificationItem.styled';
import { ParsedNotificationBody } from 'types/api/alerts.types';
import { Checkbox, IconButton, ListItemText, Tooltip } from '@mui/material';
import DeleteIcon from 'assets/icons/delete-bin-line.svg?react';
import CheckIcon from 'assets/icons/check-line.svg?react';
import { formatDate } from 'utils/helpers/dateHelper';

const NotificationItem = (props: Props) => {
    const {
        t,
        id,
        body,
        date,
        read,
        selectedNotifications,
        handleReadNotification,
        handleChangeNotification,
        handleDeleteNotification,
        isLoadingSubmitNotification,
    } = props;
    return (
        <St.NotificationItem $read={read}>
            <Checkbox
                checked={selectedNotifications?.includes(id) ?? false}
                onChange={() => handleChangeNotification(id)}
            />
            <ListItemText primary={body.title} secondary={body.message} />
            <St.OptionsContainer>
                <St.DateText variant="caption">{formatDate(date)}</St.DateText>
                <St.IconsContainer>
                    {!read && (
                        <Tooltip title={t('mark_as_read')}>
                            <IconButton
                                edge="end"
                                aria-label="mark as read"
                                onClick={handleReadNotification}
                                disabled={isLoadingSubmitNotification}>
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={t('delete_notification')}>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={handleDeleteNotification}
                            disabled={isLoadingSubmitNotification}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </St.IconsContainer>
            </St.OptionsContainer>
        </St.NotificationItem>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
    handleReadNotification: PropTypes.func.isRequired,
    handleChangeNotification: PropTypes.func.isRequired,
    handleDeleteNotification: PropTypes.func.isRequired,
    isLoadingSubmitNotification: PropTypes.bool.isRequired,
};

interface extraProps {
    body: ParsedNotificationBody;
    selectedNotifications: string[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NotificationItem.propTypes = propTypes;

export default NotificationItem;
