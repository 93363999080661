import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Tprop } from 'types/common/general.types';
import St from './TableOfTaker.styled';
import { tCommon } from 'constants/appConstants';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';
import { AvailableInstrument, GetCollateralOrderItem } from 'types/api/orders.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import InfoIcon from 'assets/icons/information-line.svg?react';
import ErrorText from 'components/common/ErrorText';

const TableOfTaker = (props: Props) => {
    const { columns, t, values, handleChange, row, errors } = props;

    return (
        <>
            <St.TableContainerWrapper $isDetail={!!row}>
                <St.Table>
                    <St.TableHead>
                        <St.TableRow>
                            {columns.map((column, index) => (
                                <St.TableCell key={index}>
                                    <span>{column}</span>
                                </St.TableCell>
                            ))}
                        </St.TableRow>
                    </St.TableHead>
                    <St.TableBody>
                        {values.tickers && Object.keys(values.tickers).length > 0 ? (
                            Object.entries(values.tickers).map(([key, ins]) => {
                                const instrument = ins as AvailableInstrument;
                                return (
                                    <St.TableRow key={instrument.id}>
                                        <St.TableCell>{instrument.ticker}</St.TableCell>
                                        {!row && (
                                            <St.TableCell>
                                                {formatNumber(
                                                    instrument.availableQuantity,
                                                    0,
                                                    false,
                                                )}
                                            </St.TableCell>
                                        )}
                                        <St.TableCell>
                                            ${formatNumber(instrument.aforadoPrice, 2)}
                                        </St.TableCell>
                                        {!row && (
                                            <St.TableCell>
                                                $
                                                {formatNumber(instrument.availableAmount, 0, false)}
                                            </St.TableCell>
                                        )}
                                        <St.TableCell
                                            sx={{ width: 'fit-content', position: 'relative' }}>
                                            <St.CustomInput
                                                field={instrument.ticker.replaceAll(' ', '_')}
                                                handleChange={val =>
                                                    handleChange(
                                                        instrument.ticker.replaceAll(' ', '_'),
                                                        val,
                                                    )
                                                }
                                                value={
                                                    values.tickers[
                                                        `${instrument.ticker.replaceAll(' ', '_')}`
                                                    ]?.warranty || '0'
                                                }
                                                values={values} // It is not actually being used
                                                errors={{
                                                    [`${instrument.ticker.replaceAll(' ', '_')}`]:
                                                        values.tickers[
                                                            `${instrument.ticker.replaceAll(' ', '_')}`
                                                        ]?.error
                                                            ? ''
                                                            : undefined,
                                                }}
                                                helperText=""
                                                maxLength={10}
                                                regex={REGEX_0_TO_9}
                                                required
                                                disabled={!!row}
                                            />
                                            {instrument.msj && (
                                                <St.Tooltip
                                                    placement="bottom"
                                                    title={instrument.msj}>
                                                    <St.IconButton $error={instrument.error}>
                                                        <CustomIconCreator
                                                            icon={InfoIcon}
                                                            alt="info-icon"
                                                        />
                                                    </St.IconButton>
                                                </St.Tooltip>
                                            )}
                                        </St.TableCell>
                                        <St.TableCell>
                                            $
                                            {formatNumber(
                                                values.tickers[
                                                    `${instrument.ticker.replaceAll(' ', '_')}`
                                                ]?.amount || 0,
                                                2,
                                            )}
                                        </St.TableCell>
                                    </St.TableRow>
                                );
                            })
                        ) : (
                            <St.TableRow>
                                <St.TableCell colSpan={6}>
                                    <St.AlerNoDataWrapper>
                                        <St.AlertNoData title={t('no_data_was_found', tCommon)} />
                                    </St.AlerNoDataWrapper>
                                </St.TableCell>
                            </St.TableRow>
                        )}
                    </St.TableBody>
                </St.Table>
            </St.TableContainerWrapper>
            {errors.tickers && <ErrorText text={errors.tickers} />}
        </>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    columns: string[];
    values: any;
    handleChange: (ticker: string, val: string) => void;
    row?: GetCollateralOrderItem;
    errors: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableOfTaker.propTypes = propTypes;

export default TableOfTaker;
