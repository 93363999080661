import React from 'react';
import endpoints from 'api/cms/cms.api';
import { useQuery } from '@tanstack/react-query';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { cmsCommercialAdapter, cmsCommercialDetailAdapter } from 'adapters/api/cms.adapter';
import { CommercialType } from 'types/api/cms.types';

export const useGetCMSCategories = (enabled?: boolean) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-cms-data'],
        () => endpoints.getCmsCategories(),
        {
            select: data => data.data,
            enabled,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        errorMessage: error ? errorResponseHelper(error) : null,
        refetch,
    };
};
export const useGetCMSRedirects = (enabled?: boolean) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-cms-redirects'],
        () => endpoints.getCmsRedirects(),
        {
            select: data => data.data,
            enabled,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        errorMessage: error ? errorResponseHelper(error) : null,
        refetch,
    };
};

export const useGetCommercialByType = (type: CommercialType) => {
    const {
        data: categories,
        isLoading: isLoadingCategories,
        errorMessage: errorMessageCategories,
    } = useGetCMSCategories();
    const {
        data: redirects,
        isLoading: isLoadingRedirects,
        errorMessage: errorMessageRedirects,
    } = useGetCMSRedirects();

    const data = React.useMemo(() => {
        if (!categories || !redirects) return null;
        return cmsCommercialAdapter(categories.data, redirects.data, type);
    }, [categories, redirects, type]);

    return {
        data,
        isLoading: isLoadingCategories || isLoadingRedirects,
        errorMessage: errorMessageCategories || errorMessageRedirects,
    };
};
export const useGetCommercialDetail = (id: number) => {
    const {
        data: categories,
        isLoading: isLoadingCategories,
        errorMessage: errorMessageCategories,
    } = useGetCMSCategories(!!id);
    const {
        data: redirects,
        isLoading: isLoadingRedirects,
        errorMessage: errorMessageRedirects,
    } = useGetCMSRedirects(!!id);

    const data = React.useMemo(() => {
        if (!categories || !redirects) return null;
        return cmsCommercialDetailAdapter(categories.data, redirects.data, id);
    }, [categories, redirects, id]);

    return {
        data,
        isLoading: isLoadingCategories || isLoadingRedirects,
        errorMessage: errorMessageCategories || errorMessageRedirects,
    };
};
