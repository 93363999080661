import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    BodyValidateNosisAnswers,
    ContactInfoRequest,
    GetBlogNewsResponse,
    GetContractPathResponse,
    GetNosisQuestionsResponse,
    GetValidateNosisAnswers,
    GetLegalDataReponse,
    GetNationalities,
    GetOnboardingStepsResponse,
    OnboardingPersonalDataPageRequest,
    StartOnboardingRequest,
    UploadContractRequest,
    Proofs,
    ValidateInvestmentTestAnswersResponse,
    BodyValidateInvestmentTestAnswers,
    GetInvestmentTestQuestionsResponse,
    ComplementaryDataBody,
    GetWorkActivitiesResponse,
    UploadDocumentRequest,
    GetStatesResponse,
    GetUserInformationForOnboardingResponse,
} from 'types/api/onboarding.types';

const apiName = 'onboarding';
const ONBOARDING_BASE_URL = '/onboarding/v1_0';
const ONBOARDING_STEPS_BASE_URL = `${ONBOARDING_BASE_URL}/onboarding/step`;
const ONBOARDING_REFERENCE_BASE_URL = `${ONBOARDING_BASE_URL}/reference`;

export default {
    getOnboardingSteps: (req: any) =>
        withFakeData<GetOnboardingStepsResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_STEPS_BASE_URL}`),
            apiName,
            'steps',
            false,
            500,
        ),
    getUserInformationForOnboarding: (req: any) =>
        withFakeData<GetUserInformationForOnboardingResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/onboarding/user_info`),
            apiName,
            'user-info',
            false,
            500,
        ),
    startOnboarding: (req: StartOnboardingRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/start_onboarding`, req),
            apiName,
            'steps',
            false,
            500,
        ),
    uploadPersonalDataStep: (argument: OnboardingPersonalDataPageRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/personal_info`, argument),
            apiName,
            'personal-info',
            false,
            500,
        ),
    uploadContactInfoStep: (argument: ContactInfoRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/contact_info`, argument),
            apiName,
            'contact-info',
            false,
            500,
        ),
    getInversorTestQuestions: () =>
        withFakeData<GetInvestmentTestQuestionsResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_STEPS_BASE_URL}/investment_test/questions`),
            apiName,
            'inversor-test',
            false,
            500,
        ),
    getValidateInvestmentTestAnswers: (req: BodyValidateInvestmentTestAnswers) =>
        withFakeData<ValidateInvestmentTestAnswersResponse>(
            () =>
                axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/investment_test/answer`, req),
            apiName,
            'getValidateInvestmentTestAnswers',
            false,
            500,
        ),
    getContractPath: () =>
        withFakeData<GetContractPathResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_STEPS_BASE_URL}/contract`),
            apiName,
            'contract',
            false,
            500,
        ),
    uploadContractStep: (argument: UploadContractRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/contract`, argument),
            apiName,
            'contract',
            false,
            500,
        ),
    getBlogNews: () =>
        withFakeData<GetBlogNewsResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/market`),
            apiName,
            'getBlogNews',
            true,
            500,
        ),
    getNosisQuestions: () =>
        withFakeData<GetNosisQuestionsResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_STEPS_BASE_URL}/validation/questions`),
            apiName,
            'getNosisQuestions',
            false,
            500,
        ),
    getValidateNosisAnswers: (body: BodyValidateNosisAnswers) =>
        withFakeData<GetValidateNosisAnswers>(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/validation/answers`, body),
            apiName,
            'getValidateNosisAnswers',
        ),
    getLegalData: (req: any) =>
        withFakeData<GetLegalDataReponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/onboarding/user_legal_data`),
            apiName,
            'legalData',
            false,
            500,
        ),
    getNationalities: () =>
        withFakeData<GetNationalities>(
            () => axiosDataInstance.get(`${ONBOARDING_REFERENCE_BASE_URL}/nationalities`),
            apiName,
            'nationalities',
            false,
            500,
        ),
    getWorkActivities: () =>
        withFakeData<GetWorkActivitiesResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_REFERENCE_BASE_URL}/work_activities`),
            apiName,
            'Activities',
            false,
            500,
        ),
    uploadComplementaryDataStep: (req: ComplementaryDataBody) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/complementary_data`, req),
            apiName,
            'complementaryData',
            false,
            500,
        ),
    uploadDocument: (argument: UploadDocumentRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/upload_document`, argument),
            apiName,
            'document',
            false,
            500,
        ),
    uploadProof: (argument: Proofs) =>
        withFakeData(
            () => axiosDataInstance.post(`${ONBOARDING_STEPS_BASE_URL}/income_proof`, argument),
            apiName,
            'proof',
            false,
            500,
        ),
    getStates: () =>
        withFakeData<GetStatesResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_REFERENCE_BASE_URL}/states`),
            apiName,
            'proof',
            false,
            500,
        ),
};
