import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstumentGraphicCard.styled';
import { Card, Grid } from '@mui/material';
import CustomLineChart from 'components/common/CustomLineChart';
import CustomHistogramChart from 'components/common/CustomHistogramChart';
import CustomIconCreator from 'components/common/CustomIconCreator';
import ArrowDown from 'assets/icons/arrow-drop-down-line.svg?react';
import CustomLoader from 'components/common/CustomLoader';
import CustomDatePicker from 'components/common/CustomDatePicker';
import { FormikContextType, FormikProvider } from 'formik';

const InstumentGraphicCard = (props: Props) => {
    const {
        showGraphic,
        dateRanges,
        handleSwitchGraphicVisibility,
        t,
        isLoadingHistoricalPrices,
        lineData,
        histogramData,
        onFilterChange,
        currentFilter,
        formik,
    } = props;

    const { values, setFieldValue } = formik;

    return (
        <Card sx={{ padding: '2rem', marginTop: 3 }}>
            <St.Title onClick={handleSwitchGraphicVisibility}>
                {t('market_instrument_details_graphic_title')}
                <CustomIconCreator
                    icon={ArrowDown}
                    alt="info-icon"
                    sx={{ rotate: showGraphic ? '180deg' : '0deg' }}
                />
            </St.Title>

            {showGraphic && (
                <>
                    {isLoadingHistoricalPrices ? (
                        <CustomLoader />
                    ) : (
                        <>
                            <FormikProvider value={formik}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ marginBottom: '20px' }}>
                                    <Grid item xs={6}>
                                        <St.StyledButtonGroup
                                            variant="outlined"
                                            aria-label="date range filter">
                                            {dateRanges.map(range => (
                                                <St.ButtonStyled
                                                    key={range}
                                                    onClick={() => onFilterChange(range)}
                                                    variant={
                                                        currentFilter === range
                                                            ? 'contained'
                                                            : 'outlined'
                                                    }
                                                    selected={currentFilter === range}>
                                                    {range}
                                                </St.ButtonStyled>
                                            ))}
                                        </St.StyledButtonGroup>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        spacing={2}
                                        container
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        sx={{ flexGrow: 0 }}>
                                        <Grid item xs={6}>
                                            <CustomDatePicker
                                                placeholder={t(
                                                    'market_instrument_graphic_date_from',
                                                )}
                                                fieldValue="dateFrom"
                                                setFieldValue={(e, value) =>
                                                    setFieldValue('dateFrom', value)
                                                }
                                                value={values.dateFrom}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomDatePicker
                                                placeholder={t('market_instrument_graphic_date_to')}
                                                fieldValue="dateTo"
                                                setFieldValue={(e, value) =>
                                                    setFieldValue('dateTo', value)
                                                }
                                                value={values.dateTo}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div style={{ marginTop: 20 }}>
                                    <CustomLineChart title="Precio" data={lineData} />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <CustomHistogramChart title="Volumen" data={histogramData} />
                                </div>
                            </FormikProvider>
                        </>
                    )}
                </>
            )}
        </Card>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoadingHistoricalPrices: PropTypes.bool.isRequired,
    lineData: PropTypes.array.isRequired,
    histogramData: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
};

interface extraProps {
    showGraphic: boolean;
    dateRanges: string[];
    handleSwitchGraphicVisibility: () => void;
    onFilterChange: (range: string) => void;
    currentFilter: string;
    formik: FormikContextType<any>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstumentGraphicCard.propTypes = propTypes;

export default InstumentGraphicCard;
