import { ReactElement } from 'react';
import { Theme } from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Trans } from 'react-i18next';
import St from './TopHeader.styled';

const TopHeader = (props: Props) => {
    const {
        firstElement,
        firstElementStylesTrans,
        title,
        titleStylesTrans,
        subtitle,
        subtitleStylesTrans,
        theme,
    } = props;

    return (
        <>
            {firstElement && typeof firstElement === 'string' ? (
                <Trans
                    transSupportBasicHtmlNodes
                    components={firstElementStylesTrans ?? St.FirstText(theme)}>
                    {firstElement}
                </Trans>
            ) : (
                firstElement
            )}
            <Trans transSupportBasicHtmlNodes components={titleStylesTrans ?? St.Title(theme)}>
                {title}
            </Trans>
            {subtitle && (
                <Trans
                    transSupportBasicHtmlNodes
                    components={subtitleStylesTrans ?? St.Subtitle(theme)}>
                    {subtitle}
                </Trans>
            )}
        </>
    );
};

const propTypes = {};

interface extraProps {
    firstElement?: string | ReactElement;
    firstElementStylesTrans?: { [key: string]: any };
    title: string;
    titleStylesTrans?: { [key: string]: any };
    subtitle?: string;
    subtitleStylesTrans?: { [key: string]: any };
    theme: Theme;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TopHeader.propTypes = propTypes;

export default TopHeader;
