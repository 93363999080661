import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomRadioButton from './CustomRadioButton';

const CustomRadioButtonContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <CustomRadioButton {...childProps} />;
};

const propTypes = {};

interface extraProps {
    name: string;
    value: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    text: string;
    fontSize?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomRadioButtonContainer.propTypes = propTypes;

export default CustomRadioButtonContainer;
