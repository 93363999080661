import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import NotificationsTool from './NotificationsTool';
import { useTranslation } from 'react-i18next';
import { NotificationsContext } from 'context/notifications.context';
import { NOTIFICATIONS_TO_SHOW_OR_ADD } from 'constants/appConstants';

const NotificationsToolContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedNotifications, setSelectedNotifications] = React.useState<string[] | null>(null);
    const [counter, setCounter] = React.useState<number>(NOTIFICATIONS_TO_SHOW_OR_ADD);
    const {
        notifications,
        unreadCount,
        isLoadingNotifications,
        handleReadNotifications,
        handleDeleteNotifications,
        isLoadingSubmitNotification,
    } = React.useContext(NotificationsContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReadOrDelete = (isRead: boolean) => {
        if (selectedNotifications) {
            isRead
                ? handleReadNotifications({ notificationIds: selectedNotifications })
                : handleDeleteNotifications({ notificationIds: selectedNotifications });
            setSelectedNotifications(null);
        }
    };

    const handleSeeMore = () => setCounter(prev => prev + NOTIFICATIONS_TO_SHOW_OR_ADD);

    React.useEffect(() => {
        return () => {
            setCounter(NOTIFICATIONS_TO_SHOW_OR_ADD);
        };
    }, []);

    const childProps = {
        ...props,
        t,
        handleClick,
        handleClose,
        open: Boolean(anchorEl),
        id: anchorEl ? 'notification-popover' : undefined,
        unreadCount,
        anchorEl,
        notifications,
        isLoadingNotifications,
        selectedNotifications,
        setSelectedNotifications,
        handleReadNotifications: () => handleReadOrDelete(true),
        handleDeleteNotifications: () => handleReadOrDelete(false),
        counter,
        handleSeeMore,
        isLoadingSubmitNotification,
    };

    return <NotificationsTool {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NotificationsToolContainer.propTypes = propTypes;

export default NotificationsToolContainer;
