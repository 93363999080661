import { SXBreakpoint } from 'types/common/general.types';

export const retainingMeshStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1400px',
    margin: '0 auto',
} as any;

export const pageContentMeshStyle = {
    maxWidth: '934px',
    margin: '0 auto 5rem',
};

export const getWidth = (padding: string = '1rem') => `calc(100% - ${padding})`;

export const getCalcWidth = (padding: SXBreakpoint<string | number> = '1rem') => {
    if (typeof padding === 'string' || typeof padding === 'number')
        return {
            width: `calc(100% - ${padding})`,
        };
    if (typeof padding === 'object' && padding !== null) {
        return {
            width: Object.fromEntries(
                Object.entries(padding).map(([key, value]) => [key, `calc(100% - ${value})`]),
            ),
        };
    }
};

export const getCalcHeigth = (padding: SXBreakpoint<string | number> = '1rem') => {
    if (typeof padding === 'string' || typeof padding === 'number')
        return {
            height: `calc(100% - ${padding})`,
        };
    if (typeof padding === 'object' && padding !== null) {
        return {
            height: Object.fromEntries(
                Object.entries(padding).map(([key, value]) => [key, `calc(100% - ${value})`]),
            ),
        };
    }
};
