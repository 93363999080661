import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { TablePagination } from '@mui/material';
import { ROWS_PER_PAGE } from 'constants/appConstants';
import { ICommonPagination } from 'types/common/tableLayout.types';

const CommonTablePagination = ({ total, page, pageSize, handlePageChange }: Props) => (
    <TablePagination
        component="div"
        count={total}
        rowsPerPage={pageSize || ROWS_PER_PAGE}
        rowsPerPageOptions={[pageSize || ROWS_PER_PAGE]}
        page={page - 1}
        onPageChange={(_, newPage: number) => handlePageChange(newPage + 1)}
        showFirstButton
        showLastButton
        labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' de ' + count}
    />
);

const propTypes = {};

interface extraProps extends ICommonPagination {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTablePagination.propTypes = propTypes;

export default CommonTablePagination;
