/* eslint-disable no-unmodified-loop-condition */
import { GetHolidayResponse } from 'types/api/dates.types';

export const holidayAdapterForCollateral = (data: GetHolidayResponse) => {
    return {
        items: data.items
            .map(({ dateFrom, dateTo }) => {
                const from = new Date(dateFrom);
                const to = new Date(dateTo);
                const timestamps = [];

                let date = new Date(from);
                while (date <= to) {
                    const formattedDate = date.toISOString().split('T')[0];
                    timestamps.push(formattedDate);
                    date.setDate(date.getDate() + 1);
                }
                return timestamps;
            })
            .flat(),
    };
};
