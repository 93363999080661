import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingContactInfoPhoneNumberSection.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import CustomInput from 'components/common/CustomInput';
import { InputAdornment } from '@mui/material';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';
import { pxToRem } from 'utils/helpers/stylesHelper';

const OnboardingContactInfoPhoneNumberSection = (props: Props) => {
    const { formik, t, enableButton } = props;
    const { errors, setFieldValue, values } = formik;

    return (
        <FormikProvider value={formik} data-testid="OnboardingContactInfoPhoneNumberSection-form">
            <St.ContactInfoInternalWrapper>
                <Form>
                    <St.PhoneNumberWrapper>
                        <CustomInput
                            field="phoneNumber"
                            regex={REGEX_0_TO_9}
                            errors={errors}
                            placeholder={t('phone_placeholder')}
                            setFieldValue={setFieldValue}
                            values={values}
                            autoFocus
                            required
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">+54</InputAdornment>
                                ),
                            }}
                        />
                        <ActionButton
                            sx={{ width: `${pxToRem(384)}!important` }}
                            type="submit"
                            variant="contained"
                            disabled={!enableButton}>
                            {t('next_button', { ns: 'auth' })}
                        </ActionButton>
                    </St.PhoneNumberWrapper>
                </Form>
            </St.ContactInfoInternalWrapper>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    enableButton: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoPhoneNumberSection.propTypes = propTypes;

export default OnboardingContactInfoPhoneNumberSection;
