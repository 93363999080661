import { styled } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Dot: styled(
        'li',
        transientOptions,
    )<{ $isSelected: boolean }>(({ theme, $isSelected }) => ({
        width: 16,
        height: 16,
        borderRadius: '16px',
        margin: '0 5px',
        backgroundColor: $isSelected && theme.palette.custom.vibrantblue,
        cursor: 'pointer',
        border: `2px solid ${theme.palette.custom.vibrantblue}`,
        display: 'inline-block',
    })),
};
