import { Box, styled } from '@mui/material';

export default {
    NavBarMobileLayoutMain: styled(Box)(({ width, theme }) =>
        theme.unstable_sx({
            width: '100%',
            transition: 'width 1s',
            height: 'fit-content',
            marginBottom: { xs: '56px', sm: '64px' },
        }),
    ),
};
