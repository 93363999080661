import { Box, IconButton, TextField, styled } from '@mui/material';

export default {
    TextField: styled(TextField)(
        (props: { readOnly: boolean; width?: string; minwidth?: string }) => ({
            width: `${props.width}`,
            minWidth: `${props.minwidth}`,
            input: {
                WebkitTextFillColor: props.readOnly ? 'rgba(0,0,0,0.6) !important' : '',
                color: props.readOnly ? 'rgba(0,0,0,0.7) !important' : '',
                opacity: props.readOnly ? 0.7 : '',
                backgroundColor: props.readOnly ? 'rgba(0,0,0,0.05) ' : '',
            },
            label: {
                color: props.readOnly ? 'rgba(0,0,0,1) !important' : '',
            },
        }),
    ),
    Box: styled(Box)(({ theme }) => theme.unstable_sx({ pl: 2.5, pt: 2 })),

    InputField: styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        border: `1px solid rgba(0, 0, 0, 0.23)`,

        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px',
            border: 'none',
        },

        '& .css-stlcrh-MuiFormControl-root-MuiTextField-root label': {
            backgroundColor: 'white',
        },

        '& .css-igs3ac': {
            border: 'none',
        },

        '& .css-y0n2ag': {
            backgroundColor: 'white',
        },
    })),

    CloseIconButton: styled(IconButton)(({ theme }) => ({
        marginRight: '5px',
    })),
};
