import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WithdrawalModal from './WithdrawalModal';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { GetBankAccountsResponse } from 'types/api/cashflow.types';
import { useGetBankAccounts, useWithdrawal } from 'hooks/api/cahsflow.hooks';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { AuthContext } from 'context/auth.context';
import i18n from 'utils/i18n';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { MarketContext } from 'context/market.context';
import { LockedFunctionalityNames } from 'types/api/rules.types';

const getInitialValues = () => ({
    destinationAccount: '',
    currency: '',
    options: [],
    comment: '',
    amountToExtract: 0,
});

const getValidationSchema = (
    arsAvailable: number | undefined,
    usdAvailable: number | undefined,
    usdcAvailable: number | undefined,
    usdmTAvailable: number | undefined,
    usdcExtAvailable: number | undefined,
    usdcTAvailable: number | undefined,
    bankAccounts: GetBankAccountsResponse | undefined,
    t: (key: string) => string,
) =>
    Yup.object().shape({
        destinationAccount: Yup.string().required(tRequiredFieldError),
        currency: Yup.string()
            .required(tRequiredFieldError)
            .test('currency-matches-account', t('currency_mismatch_error'), function (value) {
                const { destinationAccount } = this.parent;
                const selectedAccount = bankAccounts?.items.find(
                    account => account.id === destinationAccount,
                );
                if (selectedAccount) {
                    return selectedAccount.currency === value;
                }
                return true;
            }),
        comment: Yup.string().when('options', {
            is: (options: string[]) => options.length > 0,
            then: Yup.string().required(String(i18n.t('required_field', tErrorsContext))),
            otherwise: Yup.string().notRequired(),
        }),
        amountToExtract: Yup.number().when('options', {
            is: (options: string[]) => !options.includes(t('no_money_available')),
            then: Yup.number()
                .required(tRequiredFieldError)
                .min(1, String(i18n.t('valid_amount', tErrorsContext)))
                .test(
                    'max-available',
                    String(i18n.t('error_amount_available', tErrorsContext)),
                    function (value) {
                        const { currency } = this.parent;
                        const availableBalance = {
                            ARS: arsAvailable,
                            USD: usdAvailable,
                            USDC: usdcAvailable,
                            USDM: usdmTAvailable,
                            USDC_EXT: usdcExtAvailable,
                            USDC_T: usdcTAvailable,
                        };
                        const balanceValue =
                            availableBalance[currency as keyof typeof availableBalance] ?? 0;
                        return value !== undefined && value <= balanceValue;
                    },
                ),
            otherwise: Yup.number().notRequired(),
        }),
    });

const getCheckboxOptions = (values: FormikValues, t: any) => {
    if (['ARS', 'USD', 'USDM'].includes(values.currency)) {
        return [t('rescue_FCI'), t('no_money_available'), t('out_of_hours'), t('other')];
    } else if (['USDC', 'USDC_T', 'USDC_EXT'].includes(values.currency)) {
        return [t('no_money_available'), t('out_of_hours'), t('other')];
    }
    return [];
};

const WithdrawalModalContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { data, isLoading } = useGetAccountStatus();
    const { customerCode, userData } = React.useContext(AuthContext);
    const { isLockedByUserType, lockedFunctionalities, marketsTimes } =
        React.useContext(MarketContext);

    const { t0CloseTime: closeTime = '17:00', t0OpenTime: openTime = '9:00' } =
        marketsTimes?.find(el => el.name === 'WITHDRAWALS') || {};

    const filterQueryString = `&status=ACTIVE&customerCode=${customerCode}`;
    const { data: bankAccounts, isLoading: isBankAccountsLoading } = useGetBankAccounts({
        filterQueryString,
    } as GridHookParams);

    const {
        withdrawal,
        isLoadingWithdrawal,
        error: errorSubmit,
    } = useWithdrawal(setSnackBarMessage, close);

    const arsAvailable = data?.availableBalance.withdraw.ars;
    const usdAvailable = data?.availableBalance.withdraw.usd;
    const usdcAvailable = data?.availableBalance.withdraw.usdc;
    const usdmTAvailable = data?.availableBalance.withdraw.usdm;
    const usdcExtAvailable = data?.availableBalance.withdraw.usdc_ext;
    const usdcTAvailable = data?.availableBalance.withdraw.usdc_t;

    const currencyOptions = Object.entries(data?.availableBalance.withdraw || {})
        .filter(([, available]) => available > 0)
        .map(([currency, available]) => ({
            id: currency.toUpperCase(),
            name: `${currency.toUpperCase()}`,
        }));

    const optionMapping: { [key: string]: string } = {
        [t('rescue_FCI')]: 'FCI_SELL',
        [t('no_money_available')]: 'NO_MONEY',
        [t('out_of_hours')]: 'OUT_OF_TIMEFRAME',
        [t('other')]: 'OTHER',
    };

    const handleSubmit = React.useCallback(async (values: FormikValues) => {}, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validationSchema: getValidationSchema(
                arsAvailable,
                usdAvailable,
                usdcAvailable,
                usdmTAvailable,
                usdcExtAvailable,
                usdcTAvailable,
                bankAccounts,
                t,
            ),
            onSubmit: handleSubmit,
        }),
        [
            handleSubmit,
            arsAvailable,
            usdAvailable,
            usdcAvailable,
            usdmTAvailable,
            usdcExtAvailable,
            usdcTAvailable,
            bankAccounts,
            t,
        ],
    );

    const formik = useFormik(formikInitProps);

    const handleCurrencyChange = (event: React.SyntheticEvent, newValue: any) => {
        formik.setFieldValue('currency', newValue?.id ?? '');
        formik.setFieldValue('options', []);
        formik.setFieldValue('comment', '');
        formik.setFieldValue('destinationAccount', '');
    };

    const options = getCheckboxOptions(formik.values, t);

    const bankAccountsOptions =
        bankAccounts?.items
            ?.filter(account => account.currency === formik.values.currency)
            .map(item => ({
                name: item.bankName,
                id: item.id,
                description: item.description,
                currency: item.currency,
            })) || [];

    const handleSend = async () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                const withdrawalCondition = formik.values.options
                    .map((option: string) => optionMapping[option])
                    .filter(Boolean);

                const body = {
                    entryDate: new Date().toISOString(),
                    accountUuid: formik.values?.destinationAccount,
                    customerCode,
                    amount: formik.values.amountToExtract,
                    withdrawalCondition,
                    comment: formik.values.comment,
                    otp: '',
                    operableUserUuid: userData?.id,
                    validateOtp: false,
                };
                if (customerCode) {
                    withdrawal({ ...body, customerCode });
                } else {
                    setSnackBarMessage(t('error_customer_code_missing'));
                }
            }
        });
    };

    const lockedMessage: string | null = React.useMemo(() => {
        if (!lockedFunctionalities) return null;
        if (!formik.values.destinationAccount) return null;
        const currency = formik.values?.currency;
        const isLocked = isLockedByUserType(
            `WITHDRAWAL_${currency === 'USD-C' ? 'USDC' : currency}` as LockedFunctionalityNames,
        );
        if (isLocked) {
            return (
                lockedFunctionalities.find(
                    l => l.name === `WITHDRAWAL_${currency === 'USD-C' ? 'USDC' : currency}`,
                )?.details[0]?.lockReason ?? null
            );
        }
        return null;
    }, [lockedFunctionalities, isLockedByUserType, formik.values]);

    const childProps = {
        ...props,
        t,
        close,
        formik,
        bankAccountsOptions,
        isLoading,
        isBankAccountsLoading,
        availableExtraction: data?.availableBalance.withdraw,
        currencyOptions,
        options,
        handleCurrencyChange,
        isLoadingWithdrawal,
        errorSubmit,
        handleSend,
        lockedMessage,
        openTime,
        closeTime,
    };

    return <WithdrawalModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WithdrawalModalContainer.propTypes = propTypes;

export default WithdrawalModalContainer;
