import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import TableLayout from 'components/layouts/TableLayout';
import { tCommon } from 'constants/appConstants';
import { useGetReceipts } from 'hooks/api/cahsflow.hooks';
import { Receipts } from 'types/api/cashflow.types';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';

const ReportsReceiptsInvoicesPage = ({
    columns,
    filters,
    t,
    customerCode,
    filterDateValidation,
    defaultFilters,
}: Props) => (
    <TableLayout
        title={t('layout_main_navigation_reports_receipts_and_invoices', tCommon)}
        fetchData={useGetReceipts}
        columns={columns}
        filters={filters}
        canExport
        exportTitle={`${t('receipts_doc_title')} ${customerCode}`}
        defaultFilters={defaultFilters}
        customFilterValidation={filterDateValidation}
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<Receipts>[];
    filters: CommonFilter[];
    customerCode: string | null;
    defaultFilters: ExtraQueryFiltersProps[];
    filterDateValidation: (value: string) => { result: boolean; error: string | undefined };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsReceiptsInvoicesPage.propTypes = propTypes;

export default ReportsReceiptsInvoicesPage;
