import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReportsOperationDetailModal from './ReportsOperationDetailModal';
import { useTranslation } from 'react-i18next';
import { DailyOperation } from 'types/pages/reports.types';

const ReportsOperationDetailModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const onSubmit = () => {};

    const childProps = {
        ...props,
        t,
        onSubmit,
    };

    return <ReportsOperationDetailModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: DailyOperation;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsOperationDetailModalContainer.propTypes = propTypes;

export default ReportsOperationDetailModalContainer;
