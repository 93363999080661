import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RejectionModal from './RejectionModal';
import { useTranslation } from 'react-i18next';
import { Step } from 'types/pages/onboardingHome.types';

const RejectionModalContainer = (props: Props) => {
    const { setIsOpenModal } = props;
    const { t } = useTranslation('onboarding');
    const childProps = {
        ...props,
        handleClose: () => setIsOpenModal(false),
        t,
    };

    return <RejectionModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isOpenModal: boolean;
    setIsOpenModal: (x: boolean) => void;
    rejectSteps?: Array<Step>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RejectionModalContainer.propTypes = propTypes;

export default RejectionModalContainer;
