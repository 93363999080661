import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DDJJAction.styled';
import { DeclarationItem } from 'types/api/declarations.types';
import defaultTheme from 'utils/theme/defaultTheme';
import { pxToRem } from 'utils/helpers/stylesHelper';
import InfoIcon from 'assets/icons/information-line.svg?react';
import CustomIconCreator from 'components/common/CustomIconCreator';
import DDJJModal from '../DDJJModal';

const DdjjAction = ({ data, isOpen, open, close, t }: Props) => (
    <St.ModalTriggerContainer>
        {data && data.length > 0 ? (
            <St.ModalTrigger>
                <CustomIconCreator
                    icon={InfoIcon}
                    sx={{
                        margin: `0 ${pxToRem(10)} 0 ${pxToRem(14)}`,
                        color: defaultTheme.palette.custom.vibrantBlue,
                    }}
                />
                <St.ModalTitleTrigger typography="h6">
                    {data.length === 1
                        ? t('ddjj_modal_trigger_title_1')
                        : t('ddjj_modal_trigger_title_2', { number: data.length })}
                </St.ModalTitleTrigger>
                <St.ButtonTrigger typography="h6" onClick={open}>
                    {t('ddjj_modal_trigger_button')}
                </St.ButtonTrigger>
            </St.ModalTrigger>
        ) : (
            <></>
        )}
        {isOpen && data && <DDJJModal isOpen={isOpen} close={close} />}
    </St.ModalTriggerContainer>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    data?: DeclarationItem[];
    isOpen: boolean;
    open(): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DdjjAction.propTypes = propTypes;

export default DdjjAction;
