import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomCarousel.styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import CustomDot from './CustomDot';
import ArrowLeftCircle from 'assets/icons/arrow-left-circle.svg?react';
import ArrowRightCircle from 'assets/icons/arrow-right-circle.svg?react';

const CustomCarousel = (props: Props) => {
    const { children, width, matchesMd, withDots } = props;

    const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean) => (
        <St.ArrowContainer arrowPosition="left">
            <ArrowLeftCircle
                onClick={onClickHandler}
                style={{ visibility: hasPrev ? 'visible' : 'hidden' }}
            />
        </St.ArrowContainer>
    );

    const renderArrowNext = (onClickHandler: () => void, hasNext: boolean) => (
        <St.ArrowContainer arrowPosition="right">
            <ArrowRightCircle
                onClick={onClickHandler}
                style={{ visibility: hasNext ? 'visible' : 'hidden' }}
            />
        </St.ArrowContainer>
    );
    return (
        <St.CarouselContainer>
            <Carousel
                showThumbs={false}
                width={matchesMd ? window.innerWidth - 100 : (width ?? '700px')}
                renderIndicator={
                    withDots
                        ? (clickHandler, isSelected, index, label) => (
                              <CustomDot
                                  onClickHandler={clickHandler}
                                  isSelected={isSelected}
                                  index={index}
                                  label={label}
                              />
                          )
                        : undefined
                }
                showStatus={false}
                showArrows={!withDots}
                swipeable
                emulateTouch
                renderArrowPrev={!withDots ? renderArrowPrev : undefined}
                renderArrowNext={!withDots ? renderArrowNext : undefined}>
                {children}
            </Carousel>
        </St.CarouselContainer>
    );
};

const propTypes = {
    width: PropTypes.string,
    matchesMd: PropTypes.bool,
    withDots: PropTypes.bool,
};

interface extraProps {
    children: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCarousel.propTypes = propTypes;

export default CustomCarousel;
