import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingValidatePersonQuestionsSection.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import TopHeader from 'components/common/TopHeader';
import { Trans } from 'react-i18next';
import CustomLoader from 'components/common/CustomLoader';
import ErrorText from 'components/common/ErrorText';
import ActionButton from 'components/common/ActionButton';
import { RenderListProps } from 'types/pages/onboardingValidatePerson.types';

const OnboardingValidatePersonQuestionsSection = (props: Props) => {
    const {
        t,
        handleCheck,
        formik,
        isLoading,
        isLoadingValidate,
        RenderList,
        errorMessage,
        incorrectResponse,
        validationFailedErrorMessage,
        name,
    } = props;

    return (
        <St.SectionWrapper>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <>
                    <TopHeader
                        firstElement={t('nosis_first_text') as string}
                        title={t('nosis_questions_title')}
                        subtitle={
                            t('nosis_questions_subtitle', {
                                name: name ? name.toUpperCase() : '-',
                            }) as string
                        }
                    />
                    <FormikProvider value={formik}>
                        <Form>
                            {validationFailedErrorMessage && (
                                <St.ErrorTextWrapper>
                                    <Trans transSupportBasicHtmlNodes>
                                        {validationFailedErrorMessage}
                                    </Trans>
                                </St.ErrorTextWrapper>
                            )}
                            {incorrectResponse && !validationFailedErrorMessage && (
                                <St.ErrorTextWrapper>
                                    <Trans transSupportBasicHtmlNodes>{incorrectResponse}</Trans>
                                </St.ErrorTextWrapper>
                            )}
                            {!validationFailedErrorMessage && (
                                <>
                                    <St.QuestionsAnswersWrapper>
                                        <RenderList
                                            values={formik.values}
                                            handleCheck={handleCheck}
                                        />
                                    </St.QuestionsAnswersWrapper>
                                    {errorMessage && <ErrorText text={errorMessage as string} />}
                                    <St.ActionButtonWrapper>
                                        <ActionButton
                                            variant="contained"
                                            type="submit"
                                            disabled={Object.keys(formik.errors).length !== 0}
                                            loading={isLoadingValidate}>
                                            {t('btn_next')}
                                        </ActionButton>
                                    </St.ActionButtonWrapper>
                                </>
                            )}
                        </Form>
                    </FormikProvider>
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    name: PropTypes.string,
};

interface extraProps {
    formik: FormikContextType<any>;
    isLoading: boolean;
    isLoadingValidate: boolean;
    RenderList: React.FC<RenderListProps>;
    handleCheck: (field: string, questionKey: string) => void;
    errorMessage?: string | null;
    validationFailedErrorMessage: string | null;
    incorrectResponse?: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonQuestionsSection.propTypes = propTypes;

export default OnboardingValidatePersonQuestionsSection;
