import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import LoginPage from './LoginPage';
import mainLogo from 'assets/img/general/consultatioLogo.svg?react';

const LoginPageContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
        mainLogo,
    };

    return <LoginPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginPageContainer.propTypes = propTypes;

export default LoginPageContainer;
