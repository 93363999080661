import { Box, List, Typography, styled } from '@mui/material';

export default {
    Box: styled(Box)(() => ({
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    })),
    Title: styled(Typography)(({ theme }) => ({
        minHeight: '2.938rem',
        alignContent: 'space-evenly',
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '28.13px',
        textAlign: 'left',
        color: theme.palette.custom.darkblue2,
    })),
    List: styled(List)(() => ({
        display: 'grid',
        gap: '16px',
        marginTop: '27px',
        padding: 0,
    })),
};
