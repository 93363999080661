import { Box, DialogActions, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    ModalTriggerContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3rem',
    })) as typeof Box,

    ModalTrigger: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: pxToRem('72px'),
        width: pxToRem('439px'),
        backgroundColor: '#173254',
        borderRadius: pxToRem('81px'),
    })) as typeof Box,

    DialogContent: styled(Box)(({ theme }) => ({
        width: pxToRem(550),
        padding: pxToRem(20),
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: `${pxToRem(10)}`,
        },
    })),

    ModalTitleTrigger: styled(Typography)(({ theme }) => ({
        color: 'white',
        fontSize: pxToRem(18),
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(14),
        },
    })),

    ButtonTrigger: styled(Typography)(({ theme }) => ({
        width: pxToRem('85px'),
        height: pxToRem('40px'),
        textAlign: 'center',
        alignContent: 'center',
        color: 'white',
        border: 'none',
        backgroundColor: '#307FDD',
        margin: `0 ${pxToRem(14)} 0 ${pxToRem(10)}`,
        borderRadius: pxToRem('81px'),
        cursor: 'pointer',
    })),

    ModalTitle: styled(Typography)(({ theme }) => ({
        color: 'black',
        fontFamily: 'Roboto',
        fontWeight: 600,
        textAlign: 'center',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflowWrap: 'anywhere',
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(15),
            padding: `0 ${pxToRem(10)}`,
            textAlign: 'left',
        },
    })),

    DialogActions: styled(DialogActions)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: pxToRem(90),
        borderTop: '1px solid lightgrey',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: pxToRem(10),
        },
    })),

    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            marginRight: '0',
            padding: `${pxToRem(10)} ${pxToRem(60)}`,
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.custom.darkblue,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: `${pxToRem(10)} ${pxToRem(20)}`,
            },
        }),
    ),

    Li: styled('li')(({ theme }) => ({
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: pxToRem(18.75),
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(14),
        },
    })),
};
