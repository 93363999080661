import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FundDetailCard.styled';
import { TableBody, TableHead } from '@mui/material';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';

const FundDetailCard = (props: Props) => {
    const { fundDetail, t } = props;

    const TableRow: React.FC<{ label: string; value: string }> = ({ label, value }) => (
        <St.TableRow>
            <St.TableCell>
                <St.Label>{label}</St.Label>
            </St.TableCell>
            <St.TableCell sx={{ fontWeight: 'bold' }}>{value}</St.TableCell>
        </St.TableRow>
    );

    return (
        <St.Container>
            <St.Grid>
                <St.Section>
                    <St.SectionTitle>{t('market_fund_info_detail')}</St.SectionTitle>
                    <St.Table>
                        <TableBody>
                            <TableRow
                                label={t('market_fund_info_bloomberg')}
                                value={fundDetail?.ticker}
                            />
                            <TableRow
                                label={t('market_fund_info_depository')}
                                value={fundDetail?.fundInfo?.summaryDepositary}
                            />
                            <TableRow
                                label={t('market_fund_info_liquidity')}
                                value={fundDetail?.fundInfo?.summaryLiquidity}
                            />
                            <TableRow
                                label={t('market_fund_info_currency')}
                                value={fundDetail?.fundInfo?.summaryCurrencyType}
                            />
                            <TableRow
                                label={t('market_fund_info_managment_fee')}
                                value={`${formatNumber(fundDetail?.fundInfo?.summaryManagementFee || 0)}%`}
                            />
                            <TableRow
                                label={t('market_fund_info_patrimony')}
                                value={`${fundDetail?.fundInfo?.summaryCurrencyType}  ${convertCurrencyFormat(Number(fundDetail?.fundInfo?.summaryAssets), fundDetail?.fundInfo?.summaryCurrencyType)}`}
                            />
                        </TableBody>
                    </St.Table>
                </St.Section>

                <div>
                    <St.Section>
                        <St.SectionTitle>{t('market_fund_info_ratio')}</St.SectionTitle>
                        <St.RatioGrid>
                            <div>
                                <St.RatioValue>
                                    {`${formatNumber(fundDetail?.fundInfo?.performanceYield || 0)}%`}
                                </St.RatioValue>
                                <St.RatioLabel>{t('market_fund_info_yield')}</St.RatioLabel>
                            </div>
                            <div>
                                <St.RatioValue>
                                    {`${formatNumber(fundDetail?.fundInfo?.performanceVolatility || 0)}%`}
                                </St.RatioValue>
                                <St.RatioLabel>{t('market_fund_info_volatility')}</St.RatioLabel>
                            </div>
                            <div>
                                <St.RatioValue>
                                    {formatNumber(fundDetail?.fundInfo?.performanceMduration || 0)}
                                </St.RatioValue>
                                <St.RatioLabel>{t('market_fund_info_mod_duration')}</St.RatioLabel>
                            </div>
                        </St.RatioGrid>
                    </St.Section>

                    <St.Section>
                        <St.SectionTitle>
                            {t('market_fund_info_historical_returns')}
                        </St.SectionTitle>
                        <St.Table>
                            <TableBody>
                                <TableRow
                                    label=""
                                    value={`${formatNumber(fundDetail?.fundInfo?.performanceMtd || 0)}%`}
                                />
                                <TableRow
                                    label=""
                                    value={`${formatNumber(fundDetail?.fundInfo?.performanceQtd || 0)}%`}
                                />
                                <TableRow
                                    label=""
                                    value={`${formatNumber(fundDetail?.fundInfo?.performanceYtd || 0)}%`}
                                />
                            </TableBody>
                        </St.Table>
                    </St.Section>
                </div>

                <St.Section>
                    <St.SectionTitle>{t('market_fund_info_portfolio')}</St.SectionTitle>
                    <St.Table>
                        <TableHead>
                            <St.TableRow>
                                <St.TableCell>
                                    <St.Label sx={{ fontWeight: 'bold' }}>
                                        {t('market_fund_info_specie')}
                                    </St.Label>
                                </St.TableCell>
                                <St.TableCell>
                                    <St.Label sx={{ fontWeight: 'bold' }}>
                                        {t('market_fund_info_weight')}
                                    </St.Label>
                                </St.TableCell>
                            </St.TableRow>
                        </TableHead>
                        <TableBody>
                            {fundDetail?.fundInfo?.portfolioTopTenHoldings?.map(
                                (holding, index) => (
                                    <TableRow
                                        key={index}
                                        label={holding?.portfolio_top_ten_holding_campo}
                                        value={`${formatNumber(Number(holding.portfolio_top_ten_holding_valor) || 0)}%`}
                                    />
                                ),
                            )}
                        </TableBody>
                    </St.Table>
                </St.Section>

                <St.Section>
                    <St.SectionTitle>{t('market_fund_info_risk')}</St.SectionTitle>
                    <St.Table>
                        <TableBody>
                            <TableRow
                                label={t('market_fund_info_positive_months')}
                                value={fundDetail?.fundInfo?.performancePositiveMonths?.toString()}
                            />
                            <TableRow
                                label={t('market_fund_info_negative_months')}
                                value={fundDetail?.fundInfo?.performanceNegativeMonths?.toString()}
                            />
                            <TableRow
                                label={t('market_fund_info_maximun_rise')}
                                value={`${formatNumber(fundDetail?.fundInfo?.performanceMaxGain || 0)}%`}
                            />
                            <TableRow
                                label={t('market_fund_info_maximun_drop')}
                                value={`${formatNumber(fundDetail?.fundInfo?.performanceMaxLoss || 0)}%`}
                            />
                        </TableBody>
                    </St.Table>
                </St.Section>
            </St.Grid>

            <St.InfoContainer>
                <St.InfoTitle variant="h5">
                    {t('market_fund_info_what_is_ahorro_plus', {
                        summaryFund: fundDetail?.fundInfo?.summaryFund.toUpperCase(),
                    })}
                </St.InfoTitle>
                <St.InfoGrid>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('market_fund_info_fund_objectives')}
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">
                            {fundDetail?.fundInfo?.summaryObjectives}
                        </St.InfoContent>
                    </St.InfoSection>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('market_fund_info_benefits')}
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">
                            {fundDetail?.fundInfo?.summaryBenefits}
                        </St.InfoContent>
                    </St.InfoSection>
                </St.InfoGrid>
            </St.InfoContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    fundDetail: GetFundDetailResponse;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundDetailCard.propTypes = propTypes;

export default FundDetailCard;
