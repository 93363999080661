import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AlertNoData from './AlertNoData';

const AlertNoDataContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <AlertNoData {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AlertNoDataContainer.propTypes = propTypes;

export default AlertNoDataContainer;
