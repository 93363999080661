import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
} from 'react';
import { AuthContext } from './auth.context';
import {
    useGetOnboardingLegalData,
    useGetSteps,
    useGetUserInformationUploadedByHim,
} from 'hooks/api/onboarding.hooks';
import { onboardingHomeStepsDataAdapter } from 'adapters/api/onboarding.adapter';
import { Step } from 'types/pages/onboardingHome.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { onboardingRoutesPaths } from 'router/routesPaths';
import {
    GetLegalDataReponse,
    GetOnboardingStepsResponse,
    GetUserInformationForOnboardingResponse,
} from 'types/api/onboarding.types';
import { scrollToTop } from 'utils/helpers/commonHelper';

interface IntialState {
    steps: Step[] | null;
    setSteps: Dispatch<SetStateAction<Step[] | null>>;
    isLoadingSteps: boolean;
    legalData: GetLegalDataReponse | null;
    setLegalData: Dispatch<SetStateAction<GetLegalDataReponse | null | 'error' | 'pending'>>;
    isLoadingLegalData: boolean;
    errorMessageLegalData: string | null;
    isLoadingUserInformation: boolean;
    userInformationUploaded: GetUserInformationForOnboardingResponse | null;
}

export const OnboardingContext = createContext({} as IntialState);

const OnboardingContextProvider = ({ children }: { children: ReactNode }) => {
    const [steps, setSteps] = React.useState<Step[] | null>(null);
    const [legalData, setLegalData] = React.useState<
        GetLegalDataReponse | null | 'error' | 'pending'
    >('pending');
    const [userInformationUploaded, setUserInformationUploaded] =
        React.useState<GetUserInformationForOnboardingResponse | null>(null);
    const [firstLogin, setFirstLogin] = React.useState<boolean>(true);
    const { isAuthenticated } = useContext(AuthContext);
    const { mutate: getSteps, isLoading: isLoadingSteps, data: dataSteps } = useGetSteps();
    const { mutate: getUserInformation, isLoading: isLoadingUserInformation } =
        useGetUserInformationUploadedByHim();
    const {
        mutate: getLegalData,
        isLoading: isLoadingLegalData,
        errorMessage: errorMessageLegalData,
    } = useGetOnboardingLegalData();

    const navigate = useNavigate();
    const location = useLocation();

    const validatePending = (key: string, data?: GetOnboardingStepsResponse) =>
        data?.steps.filter(e => e.stepName === key)[0].status === 'PENDING';

    useEffect(() => {
        if (isAuthenticated && firstLogin) {
            getSteps(
                {},
                {
                    onSuccess: data => {
                        setFirstLogin(false);
                        setLegalData(null);
                        setSteps(onboardingHomeStepsDataAdapter(data.data));
                        const isOnboardingStartedPending = validatePending(
                            'ONBOARDING_STARTED',
                            data.data,
                        );
                        const isValidatePersonPending = validatePending(
                            'VALIDATE_PERSON',
                            data.data,
                        );
                        const thereAreRejectedSteps = data.data.steps.filter(
                            e => e.status === 'REJECTED',
                        );
                        if (thereAreRejectedSteps.length > 0)
                            getUserInformation(
                                {},
                                { onSuccess: data => setUserInformationUploaded(data.data) },
                            );
                        if (!isOnboardingStartedPending)
                            getLegalData(
                                {},
                                {
                                    onSuccess: data => setLegalData(data.data),
                                    onError: () => setLegalData('error'),
                                },
                            );
                        if (isOnboardingStartedPending || isValidatePersonPending) {
                            navigate(onboardingRoutesPaths.validatePerson, {
                                state: { showNosisQuestions: !isOnboardingStartedPending },
                            });
                        } else if (!window.location.pathname.includes('onboarding')) {
                            navigate(onboardingRoutesPaths.home);
                        }
                    },
                },
            );
        }
    }, [isAuthenticated, firstLogin]);

    useEffect(() => {
        if (!firstLogin && window.location.pathname.includes(onboardingRoutesPaths.home)) {
            scrollToTop();
            getSteps(
                {},
                {
                    onSuccess: data => {
                        setSteps(onboardingHomeStepsDataAdapter(data.data));
                    },
                },
            );
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const isOnboardingStartedPending = validatePending('ONBOARDING_STARTED', dataSteps?.data);
        const isValidatePersonPending = validatePending('VALIDATE_PERSON', dataSteps?.data);
        if (
            (isOnboardingStartedPending || isValidatePersonPending) &&
            location.pathname !== onboardingRoutesPaths.validatePerson
        ) {
            return navigate(onboardingRoutesPaths.validatePerson, {
                state: { showNosisQuestions: !isOnboardingStartedPending },
            });
        }
        if (steps) {
            const selectedStep = steps.filter(step => step.path === location.pathname)[0];
            const proofOfIncome = onboardingRoutesPaths.proofOfIncome === location.pathname;
            if (
                (!selectedStep && proofOfIncome) ||
                selectedStep?.status === 'completed' ||
                selectedStep?.disabled
            )
                navigate(onboardingRoutesPaths.home);
        }
    }, [location.pathname, steps]);

    const verificationOfLegalDataUpload =
        typeof legalData === 'string' && ['error', 'pending'].includes(legalData);

    const memoedValue = React.useMemo(() => {
        const value = {
            steps,
            setSteps,
            isLoadingSteps,
            legalData: verificationOfLegalDataUpload
                ? null
                : (legalData as GetLegalDataReponse | null), // In case the endpoint is not responding or has not been executed
            setLegalData,
            isLoadingLegalData: isLoadingLegalData || verificationOfLegalDataUpload, // If there is no data it is because you are looking for it
            errorMessageLegalData,
            isLoadingUserInformation,
            userInformationUploaded,
        };
        return value;
    }, [
        steps,
        setSteps,
        isLoadingSteps,
        legalData,
        setLegalData,
        isLoadingLegalData,
        errorMessageLegalData,
        verificationOfLegalDataUpload,
        userInformationUploaded,
        isLoadingUserInformation,
    ]);

    return <OnboardingContext.Provider value={memoedValue}>{children}</OnboardingContext.Provider>;
};

export default OnboardingContextProvider;
