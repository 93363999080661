import { Box, IconButton, styled } from '@mui/material';

export default {
    CardContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        maxWidth: '700px',
        width: '80%',
        backgroundColor: '#F5F5F5',
        justifyContent: 'space-between',
        padding: '20px',
        borderRadius: '10px',
    })),
    ContainerInfo: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
    })),
    ContainerButtons: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '10px',
            marginTop: '20px',
            flexDirection: { xs: 'column', sm: 'row' },
        }),
    ),
    CustomIconButton: styled(IconButton)(({ theme }) => ({
        border: '1px solid #307FDD',
        padding: '15px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    })),
};
