import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfilePersonalDataPage from './ProfilePersonalDataPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { useGetSignedContract } from 'hooks/api/auth.hooks';

const ProfilePersonalDataPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('profile');
    const { userData, customerCode } = React.useContext(AuthContext);
    const { fileUrl, isLoading, errorMessage } = useGetSignedContract();
    const childProps = {
        ...props,
        t,
        userData,
        fileUrl,
        customerCode,
        isLoading,
        errorMessage,
    };

    return <ProfilePersonalDataPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePersonalDataPageContainer.propTypes = propTypes;

export default ProfilePersonalDataPageContainer;
