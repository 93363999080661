import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HomePage from './HomePage';
import { UserType } from 'types/common/users.types';
import { useEffect, useRef } from 'react';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useGetBankAccounts } from 'hooks/api/cahsflow.hooks';
import { AuthContext } from 'context/auth.context';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';

const HomePageContainer = (props: Props) => {
    // const {} = props;
    const recommendedWalletRef = useRef<HTMLDivElement | null>(null);

    const { userType } = React.useContext(AuthContext);
    const isUserApAc = userType === 'CHANNEL' || userType === 'ASSISTANT';
    const { data } = useGetAccountStatus();
    const { data: bankAccounts, isLoading: isLoadingBankAccounts } = useGetBankAccounts(
        {} as GridHookParams,
    );
    const instrumentsAmount =
        data?.instruments.reduce((total, instrumentType) => {
            return total + (instrumentType.instruments?.length || 0);
        }, 0) || 0;

    useEffect(() => {
        if (window.location.hash === '#recommended-wallet') {
            recommendedWalletRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const childProps = {
        ...props,
        recommendedWalletRef,
        instrumentsAmount,
        bankAccounts,
        isLoadingBankAccounts,
        isUserApAc,
    };

    return <HomePage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    userType: UserType | null;
    setSelectedInstrumentType: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomePageContainer.propTypes = propTypes;

export default HomePageContainer;
