import {
    Box,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';
import AlertNoData from 'components/common/AlertNoData';
import CustomInput from 'components/common/CustomInput';

export default {
    TableContainerWrapper: styled(
        Box,
        transientOptions,
    )<{ $isDetail: boolean }>(({ theme, $isDetail }) =>
        theme.unstable_sx({
            width: '100%',
            minWidth: '100%',
            maxHeight: 'fit-content',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '10px' },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '4px',
                border: `3px solid ${theme.palette.background.default}`,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-corner': { backgroundColor: theme.palette.background.paper },
        }),
    ),
    Table: styled(Table)(({ theme }) => ({
        background: theme.palette.custom.vibrantblue10,
        width: '100%',
        maxHeight: '200px',
        overflow: 'auto',
        // width: pxToRem('1286px'),
    })),
    TableHead: styled(TableHead)(({ theme }) =>
        theme.unstable_sx({
            background: theme.palette.background.default,
            span: { position: 'relative' },
            th: {
                fontSize: '1rem',
                fontWeight: '700',
                lineHeight: pxToRem('18.75px'),
                letterSpacing: '0.0015em',
                p: '0.688rem 1rem 0.688rem 0.5rem',
                minWidth: 'calc(86px - 1.25rem)',
                border: 'none',
                color: theme.palette.custom.darkblue,
                verticalAlign: 'bottom',
                transition: '0.2s',
            },
            '& th:nth-of-type(5)': {
                width: '100px',
            },
        }),
    ),
    TableRow: styled(TableRow)(() => ({})),
    TableCell: styled(
        TableCell,
        transientOptions,
    )<{ $color?: string; $bg?: string }>(({ $color, $bg, theme }) => ({
        transition: '0.2s',
        color: $color === 'default' ? theme.palette.custom.darkblue : $color,
        backgroundColor: $bg === 'none' ? 'inherit' : `${$bg} !important`,
        input: { paddingRight: '25px !important' },
    })),
    TableBody: styled(TableBody)(({ theme }) =>
        theme.unstable_sx({
            '& td': {
                width: '5.375rem',
                borderCollapse: 'collapse',
                border: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                lineHeight: pxToRem('18.75px'),
                letterSpacing: '-0.0125em',
                paddingX: '0.5rem',
                paddingY: '0.664rem',
            },
        }),
    ),
    AlertNoData: styled(AlertNoData)(() => ({})),
    AlerNoDataWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            div: { margin: 'auto', width: 'fit-content' },
        }),
    ),
    Tooltip: styled(Tooltip)(() => ({
        position: 'absolute',
        right: '9px',
        bottom: '18.5px',
    })),
    IconButton: styled(
        IconButton,
        transientOptions,
    )<{ $error?: boolean }>(({ theme, $error }) => ({
        padding: '0',
        color: $error ? theme.palette.error.main : '',
        '&:hover .MuiTooltip-tooltip': {
            backgroundColor: 'transparent',
        },
    })),
    CustomInput: styled(CustomInput)(() => ({})),
};
