import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TopHeader from './TopHeader';
import { useTheme } from '@mui/material';
import { ReactElement } from 'react';

const TopHeaderContainer = (props: Props) => {
    // const {} = props;
    const theme = useTheme();

    const childProps = {
        ...props,
        theme,
    };

    return <TopHeader {...childProps} />;
};

const propTypes = {};

interface extraProps {
    firstElement?: string | ReactElement;
    firstElementStylesTrans?: { [key: string]: any };
    title: string;
    titleStylesTrans?: { [key: string]: any };
    subtitle?: string;
    subtitleStylesTrans?: { [key: string]: any };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TopHeaderContainer.propTypes = propTypes;

export default TopHeaderContainer;
