import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteOrderModal from './DeleteOrderModal';
import { useTranslation } from 'react-i18next';
import { GetMarketOrderItem } from 'types/api/orders.types';
import { AlertColor } from '@mui/material';
import { useDeleteOrder } from 'hooks/api/orders.hooks';

const DeleteOrderModalContainer = (props: Props) => {
    const { row, close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { mutate, isLoading } = useDeleteOrder(setSnackBarMessage, close);

    const onSubmit = () => mutate(row.id);

    const childProps = {
        ...props,
        isLoading,
        t,
        onSubmit,
    };

    return <DeleteOrderModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetMarketOrderItem;
    close(): void;
    setSnackBarMessage(msj: string, sever?: AlertColor): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteOrderModalContainer.propTypes = propTypes;

export default DeleteOrderModalContainer;
