import { Box, styled, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import ButtonLink from 'components/common/ButtonLink';
import { pageContentMeshStyle } from 'utils/helpers/stylesHelper';
import TriangleWithExclamationMarkIcon from 'assets/img/triangleWithExclamationMark.svg?react';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            ...pageContentMeshStyle,
            m: '3.563rem auto 4.5rem',
        }),
    ) as typeof Box,
    RejectionMessageWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            p: '1.25rem 1rem 2.5rem',
            m: '65px 0 0',
            border: `1px solid ${theme.palette.custom.vibrantblue}`,
            borderRadius: '24px',
            button: {
                width: '100%',
                maxWidth: '284px',
                backgroundColor: theme.palette.custom.rederror,
                fontSize: '1rem',
                fontWeight: '700',
                lineHeight: '18.75px',
                letterSpacing: '0.0015em',
                color: theme.palette.custom.darkblue2,
                p: '10.64px 0',
                m: '0 auto',
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: theme.palette.custom.rederror,
                    opacity: '0.9',
                },
            },
        }),
    ) as typeof Box,
    TriangleWithExclamationMarkIcon: styled(TriangleWithExclamationMarkIcon)(() => ({
        margin: 'auto',
    })),
    RejectionMessage: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '2.125rem',
            lineHeight: '39.84px',
            letterSpacing: ' 0.0025em',
            textAlign: 'center',
            color: theme.palette.custom.darkblue,
            m: '4px 20px 16px 0 ',
        }),
    ) as typeof Typography,
    StepsWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: 'repeat(4,1fr)' },
            columnGap: '40px',
            rowGap: { xs: '16px', md: '33px' },
            m: { xs: '2.563rem 0 3.625rem', md: '3.563rem 0 6.625rem' },
            '.page-link': {
                width: '100%',
                maxWidth: { xs: '100%' },
                borderRadius: '28px',
                px: '0',
                transition: '0.2s',
            },
            '.page-link p': {
                fontSize: '0.75rem',
                fontWeight: '400',
                lineHeight: '14.06px',
                letterSpacing: '0.016em',
                color: theme.palette.custom.darkblue2,
            },
            '.rejected': {
                background: theme.palette.custom.rederror,
                border: `1px solid ${theme.palette.custom.rederror}`,
            },
            '.completed': {
                background: '#CCF1AE',
                border: '1px solid #CCF1AE',
            },
            '.pending p': {
                color: theme.palette.custom.vibrantblue2,
            },
            '.page-link:hover': { opacity: 0.8 },
            '.disabled': {
                cursor: 'default',
                opacity: 0.4,
                '&:hover': { opacity: 0.4 },
            },
            '.disableCursor': {
                cursor: 'default',
            },
        }),
    ),
    ButtonLink: styled(ButtonLink)(() => ({})),
    ActionButton: styled(ActionButton)(() => ({})),
};
