import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './CloseMarketModal.styled';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const CloseMarketModal = (props: Props) => {
    const { open, title, close, t, from, to } = props;

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{t('close_market_modal_content', { from, to })}</DialogContent>
            <DialogActions>
                <ActionButton onClick={close}>{t('close', tCommon)}</ActionButton>
            </DialogActions>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    title: string;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CloseMarketModal.propTypes = propTypes;

export default CloseMarketModal;
