import { LoadingButton } from '@mui/lab';
import { Box, Grid, styled, Typography } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '20px 4rem',
    })) as typeof Box,
    SectionInfo: styled(Box)(() => ({ color: 'black', marginBottom: '3rem' })),
    TitleSection: styled(Typography)(({ theme }) => ({
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        opacity: 0.8,
        color: theme.palette.primary.main,
    })),
    GridContainer: styled(Grid)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
    })),
    GriItem: styled(Grid)(() => ({
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0',
        borderBottom: '1px solid #ccc',
        width: '100%',
        gap: '10px',
        marginTop: '20px',
    })),

    Title: styled(Typography)(() => ({
        fontSize: '1.2rem',
        fontWeight: 'bold',
    })),

    Span: styled('span')(({ theme }) => ({
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
    })),
    ContainerBtn: styled(Box)(() => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2rem',
    })),
    LoadingButton: styled(LoadingButton)(({ theme }) => ({
        fontSize: '1.2rem',
        padding: '0.5rem 1rem',
    })),
};
