import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { FormikContextType, FormikProvider } from 'formik';
import ErrorText from 'components/common/ErrorText';
import { tCommon } from 'constants/appConstants';
import { OrderFormCurrencyType } from 'types/api/marketAccount.types';
import CustomLoader from 'components/common/CustomLoader/CustomLoader';
import St from './OrderForm.styled';
import TableOfTaker from './TableOfTaker';
import { CollateralAvailableItem } from 'types/api/orders.types';
import { error } from 'console';

const OrderForm = (props: Props) => {
    const {
        t,
        close,
        isLoading,
        isLoadingSubmit,
        formik,
        errorMessage,
        tBase,
        operationType,
        autocompleteOptions,
        handleChangeAutocomplete,
        currency,
        currencies,
        handleCurrencyChange,
        availableOperate,
        handleChangeTerm,
        insufficientBalance,
        disabledDates,
        collateralsAvailable,
    } = props;
    const { errors, values, setFieldValue } = formik;
    const isTake = operationType.id === 't';

    return (
        <FormikProvider value={formik}>
            <St.Form data-testid="Order-form">
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        <St.CustomAutoComplete
                            id={t(`${tBase}form_type_operation`)}
                            value={operationType}
                            onChange={(e, value) => handleChangeAutocomplete(value)}
                            options={autocompleteOptions ?? []}
                            label={t(`${tBase}form_type_operation`)}
                            disableClearable
                            renderOption={(props, option) => (
                                <St.NameWrapper {...props} key={option.id}>
                                    {option.name}
                                </St.NameWrapper>
                            )}
                        />
                        {isTake && (
                            <TableOfTaker
                                collateralsAvailable={collateralsAvailable}
                                formik={formik}
                            />
                        )}
                        {isTake && (
                            <>
                                <St.CurrencyInput
                                    currency={currency.name}
                                    handleChange={() => null}
                                    sx={{ width: '100%' }}
                                    value={values.calculatedAmount}
                                    decimalScale={2}
                                    disabled
                                    label={t(`${tBase}form_calculated_amount`)}
                                />
                            </>
                        )}
                        <St.BoxWrapper>
                            <St.CustomAutoComplete
                                id={t('currency', tCommon)}
                                value={currency}
                                onChange={(e, value) => handleCurrencyChange(value)}
                                options={currencies ?? []}
                                label={t('currency', tCommon)}
                                disableClearable
                                renderOption={(props, option) => (
                                    <St.NameWrapper {...props} key={option.id}>
                                        {option.name}
                                    </St.NameWrapper>
                                )}
                            />
                            <St.Typography variant="body2">
                                {`${t('available_with_currency', {
                                    ...tCommon,
                                    currency: currency.name,
                                    sign: currency.id === 'ars' ? '$' : 'US$',
                                })} ${availableOperate}`}
                            </St.Typography>
                        </St.BoxWrapper>
                        <St.BoxWrapper>
                            <St.CurrencyInput
                                currency={currency.name}
                                handleChange={value => setFieldValue('amount', value)}
                                sx={{ width: '100%' }}
                                value={values.amount}
                                decimalScale={2}
                                error={errors.amount as string}
                                disabled={insufficientBalance}
                                label={t(
                                    isTake ? `${tBase}form_amount_to_guaranteed` : 'amount',
                                    !isTake && tCommon,
                                )}
                            />
                            <St.Typography variant="body2">
                                {insufficientBalance && t(`${tBase}form_insufficient_balance`)}
                            </St.Typography>
                        </St.BoxWrapper>
                        <St.TwoColumnWrapper>
                            <St.CustomDatePicker
                                placeholder={t(`${tBase}form_liquidation_day`)}
                                fieldValue="date"
                                disabledDates={disabledDates}
                                disableWeekends
                                setFieldValue={(field, value) => {
                                    setFieldValue(field, value);
                                    handleChangeTerm(value);
                                }}
                                value={values.date}
                            />
                            <St.CustomInput
                                field="term"
                                errors={errors}
                                label={t('term', tCommon)}
                                placeholder={t('term', tCommon)}
                                setFieldValue={setFieldValue}
                                values={values}
                                disabled
                            />
                        </St.TwoColumnWrapper>
                        <St.BoxWrapper>
                            <St.Typography variant="body2">{t(`${tBase}rate`)}</St.Typography>
                            <St.CustomRadioButtonWrapper>
                                <St.CustomRadioButton
                                    checked={values.isMarketRate}
                                    name={t('market', tCommon)}
                                    onChange={() =>
                                        setFieldValue('isMarketRate', !values.isMarketRate)
                                    }
                                    text={t('market', tCommon)}
                                    value={String(values.isMarketRate)}
                                />
                                <St.CustomRadioButton
                                    checked={!values.isMarketRate}
                                    name={t(`${tBase}limit_rate`)}
                                    onChange={() =>
                                        setFieldValue('isMarketRate', !values.isMarketRate)
                                    }
                                    text={t(`${tBase}limit_rate`)}
                                    value={String!(values.isMarketRate)}
                                />
                            </St.CustomRadioButtonWrapper>
                            {isTake && (
                                <St.Typography variant="body2">
                                    {t(`${tBase}form_operation_to_be_validated`)}
                                </St.Typography>
                            )}
                        </St.BoxWrapper>
                        {!values.isMarketRate && (
                            <St.CurrencyInput
                                handleChange={value => setFieldValue('percentage', value)}
                                sx={{ width: '100%' }}
                                value={values.percentage}
                                decimalScale={2}
                                label={t(`${tBase}form_percentage`)}
                                percentage
                            />
                        )}
                        {errorMessage && <ErrorText text={errorMessage} />}
                        <St.ButtonsContainer>
                            <St.ActionButton variant="outlined" onClick={close} type="button">
                                {t('cancel', tCommon)}
                            </St.ActionButton>
                            <St.LoadingButton
                                variant="contained"
                                type="submit"
                                loading={isLoadingSubmit}>
                                {t('sent', tCommon)}
                            </St.LoadingButton>
                        </St.ButtonsContainer>
                    </>
                )}
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingSubmit: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    errorMessage: string | null;
    tBase: string;
    operationType: { id: string; name: string };
    autocompleteOptions: Array<{ id: string; name: string }>;
    handleChangeAutocomplete: (x: { id: string; name: string }) => void;
    close: () => void;
    currency: OrderFormCurrencyType;
    currencies: Array<OrderFormCurrencyType>;
    handleCurrencyChange: (x: OrderFormCurrencyType) => void;
    availableOperate: string;
    handleChangeTerm: (date: number) => void;
    insufficientBalance: boolean;
    disabledDates: string[];
    collateralsAvailable?: CollateralAvailableItem[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OrderForm.propTypes = propTypes;

export default OrderForm;
