import * as React from 'react';
import { Checkbox, Divider, FormGroup, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DetailWithdrawalModal.styled';
import ActionButton from 'components/common/ActionButton';
import { GetWithdrawalsItem } from 'types/api/cashflow.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';

const DetailWithdrawalModal = (props: Props) => {
    const { t, row, close } = props;

    return (
        <Grid container spacing={2} px={3} mt={1.5} alignItems="baseline">
            <Grid container item spacing={1} xs={12} md={12}>
                <St.DetailTitle>{t('information')}</St.DetailTitle>
                <ChannelsModalItemGrid
                    label={t('entry_date')}
                    column={6}
                    text={formatDateWithHour(row?.entryDate ?? '')}
                />
                <ChannelsModalItemGrid
                    label={t('channel')}
                    column={6}
                    text={row?.channel ? `${row.channel.name} ${row.channel.lastName}` : '-'}
                />
                <ChannelsModalItemGrid
                    label={t('client')}
                    column={6}
                    text={getFullName(row?.user)}
                />
                <ChannelsModalItemGrid
                    label={t('customer_code')}
                    column={6}
                    text={row?.customerCode}
                />
                <ChannelsModalItemGrid
                    label={t('currency')}
                    column={6}
                    text={row?.bankAccount?.currency}
                />
                <ChannelsModalItemGrid
                    label={t('amount')}
                    column={6}
                    text={convertCurrencyFormat(row?.amount ?? 0, row?.bankAccount?.currency)}
                />
                {row?.reason && (
                    <>
                        <Divider sx={{ width: '100%', my: 1 }} />
                        <ChannelsModalItemGrid label={t('rejected_reason')} text={row?.reason} />
                    </>
                )}
            </Grid>
            <Grid container item spacing={1} xs={12} md={12} my={1}>
                <St.DetailTitle>{t('additional_information')}</St.DetailTitle>
                {row?.comment && <ChannelsModalItemGrid label={t('comment')} text={row?.comment} />}
                <FormGroup sx={{ paddingLeft: '20px' }}>
                    <St.FormControlLabel
                        control={<Checkbox checked={row?.conditions?.fciSell} disabled />}
                        label={t('rescue_FCI')}
                    />
                    <St.FormControlLabel
                        control={<Checkbox checked={row?.conditions?.noMoney} disabled />}
                        label={t('no_money_available')}
                    />
                    <St.FormControlLabel
                        control={<Checkbox checked={row?.conditions?.outOfTimeframe} disabled />}
                        label={t('out_of_hours')}
                    />
                    <St.FormControlLabel
                        control={<Checkbox checked={row?.conditions?.other} disabled />}
                        label={t('other')}
                    />
                </FormGroup>
            </Grid>
            <Grid container item spacing={1} xs={12} md={12} my={1}>
                <St.DetailTitle>{t('destination_account')}</St.DetailTitle>
                <ChannelsModalItemGrid label={t('cuit_cuil_dni')} text={row?.user?.docNumber} />
                <ChannelsModalItemGrid label={t('cbu')} text={row?.bankAccount?.accountNumber} />
            </Grid>
            <Divider sx={{ width: '100%', my: 1 }} />
            <Grid container item xs={12} justifyContent="center" pt={1} spacing={1} padding="10px">
                <Grid item xs={2.5}>
                    <ActionButton
                        variant="outlined"
                        onClick={() => close()}
                        sx={{ mt: 0, width: '100%', fontSize: '1rem' }}>
                        {t('back')}
                    </ActionButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: GetWithdrawalsItem;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DetailWithdrawalModal.propTypes = propTypes;

export default DetailWithdrawalModal;
