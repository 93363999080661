import { Box, Button, styled, Typography } from '@mui/material';

export default {
    Container: styled(Box)(() => ({})) as typeof Box,

    Title: styled('h1')(() => ({
        fontSize: '1.5rem',
        marginBottom: '0.5rem',
    })),

    Subtitle: styled(Typography)(() => ({
        color: '#666',
        marginBottom: '2rem',
    })),

    StepsContainer: styled(Box)(() => ({
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    })) as typeof Box,

    StepCard: styled(Box)(() => ({
        background: '#F5F5F5',
        borderRadius: '8px',
        padding: '1.5rem',
        flex: 1,
        minWidth: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    })) as typeof Box,

    StepNumber: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
            fontSize: '1.25rem',
            fontWeight: 'bold',
        }),
    ),

    StepTitle: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.vibrantblue3,
            fontSize: '1.5rem',
            marginBottom: '1rem',
        }),
    ),

    StepButton: styled(Button)(() => ({
        background: '#1976D2',
        color: 'white',
        borderRadius: '4px',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        transition: 'background 0.2s',

        '&:hover': {
            background: '#1565C0',
        },
    })),
};
