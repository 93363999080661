import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FormikContextType, FormikProvider } from 'formik';
import St from './SendMailForm.styled';
import ErrorText from 'components/common/ErrorText';
import TopHeader from 'components/common/TopHeader';

const SendMailForm = (props: Props) => {
    const { t, isLoading, formik, errorMessage } = props;

    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <St.Form data-testid="SendMail-form">
                <Stack spacing={2} alignItems="center">
                    <TopHeader title={t('forgot_password_title')} />
                    <St.ResponsiveStack spacing={2} alignItems="center">
                        <St.TextFieldStyled
                            fullWidth
                            autoFocus
                            size="small"
                            id="email"
                            label={t('email_label')}
                            type="text"
                            value={values.email}
                            onChange={({ target }: any) => {
                                setFieldValue('email', target.value);
                            }}
                            error={errors.email !== undefined}
                            helperText={errors.email?.toString()}
                            placeholder={t('email_placeholder')}
                            variant="outlined"
                        />
                        <St.LoadingButtonStyled
                            type="submit"
                            variant="contained"
                            loading={isLoading}>
                            {t('recover')}
                        </St.LoadingButtonStyled>
                        {errorMessage && <ErrorText text={errorMessage} />}
                    </St.ResponsiveStack>
                    <St.BackButtonContainer direction="column" alignItems="center">
                        <Divider sx={{ my: 4, width: '100%' }} />
                        <St.BackButtonStyled
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            component={RouterLink}
                            to="/login">
                            {t('back')}
                        </St.BackButtonStyled>
                    </St.BackButtonContainer>
                </Stack>
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    mainLogo: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    errorMessage?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SendMailForm.propTypes = propTypes;

export default SendMailForm;
