import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeadLogo from './HeadLogo';

const HeadLogoContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <HeadLogo {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeadLogoContainer.propTypes = propTypes;

export default HeadLogoContainer;
