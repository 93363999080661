import React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MarketSearchBar.styled';

const MarketSearchBar = (props: Props) => {
    const {
        query,
        setQuery,
        placeholder,
        blueMode = false,
        extraComponent,
        handleStopPropagation,
    } = props;

    return (
        <St.SearchContainer
            className="MarketSearchBar"
            $blueMode={blueMode}
            onClick={event => (handleStopPropagation ? handleStopPropagation(event) : null)}>
            <St.StyledTextField
                variant="outlined"
                placeholder={placeholder}
                value={query}
                onChange={e => setQuery(e.target.value)}
                $blueMode={blueMode}
            />
            <St.IconWrapper aria-label="search" $blueMode={blueMode}>
                <St.SearchIcon $blueMode={blueMode} />
            </St.IconWrapper>
            {extraComponent && extraComponent}
        </St.SearchContainer>
    );
};

const propTypes = {};

interface extraProps {
    query: string;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
    placeholder: string;
    blueMode?: boolean;
    extraComponent?: React.ReactNode;
    handleStopPropagation?: (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

MarketSearchBar.propTypes = propTypes;

export default MarketSearchBar;
