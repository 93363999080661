import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    Box: styledMui(Box)``,
    ListItem: styledMui(ListItem)(({ theme }) => ({
        display: 'block',
        padding: '2px 8px',
        '.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'rgba(145, 158, 171, 0.08)',
            '& .icon': { color: theme.palette.primary.main },
        },
        // transition: '.2s ease-in-out',
    })),
    Collapse: styledMui(Collapse)(({ theme }) => ({
        // display: 'block',
        // padding: '2px 8px',
        // '.Mui-selected': {
        //     color: theme.palette.primary.main,
        //     backgroundColor: 'rgba(145, 158, 171, 0.08)',
        //     '& .icon': { color: theme.palette.primary.main },
        // },
    })),
    MenuItemIcon: styledMui(ListItemIcon)<{
        ownerState: { drawerOpened: boolean; isSelected: boolean; isArrowIcon?: boolean | null };
    }>(({ theme, ownerState }) => {
        const { drawerOpened, isSelected, isArrowIcon } = ownerState;
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 0,
            padding: 0,
            marginRight: drawerOpened && !isArrowIcon ? 30 : 'auto',
            flex: 0,
            color: isSelected ? theme.palette.primary.main : '',
            position: 'absolute',
            right: 5,
        };
    }),
    MenuItemText: styledMui(ListItemText, {
        shouldForwardProp: prop => prop !== 'drawerOpened',
    })(({ drawerOpened }: { drawerOpened: boolean }) => ({
        opacity: drawerOpened ? 1 : 0,
    })),
    DrawerSubmenuButton: styledMui(ListItemButton)(({ theme }) => ({
        borderRadius: '8px',
        marginTop: '5px',
        marginBottom: '5px',
        marginLeft: '32px',
        '&:hover': {
            color: theme.palette.secondary.main,
            '& .sub_icon': { color: theme.palette.secondary.main },
        },
        // transition: '.2s ease-in-out',
        textAlign: 'left',
        position: 'relative',
    })),
    DrawerMenuButton: styledMui(ListItemButton)(({ theme }) => ({
        '&:hover': {
            color: theme.palette.secondary.main,
            '& .icon': { color: theme.palette.secondary.main },
        },
        borderRadius: '6px',
        paddingLeft: '10px',
        marginRight: '72px',
        // transition: '.2s ease-in-out',
        textAlign: 'left',
        width: '100%',
    })),
};
