import { Box, styled } from '@mui/material';

export default {
    Box: styled(Box)(() => ({
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    })),
};
