import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteModal from './DeleteModal';
import { useTranslation } from 'react-i18next';
import { useDeleteCollateralOrder } from 'hooks/api/orders.hooks';
import { AlertColor } from '@mui/material';
import { GetCollateralOrderItem } from 'types/api/orders.types';

const DeleteModalContainer = (props: Props) => {
    const { row, setSnackBarMessage, close } = props;
    const { t } = useTranslation('market');
    const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
    const { isLoading, mutate } = useDeleteCollateralOrder(setSnackBarMessage, close);
    const handleDelete = () => mutate(row.id);

    const childProps = {
        ...props,
        t,
        deleteModal,
        setDeleteModal,
        isLoading,
        handleDelete,
    };

    return <DeleteModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetCollateralOrderItem;
    close: () => void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteModalContainer.propTypes = propTypes;

export default DeleteModalContainer;
