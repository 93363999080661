import { LoadingButton } from '@mui/lab';
import { Box, Grid, ListItemText, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    IncomeProof: styled(Box)(() => ({})) as typeof Box,

    IncomeProofInternalBox: styled(Box)(({ theme }) => ({
        marginTop: pxToRem(50),
    })) as typeof Box,

    ContacInfo: styled(Typography)(() => ({
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28.13px',
    })) as typeof Typography,

    ContacInfoTitle: styled(Typography)(({ theme }) => ({
        color: '#307FDD',
        fontWeight: 300,
        fontSize: '32px',
        letterSpacing: '-1.5%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '38px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '68px',
            lineHeight: '79.69px',
        },
    })) as typeof Typography,

    ContactInfoValidationInternalBox: styled(Box)(({ theme }) => ({
        margin: `${pxToRem(80)} ${pxToRem(5)} `,
        [theme.breakpoints.up('sm')]: {
            margin: `${pxToRem(80)} ${pxToRem(10)} `,
        },
        [theme.breakpoints.up('md')]: {
            margin: `${pxToRem(80)} ${pxToRem(150)} `,
        },
    })) as typeof Box,

    ContactInfoValidationInputsGrid: styled(Grid)(({ theme }) => ({
        container: 'true',
        display: 'flex',
        flexDirection: 'column',
        border: '1px',
        borderTop: 'dashed',
        borderTopWidth: 'thin',
        borderColor: theme.palette.custom.darkblue2,
    })) as typeof Grid,

    IncomeProofInputs: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '10px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '50px',
        },
        gap: '20px',
    })) as typeof Box,

    IncomeProofText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue2,
        }),
    ) as typeof Typography,

    ContactInfoValidationInputsValue: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue2,
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '28.13px',
        }),
    ) as typeof Typography,

    IncomeProofButtonsBox: styled(Grid)(({ theme }) => ({
        container: 'true',
        alignItems: 'end',
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        marginTop: '20px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '30px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '50px',
        },
    })) as typeof Grid,

    OnBoardingStepButton: styled(LoadingButton)(({ theme }) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '200px',
        },
        [theme.breakpoints.up('md')]: {
            width: '354px',
        },
    })) as typeof LoadingButton,
    ListItemText: styled(ListItemText)(({ theme }) => ({
        fontWeight: '500!important',
        color: theme.palette.custom.darkBlue2,
    })) as typeof ListItemText,
};
