import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AccountStatus from './AccountStatus';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { useTranslation } from 'react-i18next';
import { tCommon } from 'constants/appConstants';
import { formatNumberMilis } from 'utils/helpers/commonHelper';
import { useEffect, useState } from 'react';
import { AuthContext } from 'context/auth.context';

const AccountStatusContainer = (props: Props) => {
    const { setSelectedInstrumentType = () => {} } = props;
    const { t } = useTranslation('home');
    const { userType, marketAccounts } = React.useContext(AuthContext);
    const isUserApAc = userType === 'CHANNEL' || userType === 'ASSISTANT';
    const [concertation, setConcertation] = useState<boolean>(true);
    const [liquidation, setLiquidation] = useState<boolean>(false);
    const { data, isLoading } = useGetAccountStatus(liquidation);
    const series = data?.instruments.map(i => i.percentage) ?? [];
    const labels = data?.instruments.map(i => t(i.instrumentType, tCommon)) ?? [];
    const total = React.useMemo(() => {
        if (!data?.aum) return '0';
        return formatNumberMilis(data.aum.total.ars);
    }, [data]);
    const [openAvailableBalanceDetailModal, setOpenAvailableBalanceDetailModal] =
        React.useState<boolean>(false);

    const [openAumDetailModal, setOpenAumDetailModal] = React.useState<boolean>(false);

    useEffect(() => {
        setConcertation(!liquidation);
    }, [liquidation]);

    const childProps = {
        ...props,
        aum: data?.aum,
        availableBalance: data?.availableBalance,
        performance: data?.performance,
        isLoading,
        series,
        labels,
        total,
        concertation,
        setConcertation,
        liquidation,
        setLiquidation,
        setSelectedInstrumentType,
        data,
        marketAccounts,
        isUserApAc,
        t,
        openAvailableBalanceDetailModal,
        setOpenAvailableBalanceDetailModal,
        openAumDetailModal,
        setOpenAumDetailModal,
        detailedAum: data?.aum.detailed,
    };

    return <AccountStatus {...childProps} />;
};

const propTypes = {
    isWallet: PropTypes.bool,
};

interface extraProps {
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountStatusContainer.propTypes = propTypes;

export default AccountStatusContainer;
