import { RecommendedWalletResponse } from 'types/api/auth.types';
import { RecommendedWalletValues } from 'types/pages/home.types';
import { capitalizeFirstLetter } from 'utils/helpers/stringsHelper';

export const recommendedWalletAdapter = (data: RecommendedWalletResponse) => {
    return {
        funds: {
            colors: data.fondos.map(f => f.color),
            series: data.fondos.map(f => Number(f.valor)),
            labels: data.fondos.map(f => capitalizeFirstLetter(f.campo)),
        },
        coins: {
            colors: data.moneda.map(m => m.color),
            series: data.moneda.map(m => Number(m.valor)),
            labels: data.moneda.map(m => capitalizeFirstLetter(m.campo)),
        },
        credits: {
            colors: data.credito.map(c => c.color),
            series: data.credito.map(c => Number(c.valor)),
            labels: data.credito.map(c => capitalizeFirstLetter(c.campo)),
        },
    } as RecommendedWalletValues;
};
