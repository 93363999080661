import { Box, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '0rem 4rem 8rem 4rem',
        gap: '20px',
        flexDirection: 'column',
        display: 'flex',
    })) as typeof Box,
};
