import { styled as styledMui, keyframes } from '@mui/material/styles';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export default {
    LoginForm: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.custom.whiteSmoke,
        padding: '2em',
        borderRadius: '0.5rem',
        gap: '8px',
        left: `-webkit-calc(50% - 135px - 2em)`,
        top: `-webkit-calc(50% - 135px - 2em)`,
        height: 'auto',
        maxWidth: '100%',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.98)',
        animation: fadeIn,
        animationDuration: '1s',
        animationFillMode: 'both',
    })) as typeof Box,
    CreateAccount: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    })) as typeof Box,
    CreateAccountText: styledMui(Typography)(() => ({
        margin: ' 1rem 0',
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: '12px',
    })) as typeof Typography,
    LogoContainer: styledMui(Box)({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.6rem',
    }),
    Logo: styledMui(SvgIcon)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '180px',
            objectFit: 'contain',
            width: 'auto',
            height: 'auto',
        }),
    ) as unknown as typeof SvgIcon,
    Stack: styledMui(Stack)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '300px',
            },
        }),
    ),
    ProfilePageTitle: styledMui(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.primary.main,
            fontSize: '1.2rem',
            marginBottom: '1rem',
            letterSpacing: '0.0025em',
            textAlign: 'left',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.6rem',
            },
        }),
    ) as typeof Typography,
    AccountContainer: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        }),
    ),
    AccountText: styledMui(Typography)({
        fontFamily: 'Roboto',
        fontSize: '0.85rem',
    }),
};
