import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvestmentTestValues } from 'types/common/users.types';

export const useGetInvestmentProfileFeatures = (investmentProfile?: InvestmentTestValues) => {
    const { t } = useTranslation('onboarding');

    const investorFeatures = useMemo(() => {
        switch (investmentProfile) {
            case 'AGGRESSIVE':
                return [t('feature_aggressive_one'), t('feature_aggressive_two')];
            case 'MODERATE':
                return [t('feature_moderate_one'), t('feature_moderate_two')];
            case 'CONSERVATIVE':
                return [t('feature_conservative_one'), t('feature_conservative_two')];
            default:
                return [t('feature_conservative_one'), t('feature_conservative_two')];
        }
    }, [investmentProfile, t]);

    return { investorFeatures };
};
