import { Box, styled } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';
import Union from 'assets/icons/union.svg?react';
import { InvestmentTestValues } from 'types/common/users.types';

export default {
    Container: styled(Box)(({ theme }) => ({
        marginTop: '3.5rem',
    })),
    ImageContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: pxToRem(30),
        marginBottom: pxToRem(10),
        position: 'relative',
    })),
    Img: styled('img')(({ theme }) => ({
        width: '260px',
    })),
    UnionIcon: styled(
        Union,
        transientOptions,
    )<{ $investmentProfile?: InvestmentTestValues }>(({ theme, $investmentProfile }) => ({
        position: 'absolute',
        left: $investmentProfile === 'AGGRESSIVE' ? '45%' : '40%',
        bottom:
            $investmentProfile === 'MODERATE'
                ? 0
                : $investmentProfile === 'AGGRESSIVE'
                  ? '-5%'
                  : '-10%',
        transform:
            $investmentProfile === 'MODERATE'
                ? 'rotate(70deg)'
                : $investmentProfile === 'AGGRESSIVE'
                  ? 'rotate(140deg)'
                  : 'rotate(0deg)',
    })),
    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            margin: '0',
            padding: '16px',
            color: theme.palette.custom.darkblue,
        }),
    ),
    Li: styled('li')(() => ({
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18.75px',
        letterSpacing: '0.0015em',
        // listStyleType: 'none',
    })),
    ActionButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }),
    ),
};
