import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingValidatePersonDniSection.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import CustomInput from 'components/common/CustomInput';
import TopHeader from 'components/common/TopHeader';
import ErrorText from 'components/common/ErrorText';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';
import { pxToRem } from 'utils/helpers/stylesHelper';

const OnboardingValidatePersonDniSection = (props: Props) => {
    const { formik, t, errorMessage, isLoading } = props;
    return (
        <St.SectionWrapper>
            <FormikProvider value={formik} data-testid="NosisDniSection-form">
                <Form>
                    <TopHeader
                        firstElement={t('nosis_first_text') as string}
                        title={t('nosis_dni_section_title')}
                    />
                    <St.InfoWrapper>
                        <St.InputWrapper>
                            <CustomInput
                                field="document"
                                values={formik?.values}
                                setFieldValue={formik.setFieldValue}
                                placeholder={t('nosis_dni_section_input_placeholder')}
                                helperText={t('nosis_dni_section_input_helper_text')}
                                errors={formik?.errors}
                                regex={REGEX_0_TO_9}
                            />
                        </St.InputWrapper>
                        <ActionButton
                            loading={isLoading}
                            disabled={formik.values.document.length < 8}
                            variant="contained"
                            type="submit"
                            sx={{
                                width: {
                                    sm: pxToRem(361),
                                    xs: '100%',
                                },
                            }}>
                            {t('nosis_dni_section_register_button')}
                        </ActionButton>
                    </St.InfoWrapper>
                    <ErrorText text={errorMessage} />
                </Form>
            </FormikProvider>
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: any;
    errorMessage: string | null;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonDniSection.propTypes = propTypes;

export default OnboardingValidatePersonDniSection;
