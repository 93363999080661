import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './EmailSentPage.styled';
import { Stack, Typography } from '@mui/material';
import TopHeader from 'components/common/TopHeader';
import ActionButton from 'components/common/ActionButton';
import SignUpHeaderNumbers from 'components/common/SignUpHeaderNumbers';
import HeadLogo from 'components/common/HeadLogo';
import FooterLayout from 'components/layouts/FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';
import { pxToRem } from 'utils/helpers/stylesHelper';

const EmailSentPage = (props: Props) => {
    const {
        t,
        // isLoading,
        isSigningUp,
        resendCode,
        // error,
        SnackBar,
        email,
    } = props;
    return (
        <>
            <HeadLogo />
            <St.EmailSentContainerBox>
                <St.ConfirmContainerBox>
                    <Stack spacing={2} rowGap={'1rem'}>
                        {isSigningUp && <SignUpHeaderNumbers activated={2} />}
                        <TopHeader
                            firstElement={
                                t(
                                    isSigningUp
                                        ? 'closer_to_start_to_invest'
                                        : 'closer_to_start_to_invest_forgot',
                                ) as string
                            }
                            title={t('check_your_email')}
                        />
                        <Typography variant="h3" component="h3">
                            {t(isSigningUp ? 'email_sent' : 'email_sent_forgot', {
                                email,
                            })}
                        </Typography>
                        <St.ResendButtonContainer>
                            <ActionButton
                                variant="outlined"
                                color="secondary"
                                onClick={resendCode}
                                // loading={isLoading}
                                sx={{ width: pxToRem(182), fontSize: pxToRem(12) }}>
                                {t('resend_code')}
                            </ActionButton>
                        </St.ResendButtonContainer>
                        {/* {error && <ErrorText text={error} />} */}
                    </Stack>
                    <SnackBar />
                </St.ConfirmContainerBox>
                <FooterLayout />
                <HelpTooltip />
            </St.EmailSentContainerBox>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    // isLoading: PropTypes.bool.isRequired,
    isSigningUp: PropTypes.bool.isRequired,
    // error: PropTypes.string,
    email: PropTypes.string.isRequired,
};

interface extraProps {
    resendCode: () => void;
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EmailSentPage.propTypes = propTypes;

export default EmailSentPage;
