import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DrawerMenuList.styled';
import { List } from '@mui/material';
import MenuItem from './MenuItem';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const Drawer = (props: Props) => {
    const { t, open, menusList, selectedMenu, handleNavigate, onLogout, logoutItemData } = props;

    return (
        <List>
            {menusList?.map((menuItem: SubRouteInfo, index) => (
                <MenuItem
                    key={index}
                    drawerOpened={open}
                    itemData={menuItem}
                    handleNavigate={handleNavigate}
                    selectedMenu={selectedMenu}
                />
            ))}
            <MenuItem
                key={logoutItemData.name}
                drawerOpened={open}
                itemData={logoutItemData}
                handleNavigate={onLogout}
                selectedMenu={selectedMenu}
            />
        </List>
    );
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    menusList: SubRouteInfo[];
    logoutItemData: SubRouteInfo;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Drawer.propTypes = propTypes;

export default Drawer;
