import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BuySellSucessModal from './BuySellSucessModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InstrumentType } from 'types/api/instruments.types';

const BuySellSucessModalContainer = (props: Props) => {
    const { leftUrl, rightUrl } = props;
    const { t } = useTranslation('market');
    const navigate = useNavigate();

    const handleGotoBtnLeft = () => navigate(leftUrl);
    const handleGotoBtnRight = () => navigate(rightUrl);

    const childProps = {
        ...props,
        t,
        handleGotoBtnLeft,
        handleGotoBtnRight,
    };

    return <BuySellSucessModal {...childProps} />;
};

const propTypes = {
    date: PropTypes.string.isRequired,
};

interface extraProps {
    leftUrl: string;
    rightUrl: string;
    leftText: string;
    rightText: string;
    close: () => void;
    instrumentType?: InstrumentType;
    open: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellSucessModalContainer.propTypes = propTypes;

export default BuySellSucessModalContainer;
