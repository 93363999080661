import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BlogPage.styled';
import CustomCarousel from 'components/common/CustomCarousel';
import { Box, Typography } from '@mui/material';
import CircularSpinner from 'components/common/CircularSpinner';
import { BlogArticle } from 'types/api/wrapper.types';
import CustomCarouselItems from 'components/common/CustomCarouselItems';

const BlogPage = (props: Props) => {
    const {
        groupedDataByCategory,
        isLoading,
        dataLatestArray,
        isLoadingLatest,
        matchesSm,
        matchesMd,
    } = props;
    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="BlogPage-page">
                {isLoadingLatest && isLoading ? (
                    <Box sx={{ marginTop: '10px' }}>
                        <CircularSpinner />
                    </Box>
                ) : (
                    <>
                        {dataLatestArray && (
                            <St.LatestSection>
                                <CustomCarousel width="100%" withDots={true}>
                                    {dataLatestArray?.map((article: BlogArticle, index: number) => (
                                        <St.CarouselItemContainer key={index}>
                                            <St.LatestImageContainer
                                                key={article.id}
                                                onClick={() =>
                                                    window.open(article.mobUrl, '_blank')
                                                }
                                                sx={{ cursor: 'pointer' }}>
                                                <St.Image
                                                    src={article.mobImagenGrandeHeader}
                                                    alt={article.mobTitulo}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                                <St.TitleOverlay>
                                                    <St.LatestTitle>
                                                        {article.mobTitulo}
                                                    </St.LatestTitle>
                                                </St.TitleOverlay>
                                            </St.LatestImageContainer>
                                        </St.CarouselItemContainer>
                                    ))}
                                </CustomCarousel>
                            </St.LatestSection>
                        )}
                        {groupedDataByCategory?.map((categoryData, categoryIndex) => (
                            <Box key={categoryIndex} sx={{ marginBottom: '40px' }}>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    sx={{ padding: '0px 10px;' }}>
                                    {categoryData.category}
                                </Typography>
                                <St.CategoriesSection
                                    $matchessm={matchesSm || undefined}
                                    $matchesmd={matchesMd || undefined}>
                                    <CustomCarousel
                                        width="100%"
                                        withDots={!matchesSm && !matchesMd}>
                                        {categoryData.groups.map((group, groupIndex) => (
                                            <St.CardWrapper key={groupIndex}>
                                                {group.map((article, index) => (
                                                    <CustomCarouselItems
                                                        key={index}
                                                        article={article}
                                                    />
                                                ))}
                                            </St.CardWrapper>
                                        ))}
                                    </CustomCarousel>
                                </St.CategoriesSection>
                            </Box>
                        ))}
                    </>
                )}
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    dataLatestArray: PropTypes.array as PropTypes.Requireable<BlogArticle[]>,
    groupedNewsByCategory: PropTypes.array as PropTypes.Requireable<BlogArticle[]>,
    isLoadingLatest: PropTypes.bool.isRequired,
    matchesSm: PropTypes.bool.isRequired,
    matchesMd: PropTypes.bool.isRequired,
};

interface extraProps {
    groupedDataByCategory?: { category: string; groups: BlogArticle[][] }[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BlogPage.propTypes = propTypes;

export default BlogPage;
