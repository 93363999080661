import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AccountInformation from './AccountInformation';
import { AccountAum, AccountPerformance } from 'types/api/marketAccount.types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const AccountInformationContainer = (props: Props) => {
    const { aum, performance, isWallet } = props;
    const { t } = useTranslation('home');
    const theme = useTheme();
    const [visualizeUSD, setVisualizeUSD] = React.useState<boolean>(false);

    const aumCurrency = React.useMemo(() => {
        if (!aum) return 0;
        if (visualizeUSD) return aum?.total.usd;
        return aum?.total.ars;
    }, [aum, visualizeUSD]);

    const performanceCurrency = React.useMemo(() => {
        if (!performance) return 0;
        if (visualizeUSD) return performance?.usd;
        return performance.ars;
    }, [performance, visualizeUSD]);

    const childProps = {
        ...props,
        t,
        visualizeUSD,
        setVisualizeUSD,
        aumCurrency,
        performanceCurrency,
        theme,
        isWallet,
    };

    return <AccountInformation {...childProps} />;
};

const propTypes = {
    isWallet: PropTypes.bool,
    isUserApAc: PropTypes.bool.isRequired,
};

interface extraProps {
    aum?: AccountAum;
    performance?: AccountPerformance;
    setOpenAumDetailModal: (value: boolean) => void;
    isUserApAc: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountInformationContainer.propTypes = propTypes;

export default AccountInformationContainer;
