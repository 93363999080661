import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CircleWithNumber.styled';

const CircleWithNumber = (props: Props) => {
    const { number, activated, className } = props;

    return (
        <St.CircleWithNumber $activated={activated} className={className ?? ''}>
            {number}
        </St.CircleWithNumber>
    );
};

const propTypes = {};

interface extraProps {
    number: number;
    activated?: boolean;
    className?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CircleWithNumber.propTypes = propTypes;

export default CircleWithNumber;
