import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DownloadContract.styled';
import PdfIcon from 'assets/img/general/pdfFile.svg?react';
import { SvgIcon } from '@mui/material';
import { download } from 'utils/helpers/files.helper';

const DownloadContract = (props: Props) => {
    const { t, fileUrl, label } = props;
    return (
        <St.DownloadContractWrapper>
            <St.BoxWrapper>
                <St.Typography>{label}</St.Typography>
                <St.IconButtonSvg>
                    <SvgIcon component={PdfIcon} inheritViewBox />
                </St.IconButtonSvg>
            </St.BoxWrapper>
            <St.LoadingButton
                onClick={() => (fileUrl ? download(fileUrl) : null)}
                className={'isDelete'}>
                <St.Label>{t('download')}</St.Label>
            </St.LoadingButton>
        </St.DownloadContractWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    fileUrl: PropTypes.string,
    label: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DownloadContract.propTypes = propTypes;

export default DownloadContract;
