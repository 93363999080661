import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import GoBackButton from 'components/common/GoBackButton';
import FavoriteButton from 'components/common/FavoriteButton';
import FundDetail from './FundDetail';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import InstrumentBuySell from './InstrumentBuySell';
import { Tprop } from 'types/common/general.types';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';
import CustomLoader from 'components/common/CustomLoader';
import { tCommon } from 'constants/appConstants';
import FundDetailCard from './FundDetailCard';
import InstumentGraphicCard from '../MarketInstrumentDetailPage/InstumentGraphicCard';
import GeneralMarketSearchBar from 'components/common/GeneralMarketSearchBar';
import { mainRoutesPaths } from 'router/routesPaths';
import St from './MarketFundDetailPage.styled';

const MarketFundDetailPage = (props: Props) => {
    const { fundDetail, term, setTerm, accountStatus, isLoading, t, ticker, isMarketClosed } =
        props;

    return (
        <St.SectionWrapper>
            {isLoading ? (
                <CustomLoader />
            ) : fundDetail ? (
                <>
                    <St.HeaderContainer>
                        <GoBackButton prevRoute={mainRoutesPaths.funds} />
                        <GeneralMarketSearchBar />
                        <FavoriteButton favorite={fundDetail.favorite ?? false} ticker={ticker} />
                    </St.HeaderContainer>
                    <St.PageWrapper>
                        <St.FundContentWrapper>
                            <FundDetail {...{ fundDetail, isMarketClosed }} />
                        </St.FundContentWrapper>
                        <St.FundActionsWrapper>
                            <InstrumentBuySell
                                {...{
                                    isMarketClosed,
                                    fundDetail,
                                    term,
                                    setTerm,
                                    accountStatus,
                                    marketClosingTime: fundDetail.fund_closing_time,
                                }}
                            />
                        </St.FundActionsWrapper>
                    </St.PageWrapper>
                    <InstumentGraphicCard ticker={ticker} />
                    <FundDetailCard fundDetail={fundDetail} />
                </>
            ) : (
                <St.AlerNoDataWrapper>
                    <St.AlertNoData title={t('no_data_was_found', tCommon)} />
                </St.AlerNoDataWrapper>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    setTerm: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps extends Tprop {
    fundDetail?: GetFundDetailResponse;
    term: 'CI' | '24hs';
    accountStatus?: GetAccountStatusResponse;
    ticker: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketFundDetailPage.propTypes = propTypes;

export default MarketFundDetailPage;
