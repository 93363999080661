import * as React from 'react';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tErrorsContext } from 'constants/appConstants';
import OnboardingInvestmentTestPage from './OnboardingInvestmentTestPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainRoutesPaths, onboardingRoutesPaths } from 'router/routesPaths';
import { AuthContext } from 'context/auth.context';
import {
    Option,
    Question,
    QuestionsInvestmentById,
} from 'types/pages/onboardingValidatePerson.types';
import { questionsInvestmentAdapterToValidateAnswers } from 'adapters/api/onboarding.adapter';
import {
    useGetInvestmentTestQuestions,
    useGetValidateInvestmentTestAnswers,
} from 'hooks/api/onboarding.hooks';
import { scrollToTop } from 'utils/helpers/commonHelper';
import { setUserDataStorage } from 'storage/auth.storage';
import { UserInfoLoginResponse } from 'types/api/auth.types';
import { checkIfInvestorProfileIsExpired } from 'utils/helpers/dateHelper';
import { OnboardingContext } from 'context/onboarding.context';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (data?: QuestionsInvestmentById) => ({
    questions: data?.questions,
});

const getValidationSchema = () =>
    Yup.object().shape({
        questions: Yup.object().test(
            'questions',
            i18n.t('select_an_answer', tErrorsContext) as string,
            value => {
                const questionsKeys = Object.keys(value ?? {});

                const questionAnswers: Array<boolean> = questionsKeys.map(el => {
                    const obj = value[el] as Question;
                    let optionSelection = false;

                    for (const key in obj.questionOptions) {
                        if (obj.questionOptions[key].userChoice === true) {
                            optionSelection = true;
                            break;
                        }
                    }
                    return optionSelection;
                });
                return !questionAnswers.includes(false);
            },
        ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const OnboardingInvestmentTestPageContainer = (props: Props) => {
    // const { } = props;
    const location = useLocation();

    const { userData, setUserData } = React.useContext(AuthContext);
    const isOnboarding = location.pathname.includes('onboarding');
    const { isLoadingLegalData, isLoadingSteps } = React.useContext(OnboardingContext);
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = React.useState('1');
    const { data, isLoading: isLoadingInvestmentTestQuestions } = useGetInvestmentTestQuestions();
    const {
        mutate: validateInvestmentTestAnswers,
        // data: validateInvestmentTestQuestions,
        isLoading: isLoadingValidate,
        errorMessage,
    } = useGetValidateInvestmentTestAnswers();

    const investorProfileIsExpired = React.useMemo(
        () => checkIfInvestorProfileIsExpired(userData?.info?.investorProfileExpirationDate!),
        [userData?.info?.investorProfileExpirationDate],
    );

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const formData = {
                items: questionsInvestmentAdapterToValidateAnswers(values.questions),
            };
            validateInvestmentTestAnswers(formData, {
                onSuccess(response) {
                    if (userData?.info) {
                        userData.info.investmentProfile = response.data.investmentProfile;

                        const today = new Date();
                        const nextYear = new Date(today);
                        nextYear.setFullYear(today.getFullYear() + 1);

                        const updatedInfo: UserInfoLoginResponse = {
                            ...userData.info,
                            investmentProfile: response.data.investmentProfile,
                            investorProfileUpdatedAt: today.toISOString(),
                            investorProfileExpirationDate: nextYear.toISOString(),
                        };

                        setUserData({ ...userData, info: updatedInfo });
                        setUserDataStorage({ ...userData, info: updatedInfo });
                    }
                    scrollToTop();
                    if (isOnboarding) {
                        navigate(onboardingRoutesPaths.investmentTestResult, {
                            state: {
                                investmentProfile: response.data.investmentProfile,
                            },
                        });
                        return;
                    }
                    navigate(mainRoutesPaths.investmentTestResult);
                },
            });
        },
        [validateInvestmentTestAnswers],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(data),
                validateOnChange: true,
                validateOnBlur: false,
                validateOnMount: true,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, data],
    );

    const formik = useFormik(formikInitProps);
    const { setFieldValue, values } = formik;

    const handleCheck = (field: string, questionKey: string) => {
        if (isLoadingValidate) return;
        const questionOptions = values.questions?.[questionKey]?.questionOptions as Option;

        if (questionOptions?.[field]?.userChoice) {
            setFieldValue(`questions.${questionKey}.selectedOption`, false);
            return setFieldValue(
                `questions.${questionKey}.questionOptions.${field}.userChoice`,
                false,
            );
        }
        let currentKeyWithTrue;
        Object.keys(values.questions?.[questionKey]?.questionOptions ?? {}).forEach(key => {
            if (questionOptions?.[key]?.userChoice) currentKeyWithTrue = key;
        });
        if (currentKeyWithTrue) {
            setFieldValue(
                `questions.${questionKey}.questionOptions.${currentKeyWithTrue}.userChoice`,
                false,
            );
        }
        setFieldValue(`questions.${questionKey}.questionOptions.${field}.userChoice`, true);
        if (!values.questions?.[questionKey]?.selectedOption)
            setFieldValue(`questions.${questionKey}.selectedOption`, true);
    };

    const handleChangeQuestion = (next: boolean = false) => {
        const prev = Number(currentStep);
        const current = next ? prev + 1 : prev - 1;

        setFieldValue(`questions.${prev}.current`, false);
        setFieldValue(`questions.${current}.current`, true);
        setCurrentStep(`${current}`);
    };

    const childProps = {
        ...props,
        handleCheck,
        handleChangeQuestion,
        isLoadingInvestmentTestQuestions,
        isLoadingValidate,
        errorMessage,
        formik,
        currentStep,
        t,
        isOnboarding,
        navigate,
        investorProfileIsExpired,
        isLoading: isLoadingLegalData || isLoadingSteps,
    };

    return <OnboardingInvestmentTestPage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    prevTest?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestPageContainer.propTypes = propTypes;

export default OnboardingInvestmentTestPageContainer;
