import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MenuItem from './MenuItem';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const MenuItemContainer = (props: Props) => {
    const { itemData, selectedMenu } = props;
    const hasSubroutes = (itemData.subRoutes && itemData.subRoutes?.length > 0) ?? false;
    const isSubmenuSelected = itemData.subRoutes?.find(x => x.name == selectedMenu) != null;
    const menuIsSelected = selectedMenu === itemData.name || isSubmenuSelected;
    const [isCollapseOpen, setIsCollapseOpen] = React.useState<boolean>(isSubmenuSelected);

    const childProps = {
        ...props,
        isCollapseOpen,
        setIsCollapseOpen,
        hasSubroutes,
        menuIsSelected,
        selectedMenu,
    };

    return <MenuItem {...childProps} />;
};

const propTypes = {
    drawerOpened: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

interface extraProps {
    itemData: SubRouteInfo;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MenuItemContainer.propTypes = propTypes;

export default MenuItemContainer;
