import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const CustomPieChart = (props: Props) => {
    const { options, series, width, matchesMd, id = 'big-chart' } = props;

    return (
        <div id={id}>
            <Chart
                options={options}
                series={series}
                type="donut"
                width={matchesMd ? '500' : (width ?? '380')}
            />
        </div>
    );
};

const propTypes = {
    matchesMd: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
};

interface extraProps {
    options: ApexOptions;
    series: number[];
    width?: string | number;
    height?: string | number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomPieChart.propTypes = propTypes;

export default CustomPieChart;
