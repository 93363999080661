import { Box, styled } from '@mui/material';

export default {
    BoxModal: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '40px',
            backgroundColor: 'white',
            borderRadius: '10px',
            width: { xs: '70%', sm: '80%' },
            maxWidth: '400px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }),
    ),
    ContainerImage: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })),
};
