import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { DialogActions } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const DeleteOrderModal = ({ isLoading, close, t, onSubmit }: Props) => (
    <>
        {t('delete_order_message')}
        <DialogActions>
            <ActionButton variant="outlined" disabled={isLoading} onClick={close}>
                {t('close', tCommon)}
            </ActionButton>
            <ActionButton
                variant="contained"
                disabled={isLoading}
                loading={isLoading}
                onClick={onSubmit}>
                {t('confirm', tCommon)}
            </ActionButton>
        </DialogActions>
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

interface extraProps {
    isLoading: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteOrderModal.propTypes = propTypes;

export default DeleteOrderModal;
