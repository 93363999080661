import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MenuItem.styled';
import { List } from '@mui/material';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import ArrowDownIcon from 'assets/icons/arrow-down-s-line.svg?react';
import ArrowUpIcon from 'assets/icons/arrow-up-s-line.svg?react';

const MenuItem = (props: Props) => {
    const {
        drawerOpened,
        isCollapseOpen,
        itemData,
        selectedMenu,
        handleNavigate,
        setIsCollapseOpen,
        hasSubroutes,
        menuIsSelected,
    } = props;

    return (
        <St.ListItem dense disablePadding>
            <St.DrawerMenuButton
                onClick={() =>
                    hasSubroutes ? setIsCollapseOpen(!isCollapseOpen) : handleNavigate(itemData)
                }
                selected={menuIsSelected}>
                {hasSubroutes && drawerOpened && (
                    <St.MenuItemIcon
                        className="icon"
                        ownerState={{
                            isSelected: menuIsSelected,
                            drawerOpened,
                            isArrowIcon: true,
                        }}>
                        {isCollapseOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </St.MenuItemIcon>
                )}
                <St.MenuItemText primary={itemData.title} drawerOpened={drawerOpened} />
            </St.DrawerMenuButton>
            {hasSubroutes && (
                <St.Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                    <List component="div" dense disablePadding>
                        {itemData.subRoutes?.map(subRoute => {
                            return (
                                <St.DrawerSubmenuButton
                                    key={subRoute.name}
                                    onClick={() => handleNavigate(subRoute)}
                                    selected={subRoute.name === selectedMenu}>
                                    <St.MenuItemText
                                        primary={subRoute.title}
                                        drawerOpened={drawerOpened}
                                    />
                                </St.DrawerSubmenuButton>
                            );
                        })}
                    </List>
                </St.Collapse>
            )}
        </St.ListItem>
    );
};

const propTypes = {
    drawerOpened: PropTypes.bool.isRequired,
    isCollapseOpen: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    setIsCollapseOpen: PropTypes.func.isRequired,
    hasSubroutes: PropTypes.bool.isRequired,
    menuIsSelected: PropTypes.bool.isRequired,
};

interface extraProps {
    itemData: SubRouteInfo;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MenuItem.propTypes = propTypes;
MenuItem;

export default MenuItem;
