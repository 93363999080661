import { PENDING } from 'constants/appConstants';
import { useLogout } from 'hooks/api/auth.hooks';
import { useGetDeclarations } from 'hooks/api/declarations.hooks';
import { useGetChannelMarketAccounts } from 'hooks/api/marketAccount.hooks';
import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';
import {
    getSelectedCustomerStorage,
    getIsAuthenticated,
    getUserData,
    setSelectedCustomerStorage,
    setMarketAccountsStorage,
    setUserDataStorage,
} from 'storage/auth.storage';
import { UserData } from 'types/api/auth.types';
import { DeclarationItem } from 'types/api/declarations.types';
import { MarketAccount } from 'types/api/marketAccount.types';
import { UserType } from 'types/common/users.types';
import { checkIfInvestorProfileIsExpired } from 'utils/helpers/dateHelper';

interface IntialState {
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
    userType: UserType | null;
    userData: UserData | null;
    setUserData: Dispatch<SetStateAction<UserData | null>>;
    selectedCustomer: MarketAccount | null;
    setSelectedCustomer: Dispatch<SetStateAction<MarketAccount | null>>;
    isTestExpired: boolean;
    isTermsAccepted: boolean;
    marketAccounts: MarketAccount[] | null;
    isLoadingMarketAccounts: boolean;
    errorMarketAccounts: string | null;
    customerCode: string | null;
    forceMFA: boolean;
    declarations?: DeclarationItem[];
    getUpdatedDeclarations(): void;
}

export const AuthContext = createContext({} as IntialState);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { mutate: logout } = useLogout();
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(getIsAuthenticated());
    const [isTestExpired, setIsTestExpired] = React.useState<boolean>(false);
    const [isTermsAccepted, setIsTermsAccepted] = React.useState<boolean>(true);
    const [userData, setUserData] = React.useState(getUserData() ?? null);
    const [selectedCustomer, setSelectedCustomer] = React.useState<MarketAccount | null>(
        getSelectedCustomerStorage() ?? null,
    );
    const [declarations, setDeclarations] = useState([] as DeclarationItem[]);
    const userType = React.useMemo(() => {
        return userData?.userType ?? null;
    }, [userData]);

    const [marketAccounts, setMarketAccounts] = React.useState<MarketAccount[] | null>(null);
    const customerCode = React.useMemo(() => {
        if (!selectedCustomer) return null;
        return selectedCustomer?.customerCode;
    }, [selectedCustomer]);

    const {
        mutate: getMarketAccounts,
        isLoading: isLoadingMarketAccounts,
        errorMessage: errorMarketAccounts,
    } = useGetChannelMarketAccounts();

    const { mutate: getDeclarations } = useGetDeclarations();

    const forceMFA = React.useMemo(() => {
        if (
            ['APP_LEGAL', 'APP_PHYSICAL'].includes(userType || '') &&
            !userData?.onboardingPending
        ) {
            return userData?.mfaType === null;
        }
        return false;
    }, [userType, userData]);

    const handleGetMarketAccounts = () => {
        getMarketAccounts(
            {},
            {
                onSuccess(data) {
                    setMarketAccounts(
                        data.data.items.map(
                            m =>
                                ({
                                    ...m,
                                    id: m.customerCode,
                                }) as MarketAccount,
                        ),
                    );
                },
            },
        );
    };

    const getUpdatedDeclarations = useCallback(() => {
        setDeclarations([]);
        getDeclarations(PENDING, { onSuccess: ({ data }) => setDeclarations(data.items) });
    }, [getDeclarations]);

    React.useEffect(() => {
        if (forceMFA && location.pathname !== '/home') {
            navigate(mainRoutesPaths.home);
        }
    }, [forceMFA, location.pathname, navigate]);

    React.useEffect(() => {
        if (marketAccounts?.length && !selectedCustomer) {
            setSelectedCustomer(marketAccounts[0]);
            setMarketAccountsStorage(marketAccounts);
        }
    }, [marketAccounts]);

    React.useEffect(() => {
        if (userData?.id) {
            setUserDataStorage({ ...userData } as UserData);
            if (userData?.marketAccounts?.length) {
                // Para cuando soy un usuarioFinal
                const userMarketAccount = userData?.marketAccounts[0];
                setSelectedCustomer({
                    id: userData?.id,
                    name: userData?.name,
                    email: userData?.email,
                    username: userData?.username,
                    last_name: userData?.lastName,
                    customerCode: userMarketAccount.customerCode,
                    aum_ars: 0,
                    available_operate_ars_term_0: 0,
                    available_operate_ars_term_1: 0,
                    available_operate_usd_term_0: 0,
                    available_operate_usd_term_1: 0,
                    available_operate_usdc_term_0: 0,
                    available_operate_usdc_term_1: 0,
                    is_qualified: false, // TODO: Ver que hacer con estos datos
                    tariff_sector: '', // TODO: Ver que hacer con estos datos
                    uuid: userData?.id,
                    shareholderCode: null,
                    user_type: userData.userType,
                    hasExteriorAccount: false,
                    isFavorite: true,
                });
            }
            getDeclarations(PENDING, { onSuccess: ({ data }) => setDeclarations(data.items) });
        }
    }, [userData]);

    React.useEffect(() => {
        if (['CHANNEL', 'ASSISTANT'].includes(userType || '')) {
            handleGetMarketAccounts();
        } else if (
            ['APP_LEGAL', 'APP_PHYSICAL'].includes(userType || '') &&
            !userData?.onboardingPending
        ) {
            setIsTestExpired(
                checkIfInvestorProfileIsExpired(
                    userData?.info?.investorProfileExpirationDate ?? '',
                ),
            );
            setIsTermsAccepted(userData?.hasAcceptedTermsConditions ?? false);
        }
    }, [userType, userData]);

    React.useEffect(() => {
        if (isAuthenticated && userType === null) {
            logout();
        }
    }, [isAuthenticated, userType, logout]);

    React.useEffect(() => {
        if (selectedCustomer) setSelectedCustomerStorage(selectedCustomer);
    }, [selectedCustomer]);

    const memoedValue = React.useMemo(() => {
        const value = {
            isAuthenticated,
            isTestExpired,
            setIsAuthenticated,
            userType,
            userData,
            setUserData,
            isTermsAccepted,
            customerCode,
            selectedCustomer,
            setSelectedCustomer,
            marketAccounts,
            isLoadingMarketAccounts,
            errorMarketAccounts,
            forceMFA,
            declarations,
            getUpdatedDeclarations,
        };
        return value;
    }, [
        isAuthenticated,
        setIsAuthenticated,
        userType,
        userData,
        setUserData,
        selectedCustomer,
        setSelectedCustomer,
        isTestExpired,
        isTermsAccepted,
        marketAccounts,
        isLoadingMarketAccounts,
        errorMarketAccounts,
        customerCode,
        forceMFA,
        declarations,
        getUpdatedDeclarations,
    ]);

    return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
