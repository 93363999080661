import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './VisualizeDeclaration.styled';
import ActionButton from 'components/common/ActionButton';
import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import CircularSpinner from 'components/common/CircularSpinner';
import ErrorText from 'components/common/ErrorText';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';

const VisualizeDeclaration = (props: Props) => {
    const {
        t,
        showDDJJ,
        acceptDDJJ,
        setShowDDJJ,
        setAcceptDDJJ,
        declarationHtml,
        errorGetDeclaration,
        isLoadingDeclaration,
    } = props;
    return (
        <>
            <St.Container>
                <FormControlLabel
                    checked={acceptDDJJ}
                    onClick={() => setAcceptDDJJ(!acceptDDJJ)}
                    control={<Checkbox />}
                    label={t('ddjj_modal_check')}
                />
                <ActionButton sx={{ margin: 0 }} onClick={() => setShowDDJJ(true)}>
                    {t('ddjj_modal_open_dj')}
                </ActionButton>
            </St.Container>
            <Dialog fullWidth open={showDDJJ} onClose={() => setShowDDJJ(false)}>
                <St.HTMLContainer>
                    <St.CloseIconButton aria-label="close" onClick={() => setShowDDJJ(false)}>
                        <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                    </St.CloseIconButton>
                    {isLoadingDeclaration ? (
                        <CircularSpinner />
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: declarationHtml || '',
                            }}
                        />
                    )}
                    {errorGetDeclaration && <ErrorText text={errorGetDeclaration} />}
                    <ActionButton sx={{ width: '100%' }} onClick={() => setShowDDJJ(false)}>
                        {t('ddjj_modal_close_dj')}
                    </ActionButton>
                </St.HTMLContainer>
            </Dialog>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    showDDJJ: PropTypes.bool.isRequired,
    acceptDDJJ: PropTypes.bool.isRequired,
    setShowDDJJ: PropTypes.func.isRequired,
    setAcceptDDJJ: PropTypes.func.isRequired,
    isLoadingDeclaration: PropTypes.bool.isRequired,
};

interface extraProps {
    declarationHtml?: string;
    errorGetDeclaration: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
VisualizeDeclaration.propTypes = propTypes;

export default VisualizeDeclaration;
