import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FavoriteButton.styled';

const FavoriteButton = (props: Props) => {
    const { handleFavorite, favorite, SnackBar } = props;

    return (
        <>
            <St.IconContainer onClick={handleFavorite}>
                {favorite ? <St.StarFilled /> : <St.StarEmpty />}
            </St.IconContainer>
            <SnackBar />
        </>
    );
};

const propTypes = {
    favorite: PropTypes.bool.isRequired,
    handleFavorite: PropTypes.func.isRequired,
};

interface extraProps {
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FavoriteButton.propTypes = propTypes;

export default FavoriteButton;
