import { Box, styled, Typography } from '@mui/material';

export default {
    CardQuotes: styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.custom.vibrantblue10,
        borderRadius: '40px',
        width: '249px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '4px',
    })),

    ContainerCurrencies: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '70%',
        padding: '0 10px',
    })),
    QuotesNumber: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue3,
        fontWeight: '500',
        fontSize: '20px',
    })),
};
