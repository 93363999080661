import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './PersonalDataItem.styled';

const PersonalDataItem = (props: Props) => {
    const { title, description, breakPoints = { xs: 12, md: 5 } } = props;
    return (
        <St.GriItem item {...breakPoints}>
            <St.Title>{title}</St.Title>
            <St.Description>{description}</St.Description>
        </St.GriItem>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    title: string;
    description?: string;
    breakPoints?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PersonalDataItem.propTypes = propTypes;

export default PersonalDataItem;
