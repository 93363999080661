import * as React from 'react';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FunctionComponent } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { UserType } from 'types/common/users.types';
export interface RouteInfo {
    // Identificador interno. Debe ser único
    name: string;
    // URL de la ruta
    path?: string;
    // Nombre visible de la ruta
    title?: string;
    // Componente de la pagina, en caso de ser de tipo "screen"
    component?: FunctionComponent<any> | null;
    // Subrutas del navigator (drawer, bottomTabs, topTabs, stack)
    subRoutes?: SubRouteInfo[] | null;
    // Indica si la ruta es accesible estando anonimo, autenticado o ambos
    availability?:
        | 'logged_out'
        | 'logged_in'
        | 'public'
        | 'onboarding_logged_in'
        | 'channel_logged_in';
}

export interface SubRouteInfo extends RouteInfo {
    // Icono del punto de menu
    icon?:
        | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
              muiName: string;
          })
        | (() => JSX.Element)
        | any
        | null;
    handleClick?: () => void | undefined;
}

const authenticatedDefaultRoute = (route: string) => (
    <Route key="default" path="*" element={<Navigate to={route} replace />} />
);

export const processRoutesRecursively = (
    routes: RouteInfo[],
    isAuthenticated: boolean,
    isOnboarding: boolean,
    userType: UserType | null,
    isInvestorTestExpired: boolean,
) => {
    const result = [];

    if (isInvestorTestExpired && isAuthenticated) {
        const investmentTestRoute = routes
            .find(route => route.name === 'main_layout')
            ?.subRoutes?.find(route => route.name === 'profile')
            ?.subRoutes?.find(route => route.name === 'investmentTestQuestions');
        const investmentTestResultRoute = routes
            .find(route => route.name === 'main_layout')
            ?.subRoutes?.find(route => route.name === 'profile')
            ?.subRoutes?.find(route => route.name === 'investmentTestResult');
        const testExpiredRoute = routes
            .find(route => route.name === 'main_layout')
            ?.subRoutes?.find(route => route.name === 'profile')
            ?.subRoutes?.find(route => route.name === 'testExpired');

        if (investmentTestRoute) {
            const Comp = investmentTestRoute.component;
            result.push(
                <Route
                    key={investmentTestRoute.name}
                    path={investmentTestRoute.path}
                    element={Comp && <Comp />}
                />,
            );
        }
        if (investmentTestResultRoute) {
            const Comp = investmentTestResultRoute.component;
            result.push(
                <Route
                    key={investmentTestResultRoute.name}
                    path={investmentTestResultRoute.path}
                    element={Comp && <Comp />}
                />,
            );
        }

        if (testExpiredRoute) {
            const Comp = testExpiredRoute.component;
            result.push(
                <Route
                    key={testExpiredRoute.name}
                    path={testExpiredRoute.path}
                    element={Comp && <Comp />}
                />,
            );
            result.push(
                <Route
                    key="redirect-to-test"
                    path="*"
                    element={<Navigate to={testExpiredRoute.path!} replace />}
                />,
            );
        }

        return result;
    }

    for (let index = 0; index < routes.length; index++) {
        const r = routes[index];
        const isFinalUser =
            isAuthenticated &&
            !isOnboarding &&
            r.availability === 'logged_in' &&
            (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL');
        const isChannelUser =
            isAuthenticated &&
            !isOnboarding &&
            r.availability === 'channel_logged_in' &&
            (userType === 'CHANNEL' || userType === 'ASSISTANT');
        const isOnboaringUser =
            isAuthenticated && isOnboarding && r.availability === 'onboarding_logged_in';
        const isLoggedOutUser = !isAuthenticated && r.availability === 'logged_out';

        if (
            r.availability == null ||
            r.availability == 'public' ||
            isFinalUser ||
            isChannelUser ||
            isOnboaringUser ||
            isLoggedOutUser
        ) {
            let subRoutes = null;
            if (r.subRoutes != null && r.subRoutes.length > 0) {
                subRoutes = processRoutesRecursively(
                    r.subRoutes,
                    isAuthenticated,
                    isOnboarding,
                    userType,
                    isInvestorTestExpired,
                );
            }

            const Comp = r.component ?? undefined;

            const routeProps = {
                element: Comp ? <Comp /> : undefined,
                path: r.path ?? undefined,
            };

            const routeComp = (
                <Route key={r.name} {...routeProps}>
                    {subRoutes}
                </Route>
            );

            result.push(routeComp);
        }
    }

    result.push(isAuthenticated && authenticatedDefaultRoute(result[0].props.path));

    return result;
};
