import { axiosCmsInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { CMSGetCategoriesResponse, CMSGetRedirectsResponse } from 'types/api/cms.types';

const apiName = 'cms';

export default {
    getCmsCategories: () =>
        withFakeData<CMSGetCategoriesResponse>(
            () => axiosCmsInstance.get(`/categories?populate=children&populate=parent&sort=name`),
            apiName,
            'cms-categories',
            false,
            500,
        ),
    getCmsRedirects: () =>
        withFakeData<CMSGetRedirectsResponse>(
            () => axiosCmsInstance.get(`/redirects?populate=category&populate=file`),
            apiName,
            'cms-redirects',
            false,
            500,
        ),
};
