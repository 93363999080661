import * as React from 'react';
import PropTypes from 'prop-types';
import { NavigateFunction } from 'react-router-dom';
import { Dialog } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import CustomIconCreator from 'components/common/CustomIconCreator';
import { tCommon } from 'constants/appConstants';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { pxToRem } from 'utils/helpers/stylesHelper';
import defaultTheme from 'utils/theme/defaultTheme';
import InfoIcon from 'assets/icons/information-line.svg?react';
import St from './CollateralModal.styled';
import { GetCollateralItems } from 'types/api/orders.types';

const CollateralModal = (props: Props) => {
    const { open, title, t, setOpen, navigate, collateral, hasCollateral } = props;

    return (
        <St.ModalTriggerContainer>
            {hasCollateral ? (
                <St.ModalTrigger>
                    <CustomIconCreator
                        icon={InfoIcon}
                        sx={{
                            margin: `0 ${pxToRem(10)} 0 ${pxToRem(14)}`,
                            color: defaultTheme.palette.custom.vibrantBlue,
                        }}
                    />
                    <St.ModalTitleTrigger typography="h6">
                        {collateral.length === 1
                            ? t('collateral_modal_trigger_title_1')
                            : t('collateral_modal_trigger_title_2', { number: collateral.length })}
                    </St.ModalTitleTrigger>
                    <St.ButtonTrigger typography="h6" onClick={() => setOpen(true)}>
                        {t('collateral_modal_trigger_button')}
                    </St.ButtonTrigger>
                </St.ModalTrigger>
            ) : null}

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                <St.DialogContent>
                    <St.ModalTitle>{title}</St.ModalTitle>
                    {collateral.map(client => (
                        <St.Ul key={client.userUuid}>
                            <St.Li>
                                {client.customer.toUpperCase()} - {client.customerCode}
                            </St.Li>
                        </St.Ul>
                    ))}
                </St.DialogContent>
                <St.DialogActions>
                    <ActionButton
                        sx={{
                            width: { xs: pxToRem('250px'), md: pxToRem('220px') },
                            margin: '0.5rem',
                        }}
                        onClick={() => navigate('/reports-clients/collaterals')}
                        variant="contained">
                        {t('collateral_modal_calendar')}
                    </ActionButton>
                    <ActionButton
                        sx={{ margin: '0.5rem' }}
                        onClick={() => setOpen(false)}
                        color="error"
                        variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                </St.DialogActions>
            </Dialog>
        </St.ModalTriggerContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    title: string;
    navigate: NavigateFunction;
    hasCollateral: boolean;
    collateral: GetCollateralItems[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CollateralModal.propTypes = propTypes;

export default CollateralModal;
