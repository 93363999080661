export const VALID_PASSWORD_REGEX =
    /^(?=.*?[A-Z])(?=.*[\u0021-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
export const NUMBER_REGEX = /[^0-9]/;
export const REGEX_0_TO_1 = /^[0-1]+$/;
export const REGEX_0_TO_9 = /^[0-9]*$/;
export const VALID_PHONE_NUMBER_REGEX = /^(?:11|2\d{1,2}|3\d{1,2})(\d{7,8})$/;
export const REGEX_PRICE_3_DECIMALS = /^\d*(,\d{0,3})?$/;
export const REGEX_PRICE_2_DECIMALS = /^\d*(,\d{0,2})?$/;
export const REGEX_IS_MOBILE = /Mobi|Android/i;
export const REGEX_ONE_CAPITAL_LETTER = /[A-Z]/;
export const REGEX_ONE_LOWER_LETTER = /[a-z]/;
export const REGEX_ONE_NUMBER = /[0-9]/;
export const REGEX_SPECIAL_CHARACTER = /[!¡;`'[@#$%^&*(),¿.?":{}|+<>=-]/;
