import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ReportsOperationDetailModal.styled';
import { Box } from '@mui/material';
import { DailyOperation } from 'types/pages/reports.types';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';

const ReportsOperationDetailModal = (props: Props) => {
    const { t, row } = props;
    const currency = row.currency === 'ARS' ? 'ARS' : 'USD';

    return (
        <Box>
            <Box>
                <St.Row>
                    <St.Label>{t('daily_operations_market')}</St.Label> {row.market ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_instrument_type')}</St.Label>
                    {row.instrumentType ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_operation_type_description')}</St.Label>
                    {row.operationTypeDescription ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_ticket_number')}</St.Label>
                    {row.ticketNumber ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_agreement_date')}</St.Label>
                    {formatSingleDate(row.agreementDate) ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_customer_code')}</St.Label>
                    {row.customerCode ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_customer_denomination')}</St.Label>
                    {row.customerDenomination ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_instrument_description')}</St.Label>
                    {row.instrumentDescription ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_ticker')}</St.Label> {row.ticker ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_currency_description')}</St.Label>
                    {row.currencyDescription ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_amount')}</St.Label>
                    {row.amount ? formatNumber(row.amount) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_price')}</St.Label>
                    {row.price ? convertCurrencyFormat(row.price, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_interest')}</St.Label>
                    {row.interest ? convertCurrencyFormat(row.interest, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_gross')}</St.Label>
                    {row.gross ? convertCurrencyFormat(row.gross, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_tariff')}</St.Label>
                    {row.tariff ? convertCurrencyFormat(row.tariff, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_market_fee')}</St.Label>
                    {row.marketFee ? convertCurrencyFormat(row.marketFee, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_tariff_vat')}</St.Label>
                    {row.tariffVat ? convertCurrencyFormat(row.tariffVat, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_net')}</St.Label>
                    {row.net ? convertCurrencyFormat(row.net, currency) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_liquidation_date')}</St.Label>
                    {formatSingleDate(row.liquidationDate) ?? '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_tariff_percentage')}</St.Label>
                    {row.tariffPercentage ? formatNumber(row.tariffPercentage) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_term')}</St.Label>
                    {row.term ? formatNumber(row.term) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_counterparty_tariff_vat')}</St.Label>
                    {row.counterpartyTariffVat ? formatNumber(row.counterpartyTariffVat) : '-'}
                </St.Row>
                <St.Row>
                    <St.Label>{t('daily_operations_settlement_registration_fee')}</St.Label>
                    {row.settlementRegistrationFee
                        ? formatNumber(row.settlementRegistrationFee)
                        : '-'}
                </St.Row>
            </Box>
        </Box>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

interface extraProps {
    row: DailyOperation;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsOperationDetailModal.propTypes = propTypes;

export default ReportsOperationDetailModal;
