import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HomeWelcome from './HomeWelcome';
import { mainRoutesPaths } from 'router/routesPaths';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeWelcomeContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');
    const navigate = useNavigate();

    const steps = [
        {
            number: 1,
            title: t('wecolme_step_1'),
            action: () => navigate(mainRoutesPaths.bankAccounts),
        },
        {
            number: 2,
            title: t('wecolme_step_2'),
            action: () => navigate(mainRoutesPaths.deposit),
        },
        {
            number: 3,
            title: t('wecolme_step_3'),
            action: () => navigate(mainRoutesPaths.briefcase),
        },
    ];

    const childProps = {
        ...props,
        steps,
        t,
    };

    return <HomeWelcome {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomeWelcomeContainer.propTypes = propTypes;

export default HomeWelcomeContainer;
