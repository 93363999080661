import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { AlertColor, Box, MenuItem, Select } from '@mui/material';
import BankAccountForm from 'components/pages/ProfileBankAccountPage/BankAccountForm';
import { BankAccountsTypes } from 'types/api/cashflow.types';
import CreateExteriorBankAccount from '../CreateExteriorBankAccount';
import { BROKER, DESTINATION, LOCAL } from 'constants/appConstants';

const exteriorOptions = [BROKER, DESTINATION];

const CreateBankAccountModal = ({
    options,
    selectedOption,
    setSelectedOption,
    setSnackBarMessage,
    close,
    t,
}: Props) => (
    <Box display="flex" flexDirection="column">
        <Select
            displayEmpty
            size="small"
            value={selectedOption}
            onChange={({ target: { value } }) => setSelectedOption(value)}>
            <MenuItem value="" disabled>
                {t('select_type_account')}
            </MenuItem>
            {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>

        {selectedOption === LOCAL && (
            <BankAccountForm setSnackBarMessage={setSnackBarMessage} close={close} />
        )}
        {exteriorOptions.includes(selectedOption) && (
            <CreateExteriorBankAccount
                type={selectedOption as BankAccountsTypes}
                close={close}
                setSnackBarMessage={setSnackBarMessage}
            />
        )}
    </Box>
);
const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    options: { value: string; label: string }[];
    selectedOption: string;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    setSelectedOption(selectedOption: string): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateBankAccountModal.propTypes = propTypes;

export default CreateBankAccountModal;
