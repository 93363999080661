import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HelpTooltip.styled';
import QuestionIcon from '../../../assets/svg/questionIcon.svg?react';
import { Link } from 'react-router-dom';

const HelpTooltip = (props: Props) => {
    // const { } = props;

    return (
        <Link to="/help">
            <St.ContainerIcon>
                <QuestionIcon fontSize={'150px'} />
            </St.ContainerIcon>
        </Link>
    );
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HelpTooltip.propTypes = propTypes;

export default HelpTooltip;
