import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomLabel from './CustomLabel';

const CustomLabelContainer = (props: Props) => {
    const { id } = props;

    const childProps = {
        ...props,
    };

    return <CustomLabel {...childProps} />;
};

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

interface extraProps {
    value: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomLabelContainer.propTypes = propTypes;

export default CustomLabelContainer;
