import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DisagreeModal.styled';
import PropTypes from 'prop-types';
import { Form, FormikContextType, FormikProvider } from 'formik';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomIconCreator from 'components/common/CustomIconCreator';
import { pxToRem } from 'utils/helpers/stylesHelper';

const DisagreeModal = (props: Props) => {
    const { t, openModal, handleClose, isLoadingSubmit, formik, enableButton } = props;

    const { errors, values, setFieldValue } = formik;

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}>
            <FormikProvider value={formik}>
                <Form>
                    <St.DialogTitle id="customized-dialog-title">
                        {t('disagree_modal_title')}
                        <St.IconButton aria-label="close" onClick={handleClose}>
                            <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                        </St.IconButton>
                    </St.DialogTitle>
                    <DialogContent sx={{ py: 1, px: 3 }}>
                        <DialogContentText marginBottom={pxToRem(15)}>
                            {t('disagree_modal_text')}
                        </DialogContentText>
                        <CustomInput
                            field="rejectPersonalReason"
                            values={values?.rejectPersonalReason}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            label={t('disagree_modal_text_reason')}
                            placeholder={t('disagree_modal_text_reason')}
                            autoFocus
                            multiline
                            rows={5}
                            maxLength={250}
                        />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                        <ActionButton
                            type="submit"
                            variant="contained"
                            sx={{ width: '30%', px: 3, alignSelf: 'center' }}
                            disabled={!enableButton}
                            loading={isLoadingSubmit}>
                            {t('send_button')}
                        </ActionButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    handleClose: () => void;
    setOpenModal: (state: boolean) => void;
    formik: FormikContextType<any>;
    enableButton: boolean;
    isLoadingSubmit: boolean;
    handlePersonalData: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DisagreeModal.prototype = propTypes;

export default DisagreeModal;
