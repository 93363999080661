import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import St from './TermsConditionsModal.styled';
import ErrorText from '../ErrorText';

const HtmlRenderer = ({ htmlContent }: { htmlContent: string | undefined }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent || '' }} />;
};

const TermsConditionsModal = (props: Props) => {
    const { open, t, handleAcceptTerms, terms, errorMessage, isLoading } = props;

    return (
        <Dialog open={open} disableEscapeKeyDown>
            <St.DialogTitle typography="h6">{t('terms_modal_title')}</St.DialogTitle>
            <DialogContent>
                <HtmlRenderer htmlContent={terms} />
            </DialogContent>
            <DialogActions>
                <ActionButton
                    onClick={() => handleAcceptTerms()}
                    variant="contained"
                    size="large"
                    type="button"
                    sx={{ width: '100%' }}
                    disabled={isLoading}
                    loading={isLoading}>
                    {t('terms_modal_accept_terms')}
                </ActionButton>
            </DialogActions>
            {errorMessage && <ErrorText text={errorMessage} />}
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    handleAcceptTerms: () => void;
    terms: string | undefined;
    errorMessage: string | null;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TermsConditionsModal.propTypes = propTypes;

export default TermsConditionsModal;
