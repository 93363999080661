import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MainLayout from './MainLayout';
import { AuthContext } from 'context/auth.context';
import { mainRoutes } from 'router/subRoutes/mainLayout';
import { channelRoutes } from 'router/subRoutes/channelLayout';
import useScreenWidth from 'hooks/common/screenWidth';
import { useLocation } from 'react-router-dom';
import { GROUPS_WITH_CHANNEL_LAYOUT } from 'constants/appConstants';

const MainLayoutContainer = (props: Props) => {
    // const {} = props;
    const { isChannel } = props;
    const screenWidth = useScreenWidth();
    const { isTestExpired, forceMFA, isAuthenticated, isTermsAccepted } =
        React.useContext(AuthContext);

    const { pathname } = useLocation();

    const hasAccessToChannelLayout = React.useMemo(() => {
        const group = pathname.split('/')[1];
        return GROUPS_WITH_CHANNEL_LAYOUT.includes(group);
    }, [pathname]);

    const childProps = {
        ...props,
        isAuthenticated,
        isTermsAccepted,
        routes: isChannel ? channelRoutes : mainRoutes,
        isTestExpired,
        isChannel,
        isMobile: !(screenWidth > (isChannel ? 1330 : 1050)),
        forceMFA,
        hasAccessToChannelLayout,
    };

    return <MainLayout {...childProps} />;
};

const propTypes = {
    isChannel: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayoutContainer.propTypes = propTypes;

export default MainLayoutContainer;
