import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomModalAuth from './CustomModalAuth';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import PropTypes from 'prop-types';

const CustomModalAuthContainer = (props: Props) => {
    const { sendEmailCode, handleSubmit, apiError, handleClose, open } = props;
    const [otp, setOtp] = React.useState('');
    const [otpError, setOtpError] = React.useState<string | null>(null);
    const { t } = useTranslation();
    const handleChange = (value: string) => {
        setOtp(value);
    };
    React.useEffect(() => {
        if (open) {
            sendEmailCode();
        }
        return () => {
            setOtpError(null);
        };
    }, [open]);

    const onSubmit = async () => {
        if (otp.length !== 6) {
            setOtpError(t('otp_code_length', tErrorsContext));
            return;
        }
        await handleSubmit(otp);
        handleClose && handleClose();
    };

    const childProps = {
        ...props,
        handleChange,
        otp,
        t,
        onSubmit,
        errorOtp: apiError || otpError,
    };

    return <CustomModalAuth {...childProps} />;
};

const propTypes = {
    handleClose: PropTypes.func,
};

interface extraProps {
    open: boolean;
    handleSubmit: (otp: string) => void;
    title?: string;
    buttonText?: string;
    sendEmailCode: () => void;
    apiError?: string | null;
    isLoadingSubmit: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomModalAuthContainer.propTypes = propTypes;

export default CustomModalAuthContainer;
