import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './QuoteCard.styled';
import { Typography } from '@mui/material';
import { Quotation } from 'types/api/instruments.types';
import { formatNumber } from 'utils/helpers/commonHelper';

const QuoteCard = (props: Props) => {
    const { mappedQuotes } = props;

    return mappedQuotes.map((quote, index) => (
        <St.CardQuotes key={index}>
            <Typography color={'#000'} fontWeight={'500'} fontSize={'20px'}>
                {quote.title}
            </Typography>
            <St.ContainerCurrencies>
                <St.QuotesNumber>
                    {quote?.buy !== undefined
                        ? formatNumber(Number(quote?.buy?.toFixed(2) || 0))
                        : '-'}
                </St.QuotesNumber>
                <St.QuotesNumber>
                    {quote?.sell !== undefined
                        ? formatNumber(Number(quote?.sell?.toFixed(2) || 0))
                        : '-'}
                </St.QuotesNumber>
            </St.ContainerCurrencies>
        </St.CardQuotes>
    ));
};

const propTypes = {};

interface extraProps {
    mappedQuotes: Quotation[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuoteCard.propTypes = propTypes;

export default QuoteCard;
