import { styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    CircleWithNumber: styled(
        'span',
        transientOptions,
    )<{ $activated?: boolean }>(({ theme, $activated }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px',
        height: '48px',
        fontSize: '2.125rem',
        fontWeight: '400',
        lineHeight: '39.84px',
        letterSpacing: '0.0025em',
        borderRadius: '50%',
        border: $activated ? 'none' : `1px solid ${theme.palette.custom.darkblue4}`,
        color: $activated ? theme.palette.custom.darkblue10 : theme.palette.custom.darkblue5,
        background: $activated ? theme.palette.custom.vibrantblue : 'none',
    })),
};
