import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { AlertColor, Box, Grid, TextField } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { REJECTED } from 'constants/appConstants';
import BankAccountForm from 'components/pages/ProfileBankAccountPage/BankAccountForm';
import { GetBankAccountsItem } from 'types/api/cashflow.types';

const EditBankAccount = ({
    row,
    status,
    description,
    isLoading,
    setDescription,
    close,
    handleSubmit,
    setSnackBarMessage,
    t,
}: Props) => (
    <Grid container spacing={2}>
        {status === REJECTED ? (
            <Grid item xs={12}>
                <BankAccountForm
                    setSnackBarMessage={setSnackBarMessage}
                    selectedAccount={row}
                    close={close}
                    isEdit
                />
            </Grid>
        ) : (
            <>
                <Grid item xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}>
                        <TextField
                            fullWidth
                            size="small"
                            label={t('description')}
                            value={description}
                            placeholder={t('description')}
                            inputProps={{ maxLength: 20 }}
                            onChange={({ target: { value } }) => setDescription(value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <ActionButton fullWidth variant="outlined" onClick={close} disabled={isLoading}>
                        {t('cancel')}
                    </ActionButton>
                </Grid>
                <Grid item xs={6}>
                    <ActionButton
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        loading={isLoading}
                        disabled={isLoading}>
                        {t('edit')}
                    </ActionButton>
                </Grid>
            </>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: GetBankAccountsItem;
    status: string;
    isLoading: boolean;
    description: string;
    setDescription(description: string): void;
    close(): void;
    handleSubmit(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditBankAccount.propTypes = propTypes;

export default EditBankAccount;
