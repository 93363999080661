import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { INSTRUMENTS_BASE_URL, ORDERS_BASE_URL } from 'constants/appConstants';

import {
    CreateCollateralOrderRequest,
    CreateFundOrderRequest,
    CreateMarketOrderRequest,
    GetCollateralOrderByIdResponse,
    GetCollateralOrdersResponse,
    GetFundOrderByIdResponse,
    CreateFundOrderResponse,
    GetFundOrdersResponse,
    GetMarketOrderByIdResponse,
    GetMarketOrdersResponse,
    GetMarketPreviewRequest,
    GetMarketPreviewResponse,
    GetExchangeOrdersResponse,
    GetCollateralExpiration,
    CreateExchangeOrderRequest,
    CollateralAvailableItem,
    GetReportCollateralDateResponse,
} from 'types/api/orders.types';

const apiName = 'orders';

export default {
    getMarketOrders: (filterQueryString: string) =>
        withFakeData<GetMarketOrdersResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/market/?${filterQueryString}`),
            apiName,
            'orders',
            false,
            500,
        ),
    getMarketOrdersByCustomerCode: (filterQueryString: string, customerCode?: string) =>
        withFakeData<GetMarketOrdersResponse>(
            () =>
                axiosDataInstance.get(
                    `${ORDERS_BASE_URL}/market/?customerCode=${customerCode}${filterQueryString}`,
                ),
            apiName,
            'orders',
            false,
            500,
        ),
    getMarketOrderById: (id: string) =>
        withFakeData<GetMarketOrderByIdResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/market/${id}`),
            apiName,
            'orders',
            false,
            500,
        ),
    createMarketOrder: (argument: CreateMarketOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/market`, argument),
            apiName,
            'orders',
            false,
            500,
        ),
    deleteMarketOrder: (id: string) =>
        withFakeData(
            () => axiosDataInstance.delete(`${ORDERS_BASE_URL}/market/${id}`),
            apiName,
            'orders',
            false,
            500,
        ),
    getFundOrders: (filterQueryString: string) =>
        withFakeData<GetFundOrdersResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/funds/?${filterQueryString}`),
            apiName,
            'funds',
            false,
            500,
        ),
    getFundOrdersByCustomerCode: (filterQueryString: string, customerCode?: string) =>
        withFakeData<GetFundOrdersResponse>(
            () =>
                axiosDataInstance.get(
                    `${ORDERS_BASE_URL}/funds/?customerCode=${customerCode}${filterQueryString}`,
                ),
            apiName,
            'funds',
            false,
            500,
        ),
    getFundOrderById: (id: string) =>
        withFakeData<GetFundOrderByIdResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/funds/${id}`),
            apiName,
            'funds',
            false,
            500,
        ),
    createFundOrder: (argument: CreateFundOrderRequest) =>
        withFakeData<CreateFundOrderResponse>(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/funds`, argument),
            apiName,
            'funds',
            false,
            500,
        ),
    deleteFundOrder: (id: string) =>
        withFakeData(
            () => axiosDataInstance.delete(`${ORDERS_BASE_URL}/funds/${id}`),
            apiName,
            'funds',
            false,
            500,
        ),
    getCollateralOrders: (filterQueryString: string) =>
        withFakeData<GetCollateralOrdersResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/collateral/?${filterQueryString}`),
            apiName,
            'collaterals',
            false,
            500,
        ),
    getCollateralOrderById: (id: string) =>
        withFakeData<GetCollateralOrderByIdResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/collateral/${id}`),
            apiName,
            'collaterals',
            false,
            500,
        ),
    createCollateralOrder: (argument: CreateCollateralOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/collateral`, argument),
            apiName,
            'collaterals',
            false,
            500,
        ),
    deleteCollateralOrder: (id: string) =>
        withFakeData(
            () => axiosDataInstance.delete(`${ORDERS_BASE_URL}/collateral/${id}`),
            apiName,
            'collaterals',
            false,
            500,
        ),
    getMarketPreview: (req: GetMarketPreviewRequest) =>
        withFakeData<GetMarketPreviewResponse>(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/market/preview`, req),
            apiName,
            'market-preview',
            false,
            500,
        ),
    getExchangeOrders: (filterQueryString: string) =>
        withFakeData<GetExchangeOrdersResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/exchange?${filterQueryString}`),
            apiName,
            'exchange',
            false,
            500,
        ),
    getCollateralExpiration: () =>
        withFakeData<GetCollateralExpiration>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/collateral/expiration`),
            apiName,
            'collateral-expiration',
            false,
            500,
        ),
    createExcahngeOrder: (body: CreateExchangeOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/exchange`, body),
            apiName,
            'create-exchange',
            false,
            500,
        ),
    getCollateralsAvailable: (customerCode: string) =>
        withFakeData<CollateralAvailableItem[]>(
            () =>
                axiosDataInstance.get(
                    `${INSTRUMENTS_BASE_URL}/collaterals/available?customerCode=${customerCode}`,
                ),
            apiName,
            'get-collaterals-available',
            false,
            500,
        ),
    getMarketPreviewMaxOperable: (req: GetMarketPreviewRequest) =>
        withFakeData<GetMarketPreviewResponse>(
            () => axiosDataInstance.post(`${ORDERS_BASE_URL}/market/max_operable`, req),
            apiName,
            'market-max-operable',
            false,
            500,
        ),
    getReportCollaterals: (filterQueryString: string) =>
        withFakeData<GetReportCollateralDateResponse>(
            () => {
                const url = `${ORDERS_BASE_URL}/collateral/dates/?${filterQueryString}`;
                return axiosDataInstance.get(url);
            },
            apiName,
            'collaterals-by-date',
            false,
            500,
        ),
};
