import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikContextType, FormikProvider } from 'formik';
import TopHeader from 'components/common/TopHeader';
import CircleWithNumber from 'components/common/CircleWithNumber';
import CustomLoader from 'components/common/CustomLoader';
import { tCommon } from 'constants/appConstants';
import QuestionsAnswers from './QuestionsAnswers';
import St from './OnboardingInvestmentTestPage.styled';
import React from 'react';
import { QuestionsInvestmentById } from 'types/pages/onboardingValidatePerson.types';
import PropTypes from 'prop-types';
import ActionButton from 'components/common/ActionButton';
import HeadLogo from 'components/common/HeadLogo';

const OnboardingInvestmentTestPage = (props: Props) => {
    const {
        t,
        handleCheck,
        formik,
        isLoading,
        isLoadingValidate,
        errorMessage,
        currentStep,
        handleChangeQuestion,
        isOnboarding,
        investorProfileIsExpired,
        isLoadingInvestmentTestQuestions,
    } = props;

    const values = formik.values as QuestionsInvestmentById;
    return (
        <St.SectionWrapper $isOnboarding={isOnboarding} $isExpired={investorProfileIsExpired}>
            {investorProfileIsExpired && <HeadLogo />}
            {isLoading ? (
                <CustomLoader />
            ) : (
                <>
                    <TopHeader
                        firstElement={t('investment_test_page_first_text') as string}
                        title={t('investment_test_page_title')}
                    />

                    <FormikProvider value={formik}>
                        <Form>
                            {isLoadingInvestmentTestQuestions ? (
                                <CustomLoader />
                            ) : (
                                <>
                                    <St.NumberWrapper>
                                        {Object.entries(values.questions ?? []).map(
                                            ([key, value]) => (
                                                <CircleWithNumber
                                                    key={value.questionId}
                                                    activated={value.current}
                                                    number={Number(value.questionId)}
                                                    className={
                                                        value.selectedOption && !value.current
                                                            ? 'complete'
                                                            : ''
                                                    }
                                                />
                                            ),
                                        )}
                                    </St.NumberWrapper>
                                    <St.QuestionsAnswersWrapper key={currentStep}>
                                        <QuestionsAnswers
                                            {...{
                                                ...values.questions?.[currentStep],
                                                questionKey: currentStep,
                                                handleCheck,
                                            }}
                                        />
                                        {errorMessage && (
                                            <St.ErrorTextWrapper>
                                                <St.ErrorText text={errorMessage as string} />
                                            </St.ErrorTextWrapper>
                                        )}
                                        <St.ActionButtonWrapper>
                                            {currentStep !== '1' && (
                                                <ActionButton
                                                    variant="outlined"
                                                    type="button"
                                                    disabled={isLoadingValidate}
                                                    onClick={() => handleChangeQuestion()}>
                                                    {t('back', tCommon)}
                                                </ActionButton>
                                            )}
                                            <St.LoadingButton
                                                variant="contained"
                                                type={currentStep === '8' ? 'button' : 'submit'}
                                                disabled={
                                                    !values.questions?.[currentStep]
                                                        .selectedOption || isLoadingValidate
                                                }
                                                loading={isLoadingValidate}
                                                loadingIndicator={<St.CustomCircularProgress />}
                                                onClick={() =>
                                                    currentStep === '8'
                                                        ? formik.handleSubmit()
                                                        : handleChangeQuestion(true)
                                                }>
                                                {currentStep === '8'
                                                    ? t('investment_test_page_btn_finish')
                                                    : t('next', tCommon)}
                                            </St.LoadingButton>
                                        </St.ActionButtonWrapper>
                                    </St.QuestionsAnswersWrapper>
                                </>
                            )}
                        </Form>
                    </FormikProvider>
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    investorProfileIsExpired: PropTypes.bool.isRequired,
};

interface extraProps {
    isOnboarding: boolean;
    formik: FormikContextType<any>;
    isLoading: boolean;
    isLoadingInvestmentTestQuestions: boolean;
    isLoadingValidate: boolean;
    handleCheck: (field: string, questionKey: string) => void;
    handleChangeQuestion: (next?: boolean) => void;
    errorMessage: string | boolean;
    currentStep: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestPage.propTypes = propTypes;

export default OnboardingInvestmentTestPage;
