import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from 'api/declarations/declarations.api';
import { errorResponseHelper } from 'utils/helpers/errorHelper';

export const useGetDeclarations = () => {
    const { mutate, isLoading } = useMutation(endpoints.getDeclarations);

    return {
        mutate,
        isLoading,
    };
};

export const useApproveDeclaration = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.approveDeclaration);
    return {
        mutate,
        isLoading,
        error: isError ? errorResponseHelper(error) : null,
        isError,
    };
};
export const useApproveDeclarationChannel = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.approveDeclarationChannel);
    return {
        mutate,
        isLoading,
        error: isError ? errorResponseHelper(error) : null,
        isError,
    };
};

export const useGetUserPreviewDDJJ = (enabledCondition: boolean) => {
    const { data, isLoading, error, refetch, isError } = useQuery(
        ['previewDDJJ', enabledCondition],
        () => endpoints.getPreviewDDJJ(),
        {
            select: data => data.data.content,
            enabled: enabledCondition,
        },
    );

    return {
        declarationHtml: data,
        isLoading,
        refetch,
        error: isError ? errorResponseHelper(error) : null,
    };
};
