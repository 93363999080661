import { styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    IconWrapper: styled('div')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    })),
    StyledImage: styled('img')({
        width: pxToRem('100px'),
    }),
    BodyText: styled(Typography)({
        marginBottom: '15px',
    }),
};
