import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useEffect, useRef } from 'react';
import CustomLineChart from './CustomLineChart';
import { LWChartColors, LWChartData } from 'types/common/graphics.types';
import {
    createLineChartGraphic,
    defaultColors,
    registerResizeHandler,
} from 'utils/helpers/graphicsHelper';

const CustomLineChartContainer = (props: Props) => {
    const { data, colors = defaultColors } = props;
    const chartContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!chartContainerRef.current) return;

        const chart = createLineChartGraphic(chartContainerRef.current, colors, data);
        const cleanupResizeHandler = registerResizeHandler(chart, chartContainerRef);

        return () => {
            cleanupResizeHandler();
            chart.remove();
        };
    }, [colors, data]);

    const childProps = {
        ...props,
        chartContainerRef,
    };

    return <CustomLineChart {...childProps} />;
};

const propTypes = {};

interface extraProps {
    title: string;
    data: LWChartData[];
    colors?: LWChartColors;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomLineChartContainer.propTypes = propTypes;

export default CustomLineChartContainer;
