import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentBuySell from './InstrumentBuySell';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';
import { useTranslation } from 'react-i18next';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import { tErrorsContext } from 'constants/appConstants';
import { Currency } from 'types/common/general.types';
import { AuthContext } from 'context/auth.context';
import { CreateFundOrderRequest } from 'types/api/orders.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import { useGetInstrumentByTicker } from 'hooks/api/instruments.hooks';
import { RuleConditions } from 'types/api/rules.types';
import { useLocation } from 'react-router-dom';

const InstrumentBuySellContainer = (props: Props) => {
    const { term, accountStatus, fundDetail, isMarketClosed } = props;
    const { currency, fundInfo, ticker, id: tickerId } = fundDetail;
    const { customerCode, userData, userType, selectedCustomer } = React.useContext(AuthContext);
    const { rules, instrument, isLoading, errorMessage } = useGetInstrumentByTicker(ticker);
    const location = useLocation();

    const isAPOrAC = ['ASSISTANT', 'CHANNEL'].includes(userType || '');
    const [simpleSubscription, setSimpleSubscription] = React.useState(false);

    const { t } = useTranslation('market');
    const [isError, setError] = React.useState<null | string>(null);
    const [isRescue, setIsRescue] = React.useState(false);
    const [isTotal, setIsTotal] = React.useState(false);
    const [managementRegulations, setManagementRegulations] = React.useState(false);
    const [amount, setAmount] = React.useState<number>(0);
    const [disableKey, setDisableKey] = React.useState<'rescue' | 'subscription'>('subscription');
    const [openPrevisualizeModal, setOpenPrevisualizeModal] = React.useState<boolean>(false);
    const [marketClosed, setMarketClosed] = React.useState<boolean>(false);
    const [clientCantOperate, setClientCantOperate] = React.useState<null | string>(null);
    const [shareHolderCode, setShareHolderCode] = React.useState<string>(
        selectedCustomer?.shareholderCode || '',
    );
    const [shareHolderCodeError, setShareHolderCodeError] = React.useState<undefined | string>(
        undefined,
    );

    const availableTenure = accountStatus?.instruments
        .find(el => el.instrumentType === 'FUND')
        ?.instruments.find(el2 => el2.ticker === ticker)?.quantity; // cuotaparte disponible - amount of holding

    const available = React.useMemo(() => {
        if (!accountStatus) return 0;
        const { availableBalance } = accountStatus;
        const termBalance = term === 'CI' ? 't0' : 't1';
        const currencyBalance = currency?.toLowerCase() as 'ars' | 'usd' | 'usdc';
        return isRescue
            ? availableBalance.withdraw[currencyBalance]
            : availableBalance.operate[termBalance][currencyBalance];
    }, [isRescue, term, accountStatus, currency]);

    const handleChangePrice = (val: number | undefined) => {
        if (!val) setAmount(0);
        if (!isRescue && val && val > available) {
            setError(t('amount_greater_than_available', tErrorsContext));
        }
        if (!isRescue && isError && val && val <= available) setError(null);
        if (!isRescue && instrument?.minimum && val && val < instrument?.minimum) {
            setError(
                `${t('minimum_to_operate_for_instrument', tErrorsContext)} ${currency === 'ARS' ? '$' : 'US$'} ${formatNumber(instrument.minimum, 2)}`,
            );
        }
        setAmount(val ?? 0);
    };

    const estimatedPrice = React.useMemo(() => {
        if (isRescue && isTotal) {
            setAmount(available);
            return formatNumber(availableTenure ?? 0, 2);
        }
        return formatNumber(amount / fundInfo?.performanceVcp, 2);
    }, [amount, isTotal]);

    React.useEffect(() => {
        setDisableKey(isRescue ? 'rescue' : 'subscription');
        setAmount(0);
        if (isError) setError(null);
        if (!isRescue) setIsTotal(false);
    }, [isRescue, isTotal]);

    const disable = {
        subscription:
            parseFloat(formatNumber(amount, 2)) < 0.1 ||
            parseFloat(formatNumber(amount, 2)) < 0.01 ||
            !managementRegulations ||
            !!isError,
        rescue:
            parseFloat(formatNumber(amount, 2)) < 0.1 &&
            parseFloat(formatNumber(amount, 2)) < 0.01 &&
            !isTotal,
    };

    React.useEffect(() => {
        if (isAPOrAC && fundInfo?.allowsMixedOrder && fundInfo?.isSimple)
            setSimpleSubscription(true);
    }, [fundInfo, isAPOrAC]);

    React.useEffect(() => {
        if (!isRescue && fundInfo?.targetUser === null)
            setClientCantOperate(t('cliet_cant_operate_instrument', tErrorsContext));
    }, [fundInfo]);

    const bodyToCreateOrder: CreateFundOrderRequest = {
        amount,
        buySell: isRescue ? 'SELL' : 'BUY',
        customerCode: customerCode ?? '',
        isManual:
            isAPOrAC && fundInfo?.allowsMixedOrder
                ? fundInfo?.isSimple
                    ? !simpleSubscription // si la subs es true, debe viajar directo (directo = false)
                    : simpleSubscription
                : false, // mesa = true or directo = false
        term: term === 'CI' ? 0 : 1,
        userUuid: isAPOrAC ? (selectedCustomer?.uuid ?? '') : (userData?.id ?? ''),
        tickerId,
        isSimple: isAPOrAC && fundInfo?.allowsMixedOrder ? simpleSubscription : true, // por default es simple..true
        isTotal,
        shareHolderCode: shareHolderCode ? parseInt(shareHolderCode) : null,
        quantity: null,
    };

    const fundPreview = {
        ticker,
        name: fundInfo?.shortName || '-',
        class: fundInfo?.fundClass,
        currency: currency as Currency,
        priceToOperate: fundInfo?.performanceVcp,
        cuotaparte: estimatedPrice,
        profile: fundDetail.profile ?? undefined,
    };

    const hasValidCurrencyAndTerm = React.useCallback(
        (conditions: RuleConditions[]) => {
            const validConditions = conditions.filter(c => {
                const { currencies, terms } = c;
                return currencies.includes(currency as Currency) && terms.includes(term);
            });
            return validConditions.length > 0;
        },
        [term, currency],
    );

    const handleOpenPreview = () => {
        if (shareHolderCode.length < 7 && isAPOrAC && simpleSubscription) {
            return setShareHolderCodeError(t('minimum_of_cuotapartista', tErrorsContext) as string);
        }
        setShareHolderCodeError(undefined);
        isMarketClosed ? setMarketClosed(true) : setOpenPrevisualizeModal(true);
    };

    React.useEffect(() => {
        if (location.search) setIsRescue(location.search.includes('sell'));
    }, [location]);

    const childProps = {
        ...props,
        t,
        isRescue,
        setIsRescue,
        available,
        amount,
        setAmount,
        estimatedPrice,
        isTotal,
        setIsTotal,
        currency: currency as Currency,
        managementRegulations,
        setManagementRegulations,
        availableTenure,
        fundInfo,
        handleChangePrice,
        disableBtn: !amount || disable[disableKey],
        isError,
        clientCantOperate,
        openPrevisualizeModal,
        setOpenPrevisualizeModal,
        bodyToCreateOrder,
        fundPreview,
        simpleSubscription,
        setSimpleSubscription,
        isAPOrAC,
        blockBuyRules:
            isRescue === false
                ? rules?.filter(
                      r => r.action === 'BLOCK BUY' && hasValidCurrencyAndTerm(r.conditions),
                  )
                : undefined,
        blockSellRules:
            isRescue === true
                ? rules?.filter(
                      r => r.action === 'BLOCK SELL' && hasValidCurrencyAndTerm(r.conditions),
                  )
                : undefined,
        isLoadingInstrumentDetail: isLoading,
        errorMessageDetail: errorMessage,
        marketClosed,
        setMarketClosed,
        shareHolderCode,
        setShareHolderCode,
        selectedCustomer,
        handleOpenPreview,
        shareHolderCodeError,
    };

    return <InstrumentBuySell {...childProps} />;
};

const propTypes = {
    setTerm: PropTypes.func.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    accountStatus?: GetAccountStatusResponse;
    term: 'CI' | '24hs';
    fundDetail: GetFundDetailResponse;
    marketClosingTime: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentBuySellContainer.propTypes = propTypes;

export default InstrumentBuySellContainer;
