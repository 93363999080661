import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { AuthContext } from 'context/auth.context';
import endpoints from '../../api/auth/auth.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { cleanStorage, setAuthToken, setUserDataStorage } from 'storage/auth.storage';
import { LoginResponse, UserData, ValidateCodeResponse } from 'types/api/auth.types';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { mainRoutesPaths, onboardingRoutesPaths } from 'router/routesPaths';
import { recommendedWalletAdapter } from 'adapters/api/auth.adapter';
import { NotificationsContext } from 'context/notifications.context';
import { AlertColor } from '@mui/material/Alert';
import { useCommonGrid } from 'context/common/commonGrid.context';
import { MarketAccount } from 'types/api/marketAccount.types';

export const useLogin = () => {
    const { setIsAuthenticated, setUserData } = useContext(AuthContext);
    const { connect } = useContext(NotificationsContext);

    const navigate = useNavigate();
    const { mutate, isLoading, error, isError } = useMutation(endpoints.login, {
        onSuccess: async ({ data }: { data: LoginResponse }) => {
            setIsAuthenticated(true);
            setAuthToken(data.authenticationResult.idToken);
            connect(data.authenticationResult.idToken);
            setUserData(data.user);
            if (!data.user.onboardingPending) {
                navigate(mainRoutesPaths.home);
            }
        },
    });

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useLogout = () => {
    const { setIsAuthenticated, setSelectedCustomer } = useContext(AuthContext);
    const { disconnect } = useContext(NotificationsContext);
    const navigate = useNavigate();

    const { mutate: onLogout, isLoading } = useMutation(endpoints.logout, {
        onSuccess: async () => {
            setIsAuthenticated(false);
            setSelectedCustomer(null);
            cleanStorage();
            disconnect();
            navigate('/login');
        },
    });

    const mutate = () => {
        setIsAuthenticated(false);
        cleanStorage();
        disconnect();
        setSelectedCustomer(null);
        navigate('/login');
        // TODO: Definir función en base al proyecto a desarrollar
        // const { email: username } = getUserData();
        // if (username) onLogout({ username });
    };
    return { mutate, isLoading };
};

export const useCheckLogin = () => {
    const response = true;
    if (response) {
        return { address: 'a', hasSigned: true };
    } else {
        throw new Error('Usuario deslogueado');
    }
};

export const useForgotPassword = () => {
    const [errorMessage, setErrorMessage] = React.useState();
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.forgotPassword, {
        onError: (error: any) => {
            setErrorMessage(
                error.response.data.error_messages[0]?.message ?? t('password_error_try_later'),
            );
        },
    });
    return { mutate, isLoading, errorMessage };
};

export const useForgotPasswordConfirmation = () => {
    const navigate = useNavigate();
    const { mutate, isLoading, error } = useMutation(endpoints.forgotPasswordConfirmation, {
        onSuccess: () => {
            navigate('/login', { state: { isPasswordChanged: true } });
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useRegisterEmail = () => {
    const { mutate, isLoading, error, isSuccess } = useMutation(endpoints.register);
    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error) : null,
        isSuccess,
    };
};

export const useResendEmailVerificationCode = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
) => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading, error, isSuccess } = useMutation(endpoints.resendCode, {
        onSuccess() {
            setSnackBarMessage(t('code_resend_suceesfully'));
        },
    });
    return {
        mutate,
        isLoading,
        successMessage: isSuccess ? t('resend_code_success_message') : '',
        error: error ? errorResponseHelper(error as any) : null,
    };
};

export const useValidateCode = () => {
    const { setIsAuthenticated, setUserData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { mutate, isLoading, error } = useMutation(endpoints.validateCode, {
        onSuccess({ data }: { data: ValidateCodeResponse }) {
            setIsAuthenticated(true);
            setAuthToken(data.authenticationResult.idToken);
            setUserData(data.user);
            navigate(onboardingRoutesPaths.validatePerson);
        },
    });
    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error) : null,
    };
};

export const useGetRecommendedWallet = () => {
    const { data, isLoading } = useQuery(
        ['recommended-wallet'],
        () => endpoints.getRecommendedWallet(),
        {
            select: data => recommendedWalletAdapter(data.data),
        },
    );
    return { data, isLoading };
};

export const useEnableMfaEmail = () => {
    const { mutate } = useMutation(endpoints.sendEmailEnableMfa);
    return {
        mutate,
    };
};

export const useValidateOtp = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.validateMfa);
    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error as any) : null,
    };
};

export const useRequestOtp = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.sendEmailRequestOtp);

    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error) : null,
    };
};

export const useDisableMfaOtp = (setSnackBarMessage: (msj: string, sever?: AlertColor) => void) => {
    const { t } = useTranslation();
    const { userData, setUserData } = useContext(AuthContext);

    const { mutate, error, isError, isLoading } = useMutation(endpoints.disableMfa, {
        onSuccess() {
            if (userData) {
                setUserData({ ...userData, mfaType: 'DISABLED' });
                setUserDataStorage({ ...userData, mfaType: 'DISABLED' });
            }
            setSnackBarMessage(t('preferences_mfa_disable_snackbar'), 'success');
        },
    });
    return {
        mutate,
        error: isError ? errorResponseHelper(error) : null,
        isLoading,
    };
};

export const useHandlePreferences = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    notification: string,
) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading, error } = useMutation(endpoints.handlePreferences, {
        onSuccess() {
            queryClient.invalidateQueries(['get-preferences']);
            setSnackBarMessage(`${notification} las notificaciones`, 'success');
        },
    });
    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error as any) : null,
    };
};

export const useGetPreferences = () => {
    const { data, isLoading, error } = useQuery(
        ['get-preferences'],
        () => endpoints.getPreferences(),
        {
            select: ({ data }) => ({
                emailNotifications:
                    data?.emailNotifications !== null ? data?.emailNotifications : true,
                pushNotifications:
                    data?.pushNotifications !== null ? data?.pushNotifications : true,
            }),
        },
    );
    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error as any) : null,
    };
};

export const useGetPendingRemoveAccount = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useQuery(
        ['get-pending-remove-account'],
        () => endpoints.getPendingRemoveAccount(),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading,
        errorMessage: error ? t(errorResponseHelper(error as any), tErrorsContext) : null,
    };
};

export const useSendRemoveAccountRequest = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
) => {
    const { t } = useTranslation('profile');
    const { mutate, isLoading, error } = useMutation(endpoints.sendRemoveAccountRequest, {
        onSuccess() {
            setSnackBarMessage(t('delete_account_snackbar_success_message'), 'success');
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        },
        onError() {
            setSnackBarMessage(t('delete_account_snackbar_error_message'), 'error');
        },
    });
    return {
        mutate,
        isLoading,
        error: error ? t(errorResponseHelper(error as any), tErrorsContext) : null,
    };
};

export const useAcceptTermsConditions = () => {
    const { t } = useTranslation();
    const { setUserData, userData } = useContext(AuthContext);
    const { mutate, isLoading, error } = useMutation(endpoints.acceptTermsConditions, {
        onSuccess() {
            setUserData({ ...(userData as UserData), hasAcceptedTermsConditions: true });
            window.location.reload();
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: error ? t(errorResponseHelper(error as any), tErrorsContext) : null,
    };
};

export const useGetTermsConditions = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useQuery(
        ['get-preview-terms'],
        () => endpoints.previewTermsConditions(),
        {
            select: data => data.data,
        },
    );
    return {
        previewTerms: data,
        isLoading,
        errorMessage: error ? t(errorResponseHelper(error as any), tErrorsContext) : null,
    };
};

export const useGetProfileInfo = (enabled?: boolean) => {
    const { setUserData, setSelectedCustomer, selectedCustomer } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { data, isLoading, error } = useQuery(
        ['get-profile-info'],
        () => endpoints.getProfileInfo(),
        {
            select: data => data.data,
            onSuccess(data) {
                setUserData(data);
                if (
                    data.onboardingPending === false &&
                    location.pathname.includes(onboardingRoutesPaths.home)
                ) {
                    navigate(mainRoutesPaths.home);
                } else {
                    setSelectedCustomer({
                        ...(selectedCustomer as MarketAccount),
                        hasExteriorAccount: data.hasExteriorAccount,
                    });
                }
            },
            enabled,
        },
    );
    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error as any) : null,
    };
};

export const useAddFavoriteMarketAccount = (setSnackBarMessage: Function) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation();
    const { mutate } = useMutation(endpoints.setFavoriteMarketAccount, {
        onSuccess: () => {
            setSnackBarMessage(t('favorite-added-successfully'));
            setNeedRefresh(true);
        },
        onError: error => setSnackBarMessage(errorResponseHelper(error), 'error'),
    });

    return {
        mutate,
    };
};

export const useDeleteFavoriteMarketAccount = (setSnackBarMessage: Function) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation();
    const { mutate } = useMutation(endpoints.deleteFavoriteMarketAccount, {
        onSuccess: () => {
            setSnackBarMessage(t('favorite-deleted-successfully'));
            setNeedRefresh(true);
        },
        onError: error => setSnackBarMessage(errorResponseHelper(error), 'error'),
    });

    return {
        mutate,
    };
};

export const useChangePassword = (setSnackBarMessage: Function, handleGoHome: Function) => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading } = useMutation(endpoints.changePassword, {
        onSuccess: () => {
            setSnackBarMessage(t('password_changed_successfully'));
            handleGoHome();
        },
        onError: error => setSnackBarMessage(errorResponseHelper(error), 'error'),
    });

    return { mutate, isLoading };
};

export const useGetSignedContract = () => {
    const { data, isLoading, error } = useQuery(
        ['get-signed-contract'],
        () => endpoints.getSignedContract(),
        {
            select: data => data.data.downloadPresignedUrl,
        },
    );
    return {
        fileUrl: data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error as any) : null,
    };
};
