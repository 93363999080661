import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DepositStepOne from './DepositStepOne';
import { scrollToTop } from 'utils/helpers/commonHelper';

const DepositStepOneContainer = (props: Props) => {
    const { activeStep, setActiveStep } = props;
    const { t } = useTranslation('home');
    const navigate = useNavigate();

    const handleNext = async () => {
        scrollToTop();
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const childProps = {
        t,
        navigate,
        handleNext,
    };

    return <DepositStepOne {...childProps} />;
};

const propTypes = {};

interface extraProps {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepOneContainer.propTypes = propTypes;

export default DepositStepOneContainer;
