import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'components/common/PasswordField';
import { FormikContextType, Form, FormikProvider } from 'formik';
import St from './LoginForm.styled';
import CustomInput from 'components/common/CustomInput';
import { Link as RouterLink } from 'react-router-dom';
import ErrorText from 'components/common/ErrorText';
import ActionButton from 'components/common/ActionButton';
const LoginForm = (props: Props) => {
    const { t, isLoading, formik, apiError, isError, SnackBar, handleCreateAccount } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form>
                <St.LoginForm data-testid="login-form">
                    <SnackBar />
                    <St.ProfilePageTitle>{t('login_title')}</St.ProfilePageTitle>
                    <St.Stack gap={'15px'}>
                        <CustomInput
                            field="username"
                            errors={errors}
                            label={t('username')}
                            placeholder={t('username_placeholder')}
                            setFieldValue={setFieldValue}
                            values={values}
                            autoFocus
                            maxLength={50}
                            required
                            inputProps={{ autoComplete: 'on' }}
                        />
                        <PasswordField
                            variant="outlined"
                            size="small"
                            name="password"
                            label={t('password_label')}
                            value={values.password}
                            error={errors.password?.toString()}
                            placeholder={t('password_placeholder')}
                            helperText={errors.password?.toString()}
                            onChange={({ target }: any) => {
                                setFieldValue('password', target.value);
                            }}
                            inputProps={{ autoComplete: 'on' }}
                        />
                    </St.Stack>
                    <Box sx={{ alignSelf: 'flex-end' }}>
                        <Link
                            component={RouterLink}
                            to="/forgot-password"
                            align="right"
                            underline="none"
                            fontSize={'0.75rem'}>
                            {t('forgot_password_button')}
                        </Link>
                    </Box>
                    <Box>{isError && apiError && <ErrorText text={apiError} />}</Box>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                        {t('submit_button_label')}
                    </LoadingButton>
                    <St.CreateAccount>
                        <St.CreateAccountText>{t('create_account_text')}</St.CreateAccountText>
                        <ActionButton
                            variant="outlined"
                            aria-label="export"
                            onClick={handleCreateAccount}>
                            {t('create_account')}
                        </ActionButton>
                    </St.CreateAccount>
                </St.LoginForm>
            </Form>
        </FormikProvider>
    );
};
const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
    isError: PropTypes.bool.isRequired,
};
interface extraProps {
    formik: FormikContextType<any>;
    apiError: string | null;
    SnackBar: any;
    handleCreateAccount: () => void;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginForm.propTypes = propTypes;
export default LoginForm;
