import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomInput.styled';
import { FormikErrors, FormikValues } from 'formik';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/material';

const CustomInput = (props: Props) => {
    const {
        field,
        values,
        noError = false,
        errors,
        autoFocus = false,
        label,
        required,
        inputType,
        placeholder,
        size = 'small',
        maxLength,
        disabled = false,
        inputProps,
        helperText,
        onChangeInput,
        style,
        multiline,
        rows,
        onBlur,
        sx,
        value,
    } = props;

    return (
        <St.TextField
            autoFocus={autoFocus}
            fullWidth
            id={`${field}`}
            label={label && `${label}${required ? ' * ' : ''}`}
            type={inputType ?? 'text'}
            value={value || values[field]}
            onChange={({ target }: any) => {
                onChangeInput(target.value);
            }}
            ownerState={{
                disabled,
            }}
            error={!noError && errors && errors[field] !== undefined}
            helperText={(!noError && errors && errors[field]?.toString()) ?? helperText}
            placeholder={placeholder ?? undefined}
            size={size}
            variant="outlined"
            inputProps={{
                ...inputProps,
                maxLength: maxLength ?? '',
                style: { ...style },
                autoComplete: inputProps?.autoComplete ? inputProps?.autoComplete : 'one-time-code',
            }}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            onBlur={onBlur}
            sx={sx}
        />
    );
};

const propTypes = {
    field: PropTypes.string.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    style: PropTypes.any,
    inputProps: PropTypes.any,
    placeholder: PropTypes.string,
    inputType: PropTypes.string,
    helperText: PropTypes.string,
};

interface extraProps {
    regex?: RegExp;
    disabled?: boolean;
    autoFocus?: boolean;
    noError?: boolean;
    values: FormikValues;
    errors?: FormikErrors<FormikValues>;
    size?: OverridableStringUnion<'small' | 'medium', ''>;
    multiline?: boolean;
    rows?: number;
    value?: string | number;
    onBlur?: () => void;
    sx?: SxProps;
    onKeyPress?: (event: React.KeyboardEvent) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomInput.propTypes = propTypes;

export default CustomInput;
