import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DownloadContract from './DownloadContract';
import { useTranslation } from 'react-i18next';

const DownloadContractContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <DownloadContract {...childProps} />;
};

const propTypes = {
    fileUrl: PropTypes.string,
    label: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DownloadContractContainer.propTypes = propTypes;

export default DownloadContractContainer;
