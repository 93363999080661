import { Box, Typography, styled } from '@mui/material';

const styles = {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    OptionContainer: styled('li')(({ theme }) => ({
        '&:hover': {
            backgroundColor: '#e0e0e0 !important',
        },
    })),
    OptionTitle: styled(Typography)(({ theme }) => ({
        fontWeight: '500',
    })),
    OptionDescription: styled(Typography)(({ theme }) => ({
        fontWeight: 'normal',
    })),
};

export default styles;
