import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import ChannelsDDJJCellInfo from './ChannelsDDJJCellInfo';
import { AffidavitObject } from 'types/api/orders.types';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';
const ChannelsDDJJCellInfoContainer = (props: Props) => {
    const { t } = useTranslation();
    const { isOpen, open, close } = useDialog();

    const childProps = {
        ...props,
        isOpen,
        open,
        close,
        t,
    };

    return <ChannelsDDJJCellInfo {...childProps} />;
};

const propTypes = {};

interface extraProps {
    declaration?: AffidavitObject | null;
    uploadFile?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfoContainer.propTypes = propTypes;

export default ChannelsDDJJCellInfoContainer;
