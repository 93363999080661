import { CreateStyled } from '@emotion/styled';
import { SXBreakpoint } from 'types/common/general.types';

const basePx = 16;
const maxRemPrecision = 4;

export const pageContentMeshStyle = {
    maxWidth: '1166px',
    display: 'flex',
    flexDirection: 'column',
    margin: '3.625rem auto 7.375rem',
};

export const transientOptions: Parameters<CreateStyled>[1] = {
    shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const pxToRem = (px: number | string | String) => {
    let tempPx = px;
    if (typeof tempPx === 'string' || tempPx instanceof String)
        tempPx = parseInt(tempPx.replace('px', ''));

    return parseFloat(((1 / basePx) * tempPx).toFixed(maxRemPrecision)) + 'rem';
};

export const getBaseFontsizePercentage = () => {
    return (basePx / 16) * 100 + '%';
};

export const getCalcWidth = (padding: SXBreakpoint<string | number> = '1rem') => {
    if (typeof padding === 'string' || typeof padding === 'number')
        return {
            width: `calc(100% - ${padding})`,
        };
    if (typeof padding === 'object' && padding !== null) {
        return {
            width: Object.fromEntries(
                Object.entries(padding).map(([key, value]) => [key, `calc(100% - ${value})`]),
            ),
        };
    }
};
