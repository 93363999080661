import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UploadDdjj from './UploadDDJJ';
import { useTranslation } from 'react-i18next';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import { useApproveDeclarationChannel } from 'hooks/api/declarations.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { errorResponseHelper } from 'utils/helpers/errorHelper';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = () => ({
    ddjj: null,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            ddjj: Yup.string().required(tRequiredFieldError).nullable(),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UploadDdjjContainer = (props: Props) => {
    const { id, isOpen, close } = props;
    const { t } = useTranslation();
    const { mutate, isLoading } = useApproveDeclarationChannel();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        mutate(
            { id: String(id), filePath: values.ddjj },
            {
                onSuccess: () => setSnackBarMessage(t('ddjj_uploaded_successfully')),
                onError: error => setSnackBarMessage(errorResponseHelper(error), 'error'),
            },
        );
    }, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        isOpen,
        formik,
        isLoading,
        SnackBar,
        close,
        t,
    };

    return <UploadDdjj {...childProps} />;
};

const propTypes = {};

interface extraProps {
    id?: number;
    isOpen: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UploadDdjjContainer.propTypes = propTypes;

export default UploadDdjjContainer;
