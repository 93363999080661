import { Box, styled } from '@mui/material';
interface ArrowContainerProps {
    arrowPosition: 'left' | 'right';
}

export default {
    CarouselContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            margin: '0 auto',
            maxWidth: '1100px',
            cursor: 'grab',
            ':active': {
                cursor: 'grabbing',
            },
        }),
    ),
    ArrowContainer: styled(Box)<ArrowContainerProps>(({ theme, arrowPosition }) =>
        theme.unstable_sx({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            cursor: 'pointer',
            ...(arrowPosition === 'left' && {
                left: '10px',
            }),
            ...(arrowPosition === 'right' && {
                right: '10px',
            }),
        }),
    ),
};
