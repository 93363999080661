import { Box, Card, Typography, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    NotificationItem: styled(
        Card,
        transientOptions,
    )<{ $read: boolean }>(({ theme, $read }) => ({
        backgroundColor: $read ? theme.palette.action.hover : theme.palette.background.paper,
        padding: '1rem',
        margin: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        // borderRadius: '20px',
        ':last-child': {
            marginBottom: '0.5rem',
        },
    })),
    OptionsContainer: styled(Box)(({ theme }) => ({
        margin: '1rem',
        textAlign: 'right',
    })),
    IconsContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '1rem',
        justifyContent: 'flex-end',
    })),
    DateText: styled(Typography)(({ theme }) => ({
        fontSize: '11px',
    })),
};
