import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useGetChannelAccountStatus } from 'hooks/api/marketAccount.hooks';
import { useTranslation } from 'react-i18next';
import { tCommon } from 'constants/appConstants';
import { formatNumberMilis } from 'utils/helpers/commonHelper';
import ChannelAccountStatus from './ChannelAccountStatus';

const ChannelAccountStatusContainer = (props: Props) => {
    const { setSelectedInstrumentType = () => {} } = props;
    const { t } = useTranslation('home');
    const { data: ChannelData, isLoading } = useGetChannelAccountStatus();
    const customersQuantity = ChannelData?.customersQuantity ?? 0;
    const series = React.useMemo(() => {
        if (!ChannelData?.instruments) return [];
        return Object.values(ChannelData.instruments);
    }, [ChannelData]);

    const labels = React.useMemo(() => {
        if (!ChannelData?.instruments) return [];
        return Object.keys(ChannelData.instruments).map(instrumentType =>
            t(instrumentType, tCommon),
        );
    }, [ChannelData, t, tCommon]);

    const total = React.useMemo(() => {
        if (!ChannelData?.aum) return '0';
        return formatNumberMilis(ChannelData.aum.ars);
    }, [ChannelData]);

    const childProps = {
        ...props,
        aum: ChannelData?.aum,
        availableBalance: ChannelData?.availableBalance,
        customersQuantity,
        isLoading,
        series,
        labels,
        total,
        setSelectedInstrumentType,
        t,
    };

    return <ChannelAccountStatus {...childProps} />;
};

const propTypes = {
    isWallet: PropTypes.bool,
};

interface extraProps {
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelAccountStatusContainer.propTypes = propTypes;

export default ChannelAccountStatusContainer;
