import { Box, styled } from '@mui/material';
import StarEmpty from 'assets/icons/star-line.svg?react';
import StarFilled from 'assets/icons/star-line-filled.svg?react';

export default {
    StarEmpty: styled(StarEmpty)(({ theme }) => ({
        fill: 'white',
        width: '16px',
    })),
    StarFilled: styled(StarFilled)(({ theme }) => ({
        fill: 'white',
        width: '20px',
    })),
    IconContainer: styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.custom.vibrantblue,
        fill: 'white',
        borderRadius: '34px',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.98)',
        cursor: 'pointer',
        width: '34px',
        height: '34px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    })),
};
