import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomDot from './CustomDot';

const CustomDotContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CustomDot {...childProps} />;
};

const propTypes = {
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

interface extraProps {
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomDotContainer.propTypes = propTypes;

export default CustomDotContainer;
