import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ExchangeQuotation.styled';
import { formatNumber } from 'utils/helpers/commonHelper';

const ExchangeQuotation = (props: Props) => {
    const { ticker, price } = props;
    return (
        <St.Container>
            <St.TopComponent>{ticker}</St.TopComponent>
            <St.BottomComponent>{formatNumber(price ?? 0, 2)}</St.BottomComponent>
        </St.Container>
    );
};

const propTypes = {
    price: PropTypes.number.isRequired,
    ticker: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeQuotation.propTypes = propTypes;

export default ExchangeQuotation;
