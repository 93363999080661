import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CollateralModal from './CollateralModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCollateralExpiration } from 'hooks/api/orders.hooks';

const CollateralModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('home');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState<boolean>(false);
    const { data } = useGetCollateralExpiration();

    const collateral = data?.items || [];

    const childProps = {
        ...props,
        t,
        open,
        setOpen,
        navigate,
        collateral,
        hasCollateral: (data && collateral.length > 0) || false,
        title: t('collateral_modal_title'),
    };

    return <CollateralModal {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CollateralModalContainer.propTypes = propTypes;

export default CollateralModalContainer;
