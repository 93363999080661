import {
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    styled,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { transientOptions } from 'utils/helpers/stylesHelper';

interface CustomListItemButtonProps extends ListItemButtonProps {
    markContainer?: boolean;
}

export default {
    ListItemButton: styled(ListItemButton, {
        shouldForwardProp: prop => prop !== 'markContainer',
    })<CustomListItemButtonProps>(({ theme, markContainer }) => ({
        border: `1px solid ${theme.palette.custom.vibrantblue7}`,
        padding: '2px 7px',
        transition: '.2s',
        borderRadius: '20px',
        background: markContainer ? theme.palette.custom.vibrantblue3 : 'transparent',
        '&:hover': {
            backgroundColor: markContainer ? theme.palette.custom.vibrantblue3 : '#f5f5f5',
            opacity: markContainer ? '0.8' : '1',
        },
    })),
    ListItemIcon: styled(ListItemIcon)(() => ({ minWidth: 'min-content', paddingRight: '6px' })),
    ListItemText: styled(
        ListItemText,
        transientOptions,
    )<{ $markContainer: boolean }>(({ theme, $markContainer }) => ({
        fontFamily: 'Roboto',
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: '21.09px',
        letterSpacing: '0.0015em',
        textAlign: 'left',
        color: $markContainer ? theme.palette.background.default : theme.palette.custom.darkblue4,
    })),
    Radio: styled(
        Radio,
        transientOptions,
    )<{ $markContainer: boolean }>(({ theme, $markContainer }) => ({
        width: $markContainer ? '1.25rem' : '1.5rem',
        height: $markContainer ? '1.25rem' : '1.5rem',
        marginLeft: $markContainer ? '0.125rem' : '0',
        overflow: 'hidden',
        '&.Mui-checked .MuiSvgIcon-root': {
            backgroundColor: $markContainer ? theme.palette.background.default : 'inherit',
        },
        '&.Mui-checked': {
            color: $markContainer
                ? theme.palette.background.default
                : theme.palette.custom.vibrantblue,
        },
    })),
};
