import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelAccountInformation.styled';
import { ChannelAvailableBalance } from 'types/api/marketAccount.types';
import CustomRadioButton from 'components/common/CustomRadioButton';
import CurrencyLabel from 'components/common/CurrencyLabel';
import CustomIconCreator from 'components/common/CustomIconCreator';
import MoneyIcon from 'assets/icons/money.svg?react';

const ChannelAccountInformation = (props: Props) => {
    const { t, aumCurrency, visualizeUSD, setVisualizeUSD, availableBalance } = props;
    return (
        <St.Container>
            <St.WalletSubtitle>{t('account_information_wallet_subtitle')}</St.WalletSubtitle>
            <St.RadioButtonContainer>
                <CustomRadioButton
                    checked={visualizeUSD}
                    name={t('visualize_usd')}
                    onChange={() => setVisualizeUSD(!visualizeUSD)}
                    text={t('visualize_usd')}
                    value={String(visualizeUSD)}
                />
            </St.RadioButtonContainer>
            <CurrencyLabel
                variant="h4"
                currency={visualizeUSD ? 'USD' : 'ARS'}
                value={aumCurrency}
            />
            <St.AumText typography="subtitle1">{t('aum_text')}</St.AumText>
            <St.DottedDivider />
            <St.AvailableBalanceContainer>
                <St.AccountInformationSubtitle variant="subtitle1">
                    {t('account_information_available_balance_title')}
                </St.AccountInformationSubtitle>
                <St.MoneyLabelContainer>
                    <CustomIconCreator
                        icon={MoneyIcon}
                        sx={{
                            width: '1.7rem',
                            height: '1.7rem',
                            marginRight: '0.8rem',
                            overflow: 'visible',
                        }}
                    />
                    <CurrencyLabel
                        variant="h5"
                        currency="ARS"
                        value={availableBalance?.t1.ars ?? 0}
                    />
                </St.MoneyLabelContainer>
                <St.MoneyLabelContainer>
                    <CustomIconCreator
                        icon={MoneyIcon}
                        sx={{
                            width: '1.7rem',
                            height: '1.7rem',
                            marginRight: '0.8rem',
                            overflow: 'visible',
                        }}
                    />
                    <CurrencyLabel
                        variant="h5"
                        currency="USD"
                        value={availableBalance?.t1.usd ?? 0}
                    />
                </St.MoneyLabelContainer>
                <St.MoneyLabelContainer>
                    {(!availableBalance || availableBalance?.t1.usdc > 0) && (
                        <>
                            <CustomIconCreator
                                icon={MoneyIcon}
                                sx={{
                                    width: '1.7rem',
                                    height: '1.7rem',
                                    marginRight: '0.8rem',
                                    overflow: 'visible',
                                }}
                            />
                            <CurrencyLabel
                                variant="h5"
                                currency="USD-C"
                                value={availableBalance?.t1.usdc ?? 0}
                            />
                        </>
                    )}
                </St.MoneyLabelContainer>
            </St.AvailableBalanceContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    visualizeUSD: PropTypes.bool.isRequired,
    aumCurrency: PropTypes.number.isRequired,
};

interface extraProps {
    availableBalance?: ChannelAvailableBalance;
    setVisualizeUSD: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelAccountInformation.propTypes = propTypes;

export default ChannelAccountInformation;
