import { Box, FormControlLabel, styled } from '@mui/material';
import { Form } from 'formik';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Form: styled(Form)(({ theme }) => ({
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        margin: pxToRem('30px'),
    })),
    DetailTitle: styled(Box)(({ theme }) => ({
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '1em',
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '0.5em',
        padding: '0.3em 1em',
        justifyContent: 'center',
        width: '100%',
    })),
    FormControlLabel: styled(FormControlLabel)(({ theme }) => ({ mb: 0.5 })),
};
