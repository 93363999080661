import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FundDetailCard from './FundDetailCard';
import { useTranslation } from 'react-i18next';
import { GetFundDetailResponse } from 'types/api/instruments.types';

const FundDetailCardContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <FundDetailCard {...childProps} />;
};

const propTypes = {};

interface extraProps {
    fundDetail: GetFundDetailResponse;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundDetailCardContainer.propTypes = propTypes;

export default FundDetailCardContainer;
