import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ToolsList.styled';
import { Box, IconButton } from '@mui/material';
import CustomIconCreator from 'components/common/CustomIconCreator';
import SubMenu from 'components/common/SubMenu';
import NotificationsTool from 'components/common/NotificationsTool';
import Sun from 'assets/svg/sun.svg?react';

const ToolsList = (props: Props) => {
    const { profileOptions, t, openProfile, setOpenProfile } = props;
    return (
        <St.ToolsListWrapper>
            {/* <Box style={{ position: 'relative' }}>
                <St.Tooltip
                    title={t('dark_mode')}
                    $radiusSmall={false}
                    onClick={() => console.log('sun')}>
                    <St.IconButton>
                        <CustomIconCreator icon={Sun} />
                    </St.IconButton>
                </St.Tooltip>
            </Box> */}
            <NotificationsTool />
            <St.ProfileTool onClick={() => setOpenProfile(!openProfile)}>
                <IconButton>
                    <St.MenuLine />
                </IconButton>
            </St.ProfileTool>
            <St.SubMenuWrapperProfile>
                <SubMenu
                    open={openProfile}
                    menu={{ subRoutes: profileOptions }}
                    isProfileSubMenu={true}
                    setOpenProfile={setOpenProfile}
                />
            </St.SubMenuWrapperProfile>
        </St.ToolsListWrapper>
    );
};

const propTypes = {
    openProfile: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

interface ProfileOption {
    title: string;
    path?: string;
    handleClick?: () => void;
}

interface extraProps {
    profileOptions: ProfileOption[];
    setOpenProfile: (open: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ToolsList.propTypes = propTypes;

export default ToolsList;
