import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FundDetail from './FundDetail';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { isRecommendedForYourProfile } from 'utils/helpers/commonHelper';

const FundDetailContainer = (props: Props) => {
    const { fundDetail } = props;
    const { t } = useTranslation('market');
    const { userData } = React.useContext(AuthContext);

    const recommendationText = isRecommendedForYourProfile(
        fundDetail.profile as 'AGGRESSIVE' | 'MODERATE' | 'CONSERVATIVE',
        userData?.info?.investmentProfile,
    );
    const fundType = `${fundDetail.fundInfo?.summaryFundType}, ${t((fundDetail.profile || '').toLocaleLowerCase(), { ns: 'onboarding' })}`;

    const childProps = {
        ...props,
        recommendationText,
        fundType,
        t,
    };

    return <FundDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    fundDetail: GetFundDetailResponse;
    isMarketClosed: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundDetailContainer.propTypes = propTypes;

export default FundDetailContainer;
