import { AppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { retainingMeshStyle } from 'utils/styles/commonStyles';

export default {
    AppBarContainer: styled(AppBar)(({ theme }) =>
        theme.unstable_sx({
            backgroundColor: theme.palette.background.default,
            height: '5.625rem',
            top: '0',
            borderBottom: `1px dashed ${theme.palette.custom.darkblue10}`,
            boxShadow: 'none',
        }),
    ) as typeof AppBar,
    CommonToolbarContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            ...retainingMeshStyle,
            display: 'flex',
            zIndex: 100,
            width: '100%',
            height: '44px',
            margin: 'auto',
        }),
    ),
    CommonToolbar: styled(Toolbar)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: 'none',
            flexDirection: 'row',
            minHeight: 'fit-content !important',
            height: 'inherit',
        }),
    ) as typeof Toolbar,
    DesktopNavigatorContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            columnGap: '2px',
            position: 'relative',
        }),
    ) as typeof Box,
};
