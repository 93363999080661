import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ContactInfoInternalWrapper: styled(Box)(({ theme }) => ({
        margin: `${pxToRem(80)} ${pxToRem(50)}`,
        [theme.breakpoints.up('sm')]: {
            margin: `${pxToRem(80)} ${pxToRem(60)} `,
        },
        [theme.breakpoints.up('md')]: {
            margin: `${pxToRem(80)} ${pxToRem(150)} `,
        },
    })) as typeof Box,
    ContacInfo: styled(Typography)(() => ({
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28.13px',
    })),
    ContacInfoTitle: styled(Typography)(({ theme }) => ({
        color: '#307FDD',
        fontWeight: 300,
        fontSize: '32px',
        letterSpacing: '-1.5%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '38px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '68px',
            lineHeight: '79.69px',
        },
    })) as typeof Typography,
    ContactInfoValidationInternalBox: styled(Box)(({ theme }) => ({
        marginTop: pxToRem(50),
        marginBottom: pxToRem(50),
    })) as typeof Box,
    ContacInfoValidation: styled(Typography)(() => ({
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28.13px',
    })) as typeof Typography,
    ContacInfoValidationTitle: styled(Typography)(({ theme }) => ({
        color: '#307FDD',
        fontWeight: 300,
        fontSize: '30px',
        letterSpacing: '-1.5%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '68px',
            lineHeight: '79.69px',
        },
    })) as typeof Typography,

    ButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        gap: '1.5rem',
        marginTop: '2rem',
        justifyContent: 'center',
    })),
    CustomLabelWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
    })),
    Subtitle: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkBlue2,
        paddingBottom: pxToRem(50),
        borderBottom: `1px dotted ${theme.palette.custom.darkblue7}`,
        marginBottom: pxToRem(20),
    })) as typeof Typography,
};
