import { Box, DialogActions, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ModalTitle: styled(Typography)(({ theme }) => ({
        color: 'black',
        fontFamily: 'Roboto',
        fontWeight: '600',
    })),

    ModalContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: pxToRem('40px'),
    })),

    DDJJContainer: styled(Box)(({ theme }) => ({
        border: '1px solid black',
        width: '100%',
        padding: pxToRem('10px'),
        height: pxToRem('360px'),
        overflow: 'scroll',
    })),

    ClientDataContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    })),

    ClientDataTitle: styled(Typography)(({ theme }) => ({
        color: 'black',
        fontFamily: 'Roboto',
        fontWeight: '600',
        textDecoration: 'underline',
        marginTop: pxToRem('5px'),
    })),

    PDFContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })),

    DialogActions: styled(DialogActions)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        gap: pxToRem('10px'),
        padding: pxToRem('20px'),
    })),

    CheckLabel: styled(Typography)(({ theme }) => ({
        marginTop: pxToRem('2px'),
    })),
};
