import { Box, Typography, styled } from '@mui/material';

export default {
    Wrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            padding: '0 0 .5rem',
            borderBottom: `1px dotted ${theme.palette.custom.darkblue7}`,
        }),
    ),
    Typography: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue2,
            marginBottom: '2px',
            fontSize: '0.875rem',
            lineHeight: '16.41px',
            letterSpacing: '0.0025em',
        }),
    ),
    Value: styled('span')(({ theme }) => ({
        color: theme.palette.custom.darkblue2,
        padding: '8px 0',
        marginBottom: '10px',
        fontSize: '1.125rem',
        fontWeight: '700',
        lineHeight: '21.09px',
        letterSpacing: '0.0015em',
    })),
};
