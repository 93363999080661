import { Box, styled, SvgIcon } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
import loginBackground from 'assets/img/general/loginBackground2.jpg';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            flex: 1,
            backgroundImage: `url(${loginBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100vh',
            padding: '0',
            // overflow: 'hidden',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
        }),
    ),
    ArticleWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            height: '100%',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '2rem',
                paddingRight: '0',
            },
        }),
    ) as typeof Box,
    LogoContainer: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            paddingLeft: '2rem',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
                paddingLeft: '4.5rem',
            },
        }),
    ),
    LoginFormWrapper: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            // width: '100%',
            marginTop: '1rem',
            paddingLeft: '1rem',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
                paddingLeft: '2rem',
            },
        }),
    ),
    Logo: styledMui(SvgIcon)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '180px',
            width: 'auto',
            height: 'auto',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '280px',
            },
        }),
    ) as unknown as typeof SvgIcon,
};
