import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonTablePagination from './CommonTablePagination';
import { ICommonPagination } from 'types/common/tableLayout.types';

const CommonTablePaginationContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <CommonTablePagination {...childProps} />;
};

const propTypes = {};

interface extraProps extends ICommonPagination {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTablePaginationContainer.propTypes = propTypes;

export default CommonTablePaginationContainer;
