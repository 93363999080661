import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketTermCurrencyUpdate from './MarketTermCurrencyUpdate';
import { useTranslation } from 'react-i18next';
import { CommonSelectType, Currency } from 'types/common/general.types';
import { useLocation } from 'react-router-dom';
import { GetInstrumentsItem } from 'types/api/instruments.types';

const MarketTermCurrencyUpdateContainer = (props: Props) => {
    const { currentTerm, instrument, setCurrentTerm, setCurrentCurrency, setCurrency, setTerm } =
        props;
    const { t } = useTranslation();
    const location = useLocation();

    const handleTermChange = (term: CommonSelectType) => {
        setTerm(term);
    };
    const handleCurrencyChange = (currency: CommonSelectType) => {
        setCurrency(currency);
    };

    const handleUpdateTermAndCurrency = (selectedTerm: string, selectedCurrency: string) => {
        if (selectedTerm !== currentTerm) {
            localStorage.setItem('term', selectedTerm);
            setCurrentTerm && setCurrentTerm(selectedTerm);
        }
        const currencyTicker = instrument?.tickers.find(
            i => i.currency === selectedCurrency,
        )?.ticker;
        setCurrentCurrency && setCurrentCurrency(currencyTicker);
        const ticker = location.pathname.split('/')[3];
        if (currencyTicker && ticker !== currencyTicker) {
            window.location.replace(`/market/instrument/${currencyTicker}`);
        }
    };

    const childProps = {
        ...props,
        t,

        handleTermChange,
        handleCurrencyChange,
        handleUpdateTermAndCurrency,
    };

    return <MarketTermCurrencyUpdate {...childProps} />;
};

const propTypes = {
    setCurrentTerm: PropTypes.func,
    setCurrentCurrency: PropTypes.func,
    disableButton: PropTypes.bool.isRequired,
};

interface extraProps {
    currencies: CommonSelectType[] | null;
    currentTerm: '24hs' | 'CI';
    currentCurrency: Currency;
    instrument: GetInstrumentsItem | null;
    currency: CommonSelectType | null;
    setCurrency: (value: CommonSelectType | null) => void;
    term: CommonSelectType | null;
    setTerm: (value: CommonSelectType | null) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketTermCurrencyUpdateContainer.propTypes = propTypes;

export default MarketTermCurrencyUpdateContainer;
