import { Box, Stack, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(({ theme }) => ({})),
    FooterButtons: styled(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        margin: pxToRem('16px'),
    })),
    NoDateData: styled(Typography)(() => ({
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        margin: '.5rem 0 0 1rem',
    })),
};
