import { InferPropsExtended } from 'utils/helpers/proptypesHelper.js';
import OpenMyAccountPage from './OpenMyAccountPage';
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import i18n from 'utils/i18n';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useRegisterEmail } from 'hooks/api/auth.hooks';
import { loggedOutSubroutesPaths } from 'router/routesPaths';

const getInitialValues = () => ({
    email: '',
});

const tErrorsContext = { ns: 'errors' };
const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            email: Yup.string()
                .required(tRequiredFieldError)
                .email(String(i18n.t('required_email', tErrorsContext)))
                .trim(),
        }),
    );

const OpenMyAccountPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('auth');
    const navigate = useNavigate();
    const { error, isLoading, mutate } = useRegisterEmail();

    const getInto = () => {
        navigate(loggedOutSubroutesPaths.login);
    };

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        mutate(values.email, {
            onSuccess() {
                navigate(loggedOutSubroutesPaths.emailSent, {
                    state: { isSigningUp: true, email: values.email.trim() },
                });
            },
        });
    }, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        formik,
        getInto,
        error,
        isLoading,
    };
    return <OpenMyAccountPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OpenMyAccountPageContainer.propTypes = propTypes;

export default OpenMyAccountPageContainer;
