import { Box, styled } from '@mui/material';
import AlertNoData from 'components/common/AlertNoData';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Box: styled(Box)(() => ({
        maxWidth: pxToRem('557px'),
        width: '100%',
        '& .MarketSearchBar': {
            maxWidth: `calc(${pxToRem('557px')} - 1.6rem)`,
            width: 'calc(100% - 1.6rem)',
            height: '3.025rem',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            '& div:nth-of-type(2)': {
                minWidth: '2.1215rem',
                width: '2.1215rem',
                height: '2.1215rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    })),
    DropdownContainer: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            top: '110%',
            left: '0',
            width: '100%',
            border: `1px solid ${theme.palette.custom.darkblue8}`,
            borderRadius: '0 0 1rem 1rem',
            listStyle: 'none',
            overflow: 'hidden',
            zIndex: '10',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            background: theme.palette.background.default,
            margin: 0,
            padding: 0,
        }),
    ),
    DropdownItem: styled('li')(({ theme }) =>
        theme.unstable_sx({
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            transition: '0.2s',
            fontSize: '0.9rem',
            '&:hover': {
                background: theme.palette.custom.vibrantblue2,
                color: theme.palette.primary.contrastText,
            },
        }),
    ),
    AlertNoData: styled(AlertNoData)(() => ({})),
    AlerNoDataWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            margin: 'auto',
            borderRadius: '50% !important',
            '& div:nth-of-type(2)': {
                width: 'fit-content !important',
                height: 'fit-content !important',
            },
        }),
    ),
};
