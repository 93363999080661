import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import OrderDetail from './OrderDetail';
import { GetMarketOrderItem } from 'types/api/orders.types';

const OrderDetailContainer = (props: Props) => {
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <OrderDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetMarketOrderItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OrderDetailContainer.propTypes = propTypes;

export default OrderDetailContainer;
