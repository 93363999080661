import { DialogTitle, IconButton, styled } from '@mui/material';

export default {
    DialogTitle: styled(DialogTitle)(({ theme }) => ({
        position: 'relative',
    })),
    CloseIconButton: styled(IconButton)(({ theme }) => ({
        color: theme.palette.grey[500],
        position: 'absolute',
        right: 8,
        top: 9,
    })),
};
