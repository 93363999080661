import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MobileLayout from './MobileLayout';
import { useTranslation } from 'react-i18next';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const MobileLayoutContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [openSubmenu, setOpenSubmenu] = React.useState('');

    const handleOpenSubmenu = (menu: string) => setOpenSubmenu(openSubmenu !== menu ? menu : '');

    const childProps = {
        ...props,
        t,
        isOpen,
        setIsOpen,
        openSubmenu,
        handleOpenSubmenu,
    };

    return <MobileLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {
    routes: Array<SubRouteInfo>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MobileLayoutContainer.propTypes = propTypes;

export default MobileLayoutContainer;
