import { styled } from '@mui/material/styles';
import { Box, Stack, TextField } from '@mui/material';
import { Form } from 'formik';
import { LoadingButton } from '@mui/lab';

export default {
    Form: styled(Form)(({ theme }) => ({
        // margin: 'auto',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    })) as typeof Box,
    TextFieldStyled: styled(TextField)(({ theme }) => ({
        width: '361px',
        marginTop: '16px !important',
        marginRight: '10px !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '70%',
        },
        '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: '-20px',
        },
        '& .MuiInputBase-root': {
            height: '40px',
        },
    })) as typeof TextField,
    LoadingButtonStyled: styled(LoadingButton)(({ theme }) => ({
        width: '361px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '70%',
        },
        height: '40px',
    })) as typeof LoadingButton,
    BackButtonStyled: styled(LoadingButton)(({ theme }) => ({
        width: '104px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '70%',
        },
    })) as typeof LoadingButton,
    BackButtonContainer: styled(Stack)(({ theme }) => ({
        marginTop: '2rem',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            padding: '0 10%',
        },
    })) as typeof Stack,
    ResponsiveStack: styled(Stack)(({ theme }) => ({
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    })) as typeof Stack,
};
