import * as React from 'react';
import * as Yup from 'yup';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DetailWithdrawalModal from './DetailWithdrawalModal';
import { GetWithdrawalsItem } from 'types/api/cashflow.types';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';

const DetailWithdrawalModalContainer = (props: Props) => {
    const { row, close, setSnackBarMessage } = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
        row,
        close,
    };

    return <DetailWithdrawalModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetWithdrawalsItem;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DetailWithdrawalModalContainer.propTypes = propTypes;

export default DetailWithdrawalModalContainer;
