import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MobileLayout.styled';
import AppBar from './AppBar';
import Drawer from './Drawer';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const MobileLayout = (props: Props) => {
    const { handleOpenSubmenu, isOpen, openSubmenu, routes, setIsOpen } = props;
    return (
        <St.NavBarMobileLayoutMain component="header">
            <AppBar setIsOpen={setIsOpen} />
            <Drawer
                open={isOpen}
                setIsOpen={setIsOpen}
                selectedMenu={openSubmenu}
                routes={routes}
                setSelectedMenu={handleOpenSubmenu}
            />
        </St.NavBarMobileLayoutMain>
    );
};

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    openSubmenu: PropTypes.string.isRequired,
    handleOpenSubmenu: PropTypes.func.isRequired,
};

interface extraProps {
    routes: Array<SubRouteInfo>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MobileLayout.propTypes = propTypes;

export default MobileLayout;
