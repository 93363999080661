import { styled as styledMui } from '@mui/material/styles';
import { Box } from '@mui/material';

export default {
    MainWrapper: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.background.default,
        backgroundSize: 'cover',
    })),
    SectionWrapper: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: '1rem 0',
            scrollBehavior: 'unset',
            maxWidth: 'calc(1400px + 4rem)',
            width: 'calc(100% - 2rem)',
            margin: 'auto',
        }),
    ) as typeof Box,
    PageWrapper: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            padding: { xs: '0', sm: '0 1.25rem', md: '1.25rem 1.25rem 1rem 1.25rem' },
            flexDirection: { xs: 'column', md: 'row' },
        }),
    ) as typeof Box,
};
