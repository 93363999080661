import { Box, styled, Typography } from '@mui/material';

export default {
    Box: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            marginBottom: '10%',
            marginLeft: '15%',
            color: 'black',
            backgroundColor: theme.palette.custom.vibrantblue10,
        }),
    ),
    InfoContainer: styled(Box)(({ theme }) => ({
        marginBottom: theme.spacing(5),
        marginLeft: theme.spacing(8),
    })),
    InfoSection: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        color: theme.palette.custom.darkblue4,
    })),
    InfoTitle: styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        marginBottom: theme.spacing(1),
        color: theme.palette.custom.darkblue4,
    })),
    InfoSubtitle: styled(Typography)(({ theme }) => ({
        marginRight: theme.spacing(1),
        color: theme.palette.custom.darkblue4,
        fontWeight: 'bold',
    })),
    InfoContent: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
    })),
};
