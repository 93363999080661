import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DepositModal from './DepositModal';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { tRequiredFieldError } from 'constants/appConstants';
import { CreateDepositRequest } from 'types/api/cashflow.types';
import { useCreateDeposit, useGetBankAccounts } from 'hooks/api/cahsflow.hooks';
import { AuthContext } from 'context/auth.context';
import { useState } from 'react';
import { AlertColor } from '@mui/material';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCommonGrid } from 'context/common/commonGrid.context';
import { useTranslation } from 'react-i18next';

const getInitialValues = () => ({
    amount: '0',
    document: null,
    sourceAccount: null,
    destinationAccount: null,
});

const getValidationSchema = () =>
    Yup.object().shape({
        amount: Yup.number()
            .typeError(tRequiredFieldError)
            .required(tRequiredFieldError)
            .moreThan(0, tRequiredFieldError),
        document: Yup.string().required(tRequiredFieldError).nullable(),
        sourceAccount: Yup.object().required(tRequiredFieldError),
        destinationAccount: Yup.object().required(tRequiredFieldError),
    });

const DepositModalContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { setNeedRefresh } = useCommonGrid();
    const { data: bankAccounts, isLoading: isBankAccountsLoading } = useGetBankAccounts(
        {} as GridHookParams,
    );
    const [sourceAccount, setSourceAccount] = useState();
    const { customerCode } = React.useContext(AuthContext);
    const { createDeposit, createIsLoading } = useCreateDeposit();

    const handleSubmit = React.useCallback(async (values: FormikValues) => {}, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const handleSourceAccount = (newValue: any) => {
        setSourceAccount(newValue?.id);
        formik.setFieldValue('sourceAccount', newValue);
    };

    const handleSend = async () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                const today = new Date().toISOString();
                const depositData = {
                    transferDate: today,
                    customerCode,
                    amount: parseFloat(formik.values.amount),
                    fileUrl: formik.values.document ?? '',
                    accountUuid: sourceAccount ?? '',
                };

                createDeposit(depositData as CreateDepositRequest, {
                    onSuccess() {
                        setSnackBarMessage(t('create_order_success'), 'success');
                        formik.resetForm();
                        setNeedRefresh(true);
                        close();
                    },
                    onError: () => {
                        setSnackBarMessage(t('deposit_create_error'), 'error');
                    },
                });
            }
        });
    };

    const childProps = {
        ...props,
        t,
        formik,
        handleSend,
        handleSourceAccount,
        bankAccounts,
        isBankAccountsLoading,
        createIsLoading,
    };

    return <DepositModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositModalContainer.propTypes = propTypes;

export default DepositModalContainer;
