import { styled, Link } from '@mui/material';
import { Form } from 'formik';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Form: styled(Form)(({ theme }) => ({
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        margin: pxToRem('20px'),
    })),

    StyledLink: styled(Link)(({ theme }) => ({
        marginTop: theme.spacing(2),
        display: 'block',
        maxWidth: 'fit-content',
        cursor: 'pointer',
        alignSelf: 'center',
    })),
};
