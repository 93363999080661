import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PasswordField from './PasswordField';

const PasswordFieldContainer = (props: Props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const childProps = {
        ...props,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
    };

    return <PasswordField {...childProps} />;
};

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    inputProps: PropTypes.any,
};

interface extraProps {
    variant?: 'standard' | 'outlined' | 'filled';
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    error?: string;
    autoFocus?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PasswordFieldContainer.propTypes = propTypes;

export default PasswordFieldContainer;
