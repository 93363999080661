import { styled as styledMui } from '@mui/material/styles';
import { Box } from '@mui/material';

export default {
    ContainerForm: styledMui(Box)(({ theme }) => ({
        display: 'flex',
    })),
    BoxForm: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        // padding: '20px',
        borderRadius: '10px',
        marginTop: '20px',
    })),
};
