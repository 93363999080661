import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MarketOthersPage.styled';
import ActionButton from 'components/common/ActionButton/ActionButton';
import Notepad from 'assets/img/general/notepad.png';

const MarketOthersPage = (props: Props) => {
    const { t } = props;
    return (
        <St.SectionWrapper>
            <St.Img src={Notepad} alt="notepad" />
            <St.Title> {t('market_others_title')} </St.Title>
            <St.Subtitle>{t('market_others_subtitle')}</St.Subtitle>
            <St.Ul>
                <St.Li>{t('market_others_li_futures')}</St.Li>
                <St.Li>{t('market_others_li_options')}</St.Li>
                <St.Li>{t('market_others_li_sureties')}</St.Li>
                <St.Li>{t('market_others_li_payments_checks')}</St.Li>
            </St.Ul>
            <ActionButton
                variant="contained"
                type="button"
                size="large"
                onClick={() =>
                    (window.location.href =
                        'mailto:contactos@consultatioinvestments.com?subject=Consulta Operaciones')
                }>
                {t('market_others_contact_button')}
            </ActionButton>
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketOthersPage.propTypes = propTypes;

export default MarketOthersPage;
