import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomModalAuth.styled';
import { Modal, Typography } from '@mui/material';
import VerificationInput from 'react-verification-input';
import PropTypes from 'prop-types';
import ActionButton from '../ActionButton';

const CustomModalAuth = (props: Props) => {
    const {
        open,
        t,
        handleClose,
        handleChange,
        onSubmit,
        title = t('auth_modal_title'),
        buttonText = t('auth_modal_button'),
        errorOtp,
        otp,
        isLoadingSubmit,
    } = props;

    return (
        <Modal open={open} onClose={handleClose || undefined}>
            <St.ModalBox>
                <St.ContainerBox>
                    <Typography align="center">{title}</Typography>
                    {isLoadingSubmit ? (
                        <St.CustomLoaderWrapper>
                            <St.CustomLoader />
                        </St.CustomLoaderWrapper>
                    ) : (
                        <>
                            <VerificationInput
                                length={6}
                                onChange={handleChange}
                                autoFocus={true}
                                validChars="0-9"
                            />
                            {errorOtp && <St.ErrorText text={errorOtp} />}
                            <ActionButton
                                variant="contained"
                                onClick={() => onSubmit(otp)}
                                sx={{ width: '100%' }}>
                                {buttonText}
                            </ActionButton>
                        </>
                    )}
                </St.ContainerBox>
            </St.ModalBox>
        </Modal>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
};

interface extraProps {
    open: boolean;
    handleChange: (value: string) => void;
    onSubmit: (otp: string) => void;
    title?: string;
    buttonText?: string;
    errorOtp: string | null;
    otp: string;
    isLoadingSubmit: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomModalAuth.propTypes = propTypes;

export default CustomModalAuth;
