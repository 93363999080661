/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketSearchBar from './MarketSearchBar';

const MarketSearchBarContainer = (props: Props) => {
    const { onSearch, defaultValue } = props;
    const [query, setQuery] = React.useState(defaultValue || '');
    const [debouncedQuery, setDebouncedQuery] = React.useState(query);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    React.useEffect(() => {
        onSearch(debouncedQuery);
    }, [debouncedQuery]);

    const childProps = {
        ...props,
        query,
        setQuery,
    };

    return <MarketSearchBar {...childProps} />;
};

const propTypes = {};

interface extraProps {
    onSearch: (query: string) => void;
    placeholder: string;
    defaultValue: string;
    blueMode?: boolean;
    extraComponent?: React.ReactNode;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

MarketSearchBarContainer.propTypes = propTypes;

export default MarketSearchBarContainer;
