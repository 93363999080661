import { Box, styled, Typography } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            flex: 1,
            margin: { xs: '0rem 1rem', md: '0rem 4rem' },
        }),
    ) as typeof Box,
    TitleWrapper: styled(Box)(() => ({
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '1.5rem',
    })) as typeof Box,
    Title: styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.main,
    })),
};
