import { Dialog, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Dialog: styled(
        Dialog,
        transientOptions,
    )<{ $maxW?: string }>(({ theme, $maxW, maxWidth }) =>
        theme.unstable_sx({
            '& .MuiPaper-root.MuiDialog-paper': {
                maxWidth: $maxW,
                width: '100%',
            },
        }),
    ),
};
