import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonTableActions from './CommonTableActions';
import { useTranslation } from 'react-i18next';
import { ICommonActions } from 'types/common/tableLayout.types';

const CommonTableActionsContainer = (props: Props) => {
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <CommonTableActions {...childProps} />;
};

const propTypes = {};

interface extraProps extends ICommonActions {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTableActionsContainer.propTypes = propTypes;

export default CommonTableActionsContainer;
