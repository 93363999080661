import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfileDeleteAccountPage from './ProfileDeleteAccountPage';
import { useTranslation } from 'react-i18next';
import { useGetPendingRemoveAccount } from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';

const ProfileDeleteAccountPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('profile');

    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const { data, isLoading } = useGetPendingRemoveAccount();

    const childProps = {
        ...props,
        SnackBar,
        setSnackBarMessage,
        hasPendingRequest: data !== null,
        isLoading,
        t,
    };

    return <ProfileDeleteAccountPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileDeleteAccountPageContainer.propTypes = propTypes;

export default ProfileDeleteAccountPageContainer;
