import { useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CreateBankAccountModal from './CreateBankAccountModal';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { BROKER, DESTINATION, LOCAL } from 'constants/appConstants';

const CreateBankAccountModalContainer = (props: Props) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState('');

    const options = [
        { value: LOCAL, label: t('local_bank') },
        { value: DESTINATION, label: t('exterior_bank') },
        { value: BROKER, label: t('broker_bank') },
    ];

    const childProps = {
        ...props,
        selectedOption,
        setSelectedOption,
        options,
        t,
    };

    return <CreateBankAccountModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateBankAccountModalContainer.propTypes = propTypes;

export default CreateBankAccountModalContainer;
