import React from 'react';
import { useTranslation } from 'react-i18next';
import { WSFundTableKey, WSTableKey } from 'types/context/prices.context.types';

type Column<T> = {
    text: string;
    key: T;
    align?: 'left' | 'center';
};

const tBase = 'market_table_';

const useColumns = () => {
    const { t } = useTranslation('market');

    const { defaultColumn, fundsColumn } = React.useMemo(() => {
        const defaultColumn: Array<Column<WSTableKey>> = [
            { text: t(`${tBase}species`), key: 'ticker', align: 'left' },
            { text: t(`${tBase}term`), key: 'term', align: 'left' },
            { text: t(`${tBase}purchase_quantity`), key: 'buyQuantity', align: 'center' },
            { text: t(`${tBase}purchase_price`), key: 'buyPrice', align: 'center' },
            { text: t(`${tBase}sale_price`), key: 'sellPrice', align: 'center' },
            { text: t(`${tBase}sale_quantity`), key: 'sellQuantity', align: 'center' },
            { text: t(`${tBase}last`), key: 'lastPrice' },
            { text: t(`${tBase}variation`), key: 'variation' },
            { text: t(`${tBase}nominal_volume`), key: 'nominalVolume', align: 'center' },
            { text: t(`${tBase}opening`), key: 'openingPrice' },
            { text: t(`${tBase}maximum`), key: 'maximum' },
            { text: t(`${tBase}minimum`), key: 'minimum' },
            { text: t(`${tBase}previous`), key: 'previous' },
            { text: t(`${tBase}traded_amount`), key: 'tradedAmount' },
        ];

        const fundsColumn: Array<Column<WSFundTableKey>> = [
            { text: t(`${tBase}name`), key: 'name', align: 'left' },
            { text: t(`${tBase}VCP`), key: 'yieldVcp', align: 'left' },
            { text: t(`${tBase}DTD`), key: 'yieldVcpPercentage' },
            { text: t(`${tBase}MTD`), key: 'yieldMtd' },
            { text: t(`${tBase}QTD`), key: 'yieldQtd' },
            { text: t(`${tBase}YTD`), key: 'yieldYtd' },
            { text: t(`${tBase}patrimony`), key: 'patrimonySummary' },
            { text: t(`${tBase}currency`), key: 'currencyTypeSummary' },
            { text: t(`${tBase}profile`), key: 'profile' },
            { text: t(`${tBase}liquidity`), key: 'liquiditySummary' },
        ];
        return { defaultColumn, fundsColumn };
    }, [t]);

    return { funds: fundsColumn, default: defaultColumn };
};

export default useColumns;
