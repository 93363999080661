import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';

const DeleteBankAccount = ({ isLoading, handleSubmit, close, t }: Props) => (
    <Box display="flex" flexDirection="column" gap="10px">
        <Typography>{t('delete_bank_account_question')}</Typography>

        <Box display="flex" gap="10px">
            <ActionButton fullWidth variant="outlined" onClick={close} disabled={isLoading}>
                {t('cancel')}
            </ActionButton>
            <ActionButton
                fullWidth
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleSubmit}>
                {t('delete_text')}
            </ActionButton>
        </Box>
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    isLoading: boolean;
    handleSubmit(): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteBankAccount.propTypes = propTypes;

export default DeleteBankAccount;
