import { createTheme, responsiveFontSizes } from '@mui/material';
import { red } from '@mui/material/colors';
import { CustomThemeOptions } from './types/theme.types';
import { pxToRem } from 'utils/helpers/stylesHelper';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        custom: {
            [key: string]: any;
        };
    }
}

const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#ffffff',
        },
        error: {
            main: red.A400,
        },
        primary: {
            light: '#D6E5F8',
            main: '#307FDD',
            contrastText: '#fff',
        },
        secondary: {
            light: '#D1D6DD',
            main: '#173254',
            dark: '#826fa9',
            contrastText: '#212121',
        },
        custom: {
            whiteSmoke: '#f0f0f0',
            red: '#ff5e36',
            rederror: '#F1AEAE',
            purple: '#8b51f0',
            darkblue: '#173254',
            darkblue2: '#2e4665',
            darkblue3: '#455b76',
            darkblue4: '#5d7087',
            darkblue5: '#748498',
            darkblue6: '#8B99A9',
            darkblue7: '#A2ADBB',
            darkblue8: '#B9C1CC',
            darkblue9: '#D1D6DD',
            darkblue10: '#E8EAEE',
            vibrantblue: '#307FDD',
            vibrantblue2: '#307FDD',
            vibrantblue3: '#5999E4',
            vibrantblue4: '#6EA5E7',
            vibrantblue5: '#83B2EB',
            vibrantblue6: '#98BFEE',
            vibrantblue7: '#ACCCF1',
            vibrantblue8: '#C1D9F5',
            vibrantblue9: '#D6E5F8',
            vibrantblue10: '#EAF2FC',
            vibrantblue11: '#D6E5F8',
            lightGreen: '#34C759',
            lightRed: '#FF5E36',
            lightYellow: '#FFEE93',
            green: '#7ed321',
            orange: '#f6a623',
            red2: '#FF3B30',
        },
    },
    typography: {
        caption: {
            fontFamily: 'Roboto',
            fontSize: pxToRem('12px'),
            fontWeight: 400,
        },
        h1: {
            fontFamily: 'Roboto',
            fontWeight: '300',
            fontSize: pxToRem('96px'),
        },
        h2: {
            fontFamily: 'Roboto',
            fontWeight: '300',
            fontSize: pxToRem('68px'),
        },
        h3: {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: pxToRem('48px'),
        },
        h4: {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: pxToRem('34px'),
        },
        h5: {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: pxToRem('24px'),
        },
        h6: {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: pxToRem('20px'),
        },
        body1: {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: pxToRem('16px'),
        },
        fontFamily: [
            'Roboto',
            'Montserrat',
            '-apple-system, system-ui',
            'BlinkMacSystemFont',
            'Segoe UI',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
} as CustomThemeOptions);

export default responsiveFontSizes(defaultTheme);
