import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BuySellSwitch from './BuySellSwitch';
import { useTranslation } from 'react-i18next';

const BuySellSwitchContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
    };

    return <BuySellSwitch {...childProps} />;
};

const propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    buyText: PropTypes.string,
    sellText: PropTypes.string,
};

interface extraProps {
    disableBtn1?: boolean;
    disableBtn2?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellSwitchContainer.propTypes = propTypes;

export default BuySellSwitchContainer;
