import { Box, styled } from '@mui/material';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    ContainerIcon: styled(Box)(({ theme }) => ({
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        padding: '5px',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: 1000,
        width: '30px',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.custom.vibrantblue10,
    })),
};
