import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReportsCollateralsPage from './ReportsCollateralsPage';
import { useTranslation } from 'react-i18next';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { formatDate } from 'utils/helpers/dateHelper';
import { ReportCollateral } from 'types/pages/reports.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import moment from 'moment';
import { AuthContext } from 'context/auth.context';

const ReportsCollateralsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_collaterals_page_column_';
    const { marketAccounts } = React.useContext(AuthContext);

    const current = moment();
    const defaultFilters = [{ query: 'liquidationDate', queryValue: current.format('YYYY-MM-DD') }];

    const columns: CommonColumn<ReportCollateral>[] = [
        {
            id: 'customerCode',
            name: t(`${tBase}customer_code`),
            selector: row => row.customerCode ?? '-',
            exportOption: row => row.customerCode ?? '-',
        },
        {
            id: 'typeCode',
            name: t(`${tBase}type_code`),
            selector: row => row.typeCode ?? '-',
            exportOption: row => String(row.typeCode ?? '-'),
        },
        {
            id: 'term',
            name: t(`${tBase}term`),
            selector: row => (row.term === 0 ? t('term_CI') : '24hs'),
            exportOption: row => String(row.term ?? '-'),
        },
        {
            id: 'market',
            name: t(`${tBase}market`),
            selector: row => row.market ?? '-',
            exportOption: row => row.market ?? '-',
        },
        {
            id: 'instrument',
            name: t(`${tBase}instrument`),
            selector: row => row.instrument ?? '-',
            exportOption: row => row.instrument ?? '-',
        },
        {
            id: 'quantity',
            name: t(`${tBase}quantity`),
            selector: row => (row.quantity != null ? formatNumber(row.quantity, 0, false) : '-'),
            exportOption: row => String(row.quantity ?? '-'),
        },
        {
            id: 'gross',
            name: t(`${tBase}gross`),
            selector: row =>
                row.currencyCode != null
                    ? row.currencyCode == 1
                        ? '$' + formatNumber(row.gross)
                        : 'US$' + formatNumber(row.gross)
                    : '-',
            exportOption: row => String(row.gross ?? '-'),
        },
        {
            id: 'agreementDate',
            name: t(`${tBase}agreement_date`),
            selector: ({ agreementDate }) => (agreementDate ? formatDate(agreementDate) : '-'),
            exportOption: row => row.agreementDate ?? '-',
        },
        {
            id: 'settlementDate',
            name: t(`${tBase}settlement_date`),
            selector: ({ settlementDate }) => (settlementDate ? formatDate(settlementDate) : '-'),
            exportOption: row => row.settlementDate ?? '-',
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'liquidationDate',
            label: t('reports_collaterals_page_filter_liquidation_date'),
            type: 'date',
            query: 'liquidationDate',
            disableFutureDaysFromN: true,
        },
        {
            id: 'customerName',
            label: t('customer_name', tCommon),
            type: 'select',
            query: 'fullName',
            options: marketAccounts?.map(el => ({ id: el.name, name: el.name })) ?? [],
        },
        {
            id: 'customerCode',
            label: t('customer_code', tCommon),
            type: 'select',
            query: 'customerCode',
            options:
                marketAccounts?.map(el => ({ id: el.customerCode, name: el.customerCode })) ?? [],
        },
    ];

    const filterDateValidation = (
        filters: string,
    ): { result: boolean; error: string | undefined } => {
        if (['', undefined].includes(filters) || !filters.includes('liquidationDate'))
            return {
                result: false,
                error: t('enter_date_from_and_to', tErrorsContext) as string,
            };

        return { result: true, error: undefined };
    };

    const childProps = {
        ...props,
        columns,
        filters,
        defaultFilters,
        filterDateValidation,
        t,
    };

    return <ReportsCollateralsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsCollateralsPageContainer.propTypes = propTypes;

export default ReportsCollateralsPageContainer;
