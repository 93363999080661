import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SubMenu.styled';
import { Collapse, Theme } from '@mui/material';
import { Tprop } from 'types/common/general.types';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import { useNavigate } from 'react-router-dom';

const SubMenu = (props: Props) => {
    const {
        menu,
        drawerOpened,
        theme,
        handleNavigate,
        listButtonSelected,
        link,
        backgroundColor,
        width,
        navigate,
        isProfileSubMenu,
        setOpenProfile,
    } = props;

    return (
        <Collapse in={drawerOpened} timeout="auto" unmountOnExit>
            <St.ListWrapper
                width={width ?? '234px'}
                ownerState={{ link }}
                dense
                sx={{ backgroundColor: backgroundColor ?? '' }}>
                {menu.subRoutes?.map((route, index) => (
                    <St.DrawerSubmenuButton
                        theme={theme}
                        ownerState={{ link }}
                        key={index}
                        onClick={() => {
                            if (!isProfileSubMenu) {
                                handleNavigate(route);
                            } else {
                                if (route.handleClick) {
                                    route.handleClick();
                                } else if (navigate && route.path) {
                                    navigate(route.path);
                                    if (setOpenProfile) {
                                        setOpenProfile(false);
                                    }
                                }
                            }
                        }}
                        selected={listButtonSelected === route.path}>
                        <St.ListItemText primary={route.title} />
                    </St.DrawerSubmenuButton>
                ))}
            </St.ListWrapper>
        </Collapse>
    );
};
const propTypes = {};

interface extraProps extends Tprop {
    menu: SubRouteInfo;
    drawerOpened: boolean;
    theme: Theme;
    handleNavigate: (item: SubRouteInfo) => void;
    listButtonSelected: string;
    link?: boolean;
    backgroundColor?: string;
    width?: string;
    navigate?: ReturnType<typeof useNavigate>;
    isProfileSubMenu?: boolean;
    setOpenProfile?: (open: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SubMenu.propTypes = propTypes;

export default SubMenu;
