import { Box, styled, Typography } from '@mui/material';
import CustomRadioButton from 'components/common/CustomRadioButton';
import ErrorText from 'components/common/ErrorText';

export default {
    TitleContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        fontWeight: 700,
    })),
    Header: styled(Box)(({ theme }) => ({
        paddingTop: '1rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.8rem',
        paddingBottom: '0.5rem',
    })),
    ContentRowContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
    })),
    BlueTypography: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.vibrantblue4,
    })),
    ButtonContainer: styled(Box)(({ theme }) => ({
        padding: '0 1rem 1rem',
        button: { marginTop: 0 },
    })),
    ErrorText: styled(ErrorText)(() => ({})),
    CustomRadioButtonWrapper: styled(Box)(() => ({
        width: 'fit-content',
        '& > div, input': {
            cursor: 'default !important',
        },
    })),
    CustomRadioButton: styled(CustomRadioButton)(() => ({})),
};
