import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Alert, Typography } from '@mui/material';
import InfoIcon from 'assets/icons/information-line.svg?react';
import CustomIconCreator from '../CustomIconCreator';
import St from './AlertNoData.styled';

const AlertNoData = (props: Props) => {
    const { title } = props;

    return (
        <Alert
            variant="standard"
            severity="info"
            sx={{
                marginY: '1rem',
                color: 'primary',
                backgroundColor: 'rgb(243 242 247)',
                borderRadius: '70px',
            }}
            icon={<CustomIconCreator icon={InfoIcon} alt="info-icon" />}>
            <Typography variant="body1" color="primary">
                {title}
            </Typography>
        </Alert>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AlertNoData.propTypes = propTypes;
AlertNoData;

export default AlertNoData;
