import React from 'react';
import { Typography, Box } from '@mui/material';
import St from './DepositStepOne.styled';
import { tCommon } from 'constants/appConstants';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DepositIcon from 'assets/img/general/icon-deposit.png';
import { mainRoutesPaths } from 'router/routesPaths';
import { NavigateFunction } from 'react-router-dom';
import ActionButton from 'components/common/ActionButton';

const DepositStepOne = (props: Props) => {
    const { t, navigate, handleNext } = props;

    return (
        <>
            <St.IconWrapper>
                <St.StyledImage
                    style={{
                        marginTop: '2rem',
                        marginBottom: '2rem',
                    }}
                    src={DepositIcon}
                    alt="depositIcon"
                />
            </St.IconWrapper>
            <Typography variant="h5" gutterBottom fontWeight="bold">
                {t('deposit_title_step_1')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('deposit_should_transfer')}
            </Typography>
            <St.BodyText variant="body2">
                <span style={{ fontWeight: 'bold' }}>{t('ars', tCommon)} </span>
                {t('deposit_transfer_bank_ars')}
                <br />
                <span>{t('deposit_transfer_bank_cbu')}: </span>
                <St.BlueText as="span">{t('deposit_transfer_bank_ars_cbu_number')}</St.BlueText>
            </St.BodyText>
            <St.BodyText variant="body2">
                <span style={{ fontWeight: 'bold' }}>{t('usd', tCommon)} </span>
                {t('deposit_transfer_bank_usd')}
                <br />
                <span>{t('deposit_transfer_bank_cbu')}: </span>
                <St.BlueText as="span">{t('deposit_transfer_bank_usd_cbu_number')}</St.BlueText>
            </St.BodyText>
            <Box>
                <St.BodyText
                    sx={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}>
                    <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        {t('deposit_consultatio_investments_sa')}{' '}
                    </span>
                    <br />
                    <span>{t('deposit_consultatio_cuit')} </span>
                    <St.BlueText as="span">{t('deposit_consultatio_cuit_number')}</St.BlueText>
                </St.BodyText>
            </Box>
            <St.BodyText variant="body2" paragraph>
                {t('deposit_reminder')}
            </St.BodyText>
            <St.StyledLink
                variant="body2"
                paragraph
                onClick={() => navigate(mainRoutesPaths.bankAccounts)}>
                {t('deposit_manage_my_bank_accounts')}
            </St.StyledLink>
            <St.BodyText variant="body2" paragraph>
                {t('deposit_reminder_2')}
            </St.BodyText>
            <St.BlueText variant="h5">{t('deposit_important')}</St.BlueText>
            <Typography variant="body2" paragraph>
                {t('deposit_reminder_3')}
            </Typography>

            <Box display="flex" justifyContent="space-between" mt={2}>
                <ActionButton variant="outlined" disabled={true}>
                    {t('deposit_btn_back')}
                </ActionButton>
                <ActionButton variant="contained" color="primary" onClick={handleNext}>
                    {t('next', tCommon)}
                </ActionButton>
            </Box>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

interface extraProps {
    navigate: NavigateFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepOne.propTypes = propTypes;
DepositStepOne;

export default DepositStepOne;
