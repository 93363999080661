import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from '../CustomCarouselItems/CustomCarouselItems.styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BlogArticle } from 'types/api/wrapper.types';

const CustomCarouselItems = (props: Props) => {
    const { article } = props;

    return (
        <St.CarouselItemContainer>
            <St.ImageContainer
                key={article.id}
                onClick={() => window.open(article.mobUrl, '_blank')}
                sx={{ cursor: 'pointer' }}>
                <St.Image src={article.mobImagenGrandeHeader} alt={article.mobTitulo} />
                <St.TitleOverlay>
                    <St.AvailabeByText variant="subtitle1" sx={{ color: 'white' }}>
                        {article.mobTitulo}
                    </St.AvailabeByText>
                </St.TitleOverlay>
            </St.ImageContainer>
        </St.CarouselItemContainer>
    );
};

const propTypes = {};

interface extraProps {
    article: BlogArticle;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCarouselItems.propTypes = propTypes;

export default CustomCarouselItems;
