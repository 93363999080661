import { LoadingButton } from '@mui/lab';
import { Box, List, styled } from '@mui/material';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

export default {
    SectionWrapper: styled(Box)(() => ({})) as typeof Box,
    List: styled(List)(() => ({})),
    NameWrapper: styled(Box)(({ theme }) => ({})),
    AutocompleteWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginTop: '2rem',
        '& input::placeholder': {
            color: theme.palette.custom.vibrantblue,
            opacity: 1,
        },
        '& .MuiInputLabel-root': {
            color: `${theme.palette.custom.darkblue} !important`,
        },
    })),
    CustomAutoComplete: styled(CustomAutoComplete)(() => ({})) as typeof CustomAutoComplete,
    LoadingButton: styled(LoadingButton)(({ theme }) =>
        theme.unstable_sx({
            textTransform: 'uppercase !important',
            margin: '0 auto ',
            width: '100%',
            maxWidth: { xs: '100%', sm: '339px' },
        }),
    ),
    DownloadButton: styled('button')(({ theme }) =>
        theme.unstable_sx({
            fontFamily: 'Roboto',
            fontSize: '0.625rem',
            fontWeight: '400',
            lineHeight: '11.72px',
            letterSpacing: '0.015em',
            border: 'none',
            background: 'transparent',
            width: 'fit-content',
            color: theme.palette.custom.vibrantblue,
            textDecoration: 'underline',
            cursor: 'pointer',
            margin: '-16px 14px 26px',
        }),
    ),
};
