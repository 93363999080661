import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CommercialLayout.styled';
import { CMSCategory, CMSRedirects } from 'types/api/cms.types';
import CustomLoader from 'components/common/CustomLoader';
import { Grid } from '@mui/material';
import CommercialItemButton from 'components/common/CommercialItemButton';
import AlertNoData from 'components/common/AlertNoData';
import GoBackButton from 'components/common/GoBackButton';

const CommercialLayout = (props: Props) => {
    const { t, title, categories, redirects, isLoading, withGoBackButton } = props;
    return (
        <St.SectionWrapper>
            <St.TitleWrapper>
                {withGoBackButton && <GoBackButton />}
                <St.Title variant="h3">{title}</St.Title>
            </St.TitleWrapper>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <>
                    {categories ? (
                        <Grid container spacing={2}>
                            {categories.map(c => (
                                <Grid item xs={12} sm={6} md={4} key={c.id}>
                                    <CommercialItemButton
                                        id={c.id}
                                        subcategories={c?.children}
                                        name={c.name}
                                        redirects={redirects}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <AlertNoData title={t('no_data_was_found')} />
                    )}
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    withGoBackButton: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    categories?: CMSCategory[];
    redirects?: CMSRedirects[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialLayout.propTypes = propTypes;

export default CommercialLayout;
