import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import GoBackButton from './GoBackButton';
import { useNavigate } from 'react-router-dom';

const GoBackButtonContainer = (props: Props) => {
    const { prevRoute } = props;
    const navigate = useNavigate();

    const goBack = () => {
        prevRoute ? navigate(prevRoute) : navigate(-1);
    };

    const childProps = {
        ...props,
        goBack,
    };

    return <GoBackButton {...childProps} />;
};

const propTypes = {
    prevRoute: PropTypes.string,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
GoBackButtonContainer.propTypes = propTypes;

export default GoBackButtonContainer;
