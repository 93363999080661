import { styled as styledMui } from '@mui/material/styles';
import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';
import { LoadingButton } from '@mui/lab';

export default {
    LogoBox: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: pxToRem(50),
    })) as typeof Box,
    LogoImg: styled('img')(({ theme }) =>
        theme.unstable_sx({
            height: { xs: pxToRem(128), sm: pxToRem(128), md: pxToRem(128), lg: pxToRem(148) },
            width: { xs: pxToRem(379), sm: pxToRem(379), md: pxToRem(379), lg: pxToRem(379) },
        }),
    ),
    OpenBox: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            marginTop: '50px',
            marginRight: { xs: '10px', sm: '100px' },
            marginLeft: { xs: '10px', sm: '100px' },
        }),
    ) as typeof Box,
    OpenMyAccountBox: styledMui(Box)(() => ({})) as typeof Box,
    NumberBox: styledMui(Box)(() => ({
        display: 'flex',
        gap: '2em',
        marginBottom: '2em',
    })) as typeof Box,
    OpenMyAccountTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            margin: '1em 0em',
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                textAlign: 'start',
            },
            fontWeight: 300,
            fontSize: { xs: pxToRem(50), sm: pxToRem(50), md: pxToRem(40), lg: pxToRem(68) },
            fontStyle: 'normal',
            color: theme.palette.primary.main,
            width: '100%',
        }),
    ),
    Body: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '1em',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', lg: 'row' },
        }),
    ) as typeof Box,
    InputTab: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '80%', sm: '361px' },
            marginTop: '18px',
        }),
    ) as typeof Box,
    SecondTab: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                gap: '1em',
                alignItems: 'baseline',
            },
            justifyContent: 'center',
            flexDirection: { xs: 'column', lg: 'row' },
            margin: '1em 0em',
        }),
    ) as typeof Box,
    CreateAccountText: styled(Typography)(() => ({})) as typeof Typography,
    CreateAccountSecondaryText: styled(Typography)(() => ({})) as typeof Typography,
    ResendEmailButton: styledMui(LoadingButton)(({ theme }) =>
        theme.unstable_sx({
            width: 'auto',
            margin: { xs: '10px', sm: '12px', md: '14px', lg: '16px' },
        }),
    ) as typeof LoadingButton,
};
