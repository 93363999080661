import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CommercialItemButton.styled';
import { CMSCategoryItem } from 'types/api/cms.types';

const CommercialItemButton = (props: Props) => {
    const { name, handleSubmit } = props;

    return (
        <St.ComercialItemButton onClick={handleSubmit} variant="outlined">
            {name}
        </St.ComercialItemButton>
    );
};

const propTypes = {
    name: PropTypes.string.isRequired,
};

interface extraProps {
    subcategories: CMSCategoryItem[] | null;
    handleSubmit: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialItemButton.propTypes = propTypes;

export default CommercialItemButton;
