import { Box, styled } from '@mui/material';
import AlertNoData from 'components/common/AlertNoData';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            flex: 1,
            margin: { xs: '0', lg: '0 0 2rem 2rem' },
        }),
    ),
    HeaderContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1.6rem',
    })),
    PageWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            marginTop: '2rem',
            display: { xs: 'block', lg: 'flex' },
            gap: '2rem',
            width: '100%',
        }),
    ),
    FundContentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            left: { xs: 0, lg: 0, xl: 0 },
            width: { xs: 'calc(100% - 4rem)', lg: '62%', xl: '100%' },
            padding: '2rem',
            backgroundColor: theme.palette.custom.vibrantblue10,
            borderBottomRightRadius: pxToRem(20),
            borderTopRightRadius: pxToRem(20),
            borderBottomLeftRadius: { xl: pxToRem(20), xs: 0 },
            borderTopLeftRadius: { xl: pxToRem(20), xs: 0 },
        }),
    ),
    FundActionsWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            m: 'auto',
            mt: { xs: '2rem', lg: '0' },
            padding: '2rem 0',
            position: { xs: 'relative', sm: 'relative', xl: 'relative' },
            left: { xs: 0, lg: 0, xl: 0 },
            width: { xs: '100%', lg: '32%', xl: '40%' },
            maxWidth: pxToRem(756),
        }),
    ),
    AlertNoData: styled(AlertNoData)(() => ({})),
    AlerNoDataWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({ width: 'fit-content', margin: 'auto' }),
    ),
};
