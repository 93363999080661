import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { dateExtraction, formatSingleDate } from 'utils/helpers/dateHelper';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import ReportsTicketsPage from './ReportsTicketsPage';
import { ReportTicket } from 'types/pages/reports.types';
import { AuthContext } from 'context/auth.context';
import { formatNumber } from 'utils/helpers/commonHelper';

const ReportsTicketsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_tickets_page_';
    const { customerCode } = React.useContext(AuthContext);

    const date = new Date();
    const current = dateExtraction(date);
    const lastWeek = dateExtraction(new Date(date.setDate(date.getDate() - 7)));

    const defaultFilters = [
        { query: 'byLiquidation', queryValue: 'false' },
        { query: 'dateFrom', queryValue: `${lastWeek.year}-${lastWeek.month}-${lastWeek.day}` },
        { query: 'dateTo', queryValue: `${current.year}-${current.month}-${current.day}` },
    ];

    const columns: CommonColumn<ReportTicket>[] = [
        {
            id: 'typeSpecies',
            name: t(`${tBase}column_instrument_type`),
            selector: row => row.typeSpecies,
            exportOption: row => row.typeSpecies ?? '-',
        },
        {
            id: 'instrumentDescription',
            name: t(`${tBase}column_instrument_name`),
            selector: row => row.instrumentDescription,
            exportOption: row => row.instrumentDescription,
        },
        {
            id: 'ticketNumber',
            name: t(`${tBase}column_ticket_number`),
            selector: row => row.ticketOperationNumber,
            exportOption: row => row.ticketOperationNumber,
        },
        {
            id: 'agreementDate',
            name: t(`${tBase}column_concertation_date`),
            selector: ({ agreementDate }) =>
                agreementDate ? formatSingleDate(agreementDate) : '-',
            exportOption: ({ agreementDate }) =>
                agreementDate ? formatSingleDate(agreementDate) : '-',
        },
        {
            id: 'liquidationDate',
            name: t(`${tBase}column_liquidation_date`),
            selector: ({ liquidationDate }) =>
                liquidationDate ? formatSingleDate(liquidationDate) : '-',
            exportOption: ({ liquidationDate }) =>
                liquidationDate ? formatSingleDate(liquidationDate) : '-',
        },
        {
            id: 'operationType',
            name: t(`${tBase}column_operation_type`),
            selector: row => row.operationType,
            exportOption: row => row.operationType,
        },
        {
            id: 'amount',
            name: t('quantity', tCommon),
            selector: ({ quantity }) =>
                quantity === null ? '-' : String(quantity).replace('-', ''),
            exportOption: ({ quantity }) =>
                quantity === null ? '-' : String(quantity).replace('-', ''),
        },
        {
            id: 'price',
            name: t('price', tCommon),
            selector: ({ currency, price }) =>
                price === null ? '-' : `${currency} ${formatNumber(price, 2).replace('-', '')}`,
            exportOption: ({ currency, price }) =>
                price === null ? '-' : `${currency} ${formatNumber(price, 2).replace('-', '')}`,
        },
        {
            id: 'gross',
            name: t(`${tBase}column_gross`),
            selector: ({ currency, gross }) =>
                `${currency} ${formatNumber(gross, 2).replace('-', '')}`,
            exportOption: ({ currency, gross }) =>
                `${currency} ${formatNumber(gross, 2).replace('-', '')}`,
        },
        {
            id: 'tariff',
            name: t(`${tBase}column_tariff`),
            selector: ({ tariffCurrency, tariff, tariffVat }) =>
                tariff === null ? '-' : `${tariffCurrency} ${formatNumber(tariff - tariffVat, 2)}`,
            exportOption: ({ tariffCurrency, tariff, tariffVat }) =>
                tariff === null ? '-' : `${tariffCurrency} ${formatNumber(tariff - tariffVat, 2)}`,
        },
        {
            id: 'tariffVat',
            name: t(`${tBase}column_tariff_vat`),
            selector: ({ tariffCurrency, tariffVat }) =>
                `${tariffCurrency} ${formatNumber(tariffVat, 2).replace('-', '')}`,
            exportOption: ({ tariffCurrency, tariffVat }) =>
                `${tariffCurrency} ${formatNumber(tariffVat, 2).replace('-', '')}`,
        },
        {
            id: 'market',
            name: t(`${tBase}column_market`),
            selector: ({ feeCurrency, marketFee, feeVat }) =>
                `${feeCurrency} ${formatNumber(marketFee - feeVat, 2).replace('-', '')}`,
            exportOption: ({ feeCurrency, marketFee, feeVat }) =>
                `${feeCurrency} ${formatNumber(marketFee - feeVat, 2).replace('-', '')}`,
        },
        {
            id: 'feeVat',
            name: t(`${tBase}column_fee_vat`),
            selector: ({ feeCurrency, feeVat }) =>
                `${feeCurrency} ${formatNumber(feeVat, 2).replace('-', '')}`,
            exportOption: ({ feeCurrency, feeVat }) =>
                `${feeCurrency} ${formatNumber(feeVat, 2).replace('-', '')}`,
        },
        {
            id: 'net',
            name: t(`${tBase}column_net`),
            selector: ({ currency, net }) => `${currency} ${formatNumber(net, 2).replace('-', '')}`,
            exportOption: ({ currency, net }) =>
                `${currency} ${formatNumber(net, 2).replace('-', '')}`,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateType',
            label: t(`${tBase}date_type`, tCommon),
            type: 'select',
            query: 'byLiquidation',
            options: [
                { id: 'true', name: t(`${tBase}for_liquidation`) },
                { id: 'false', name: t(`${tBase}for_concertation`) },
            ],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
            disablePastDaysFromN: `${lastWeek.year}/${lastWeek.month}/${lastWeek.day}`,
            disableFutureDaysFromN: true,
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
            disablePastDaysFromN: `${lastWeek.year}/${lastWeek.month}/${lastWeek.day}`,
            disableFutureDaysFromN: true,
        },
    ];

    const filterDateValidation = (
        filters: string,
    ): { result: boolean; error: string | undefined } => {
        if (
            ['', undefined].includes(filters) ||
            !filters.includes('dateFrom') ||
            !filters.includes('dateTo')
        )
            return {
                result: false,
                error: t('enter_date_from_and_to', tErrorsContext) as string,
            };

        return { result: true, error: undefined };
    };

    const childProps = {
        ...props,
        filterDateValidation,
        defaultFilters,
        columns,
        filters,
        customerCode,
        t,
    };

    return <ReportsTicketsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsTicketsPageContainer.propTypes = propTypes;

export default ReportsTicketsPageContainer;
