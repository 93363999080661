import { Box, styled, Typography } from '@mui/material';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

export default {
    BodyText: styled(Typography)({
        marginBottom: '15px',
    }),
    CustomAutoComplete: styled(CustomAutoComplete)(() => ({})) as typeof CustomAutoComplete,
    InputContainer: styled(Box)(() => ({
        alignItems: 'center',
        gap: '10px',
    })),
    AttachButtonContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
        },
    })),
};
