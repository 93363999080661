import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DdjjAction from './DDJJAction';
import { useTranslation } from 'react-i18next';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';
import { AuthContext } from 'context/auth.context';

const DdjjActionContainer = (props: Props) => {
    const { t } = useTranslation('home');
    const { isOpen, open, close } = useDialog();

    const { declarations } = React.useContext(AuthContext);

    const childProps = {
        ...props,
        data: declarations,
        isOpen,
        open,
        close,
        t,
    };

    return <DdjjAction {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DdjjActionContainer.propTypes = propTypes;

export default DdjjActionContainer;
