import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconCreator from './CustomIconCreator';

const CustomIconCreatorContainer = (props: Props) => {
    const { icon } = props;

    const childProps = {
        ...props,
        icon,
    };

    return <CustomIconCreator {...childProps} />;
};

const propTypes = {};

interface extraProps {
    sx?: any;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    alt?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomIconCreatorContainer.propTypes = propTypes;

export default CustomIconCreatorContainer;
