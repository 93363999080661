import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelHomePage from './ChannelHomePage';
import { useTranslation } from 'react-i18next';

const ChannelHomePageContainer = (props: Props) => {
    // const { } = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <ChannelHomePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelHomePageContainer.propTypes = propTypes;

export default ChannelHomePageContainer;
