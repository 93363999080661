import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import LoginPage from './LoginForm';
import { useLogin } from 'hooks/api/auth.hooks';
import { LoginRequest } from 'types/api/auth.types';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useLocation } from 'react-router-dom';
import { loggedOutSubroutesPaths } from 'router/routesPaths';
// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = () => ({
    username: '',
    password: '',
    urlTest: '',
});
const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            username: Yup.string().required(tRequiredFieldError).trim(),
            password: Yup.string().required(tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//
const LoginFormContainer = (props: Props) => {
    const { isLoading, mutate: onLogin, errorMessage: apiError, isError } = useLogin();
    const { t } = useTranslation('auth');
    const location = useLocation();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    React.useEffect(() => {
        if (location.state && location.state.isPasswordChanged) {
            setSnackBarMessage(t('password_changed_message'));
        }
        if (location.state && location.state.verifiedEmail) {
            setSnackBarMessage(t('email_verified_message'));
        }
        return () => {
            window.history.replaceState({}, document.title);
        };
    }, [location, setSnackBarMessage, t]);

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            onLogin({
                authUser: values.username.trim(),
                authSecret: values.password,
            } as LoginRequest);
        },
        [onLogin],
    );

    const handleCreateAccount = () => {
        window.location.replace(loggedOutSubroutesPaths.createAccount);
    };

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        t,
        isLoading,
        formik,
        apiError,
        isError,
        SnackBar,
        handleCreateAccount,
    };
    return <LoginPage {...childProps} />;
};
const propTypes = {};
interface extraProps {}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginFormContainer.propTypes = propTypes;
export default LoginFormContainer;
