import React from 'react';
import endpoints from 'api/alerts/alerts.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { AuthContext } from 'context/auth.context';

export const useGetNotifications = () => {
    const { isAuthenticated } = React.useContext(AuthContext);
    const { data, isLoading, error } = useQuery(
        ['get-notifications', isAuthenticated],
        () => endpoints.getNotifications(),
        {
            select: data => data.data.notifications,
            enabled: isAuthenticated === true,
        },
    );

    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useReadNotifications = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.readNotifications);
    return {
        mutate,
        isLoading,
        error: isError ? errorResponseHelper(error as any) : null,
    };
};

export const useDeleteNotifications = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteNotifications);
    return {
        mutate,
        isLoading,
        error: isError ? errorResponseHelper(error as any) : null,
    };
};
