import { InferPropsExtended } from 'utils/helpers/proptypesHelper.js';
import St from './OpenMyAccountPage.styled';
import PropTypes from 'prop-types';
import { Form, FormikContextType, FormikProvider } from 'formik';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import SignUpHeaderNumbers from 'components/common/SignUpHeaderNumbers';
import ErrorText from 'components/common/ErrorText';
import HeadLogo from 'components/common/HeadLogo';
import FooterLayout from 'components/layouts/FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';

const OpenMyAccountPage = (props: Props) => {
    const { t, formik, getInto, isLoading, error } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form>
                <HeadLogo />
                <St.OpenBox>
                    <St.OpenMyAccountBox>
                        <SignUpHeaderNumbers activated={1} />
                        <St.OpenMyAccountTitle>{t('open_my_account_title')}</St.OpenMyAccountTitle>
                        <St.Body>
                            <St.InputTab>
                                <CustomInput
                                    field="email"
                                    errors={errors}
                                    placeholder={t('email_placeholder')}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    autoFocus
                                    required
                                />
                            </St.InputTab>
                            <ActionButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                sx={{ width: { xs: '80%', sm: '361px' } }}>
                                {t(`open_my_account_register`)}
                            </ActionButton>
                        </St.Body>
                        <St.SecondTab>
                            <St.CreateAccountText>
                                {t('create_account_already_has_account_text')}
                            </St.CreateAccountText>
                            <ActionButton variant="outlined" aria-label="export" onClick={getInto}>
                                {t('create_account_button_get_into')}
                            </ActionButton>
                        </St.SecondTab>
                    </St.OpenMyAccountBox>
                    <ErrorText text={error} />
                </St.OpenBox>
            </Form>
            <FooterLayout />
            <HelpTooltip />
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    getInto: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OpenMyAccountPage.propTypes = propTypes;

export default OpenMyAccountPage;
