import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import { useGetFunds, useGetMarkets, useGetTagsForInstruments } from 'hooks/api/instruments.hooks';
import {
    GetFundsResponse,
    GetMarketTimesResponse,
    GetTagsForInstrumentsResponse,
} from 'types/api/instruments.types';
import { useGetLockedFunctionalities } from 'hooks/api/rules.hooks';
import { LockedFunctionalityItem, LockedFunctionalityNames } from 'types/api/rules.types';
import { channelRoutesPaths } from 'router/routesPaths';
import { AuthContext } from './auth.context';
import { useLocation } from 'react-router-dom';

interface IntialState {
    funds?: GetFundsResponse[];
    isLoadingFunds: boolean;
    errorMessageFunds: string | null;
    marketsTimes?: GetMarketTimesResponse[];
    isLoadingMarkets: boolean;
    errorMessageMarkets: string | null;
    tagsForInstruments: GetTagsForInstrumentsResponse | undefined;
    tagsForInstrumentsIsLoading: boolean;
    lockedFunctionalities?: LockedFunctionalityItem[];
    isLoadingLockedFunctionalities: boolean;
    errorMessageFunctionalities: string | null;
    lockedFunctionality: LockedFunctionalityNames | null;
    setLockedFunctionality: Dispatch<SetStateAction<LockedFunctionalityNames | null>>;
    showLockedModal: (path: string) => boolean;
    forceHome: boolean;
    isLockedByUserType: (lockedType: LockedFunctionalityNames) => boolean;
}

export const MarketContext = createContext({} as IntialState);

const MarketContextProvider = ({ children }: { children: ReactNode }) => {
    const { userType } = useContext(AuthContext);
    const { pathname } = useLocation();
    const [lockedFunctionality, setLockedFunctionality] = useState<LockedFunctionalityNames | null>(
        null,
    );
    const [forceHome, setForceHome] = React.useState(true);
    const { funds, isLoading: isLoadingFunds, errorMessage: errorMessageFunds } = useGetFunds();
    const {
        data: tagsForInstruments,
        isLoading: tagsForInstrumentsIsLoading,
        errorMessage,
    } = useGetTagsForInstruments();

    const {
        data: marketsTimes,
        isLoading: isLoadingMarkets,
        errorMessage: errorMessageMarkets,
    } = useGetMarkets();

    const {
        data: lockedFunctionalities,
        isLoading: isLoadingLockedFunctionalities,
        errorMessage: errorMessageFunctionalities,
    } = useGetLockedFunctionalities();

    const isLockedByUserType = useCallback(
        (lockedType: LockedFunctionalityNames) => {
            const selectedLock = lockedFunctionalities?.find(l => l.name === lockedType)
                ?.details[0];

            if (!selectedLock || selectedLock.platform === 'MOBILE') return false;

            if (selectedLock.appliesTo === 'ALL') return true;

            if (userType === selectedLock.appliesTo) return true;

            if (
                (userType === 'ASSISTANT' || userType === 'CHANNEL') &&
                selectedLock.appliesTo === 'CHANNEL'
            )
                return true;
            if (
                (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL') &&
                selectedLock.appliesTo === 'APP_USERS'
            )
                return true;

            return false;
        },
        [lockedFunctionalities, userType],
    );

    const showLockedModal = useCallback(
        (path: string) => {
            if (!path.includes('market')) return false; // Valido estar en una pantalla de mercados
            if (path.includes(channelRoutesPaths.others)) return false; // Otros no se va a ver afectado por esto
            if (path.includes(channelRoutesPaths.collaterals)) {
                if (isLockedByUserType('COLLATERAL')) {
                    setLockedFunctionality('COLLATERAL');
                    return true;
                }
                return false;
            } // Valido el modal para cauciones

            if (path.includes(channelRoutesPaths.exchange)) {
                if (isLockedByUserType('EXCHANGE')) {
                    setLockedFunctionality('EXCHANGE');
                    return true;
                }
                return false;
            } // Valido el modal para arbitraje

            // Valido el modal para mercados en gral
            if (isLockedByUserType('MARKET')) {
                setLockedFunctionality('MARKET');
                return true;
            }
            return false;
        },
        [isLockedByUserType],
    );

    React.useEffect(() => {
        if (pathname.includes('market')) {
            if (showLockedModal(pathname)) setForceHome(true);
            else setForceHome(false);
        } else {
            setForceHome(false);
        }
    }, [pathname, showLockedModal, setForceHome]);

    const memoedValue = React.useMemo(() => {
        const value = {
            funds,
            forceHome,
            marketsTimes,
            isLoadingFunds,
            showLockedModal,
            isLoadingMarkets,
            errorMessageFunds,
            isLockedByUserType,
            errorMessageMarkets,
            tagsForInstruments,
            tagsForInstrumentsIsLoading,
            lockedFunctionality,
            lockedFunctionalities,
            setLockedFunctionality,
            errorMessageFunctionalities,
            isLoadingLockedFunctionalities,
        };
        return value;
    }, [
        funds,
        forceHome,
        marketsTimes,
        isLoadingFunds,
        showLockedModal,
        isLoadingMarkets,
        errorMessageFunds,
        isLockedByUserType,
        errorMessageMarkets,
        tagsForInstruments,
        tagsForInstrumentsIsLoading,
        lockedFunctionality,
        lockedFunctionalities,
        setLockedFunctionality,
        errorMessageFunctionalities,
        isLoadingLockedFunctionalities,
    ]);

    return <MarketContext.Provider value={memoedValue}>{children}</MarketContext.Provider>;
};

export default MarketContextProvider;
