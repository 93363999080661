import { ReactElement } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InfoLabel.styled';
import PropTypes from 'prop-types';

const InfoLabel = (props: Props) => {
    const { label, text, withoutSeparation = false, fontSize, color } = props;

    return (
        <St.Wrapper data-testid="InfoLabel-default">
            <St.Label
                color={color}
                fontSize={fontSize}
                $withoutSeparation={withoutSeparation ?? false}>
                {label}:
            </St.Label>
            <St.Data color={color} fontSize={fontSize}>
                {text}
            </St.Data>
        </St.Wrapper>
    );
};

const propTypes = {
    label: PropTypes.string.isRequired,
    withoutSeparation: PropTypes.bool,
};

interface extraProps {
    label: string | null;
    text?: string | number | ReactElement;
    column?: number;
    fontSize?: string;
    color?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InfoLabel.propTypes = propTypes;
InfoLabel;

export default InfoLabel;
