import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomDot.styled';

const CustomDot = (props: Props) => {
    const { onClickHandler, isSelected, label, index } = props;

    return (
        <St.Dot
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
            $isSelected={isSelected}
        />
    );
};

const propTypes = {
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

interface extraProps {
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomDot.propTypes = propTypes;

export default CustomDot;
