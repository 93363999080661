import { Box, styled, Typography } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(Box)(({ theme }) => ({
        padding: '10px 0px',
    })),
    LastUpdate: styled(Typography)(({ theme }) => ({
        color: '#8b99a9',
        fontSize: pxToRem(12),
    })),
    Title: styled(Typography)(({ theme }) => ({
        lineHeight: 1.2,
        color: theme.palette.custom.vibrantblue,
    })),

    ContentContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'center',
        }),
    ),

    ClassType: styled(Typography)(({ theme }) => ({
        marginTop: pxToRem(10),
        color: '#8B99A9',
    })),
    BlueTypography: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.vibrantblue4,
        marginBottom: pxToRem(5),
    })),
    FundValue: styled(Typography)(({ theme }) => ({
        color: '#173254',
    })),
    VCPDescription: styled(Typography)(({ theme }) => ({
        color: '#8B99A9',
        fontSize: pxToRem(14),
    })),
    VCPValue: styled(Typography)(({ theme }) => ({
        color: '#34C759',
        fontSize: pxToRem(24),
    })),
    RecommendedContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        marginTop: pxToRem(40),
    })),

    RecommendedIcon: styled(
        Box,
        transientOptions,
    )<{}>(({ theme }) =>
        theme.unstable_sx({
            backgroundColor: '#34C759',
            width: 16,
            height: 16,
            borderRadius: '100%',
            marginRight: 1,
        }),
    ),

    RecommendedText: styled(Typography)(({ theme }) => ({
        fontWeight: 'bold',
    })),

    TableContainer: styled(Box)(({ theme }) => ({
        marginTop: pxToRem(20),
    })),
    Table: styled(Box)(({ theme }) => ({
        width: '300px',
        padding: '10px 0px',
        backgroundColor: theme.palette.custom.vibrantblue10,
        borderRadius: pxToRem(20),
        border: `1px solid #D6E5F8`,
    })),
    TableTitle: styled(Typography)(({ theme }) => ({
        color: '#455B76',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 1,
        marginBottom: 0,
        marginTop: 3,
    })),
    TableContentWrapper: styled(Box)(({ theme }) => ({
        padding: '0px 15px',
        marginTop: 10,
    })),
    TableCell: styled(
        Box,
        transientOptions,
    )<{ $index: number }>(({ $index, theme }) =>
        theme.unstable_sx({
            marginTop: $index === 0 ? pxToRem(15) : 0,
            marginBottom: $index === 3 ? 0 : pxToRem(10),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 15px',
            lineHeight: 1,
            borderRadius: 20,
            height: 35,
            backgroundColor: $index % 2 !== 0 ? '#D6E5F8' : '',
        }),
    ),
    TableCellLabel: styled(Typography)(({ theme }) => ({
        color: '#173254',
        fontSize: pxToRem(14),
    })),
    TableCellValue: styled(Typography)(({ theme }) => ({
        color: '#173254',
        fontWeight: 'bold',
        fontSize: pxToRem(14),
    })),
};
