import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SignUpHeaderNumbers.styled';
import CircleWithNumber from '../CircleWithNumber';

const SignUpHeaderNumbers = (props: Props) => {
    const { activated } = props;

    return (
        <St.NumberBox>
            <CircleWithNumber activated={activated === 1} number={1} />
            <CircleWithNumber activated={activated === 2} number={2} />
            <CircleWithNumber activated={activated === 3} number={3} />
        </St.NumberBox>
    );
};

const propTypes = {
    activated: PropTypes.number.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SignUpHeaderNumbers.propTypes = propTypes;

export default SignUpHeaderNumbers;
