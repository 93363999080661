import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material';
import QuestionMarkRadiusIcon from 'assets/svg/questionMarkRadius.svg?react';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import CustomInput from 'components/common/CustomInput';

export default {
    Li: styled('li')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '2rem 0.875rem 2rem 0',
            borderBottom: `1px solid ${theme.palette.custom.darkblue7}`,
            gap: '1rem',
        }),
    ),
    FirstWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: '.5rem',
            minWidth: { xs: '100%', sm: '407px' },
        }),
    ),
    Box: styled(Box)(({ theme }) => ({ display: 'flex', alignItems: 'center', gap: '.8rem' })),
    SecondWrapper: styled(Box)(({ theme }) => ({ display: 'flex', gap: '.5rem' })),
    Text: styled(Typography)(({ theme }) => ({
        fontSize: '1.5rem',
        fontWeight: '500',
        lineHeight: '28.13px',
        color: theme.palette.custom.darkblue2,
    })),
    Tooltip: styled(Tooltip)(() => ({})),
    IconButton: styled(IconButton)(() => ({})),
    QuestionMarkRadiusIcon: styled(QuestionMarkRadiusIcon)(() => ({})),
    tooltipText: () => {
        return {
            a: <a style={{ color: 'currentcolor', marginLeft: '.5rem' }} />,
        };
    },
    CustomAutoComplete: styled(CustomAutoComplete)(({ theme }) => theme.unstable_sx({})),
    NameWrapper: styled(Box)(({ theme }) => ({})),
    CustomInput: styled(CustomInput)(({ theme }) => theme.unstable_sx({})),
    InputWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '100%', md: '280px' },
            input: { xs: '100% !important', md: '280px !important' },
        }),
    ),
};
