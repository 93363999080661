import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import St from './OnboardingDniPhotoPage.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import FileUploader from 'components/common/FileUploader';
import TopHeader from 'components/common/TopHeader';
import ErrorText from 'components/common/ErrorText';
import CustomLoader from 'components/common/CustomLoader';

export const OnboardingDniPhotoPage = (props: Props) => {
    const {
        t,
        formik,
        enableButton,
        errorMessage,
        isLoading,
        isLoadingSubmit,
        setLoadingFile,
        preloadedData,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <St.DniPhotoBox>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <>
                    <TopHeader
                        firstElement={t('dni_photo_title') as string}
                        title={t('dni_photo_subtitle')}
                        subtitle={t('dni_photo_text')}
                    />
                    <FormikProvider value={formik}>
                        <Form>
                            <St.DniPhotoInputs>
                                <FileUploader
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue as any}
                                    label={t('dni_photo_file1')}
                                    field="dniPhoto"
                                    isEdit={!!values.dniPhoto && preloadedData}
                                    setDisableButton={setLoadingFile}
                                />
                                <FileUploader
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue as any}
                                    label={t('dni_photo_file2')}
                                    field="dniSecondPhoto"
                                    isEdit={!!values.dniSecondPhoto && preloadedData}
                                    setDisableButton={setLoadingFile}
                                />
                            </St.DniPhotoInputs>
                            <St.DniPhotoButtonsBox>
                                <St.DniPhotoButtonsBoxInnerGrid>
                                    <St.DniPhotoLoadingButton
                                        type="submit"
                                        variant="contained"
                                        disabled={!enableButton}
                                        loading={isLoadingSubmit}
                                        fullWidth>
                                        {t('send_button')}
                                    </St.DniPhotoLoadingButton>
                                </St.DniPhotoButtonsBoxInnerGrid>
                            </St.DniPhotoButtonsBox>
                            {errorMessage ? <ErrorText text={errorMessage} /> : null}
                        </Form>
                    </FormikProvider>
                </>
            )}
        </St.DniPhotoBox>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingSubmit: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    enableButton: boolean;
    errorMessage: string | null;
    setLoadingFile: (x: boolean) => void;
    preloadedData: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingDniPhotoPage.propTypes = propTypes;

export default OnboardingDniPhotoPage;
