import { Box, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ContactInfoInternalWrapper: styled(Box)(({ theme }) => ({
        margin: `${pxToRem(80)} ${pxToRem(50)}`,
        [theme.breakpoints.up('sm')]: {
            margin: `${pxToRem(80)} ${pxToRem(60)} `,
        },
        [theme.breakpoints.up('md')]: {
            margin: `${pxToRem(80)} ${pxToRem(150)} `,
        },
    })) as typeof Box,
    PhoneNumberWrapper: styled(Box)(() => ({
        display: 'flex',
        gap: '1.5rem',
    })),
};
