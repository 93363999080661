import { ColorType, createChart, IChartApi } from 'lightweight-charts';
import { RefObject } from 'react';
import { HistogramLWData, LWChartColors, LWChartData } from 'types/common/graphics.types';
import defaultTheme from 'utils/theme/defaultTheme';

export const defaultColors: LWChartColors = {
    backgroundColor: defaultTheme.palette.common.white,
    lineColor: defaultTheme.palette.primary.main,
    textColor: defaultTheme.palette.common.black,
    areaTopColor: '#2962FF',
    areaBottomColor: defaultTheme.palette.primary.light,
};

export const createLineChartGraphic = (
    chartRef: HTMLDivElement,
    colors: LWChartColors,
    data: LWChartData[],
    customHeight = 400,
): IChartApi => {
    const chart = createChart(chartRef, {
        layout: {
            background: { type: ColorType.Solid, color: colors.backgroundColor },
            textColor: colors.textColor,
            attributionLogo: false,
        },
        width: chartRef.clientWidth,
        height: customHeight,
        crosshair: {
            vertLine: {
                color: colors.lineColor,
                labelBackgroundColor: colors.areaBottomColor,
            },
            horzLine: {
                color: colors.lineColor,
                labelBackgroundColor: colors.areaBottomColor,
            },
        },
        grid: {
            vertLines: { visible: true },
            horzLines: { visible: true },
        },
    });

    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
        lineColor: colors.lineColor,
        topColor: colors.areaTopColor,
        bottomColor: colors.areaBottomColor,
        lastValueVisible: true,
        baseLineStyle: 0,
        crosshairMarkerBackgroundColor: colors.lineColor,
        crosshairMarkerBorderColor: defaultTheme.palette.common.white,
    });

    newSeries.priceScale().applyOptions({
        scaleMargins: {
            top: 0.15,
            bottom: 0.15,
        },
    });

    newSeries.setData(data);

    return chart;
};

export const createHistogramGraphic = (
    chartRef: HTMLDivElement,
    colors: LWChartColors,
    data: HistogramLWData[],
    customHeight = 250,
): IChartApi => {
    const chart = createChart(chartRef, {
        layout: {
            background: { type: ColorType.Solid, color: colors.backgroundColor },
            textColor: colors.textColor,
            attributionLogo: false,
        },
        width: chartRef.clientWidth,
        height: customHeight,
        crosshair: {
            vertLine: {
                color: colors.lineColor,
                labelBackgroundColor: colors.areaBottomColor,
            },
            horzLine: {
                color: colors.lineColor,
                labelBackgroundColor: colors.areaBottomColor,
            },
        },
    });

    chart.timeScale().fitContent();

    const serie = chart.addHistogramSeries({
        color: '#26a69a',
        priceFormat: {
            type: 'volume',
            precision: 2,
        },
    });

    serie.priceScale().applyOptions({
        scaleMargins: {
            top: 0.1,
            bottom: 0,
        },
    });

    serie.setData(data);

    return chart;
};

export const registerResizeHandler = (
    chart: IChartApi,
    chartContainerRef: RefObject<HTMLDivElement>,
) => {
    const handleResize = () => {
        if (!chartContainerRef.current) return;
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
};
