import * as React from 'react';
import St from './CustomRadioButton.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';

const CustomRadioButton = (props: Props) => {
    const { name, value, checked, onChange, text, fontSize } = props;

    return (
        <St.RadioButtonContainer
            onClick={() =>
                onChange({ target: { name, value } } as React.ChangeEvent<HTMLInputElement>)
            }
            className="radioButton">
            <St.RadioButton
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <St.RadioButtonText $fontSize={fontSize}>{text}</St.RadioButtonText>
        </St.RadioButtonContainer>
    );
};

const propTypes = {};

interface extraProps {
    name: string;
    value: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    text: string;
    fontSize?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomRadioButton.propTypes = propTypes;

export default CustomRadioButton;
