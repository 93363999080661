import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ExchangeQuotation from './ExchangeQuotation';
import { QuotationTickerPrices } from 'types/pages/marketExchangePage.types';
import { CommonSelectType } from 'types/common/general.types';

const ExchangeQuotationContainer = (props: Props) => {
    const { quotationPrices, currencyStartEnd } = props;

    const price = React.useMemo(() => {
        switch (currencyStartEnd?.name) {
            case 'MEP - ARS':
                if (!quotationPrices.usd.offer) return 0;
                return quotationPrices.ars.bid / quotationPrices.usd.offer / 1.001;
            case 'MEP - CABLE':
                if (!quotationPrices.usd.offer) return 0;
                return quotationPrices.usdc.bid / quotationPrices.usd.offer / 1.001;
            case 'CABLE - ARS':
                if (!quotationPrices.usdc.offer) return 0;
                return quotationPrices.ars.bid / quotationPrices.usdc.offer / 1.001;
            case 'CABLE - MEP':
                if (!quotationPrices.usdc.offer) return 0;
                return quotationPrices.usd.bid / quotationPrices.usdc.offer / 1.001;

            default:
                return 0;
        }
    }, [quotationPrices, currencyStartEnd]);

    const childProps = {
        ...props,
        price,
        ticker: quotationPrices?.ticker,
    };

    return <ExchangeQuotation {...childProps} />;
};

const propTypes = {};

interface extraProps {
    quotationPrices: QuotationTickerPrices;
    currencyStartEnd: CommonSelectType;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExchangeQuotationContainer.propTypes = propTypes;

export default ExchangeQuotationContainer;
