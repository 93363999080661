import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/auth.context';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientsPage from './ClientsPage';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import CustomIconCreator from 'components/common/CustomIconCreator';
import StarEmpty from 'assets/icons/star-line.svg?react';
import StarFilled from 'assets/icons/star-line-filled.svg?react';
import Wallet from 'assets/icons/wallet.svg?react';
import Exchange from 'assets/icons/exchange-dollar-line.svg?react';
import CheckEmpty from 'assets/icons/checkbox-blank-circle-line.svg?react';
import CheckFilled from 'assets/icons/checkbox-blank-circle-fill.svg?react';
import { useAddFavoriteMarketAccount, useDeleteFavoriteMarketAccount } from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useNavigate } from 'react-router-dom';
import { MarketAccount } from 'types/api/marketAccount.types';
import { channelRoutesPaths } from 'router/routesPaths';
import { IconButton, Tooltip } from '@mui/material';
import { useGetChannelClients } from 'hooks/api/marketAccount.hooks';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';
import { INSTRUMENTS_BASE_URL, USD } from 'constants/appConstants';

const ClientsPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const { selectedCustomer, setSelectedCustomer, marketAccounts } = useContext(AuthContext);
    const { setSnackBarMessage } = useSnackBar();
    const { mutate: addFavorite } = useAddFavoriteMarketAccount(setSnackBarMessage);
    const { mutate: deleteFavorite } = useDeleteFavoriteMarketAccount(setSnackBarMessage);
    const [filterFavorite, setFilterFavorite] = useState(false);
    const [filterCurrency, setFilterCurrency] = useState('ars');
    const [tableColumns, setTableColumns] = useState({ columns: [], actions: [] } as {
        columns: CommonColumn<MarketAccount>[];
        actions: CommonAction<MarketAccount>[];
    });
    const navigate = useNavigate();

    const {
        data,
        loading,
        sortInfo,
        handleSort,
        handlePageChange,
        filterHeaderString,
        setFilterHeaderString,
    } = useCustomFetch({ useGetData: useGetChannelClients, defaultSortFieldId: 'name' });

    const handleFavorite = (customerCode: string, favorite?: boolean) =>
        favorite
            ? deleteFavorite({ customerCodes: [String(customerCode)] })
            : addFavorite({ customerCodes: [String(customerCode)] });

    const handleGoToWallet = (row: MarketAccount) => {
        setSelectedCustomer(row);
        navigate(channelRoutesPaths.wallet);
    };

    const handleGoToSellInstrument = (row: MarketAccount) => {
        setSelectedCustomer(row);
        const instrumentType = row?.instrumentHolding?.instrumentType;
        const instrument = row?.instrumentHolding?.ticker;
        if (instrument && instrumentType) {
            const url = `${
                instrumentType === 'FUND'
                    ? channelRoutesPaths.fundDetail.replace(':ticker', instrument)
                    : channelRoutesPaths.instrumentDetail.replace(':ticker', instrument)
            }?sell`;
            navigate(url);
        }
    };

    const handleChangeFavoriteFilter = () => setFilterFavorite(prevState => !prevState);

    const handleSelectClient = (row: MarketAccount) => setSelectedCustomer(row);

    const baseColumns: CommonColumn<MarketAccount>[] = [
        {
            id: 'favorite',
            name: '',
            selector: ({ isFavorite, customerCode }) => (
                <Tooltip title={t('favorite')}>
                    <IconButton onClick={() => handleFavorite(customerCode, isFavorite)}>
                        <CustomIconCreator icon={isFavorite ? StarFilled : StarEmpty} />
                    </IconButton>
                </Tooltip>
            ),
            align: 'center',
        },
        {
            id: 'customerCode',
            name: t('customer_code'),
            sortable: true,
        },
        {
            id: 'name',
            name: t('name'),
            sortable: true,
        },
        {
            id: 'last_name',
            name: t('last_name'),
        },
    ];

    const getCurrencyByType = (row: MarketAccount, type: string) => {
        switch (filterCurrency) {
            case 'usdc':
                return convertCurrencyFormat(
                    type === 'today'
                        ? row.available_operate_usdc_term_0
                        : row.available_operate_usdc_term_1,
                    USD,
                );
            case 'usd':
                return convertCurrencyFormat(
                    type === 'today'
                        ? row.available_operate_usd_term_0
                        : row.available_operate_usd_term_1,
                    USD,
                );
            case 'ars':
            default:
                return convertCurrencyFormat(
                    type === 'today'
                        ? row.available_operate_ars_term_0
                        : row.available_operate_ars_term_1,
                );
        }
    };

    const fullColumns: CommonColumn<MarketAccount>[] = [
        ...baseColumns,
        {
            id: 'aum_ars',
            name: t('AUM_ARS'),
            selector: row => convertCurrencyFormat(row.aum_ars),
        },
        {
            id: 'available_operate_0',
            name: t('today'),
            selector: row => getCurrencyByType(row, 'today'),
        },
        {
            id: 'available_operate_1',
            name: t('24h'),
            selector: row => getCurrencyByType(row, '24h'),
        },
    ];

    const bondColumns: CommonColumn<MarketAccount>[] = [
        ...baseColumns,
        {
            id: 'quantity',
            name: t('quantity'),
            selector: ({ instrumentHolding }) =>
                formatNumber(instrumentHolding?.quantity || 0, 0, false),
        },
        {
            id: 'quantityNotAvailable',
            name: t('quantity_not_available'),
            selector: ({ instrumentHolding }) =>
                formatNumber(instrumentHolding?.quantityNotAvailable || 0, 0, false),
        },
        {
            id: 'value',
            name: t('valuation'),
            selector: ({ instrumentHolding }) =>
                convertCurrencyFormat(instrumentHolding?.value || 0),
        },
        {
            id: 'percentageInWallet',
            name: t('percentage_in_wallet'),
            selector: ({ instrumentHolding }) =>
                formatNumber(instrumentHolding?.percentageInWallet || 0),
        },
    ];

    const instrumentColumns: CommonColumn<MarketAccount>[] = [
        ...bondColumns,
        {
            id: 'ppc',
            name: t('ppc'),
            selector: ({ instrumentHolding }) =>
                convertCurrencyFormat(instrumentHolding?.pppc?.value || 0),
        },
        {
            id: 'returns',
            name: t('returns'),
            selector: ({ instrumentHolding }) =>
                convertCurrencyFormat(instrumentHolding?.returns || 0),
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'customerFullName',
            label: t('name'),
            type: 'text',
            query: 'customerFullName',
        },
        {
            id: 'customerCode',
            label: t('customer_code'),
            type: 'select',
            query: 'customerCode',
            options:
                marketAccounts?.map(el => ({ id: el.customerCode, name: el.customerCode })) ?? [],
        },
        {
            id: 'ticker',
            label: t('instrument'),
            type: 'requestAutocomplete',
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/instruments/`,
                param: 'ticker',
                optionLabel: 'name',
            },
        },
        {
            id: 'userType',
            label: t('user_type'),
            type: 'select',
            query: 'userType',
            options: [
                { id: 'legal', name: t('legal') },
                { id: 'physical', name: t('physical') },
            ],
        },
    ];

    const actions: CommonAction<MarketAccount>[] = [
        {
            id: 'wallet',
            Icon: () => <CustomIconCreator icon={Wallet} />,
            onClick: ({ row }) => handleGoToWallet(row),
        },
        {
            id: 'selected_customer',
            Icon: () => <CustomIconCreator icon={CheckFilled} />,
            isHidden: ({ customerCode }) => selectedCustomer?.customerCode !== customerCode,
            onClick: ({ row }) => handleSelectClient(row),
        },
        {
            id: 'select_customer',
            Icon: () => <CustomIconCreator icon={CheckEmpty} />,
            onClick: ({ row }) => handleSelectClient(row),
            isHidden: ({ customerCode }) => selectedCustomer?.customerCode === customerCode,
        },
    ];

    const sellAction: CommonAction<MarketAccount>[] = [
        {
            id: 'sell',
            Icon: () => <CustomIconCreator icon={Exchange} />,
            onClick: ({ row }) => handleGoToSellInstrument(row),
            isHidden: row => row?.instrumentHolding?.instrumentType === 'EXTERIOR',
        },
    ];

    const getColumns = (type: string) => {
        switch (type) {
            case 'bond':
                return { columns: bondColumns, actions: sellAction };
            case 'instrument':
                return { columns: instrumentColumns, actions: sellAction };
            case 'full':
                return { columns: fullColumns, actions };
            case 'base':
            default:
                return { columns: baseColumns, actions };
        }
    };

    useEffect(() => {
        if (filterFavorite) {
            filterHeaderString.includes('favoritesOnly')
                ? setFilterCurrency(
                      filterHeaderString.replace(/favoritesOnly=false/g, 'favoritesOnly=true'),
                  )
                : setFilterHeaderString(`${filterHeaderString}&favoritesOnly=true`);
        } else {
            setFilterHeaderString(
                filterHeaderString.replace(/favoritesOnly=true/g, 'favoritesOnly=false'),
            );
        }
    }, [filterFavorite]);

    useEffect(() => {
        if (filterHeaderString) {
            if (data.items && filterHeaderString.includes('ticker')) {
                const instrumentType = data?.items[0]?.instrumentHolding?.instrumentType;
                setFilterFavorite(false);
                setTableColumns(getColumns(instrumentType === 'BOND' ? 'bond' : 'instrument'));
            } else setTableColumns(getColumns('full'));
        } else {
            setTableColumns(getColumns('base'));
        }
    }, [data, filterCurrency]);

    const childProps = {
        ...props,
        columns: tableColumns.columns,
        filters,
        actions: tableColumns.actions,
        rows: data,
        loading,
        sortInfo,
        filterFavorite,
        filterHeaderString,
        filterCurrency,
        setFilterCurrency,
        setFilterHeaderString,
        handleSort,
        handlePageChange,
        handleChangeFavoriteFilter,
        t,
    };

    return <ClientsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPageContainer.propTypes = propTypes;

export default ClientsPageContainer;
