import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import QuestionsAnswers from './QuestionsAnswers';

const QuestionsAnswersContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <QuestionsAnswers {...childProps} />;
};

const propTypes = {};

interface extraProps {
    questionKey: string;
    number: number;
    handleCheck: (field: string, questionKey: string) => void;
    questionId: string;
    text: string;
    questionOptions: {
        [key: string]: { id: string; text: string; userChoice: boolean };
    };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
QuestionsAnswersContainer.propTypes = propTypes;

export default QuestionsAnswersContainer;
