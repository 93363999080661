import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelProfilePersonalDataPage from './ChannelProfilePersonalDataPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';

const ChannelProfilePersonalDataPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('profile');
    const { userData } = React.useContext(AuthContext);
    const referralCodesArray = userData?.channelInfo?.map(info => info.referralCode) ?? [];
    const referralCodes = referralCodesArray.join(', ') || t('personal_data_no_referral_code');
    const referralCodeTitle = t(
        referralCodesArray.length > 1
            ? t('personal_data_referral_codes')
            : t('personal_data_referral_code'),
    );

    const childProps = {
        ...props,
        userData,
        referralCodes,
        referralCodeTitle,
        t,
    };

    return <ChannelProfilePersonalDataPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelProfilePersonalDataPageContainer.propTypes = propTypes;

export default ChannelProfilePersonalDataPageContainer;
