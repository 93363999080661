import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import LockedFunctionalityModal from './LockedFunctionalityModal';
import { useTranslation } from 'react-i18next';
import { MarketContext } from 'context/market.context';
import { useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';

const LockedFunctionalityModalContainer = (props: Props) => {
    const { t } = useTranslation();
    const { forceHome, lockedFunctionality, lockedFunctionalities, setLockedFunctionality } =
        React.useContext(MarketContext);
    const navigate = useNavigate();

    const close = () => {
        if (forceHome) navigate(mainRoutesPaths.home);
        setLockedFunctionality(null);
    };

    const childProps = {
        ...props,
        t,
        title: t(
            `${'lockedFunctionalityModal'.replace(/([A-Z])/g, '_$1').toLowerCase()}_title_name`,
        ),
        lockInfo: lockedFunctionalities?.find(l => l.name === lockedFunctionality) ?? null,
        open: lockedFunctionality !== null,
        close,
        forceHome,
    };

    return <LockedFunctionalityModal {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LockedFunctionalityModalContainer.propTypes = propTypes;

export default LockedFunctionalityModalContainer;
