import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AumDetailModal from './AumDetailModal';
import { useTranslation } from 'react-i18next';
import { MultiCurrencyObj } from 'types/api/marketAccount.types';

const AumDetailModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
        title: t(`aum_detail_modal_title_name`),
    };

    return <AumDetailModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
    close: () => void;
    aum?: MultiCurrencyObj;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AumDetailModalContainer.propTypes = propTypes;

export default AumDetailModalContainer;
