import React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonMarketTable from './CommonMarketTable';
import { MarketTableFilters } from 'types/context/prices.context.types';
import { useTranslation } from 'react-i18next';
import { MarketTableInstrumentType } from 'types/api/instruments.types';
import useFilters from 'hooks/common/CommonMarketTable/filters.hooks';

const CommonMarketTableContainer = (props: Props) => {
    const { typeInstrument, useGetData } = props;
    const { t } = useTranslation();
    const isPreferred = typeInstrument === 'PREFERRED';

    const {
        filteredByTypeOptions,
        listOfCurrencies,
        liquidityList,
        classList,
        tagsForInstrumentsIsLoading,
    } = useFilters();
    const [filters, setFilters] = React.useState<MarketTableFilters>({
        state24OrCI: (localStorage.getItem('term') || '24hs') as '24hs' | 'CI',
        onlyFavorites: false,
        searchByTicker: '',
        currency: listOfCurrencies[typeInstrument === 'EQUITY' ? 1 : 0] ?? null,
        filteredByType: filteredByTypeOptions[typeInstrument][0] ?? null,
        typeInstrument,
        name: '',
        liquidity: liquidityList[0] ?? null,
        classOfFunds: null,
    });

    React.useEffect(() => {
        if (filteredByTypeOptions[typeInstrument][0]) {
            setFilters(prev => ({
                ...prev,
                filteredByType: filteredByTypeOptions[typeInstrument][0],
            }));
        }
    }, [filteredByTypeOptions]);

    const { tableData, isLoading, changeValues, totalSize, refetch } = useGetData({
        termConn: filters.state24OrCI,
        typeInstrument: isPreferred ? (filters.filteredByType?.name ?? '') : typeInstrument, // modification so that it can work with isPreferred
        currency: filters.currency?.name ?? '',
        filteredByType: isPreferred ? undefined : (filters.filteredByType?.name ?? ''), // modification so that it can work with isPreferred
        favorite: isPreferred || filters.onlyFavorites, // modification so that it can work with isPreferred
        ticker: filters.searchByTicker,
        name: filters.name,
        liquidity: filters.liquidity?.name ?? '',
        classOfFunds: filters.classOfFunds?.name ?? '',
    });

    const childProps = {
        ...props,
        filters,
        setFilters,
        tableData,
        isLoading: isLoading || tagsForInstrumentsIsLoading,
        totalSize,
        changeValues,
        listOfCurrencies,
        filteredByTypeOptions: filteredByTypeOptions[typeInstrument],
        liquidityList,
        classList,
        refetch,
        isPreferred,
        t,
    };

    return <CommonMarketTable {...childProps} />;
};

const propTypes = {};

interface extraProps {
    typeInstrument: MarketTableInstrumentType;
    title: string;
    useGetData: any;
    table?: 'default' | 'funds';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonMarketTableContainer.propTypes = propTypes;

export default CommonMarketTableContainer;
