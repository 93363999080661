import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingValidatePersonDniSection from './OnboardingValidatePersonDniSection';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { useStartOnboarding } from 'hooks/api/onboarding.hooks';
import i18n from 'utils/i18n';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    document: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        document: Yup.number()
            .required(tRequiredFieldError)
            .max(
                99999999,
                i18n.t('maximum_characters', { number: 8, ...tErrorsContext }) as string,
            ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const OnboardingValidatePersonDniSectionContainer = (props: Props) => {
    // const {} = props;

    const { setShowNosisQuestions } = props;
    const { t } = useTranslation('onboarding');
    const { errorMessage, isLoading, mutate } = useStartOnboarding(setShowNosisQuestions);

    const handleSubmit = React.useCallback(
        async (data: any) => {
            const document = data.document;
            mutate({ document });
        },
        [mutate],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        formik,
        t,
        errorMessage,
        isLoading,
    };

    return <OnboardingValidatePersonDniSection {...childProps} />;
};

const propTypes = {};

interface extraProps {
    setShowNosisQuestions: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonDniSectionContainer.propTypes = propTypes;

export default OnboardingValidatePersonDniSectionContainer;
