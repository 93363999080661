import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CurrencyLabel from './CurrencyLabel';
import { Currency } from 'types/common/general.types';
import { Variant } from '@mui/material/styles/createTypography';

const CurrencyLabelContainer = (props: Props) => {
    const { value = 0 } = props;

    const childProps = {
        ...props,
        value,
    };

    return <CurrencyLabel {...childProps} />;
};

const propTypes = {
    value: PropTypes.number.isRequired,
};

interface extraProps {
    currency: Currency;
    variant?: Variant;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyLabelContainer.propTypes = propTypes;

export default CurrencyLabelContainer;
