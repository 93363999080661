import React from 'react';
import { useNavigate } from 'react-router-dom';
import { mainRoutesPaths } from 'router/routesPaths';
import {
    FundsTable,
    WSFundTableKey,
    WSTable,
    WSTableKey,
} from 'types/context/prices.context.types';
import {
    convertCurrencyFormat,
    formatNumber,
    getIntegerWithFirstTwoAfterComma,
    getStylesOfVariation,
} from 'utils/helpers/commonHelper';

export interface TableRow<T> {
    id: WSTableKey | WSFundTableKey;
    cell: (row: T, state24OrCI: string) => React.ReactNode | Element;
    align?: 'right';
    bg?: boolean;
    color?: (row: T) => string;
    sx?: { [key: string]: string };
    onClick?: (row: T, state24OrCI: string) => void;
}

const useRows = (St: any) => {
    const navigate = useNavigate();
    const varationProp = {
        icons: { n: <St.Neutral />, b: <St.DownArrow />, t: <St.UpArrow /> },
        colors: { n: '#9b9b9b', b: '#EB5757', t: '#219653' },
    };

    const defaultRows: TableRow<WSTable>[] = [
        {
            id: 'ticker',
            cell: row => row.ticker,
            sx: { cursor: 'pointer' },
            onClick: (row, state24OrCI) => {
                localStorage.setItem('term', state24OrCI);
                window.location.replace(
                    `${mainRoutesPaths.instrumentDetail.replace(':ticker', row.ticker)}`,
                );
            },
        },
        {
            id: 'term',
            cell: (row, state24OrCI) => state24OrCI,
        },
        {
            id: 'buyQuantity',
            cell: row => (row.buyQuantity ? formatNumber(row.buyQuantity) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'buyPrice',
            cell: row => (row.buyPrice ? formatNumber(row.buyPrice, 0, false) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'sellPrice',
            cell: row => (row.sellPrice ? formatNumber(row.sellPrice, 0, false) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'sellQuantity',
            cell: row => (row.sellQuantity ? formatNumber(row.sellQuantity) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'lastPrice',
            cell: row => (row.lastPrice ? formatNumber(row.lastPrice, 0, false) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'variation',
            cell: row => (
                <>
                    {getIntegerWithFirstTwoAfterComma(row.variation)}
                    {varationProp.icons[getStylesOfVariation(row.variation)]}
                </>
            ),
            bg: true,
            align: 'right',
            color: row => varationProp.colors[getStylesOfVariation(row.variation)],
        },
        {
            id: 'nominalVolume',
            cell: row => (row.nominalVolume ? formatNumber(row.nominalVolume, 0, false) : '-'),
            bg: true,
            align: 'right',
        },
        {
            id: 'openingPrice',
            cell: row => (row.openingPrice ? formatNumber(row.openingPrice, 0, false) : '-'),
            align: 'right',
        },
        {
            id: 'maximum',
            cell: row => (row.maximum ? formatNumber(row.maximum, 0, false) : '-'),
            align: 'right',
        },
        {
            id: 'minimum',
            cell: row => (row.minimum ? formatNumber(row.minimum, 0, false) : '-'),
            align: 'right',
        },
        {
            id: 'previous',
            cell: row => (row.previous ? formatNumber(row.previous, 0, false) : '-'),
            align: 'right',
        },
        {
            id: 'tradedAmount',
            cell: row => (row.tradedAmount ? formatNumber(row.tradedAmount, 0, false) : '-'),
            align: 'right',
        },
    ];

    const fundsRows: TableRow<FundsTable>[] = [
        {
            id: 'name',
            cell: row => row.name,
            sx: { cursor: 'pointer', width: 'fit-content !important' },
            onClick: row =>
                navigate(`${mainRoutesPaths.fundDetail.replace(':ticker', row.ticker)}`),
        },
        {
            id: 'yieldVcp',
            cell: row => (row.yieldVcp ? formatNumber(Number(row.yieldVcp), 2) : '-'),
            align: 'right',
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'yieldVcpPercentage',
            cell: row => {
                return (
                    <>
                        {!row.yieldVcpPercentage.includes('-') && '+'}
                        {getIntegerWithFirstTwoAfterComma(row.yieldVcpPercentage)}
                        {varationProp.icons[getStylesOfVariation(row.yieldVcpPercentage)]}
                    </>
                );
            },
            align: 'right',
            color: row => varationProp.colors[getStylesOfVariation(row.yieldVcpPercentage)],
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'yieldMtd',
            cell: row =>
                row.yieldMtd === '-' ? (
                    '-'
                ) : (
                    <>
                        {!row.yieldMtd.includes('-') && '+'}
                        {getIntegerWithFirstTwoAfterComma(row.yieldMtd)}
                        {varationProp.icons[getStylesOfVariation(row.yieldMtd)]}
                    </>
                ),
            align: 'right',
            color: row =>
                varationProp.colors[
                    getStylesOfVariation(row.yieldMtd === '-' ? '0' : row.yieldMtd)
                ],
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'yieldQtd',
            cell: row => (
                <>
                    {!row.yieldQtd.includes('-') && '+'}
                    {getIntegerWithFirstTwoAfterComma(row.yieldQtd)}
                    {varationProp.icons[getStylesOfVariation(row.yieldQtd)]}
                </>
            ),
            align: 'right',
            color: row => varationProp.colors[getStylesOfVariation(row.yieldQtd)],
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'yieldYtd',
            cell: row =>
                row.yieldMtd === '-' ? (
                    '-'
                ) : (
                    <>
                        {!row.yieldYtd.includes('-') && '+'}
                        {getIntegerWithFirstTwoAfterComma(row.yieldYtd)}
                        {varationProp.icons[getStylesOfVariation(row.yieldYtd)]}
                    </>
                ),
            align: 'right',
            color: row =>
                varationProp.colors[
                    getStylesOfVariation(row.yieldYtd === '-' ? '0' : row.yieldYtd)
                ],
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'patrimonySummary',
            cell: row =>
                row.patrimonySummary
                    ? convertCurrencyFormat(Number(row.patrimonySummary), row.currency)
                    : '-',
            align: 'right',
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'currencyTypeSummary',
            cell: row => row.currencyTypeSummary || '-',
            align: 'right',
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'profile',
            cell: row => row.profile || '-',
            align: 'right',
            sx: { width: 'fit-content !important' },
        },
        {
            id: 'liquiditySummary',
            cell: row => row.liquiditySummary || '-',
            align: 'right',
            sx: { width: 'fit-content !important' },
        },
    ];

    return { default: defaultRows, funds: fundsRows };
};

export default useRows;
