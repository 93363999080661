import React, { createContext, ReactNode } from 'react';
import {
    useDeleteNotifications,
    useGetNotifications,
    useReadNotifications,
} from 'hooks/api/alerts.hooks';
import {
    DeleteNotificationsRequest,
    NotificationObj,
    ParsedNotificationBody,
    ReadNotificationsRequest,
} from 'types/api/alerts.types';
import getEnvVariables from 'utils/helpers/envVarsHelper';
import { enqueueSnackbar } from 'notistack';

interface IntialState {
    connect: (token: string) => void;
    disconnect: () => void;
    unreadCount: number;
    notifications: NotificationObj[] | null;
    isLoadingNotifications: boolean;
    isLoadingSubmitNotification: boolean;
    errorMessageNotifications: string | null;
    handleReadNotifications: (body: ReadNotificationsRequest) => void;
    handleDeleteNotifications: (body: DeleteNotificationsRequest) => void;
}

export const NotificationsContext = createContext({} as IntialState);

const NotificationsContextProvider = ({ children }: { children: ReactNode }) => {
    const [connection, setConnection] = React.useState<any>(null);
    const [notifications, setNotifications] = React.useState<NotificationObj[] | null>(null);
    const { mutate: readNotification, isLoading: isLoadingReadNotification } =
        useReadNotifications();
    const { mutate: deleteNotification, isLoading: isLoadingDeleteNotification } =
        useDeleteNotifications();
    const {
        data,
        isLoading: isLoadingNotifications,
        errorMessage: errorMessageNotifications,
    } = useGetNotifications();
    const { VITE_WEB_WS_URL } = getEnvVariables();

    const connect = React.useCallback(
        (token: string) => {
            const ws = new WebSocket(`${VITE_WEB_WS_URL}/?auth=${token}`);
            setConnection(ws);

            ws.onmessage = (ev: MessageEvent) => {
                const notification = JSON.parse(ev.data);
                const { body } = notification as NotificationObj;
                const { icon, message } = JSON.parse(body) as ParsedNotificationBody;
                setNotifications(prev => {
                    if (!prev) return [notification];
                    return [...prev, notification];
                });

                enqueueSnackbar(message, {
                    variant: icon,
                    persist: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                });
            };
        },
        [enqueueSnackbar],
    );

    const disconnect = React.useCallback(() => connection?.close(), [connection]);

    React.useEffect(() => {
        const token = localStorage.getItem('IdToken');
        if (token) connect(token);
        else disconnect();
        return () => disconnect();
    }, []);

    React.useEffect(() => {
        if (data) {
            setNotifications(data);
        }

        return () => {
            setNotifications(null);
        };
    }, [data]);

    const handleDeleteNotifications = React.useCallback(
        (body: DeleteNotificationsRequest) => {
            deleteNotification(body, {
                onSuccess() {
                    setNotifications(prev => {
                        if (!prev) return null;
                        return prev?.filter(p => !body.notificationIds.includes(p.NotificationID));
                    });
                },
            });
        },
        [setNotifications, deleteNotification],
    );

    const handleReadNotifications = React.useCallback(
        (body: ReadNotificationsRequest) => {
            readNotification(body, {
                onSuccess() {
                    setNotifications(prev => {
                        if (!prev) return null;
                        return prev?.map(p => {
                            if (body.notificationIds.includes(p.NotificationID)) {
                                return { ...p, read: true };
                            }
                            return p;
                        });
                    });
                },
            });
        },
        [setNotifications, readNotification],
    );

    const unreadCount = React.useMemo(() => {
        if (!notifications) return 0;
        return notifications.filter(n => !n.read).length;
    }, [notifications]);

    const memoedValue = React.useMemo(() => {
        const value = {
            connect,
            disconnect,
            unreadCount,
            notifications,
            isLoadingNotifications,
            isLoadingSubmitNotification: isLoadingReadNotification || isLoadingDeleteNotification,
            errorMessageNotifications,
            handleReadNotifications,
            handleDeleteNotifications,
        };
        return value;
    }, [
        connect,
        disconnect,
        unreadCount,
        notifications,
        isLoadingNotifications,
        isLoadingReadNotification,
        isLoadingDeleteNotification,
        errorMessageNotifications,
        handleReadNotifications,
        handleDeleteNotifications,
    ]);

    return (
        <NotificationsContext.Provider value={memoedValue}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsContextProvider;
