import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import { Box } from '@mui/material';
import TopHeader from 'components/common/TopHeader';
import DisagreeModal from './DisagreeModal';
import { Nationality } from 'types/api/onboarding.types';
import ActionButton from 'components/common/ActionButton';
import { pxToRem } from 'utils/helpers/stylesHelper';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import CustomLabel from 'components/common/CustomLabel';
import CustomLoader from 'components/common/CustomLoader';
import St from './OnboardingPersonalDataPage.styled';
import { Tprop } from 'types/common/general.types';
import ErrorText from 'components/common/ErrorText';

export const OnboardingPersonalDataPage = (props: Props) => {
    const {
        formik,
        t,
        openDisagreeModal,
        setOpenDisagreeModal,
        email,
        isLoadingNationalities,
        nationalitiesOptions,
        handlePersonalData,
        isLoadingLegalData,
        errorMessageLegalData,
        isLoadingSubmit,
        errorSubmit,
        SnackBar,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <St.OnboardingPersonalDataPageBox>
            {isLoadingLegalData ? (
                <CustomLoader />
            ) : errorMessageLegalData ? (
                <ErrorText text={errorMessageLegalData} />
            ) : (
                <>
                    <St.OnboardingPersonalDataPageInternalBox>
                        <TopHeader
                            firstElement={t('personal_data') as string}
                            title={t('check_data')}
                        />
                        <FormikProvider value={formik}>
                            <Form>
                                <St.FormWrapper>
                                    <CustomLabel
                                        id="email"
                                        value={email}
                                        label={t('email_label')}
                                    />
                                    <CustomLabel
                                        id="name"
                                        value={values.name}
                                        label={t('name_label')}
                                    />
                                    <CustomLabel
                                        id="lastName"
                                        value={values.lastName}
                                        label={t('surname_label')}
                                    />
                                    <CustomLabel
                                        id="docNumber"
                                        value={values.taxDocNumber}
                                        label={t('cuit_cuil_label')}
                                    />
                                    <CustomLabel
                                        id="birthDate"
                                        value={values.birthDate}
                                        label={t('birth_date_label')}
                                    />
                                    <CustomLabel
                                        id="gender"
                                        value={values.gender}
                                        label={t('gender_label')}
                                    />
                                    <CustomAutoComplete
                                        id="nationality"
                                        value={values.nationality}
                                        onChange={(e, value) => {
                                            setFieldValue('nationality', value);
                                        }}
                                        error={errors.nationality ?? false}
                                        options={nationalitiesOptions ?? []}
                                        isLoading={isLoadingNationalities}
                                        label={t('nationality_label') as string}
                                        placeholder={t('nationality_placeholder') as string}
                                        renderOption={(props, option) => (
                                            <Box {...props} key={option.id}>
                                                {option.name}
                                            </Box>
                                        )}
                                    />
                                    {errorSubmit && <ErrorText text={errorSubmit} />}
                                </St.FormWrapper>
                                <St.ButtonWrapper>
                                    <ActionButton
                                        type="submit"
                                        variant="contained"
                                        disabled={!values.nationality || isLoadingSubmit}
                                        loading={isLoadingSubmit}
                                        sx={{ width: pxToRem(300) }}>
                                        {t('confirm_data_button')}
                                    </ActionButton>
                                </St.ButtonWrapper>
                                <St.ButtonWrapper>
                                    <ActionButton
                                        type="button"
                                        variant="outlined"
                                        disabled={!values.nationality || isLoadingSubmit}
                                        sx={{ width: pxToRem(300) }}
                                        onClick={() => setOpenDisagreeModal(true)}>
                                        {t('disagree_button')}
                                    </ActionButton>
                                </St.ButtonWrapper>
                            </Form>
                        </FormikProvider>
                    </St.OnboardingPersonalDataPageInternalBox>
                    <DisagreeModal
                        t={t}
                        openModal={openDisagreeModal}
                        setOpenModal={setOpenDisagreeModal}
                        handlePersonalData={handlePersonalData}
                        isLoadingSubmit={isLoadingSubmit}
                        nationality={values.nationality}
                    />
                    <SnackBar />
                </>
            )}
        </St.OnboardingPersonalDataPageBox>
    );
};

const propTypes = {
    isLoadingNationalities: PropTypes.bool.isRequired,
    handlePersonalData: PropTypes.func.isRequired,
};

interface extraProps extends Tprop {
    formik: any;
    openDisagreeModal: boolean;
    setOpenDisagreeModal: (x: boolean) => void;
    email: string;
    nationalitiesOptions?: Nationality[];
    isLoadingLegalData: boolean;
    errorMessageLegalData: string | null;
    isLoadingSubmit: boolean;
    errorSubmit: string | null;
    SnackBar: () => JSX.Element;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingPersonalDataPage.propTypes = propTypes;

export default OnboardingPersonalDataPage;
