import { Dialog, DialogTitle, IconButton, Typography, styled } from '@mui/material';
export default {
    ModalText: styled(Typography)(({ theme }) => ({
        marginTop: '1em',
        color: '#2E4665',
        fontSize: '1em',
        width: '100%',
    })) as typeof Typography,
    Dialog: styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    })),
    DialogTitle: styled(DialogTitle)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.5rem',
            m: 0,
            p: 2,
            pr: 1,
        }),
    ),
    IconButton: styled(IconButton)(({ theme }) => ({
        color: theme.palette.grey[500],
    })),
};
