import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './LoginPage.styled';
import LoginForm from './LoginForm';
import FooterLayout from 'components/layouts/FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';

const LoginPage = (props: Props) => {
    const { mainLogo } = props;
    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="login-section">
                <St.ArticleWrapper component={'article'}>
                    <St.LogoContainer>
                        <St.Logo
                            color="primary"
                            inheritViewBox
                            component={mainLogo}
                            alt="consultatio_logo"
                        />
                    </St.LogoContainer>
                    <St.LoginFormWrapper>
                        <LoginForm />
                    </St.LoginFormWrapper>
                </St.ArticleWrapper>
                <FooterLayout />
                <HelpTooltip />
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {};

interface extraProps {
    mainLogo: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginPage.propTypes = propTypes;

export default LoginPage;
