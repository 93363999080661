import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AvailableBalanceDetailModal from './AvailableBalanceDetailModal';
import { useTranslation } from 'react-i18next';
import { AvailableBalance } from 'types/api/marketAccount.types';

const AvailableBalanceDetailModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
        title: t(`available_balance_detail_modal_title_name`),
    };

    return <AvailableBalanceDetailModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
    availableBalance?: AvailableBalance;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AvailableBalanceDetailModalContainer.propTypes = propTypes;

export default AvailableBalanceDetailModalContainer;
