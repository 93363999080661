import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './MarketTermCurrencyUpdate.styled';
import ActionButton from '../ActionButton';
import { Box } from '@mui/material';
import CustomAutoComplete from '../CustomAutoComplete';
import { CommonSelectType } from 'types/common/general.types';
import { TERMS } from 'constants/appConstants';

const MarketTermCurrencyUpdate = (props: Props) => {
    const {
        t,
        term,
        currency,
        currencies,
        disableButton,
        handleTermChange,
        handleCurrencyChange,
        handleUpdateTermAndCurrency,
    } = props;

    return (
        <Box display="flex" justifyContent="center" gap={1}>
            <Box flex={1}>
                <CustomAutoComplete
                    id="term_autocomplete"
                    value={term}
                    disableClearable
                    onChange={(e, value) => handleTermChange(value)}
                    options={TERMS ?? []}
                    label={t('instrument_term', { ns: 'home' }) as string}
                    placeholder={t('term_placeholder', { ns: 'home' }) as string}
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id} sx={{ width: 'auto' }}>
                            {option.name}
                        </Box>
                    )}
                />
            </Box>
            <Box flex={1}>
                <CustomAutoComplete
                    id="currency_autocomplete"
                    value={currency}
                    disableClearable
                    onChange={(e, value) => handleCurrencyChange(value)}
                    options={currencies ?? []}
                    label={t('instrument_currency', { ns: 'home' }) as string}
                    placeholder={t('currency_placeholder', { ns: 'home' }) as string}
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id} sx={{ width: 'auto' }}>
                            {option.name}
                        </Box>
                    )}
                />
            </Box>
            <Box flex={1}>
                <ActionButton
                    disabled={disableButton}
                    onClick={() => handleUpdateTermAndCurrency(term?.name, currency?.name)}
                    sx={{ margin: 0, width: 'auto' }}>
                    {t('update')}
                </ActionButton>
            </Box>
        </Box>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    disableButton: PropTypes.bool.isRequired,
    handleTermChange: PropTypes.func.isRequired,
    handleCurrencyChange: PropTypes.func.isRequired,
    handleUpdateTermAndCurrency: PropTypes.func.isRequired,
};

interface extraProps {
    currencies: CommonSelectType[] | null;
    term: CommonSelectType | null;
    currency: CommonSelectType | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketTermCurrencyUpdate.propTypes = propTypes;

export default MarketTermCurrencyUpdate;
