import { Box, Button, ButtonGroup, styled, Typography } from '@mui/material';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    Title: styled(Typography)(({ theme }) => ({
        display: 'flex',
        gap: 2,
        fontSize: '1rem',
        fontWeight: 'bold',
        cursor: 'pointer',
    })),
    StyledButtonGroup: styled(ButtonGroup)(({ theme }) => ({
        '& .MuiButtonGroup-grouped': {
            border: 'none',
            borderRadius: '16px',
            margin: '2px 2px',
            padding: '4px 12px',
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.custom.vibrantblue10,
            '&:hover': {
                backgroundColor: theme.palette.custom.vibrantblue7,
            },
            '&.Mui-selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                },
            },
        },
    })),
    ButtonStyled: styled(Button)<{ selected: boolean }>(({ theme, selected }) => ({
        paddingTop: '10px',
        borderColor: selected ? '#2962FF' : 'rgba(0, 0, 0, 0.23)',
        color: selected ? '#fff' : '#2962FF',
        backgroundColor: selected ? '#2962FF' : 'transparent',
        '&:hover': {
            backgroundColor: selected ? '#0039CB' : 'rgba(41, 98, 255, 0.08)',
        },
    })),
};
