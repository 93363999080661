import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingStepIncomeProofPage from './OnboardingStepIncomeProofPage';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import React, { useEffect } from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useIncomeProof } from 'hooks/api/onboarding.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { onboardingRoutesPaths } from 'router/routesPaths';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from 'context/onboarding.context';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = () => ({
    proof: null,
    second_proof: null,
    third_proof: null,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            proof: Yup.string().required(tRequiredFieldError).nullable(),
            second_proof: Yup.string().nullable(),
            third_proof: Yup.string().nullable(),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

export const OnboardingStepIncomeProofPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate();
    const { isLoadingLegalData, isLoadingSteps } = React.useContext(OnboardingContext);

    const [enableButton, setEnableButton] = React.useState(false);
    const { mutate, isLoading: isLoadingSubmit, errorMessage } = useIncomeProof();
    const { setSnackBarMessage } = useSnackBar();
    const proofListText = [
        'valid_proof_paycheck',
        'valid_proof_profit_ddjj',
        'valid_proof_personal_property_ddjj',
        'valid_proof_accounting_certificate',
        'valid_proof_retirement_receipt',
        'valid_proof_monotax_certification',
        'valid_proof_other_proof',
    ];

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const documents = [
                {
                    documentPath: values?.proof,
                    documentName: t('income_proof_photo_1'),
                },
            ];

            if (values?.second_proof) {
                documents.push({
                    documentPath: values.second_proof,
                    documentName: t('income_proof_photo_2'),
                });
            }

            if (values?.third_proof) {
                documents.push({
                    documentPath: values.third_proof,
                    documentName: t('income_proof_photo_3'),
                });
            }

            const payload = { documents };

            mutate(payload as any, {
                onSuccess: () => {
                    navigate(onboardingRoutesPaths.home);
                },
                onError: () => {
                    setSnackBarMessage(t('error', { ns: 'auth' }));
                },
            });
        },
        [mutate, setSnackBarMessage, t],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const { values } = formik;

    useEffect(() => {
        setEnableButton(values.proof || values.second_proof || values.third_proof);
    }, [values]);

    const childProps = {
        t,
        formik,
        enableButton,
        proofListText,
        isLoadingSubmit,
        errorMessage,
        isLoading: isLoadingLegalData || isLoadingSteps,
    };
    return <OnboardingStepIncomeProofPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingStepIncomeProofPageContainer.propTypes = propTypes;

export default OnboardingStepIncomeProofPageContainer;
