import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HelpTooltip from './HelpTooltip';
const HelpTooltipContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <HelpTooltip {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HelpTooltipContainer.propTypes = propTypes;

export default HelpTooltipContainer;
