import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FooterLayout.styled';
import { Link, Typography } from '@mui/material';
import UpIcon from 'assets/icons/arrow-drop-up-line.svg?react';
import GoogleSvg from '../../../assets/img/general/googelPlayStore.png';
import AppStoreSvg from '../../../assets/img/general/appStore.png';
import PdpPolicies from '../../../assets/img/general/pdp_Policies.png';
import {
    APP_STORE_ANCHOR,
    CLICK_HERE_ANCHOR,
    CNV_ANCHOR,
    PLAY_STORE_ANCHOR,
} from 'constants/appConstants';
import CustomIconCreator from 'components/common/CustomIconCreator';
import { scrollToTop } from 'utils/helpers/commonHelper';

const FooterLayout = (props: Props) => {
    const { t, businessInformation, businessSocialMedia, businessLinks } = props;

    return (
        <St.PrimarySection component={'footer'} data-testid="FooterLayout-layout">
            <St.BoxHeaderFooter>
                <St.BoxAppsImages>
                    <Link target="_blank" href={APP_STORE_ANCHOR}>
                        <img src={AppStoreSvg} alt="app-store" />
                    </Link>
                    <Link target="_blank" href={PLAY_STORE_ANCHOR}>
                        <img src={GoogleSvg} alt="google-play-store" />
                    </Link>
                </St.BoxAppsImages>
                <St.BoxScrollToTop onClick={scrollToTop}>
                    <Typography color={'secondary'} fontWeight={'bold'}>
                        {t('scroll_to_top')}
                    </Typography>
                    <CustomIconCreator
                        icon={UpIcon}
                        alt="arrow-up"
                        sx={{
                            marginBottom: '-20px',
                            color: 'secondary.main',
                        }}
                    />
                </St.BoxScrollToTop>
            </St.BoxHeaderFooter>

            <St.DividerFooter />

            <St.BoxInfoFooter>
                <St.ChildrenInfoBox>
                    {businessInformation.map(item => (
                        <Typography
                            key={item.id}
                            variant="body2"
                            color={item.text === 'business_name' ? 'secondary' : ''}
                            fontWeight={item.text === 'business_name' ? 'bold' : ''}>
                            {t(item.text)}
                        </Typography>
                    ))}
                </St.ChildrenInfoBox>
                <St.ChildrenInfoBox>
                    {businessSocialMedia.map(item => (
                        <St.TextLink key={item.id} target="_blank" href={t(item.anchor)}>
                            {item.text}
                        </St.TextLink>
                    ))}
                </St.ChildrenInfoBox>
                <St.ChildrenInfoBox sx={{ width: '25%' }}>
                    <Link target="_blank" href="https://www.argentina.gob.ar/aaip/datospersonales">
                        <img
                            src={PdpPolicies}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                marginTop: '-10px',
                            }}
                            alt="arrow-up"
                        />
                    </Link>
                    <Typography>
                        {t('pdp_title')}
                        <St.TextLink
                            href={CNV_ANCHOR}
                            target="_blank"
                            color={'secondary'}
                            sx={{
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                marginRight: '5px',
                            }}>
                            {t('cnv_web_title')}
                        </St.TextLink>
                        {t('pdp_sub_title')}
                    </Typography>
                </St.ChildrenInfoBox>
                <St.ChildrenInfoBox>
                    {businessLinks.map(item => (
                        <St.TextLink key={item.id} target="_blank" href={t(item.anchor)}>
                            {t(item.text)}
                        </St.TextLink>
                    ))}
                </St.ChildrenInfoBox>
            </St.BoxInfoFooter>

            <St.DividerFooter />

            <St.DisclaimerBox>
                <Typography variant="body2">{t('disclaimer')}</Typography>
                <Typography variant="body2" textAlign={'justify'}>
                    {t('disclaimer_first_paragraph')}
                    <St.TextLink
                        href={CLICK_HERE_ANCHOR}
                        target="_blank"
                        color={'secondary'}
                        sx={{
                            fontWeight: 'bold',
                            marginLeft: '5px',
                        }}>
                        {t('click_here_text')}
                    </St.TextLink>
                </Typography>
                <Typography variant="body2" textAlign={'justify'}>
                    {t('disclaimer_second_paragraph')}
                </Typography>
            </St.DisclaimerBox>
        </St.PrimarySection>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    businessInformation: { id: number; text: string }[];
    businessSocialMedia: { id: number; text: string; anchor: string }[];
    businessLinks: { id: number; text: string; anchor: string }[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FooterLayout.propTypes = propTypes;

export default FooterLayout;
