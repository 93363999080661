import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SelectOptions: styled(Box)(() => ({})) as typeof Box,
    ActionContainer: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    TextAreaLabel: styled(Typography)(() => ({
        marginBottom: pxToRem('15px'),
        marginTop: pxToRem('20px'),
    })) as typeof Typography,
    InfoText: styled(Typography)(() => ({
        color: '#b56463',
        marginTop: pxToRem('15px'),
        marginBottom: pxToRem('10px'),
        width: '100%',
        borderTop: '0.1rem solid lightGrey',
        paddingTop: pxToRem('5px'),
    })) as typeof Typography,
    RequestSendText: styled(Typography)(() => ({
        width: '98%',
        textAlign: 'center',
        padding: pxToRem('10px'),
        borderRadius: pxToRem('5px'),
        marginTop: pxToRem('15px'),
        marginBottom: pxToRem('10px'),
        color: '#678395',
        backgroundColor: '#d7ecf4',
    })) as typeof Typography,
};
