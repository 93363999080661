import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BlogPage from './BlogPage';
import { useGetBlogs, useGetLatestBlogs } from 'hooks/api/wrapper.hooks';
import { BlogArticle } from 'types/api/wrapper.types';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { CATEGORY_ORDER } from 'constants/appConstants';

const BlogPageContainer = (props: Props) => {
    // const {} = props;
    const { data: blogNews, isLoading } = useGetBlogs();
    const { data: dataLatest, isLoading: isLoadingLatest } = useGetLatestBlogs();
    const { matchesMd, matchesSm } = useBreakpoints();

    const groupedData =
        blogNews?.reduce((acc: { [key: string]: BlogArticle[] }, article: BlogArticle) => {
            const categoryName = article.category[0].nombre;
            if (!acc[categoryName]) acc[categoryName] = [];
            acc[categoryName].push(article);
            return acc;
        }, {}) || {};

    const groupByFour = (articles: BlogArticle[]) => {
        const grouped: BlogArticle[][] = [];

        if (matchesMd || matchesSm) {
            return articles.map(article => [article]);
        }

        const totalArticles = articles.length;
        const remainder = totalArticles % 4;
        if (remainder > 0) {
            const itemsToAdd = 4 - remainder;
            articles = [...articles, ...articles.slice(0, itemsToAdd)];
        }
        for (let i = 0; i < articles.length; i += 4) {
            grouped.push(articles.slice(i, i + 4));
        }
        return grouped;
    };

    const groupedDataByCategory = Object.keys(groupedData)
        .map(categoryName => {
            const articles = groupedData[categoryName];
            const groups = groupByFour(articles);
            return {
                category: categoryName,
                groups,
            };
        })
        .sort((a, b) => {
            const indexA = CATEGORY_ORDER.indexOf(a.category);
            const indexB = CATEGORY_ORDER.indexOf(b.category);
            return indexA - indexB;
        });

    const dataLatestArray = dataLatest || [];

    const childProps = {
        ...props,
        groupedDataByCategory,
        isLoading,
        dataLatestArray,
        isLoadingLatest,
        matchesSm,
        matchesMd,
    };

    return <BlogPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BlogPageContainer.propTypes = propTypes;

export default BlogPageContainer;
