import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';
import InformationLine from '../../../assets/icons/information-line.svg?react';

export default {
    AccountStatusWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            width: '100%',
        }),
    ),
    AccountStatusContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            backgroundColor: theme.palette.custom.vibrantblue10,
            padding: { xs: '1rem', sm: '2.5rem', md: '1.5rem', lg: '2.5rem' },
            margin: { xs: 'auto', md: '-1.5rem', lg: 'auto' },
            borderTopLeftRadius: pxToRem(40),
            borderBottomLeftRadius: pxToRem(40),
            borderBottomRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            borderTopRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            overflow: 'hidden',
            [theme.breakpoints.down(450)]: {
                overflow: 'scroll',
            },
        }),
    ),
    WalletSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
            marginBottom: pxToRem('20px'),
        }),
    ),
    WalletTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            textAlign: 'center',
            marginBottom: '10px',
        }),
    ),
    RadioButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { sm: '100%', xs: '100%' },
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '0.9rem',
        }),
    ),
    NoDataChartContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.custom.vibrantblue,
        }),
    ),
    Amount: styled(Typography)(({ theme }) => theme.unstable_sx({})),
    TotalText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            marginTop: pxToRem('10px'),
        }),
    ),
    ClientAmountContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            gap: '8px',
            marginBottom: '20px',
        }),
    ),
    ClientAmount: styled(Typography)(({ theme }) => theme.unstable_sx({})),
    AvailableBalanceContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem('5px'),
            marginBottom: pxToRem('20px'),
        }),
    ),
    AccountInformationSubtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
        }),
    ),
    MoneyLabelContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            gap: pxToRem('15px'),
        }),
    ),
    ModalTextContainer: styled(Box)(({ theme, color }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            gap: pxToRem('5px'),
        }),
    ),
    ModalText: styled(Typography)(({ theme, color }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue4,
            textAlign: 'center',
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline',
            },
        }),
    ),
    InfoIcon: styled(InformationLine)(({ theme, color }) =>
        theme.unstable_sx({
            fill: theme.palette.custom.darkblue4,
            width: pxToRem('15px'),
        }),
    ),
    FooterButtons: styled(Box)(({ theme, color }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        }),
    ),
};
