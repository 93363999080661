import React from 'react';
import { Box, Typography } from '@mui/material';
import St from './DepositStepThree.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import StorkIcon from 'assets/img/general/stork.png';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const DepositStepThree = (props: Props) => {
    const { t, handleBack } = props;

    return (
        <>
            <St.IconWrapper>
                <St.StyledImage
                    style={{
                        marginTop: '2rem',
                    }}
                    src={StorkIcon}
                    alt="dtorkIcon"
                />
            </St.IconWrapper>
            <Typography variant="h5" gutterBottom textAlign={'center'}>
                {t('deposit_title_step_3')}
            </Typography>
            <St.BodyText variant="body2" paragraph textAlign={'center'}>
                {t('deposit_will_notify')}
            </St.BodyText>
            <Box display="flex" justifyContent="center" mt={2}>
                <ActionButton variant="contained" color="primary" onClick={handleBack}>
                    {t('back', tCommon)}
                </ActionButton>
            </Box>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepThree.propTypes = propTypes;
DepositStepThree;

export default DepositStepThree;
