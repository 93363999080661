import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CircularProgress, Grid, Tab } from '@mui/material';
import {
    CommonAction,
    CommonColumn,
    CommonFilter,
    SortInformation,
} from 'types/common/tableLayout.types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CommonTable from 'components/common/CommonTable';
import TableLayoutHeader from 'components/common/TableLayoutHeader';
import {
    GetDepositsItem,
    GetDepositsResponse,
    GetWithdrawalsItem,
    GetWithdrawalsResponse,
} from 'types/api/cashflow.types';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';

const TreasuryOrdersChannelPage = ({
    t,
    currentTab,
    rows,
    columns,
    filters,
    sortInfo,
    filterHeaderString,
    loading,
    handleTabChange,
    setFilterHeaderString,
    handlePageChange,
    handleSort,
    actionColumns,
    fetchData,
    headAction,
}: Props) => (
    <Grid container display="flex" flexDirection="column">
        <TableLayoutHeader
            title={t('layout_main_navigation_operations_orders')}
            filters={filters}
            filterHeaderString={filterHeaderString}
            setFilterHeaderString={setFilterHeaderString}
            fetchData={fetchData}
            columns={columns}
            createAction={headAction}
        />
        <TabContext value={currentTab}>
            <Grid container>
                <Grid item xs={6}>
                    <TabList
                        sx={{
                            p: 0,
                            borderBottom: 1,
                            borderColor: 'divider',
                            width: 'fit-content',
                        }}
                        onChange={(_, newValue) => handleTabChange(newValue)}>
                        <Tab
                            value="withdrawals"
                            label={t('withdrawals')}
                            sx={{
                                textTransform: 'none',
                                fontSize: 18,
                                color: 'primary.main',
                                paddingInline: 4,
                            }}
                        />
                        <Tab
                            value="deposits"
                            label={t('deposits')}
                            sx={{
                                textTransform: 'none',
                                fontSize: 18,
                                color: 'primary.main',
                                paddingInline: 4,
                            }}
                        />
                    </TabList>
                </Grid>

                {loading ? (
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={5}>
                        <CircularProgress color="primary" size="6rem" />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <TabPanel value="withdrawals">
                            <CommonTable
                                columns={columns}
                                rows={rows}
                                sortInfo={sortInfo}
                                handlePageChange={handlePageChange}
                                handleSort={handleSort}
                                actions={actionColumns}
                            />
                        </TabPanel>

                        <TabPanel value="deposits">
                            <CommonTable
                                columns={columns}
                                rows={rows}
                                sortInfo={sortInfo}
                                handlePageChange={handlePageChange}
                                handleSort={handleSort}
                                actions={actionColumns}
                            />
                        </TabPanel>
                    </Grid>
                )}
            </Grid>
        </TabContext>
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    rows: GetWithdrawalsResponse | GetDepositsResponse;
    columns: CommonColumn<GetWithdrawalsItem | GetDepositsItem>[];
    filters: CommonFilter[];
    sortInfo: SortInformation;
    filterHeaderString: string;
    currentTab: string;
    loading: boolean;
    setFilterHeaderString(filterHeaderString: string): void;
    handlePageChange(pag: number): void;
    handleSort(sortInfo: SortInformation): void;
    handleTabChange(newValue: string): void;
    actionColumns: CommonAction<any>[];
    headAction: CommonAction<any>;
    fetchData(params: GridHookParams): any;
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TreasuryOrdersChannelPage.propTypes = propTypes;

export default TreasuryOrdersChannelPage;
