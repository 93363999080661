import * as React from 'react';
import { AlertColor } from '@mui/material';
import { createContext, useContext, useMemo, useState } from 'react';

interface CommonGridContextProps {
    needRefresh: boolean;
    setNeedRefresh: (value: boolean) => void;
    openSnackBar: boolean;
    setOpenSnackBar: (value: boolean) => void;
    severity: AlertColor;
    setSeverity: (value: AlertColor) => void;
    message: string;
    setMessage: (value: string) => void;
    openCreateModal: boolean;
    setOpenCreateModal: (value: boolean) => void;
    showDisabled: boolean;
    setShowDisabled: (value: boolean) => void;
}

export const CommonGridContext = createContext<CommonGridContextProps>(
    {} as CommonGridContextProps,
);

export const useCommonGrid = () => useContext(CommonGridContext);

export const CommonGridProvider = ({ children }: any) => {
    const [needRefresh, setNeedRefresh] = useState<boolean>(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>('success');
    const [message, setMessage] = useState('');
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [showDisabled, setShowDisabled] = useState(false);

    const MemoValue = useMemo(() => {
        const value = {
            severity,
            message,
            needRefresh,
            openCreateModal,
            openSnackBar,
            setSeverity,
            setMessage,
            setNeedRefresh,
            setOpenCreateModal,
            setOpenSnackBar,
            setShowDisabled,
            showDisabled,
        };
        return value;
    }, [
        severity,
        message,
        needRefresh,
        openCreateModal,
        openSnackBar,
        setSeverity,
        setMessage,
        setNeedRefresh,
        setOpenCreateModal,
        setOpenSnackBar,
        setShowDisabled,
        showDisabled,
    ]);

    return <CommonGridContext.Provider value={MemoValue}>{children}</CommonGridContext.Provider>;
};
