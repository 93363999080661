import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomRadioButton from './CustomRadioButton';
import { Trans } from 'react-i18next';
import { tCommon } from 'constants/appConstants';
import { Nationality } from 'types/api/onboarding.types';
import { FormikErrors } from 'formik';
import { Tprop } from 'types/common/general.types';
import St from './ComponentLi.styled';

const ComponentLi = (props: Props) => {
    const {
        text,
        tooltipText,
        values,
        setFieldValue,
        formKey,
        handleClick,
        enableInput = false,
        inputType,
        inputKey,
        countriesList,
        errors,
        t,
    } = props;

    return (
        <St.Li>
            <St.FirstWrapper>
                <St.Box>
                    <St.Text>{text}</St.Text>
                    <St.Tooltip
                        title={
                            <Trans transSupportBasicHtmlNodes components={St.tooltipText()}>
                                {tooltipText}
                            </Trans>
                        }>
                        <St.IconButton>
                            <St.QuestionMarkRadiusIcon />
                        </St.IconButton>
                    </St.Tooltip>
                </St.Box>
                {enableInput && inputKey && (
                    <St.InputWrapper>
                        {inputType === 'autocomplete' ? (
                            <St.CustomAutoComplete
                                id="countryTaxResidence"
                                value={values[inputKey]}
                                onChange={(e, value) => {
                                    setFieldValue(inputKey, value);
                                }}
                                options={countriesList ?? []}
                                error={errors[inputKey] ?? null}
                                helperText={errors[inputKey]}
                                label={t('complementary_data_ocde_input') as string}
                                placeholder={t('complementary_data_select_placeholder') as string}
                                renderOption={(props, option) => (
                                    <St.NameWrapper {...props} key={option.id}>
                                        {option.name}
                                    </St.NameWrapper>
                                )}
                            />
                        ) : (
                            <St.CustomInput
                                field={inputKey}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={inputType}
                                label={t(`complementary_data_${inputKey}`)}
                                placeholder={t(`complementary_data_${inputKey}_placeholder`)}
                                required
                                errors={errors ?? null}
                            />
                        )}
                    </St.InputWrapper>
                )}
            </St.FirstWrapper>
            <St.SecondWrapper>
                <CustomRadioButton
                    name={formKey}
                    value={values[formKey]}
                    handleClick={handleClick}
                    text={t('yes', tCommon)}
                />
                <CustomRadioButton
                    name={formKey}
                    value={!values[formKey]}
                    handleClick={handleClick}
                    text="No"
                />
            </St.SecondWrapper>
        </St.Li>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    text: string;
    tooltipText: string;
    formKey: string;
    values: { [key: string]: boolean };
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<any>>;
    inputKey?: string;
    inputType?: string;
    countriesList?: Nationality[];
    errors: any;
    enableInput: boolean;
    handleClick: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ComponentLi.propTypes = propTypes;

export default ComponentLi;
