import { Box, styled, TextField } from '@mui/material';
import MagnifyingGlassIcon from 'assets/icons/magnifying-glass.svg?react';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    SearchContainer: styled(
        Box,
        transientOptions,
    )<{ $blueMode: boolean }>(({ theme, $blueMode }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${$blueMode ? theme.palette.custom.vibrantblue2 : theme.palette.custom.darkblue8}`,
            p: '0 0.5rem 0 1rem',
            borderRadius: '1.625rem',
            background: $blueMode ? theme.palette.custom.vibrantblue8 : 'transparent',
        }),
    ),
    StyledTextField: styled(
        TextField,
        transientOptions,
    )<{ $blueMode: boolean }>(({ theme, $blueMode }) => ({
        fontStyle: 'italic',
        fontWeight: '300',
        lineHeight: pxToRem('18.75px'),
        letterSpacing: '0.0015em',
        color: theme.palette.custom.darkblue2,
        width: $blueMode ? '100%' : '10.625rem',
        '& fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
        input: {
            padding: '0',
            border: 'none',
            width: $blueMode ? '98%' : 'auto',
        },
        '& .MuiInputBase-input::placeholder': {
            color: theme.palette.custom.darkblue2,
            opacity: '0.8',
        },
    })),
    IconWrapper: styled(
        Box,
        transientOptions,
    )<{ $blueMode: boolean }>(({ theme, $blueMode }) => ({
        background: $blueMode ? theme.palette.custom.vibrantblue : 'transparent',
        borderRadius: '50%',
    })),
    SearchIcon: styled(
        MagnifyingGlassIcon,
        transientOptions,
    )<{ $blueMode: boolean }>(({ theme, $blueMode }) => ({
        color: $blueMode ? theme.palette.background.default : theme.palette.custom.vibrantblue,
        verticalAlign: 'bottom',
    })),
};
