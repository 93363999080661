import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import TableLayout from 'components/layouts/TableLayout';
import { DailyOperation } from 'types/pages/reports.types';
import { useGetDailyOperations } from 'hooks/api/marketAccount.hooks';

const ReportsDailyOperationsPage = (props: Props) => {
    const { columns, filters, t, defaultFilters, filterDateValidation, actions } = props;
    return (
        <TableLayout
            title={t('channel_daily_operations_title')}
            fetchData={useGetDailyOperations}
            columns={columns}
            filters={filters}
            canExport
            exportTitle={t('daily_operations_doc_title')}
            defaultFilters={defaultFilters}
            customFilterValidation={filterDateValidation}
            actions={actions}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<DailyOperation>[];
    actions: CommonAction<DailyOperation>[];
    filters: CommonFilter[];
    defaultFilters: ExtraQueryFiltersProps[];
    filterDateValidation: (value: string) => { result: boolean; error: string | undefined };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsDailyOperationsPage.propTypes = propTypes;

export default ReportsDailyOperationsPage;
