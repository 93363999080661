import { Box, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    OnboardingPersonalDataPageBox: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    FormWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginTop: pxToRem(70),
    })),
    OnboardingPersonalDataPageInternalBox: styled(Box)(({ theme }) => ({
        width: '100%',
        margin: `${pxToRem(25)} ${pxToRem(50)}`,
        [theme.breakpoints.up('sm')]: {
            margin: `${pxToRem(50)} ${pxToRem(60)} `,
        },
        [theme.breakpoints.up('md')]: {
            margin: `${pxToRem(50)} ${pxToRem(100)}`,
        },
    })) as typeof Box,
    ButtonWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8.5px 112px',
        '& > *': {
            width: pxToRem(300),
        },
    })) as typeof Box,
};
