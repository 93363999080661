export const CHART_MAIN_COLORS = [
    '#848DA7',
    '#01ACED',
    '#7E54A0',
    '#008FD3',
    '#70CEEA',
    '#3BC2DF',
    '#11A1C1',
    '#6C7CB8',
    '#0170BB',
    '#946744',
    '#2E3092',
    '#F6941D',
    '#6E2C90',
    '#FEB913',
    '#A154A1',
    '#17469E',
    '#FF3D00',
    '#BE216D',
    '#AE063A',
    '#EB1B39',
    '#F6941D',
];
