import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientInformation from './ClientInformation';
import { MarketAccount } from 'types/api/marketAccount.types';
import { AuthContext } from 'context/auth.context';

const ClientInformationContainer = (props: Props) => {
    const { t, marketAccounts } = props;

    const { customerCode } = React.useContext(AuthContext);

    const selectedAccount = marketAccounts?.find(account => account.customerCode === customerCode);

    const childProps = {
        ...props,
        selectedAccount,
    };

    return <ClientInformation {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
    marketAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

interface extraProps {
    marketAccounts: MarketAccount[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientInformationContainer.propTypes = propTypes;

export default ClientInformationContainer;
