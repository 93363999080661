import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfileDeleteAccountPage.styled';
import DeleteAccountForm from './DeleteAccountForm';
import { AlertColor } from '@mui/material';

const ProfileDeleteAccountPage = (props: Props) => {
    const { SnackBar, hasPendingRequest, setSnackBarMessage, isLoading, t } = props;

    return (
        <St.SectionWrapper component={'section'}>
            <St.PageTitle>{t('delete_account_title')}</St.PageTitle>
            <DeleteAccountForm
                hasPendingRequest={hasPendingRequest}
                setSnackBar={setSnackBarMessage}
                isLoading={isLoading}
                t={t}
            />
            <SnackBar />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    SnackBar: () => JSX.Element;
    hasPendingRequest: boolean;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileDeleteAccountPage.propTypes = propTypes;

export default ProfileDeleteAccountPage;
