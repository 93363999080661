import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Fragment } from 'react';
import { ICommonHeaderView } from 'types/common/tableLayout.types';

const CommonTableHeader = ({ columns, hasActions, sort, onSortChange, headerStyles }: Props) => (
    <TableHead>
        <TableRow>
            {columns.map(({ id, name, align, sortable }) => (
                <Fragment key={id}>
                    {sortable ? (
                        <TableCell key={id} align={align} style={headerStyles}>
                            <TableSortLabel
                                active={sortable && sort.column === id}
                                hidden={sort.column !== id}
                                direction={sort.column === id ? sort.sortDirection : 'asc'}
                                onClick={() => onSortChange(id)}>
                                {name}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell key={id} align={align} style={headerStyles}>
                            {name}
                        </TableCell>
                    )}
                </Fragment>
            ))}

            {hasActions && (
                <TableCell style={headerStyles} align="center">
                    Acciones
                </TableCell>
            )}
        </TableRow>
    </TableHead>
);

const propTypes = {};

interface extraProps extends ICommonHeaderView {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTableHeader.propTypes = propTypes;

export default CommonTableHeader;
