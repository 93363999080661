import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonMarketTable from 'components/common/CommonMarketTable';
import { useGetTableQuotations } from 'hooks/ws/prices.hooks';
import St from './MarketEquitiesPage.styled';

const MarketEquitiesPage = (props: Props) => {
    const { t } = props;
    return (
        <St.SectionWrapper>
            <CommonMarketTable
                typeInstrument="EQUITY"
                title={t('market_table_actions_title')}
                useGetData={useGetTableQuotations}
            />
        </St.SectionWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketEquitiesPage.propTypes = propTypes;

export default MarketEquitiesPage;
