import { styled as styledMui } from '@mui/material/styles';
import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    CreateAccountContainerBox: styledMui(Box)(({ theme }) => ({
        width: 'min-content',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    })) as typeof Box,

    LogoBox: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: pxToRem(50),
    })) as typeof Box,

    LogoImg: styled('img')(({ theme }) =>
        theme.unstable_sx({
            height: { xs: pxToRem(128), sm: pxToRem(128), md: pxToRem(128), lg: pxToRem(148) },
            width: { xs: pxToRem(379), sm: pxToRem(379), md: pxToRem(379), lg: pxToRem(379) },
        }),
    ),

    CreateAccountBox: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            margin: `${pxToRem(50)} ${pxToRem(100)}`,
        },
    })) as typeof Box,

    CreateAccountInformationBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: pxToRem(30), // default for xs and sm
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginTop: pxToRem(50),
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            marginTop: pxToRem(55),
        },
    })) as typeof Box,

    CreateAccountInformationFirstTab: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
    })) as typeof Box,

    CreateAccountTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontWeight: 300,
            fontSize: { xs: pxToRem(20), sm: pxToRem(20), md: pxToRem(40), lg: pxToRem(68) },
            lineHeight: { xs: pxToRem(20), sm: pxToRem(20), md: pxToRem(40), lg: pxToRem(79.69) },
            fontStyle: 'normal',
            color: theme.palette.primary.main,
            width: '100%',
            height: { xs: pxToRem(50), sm: pxToRem(50), md: pxToRem(100), lg: pxToRem(225) },
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                top: pxToRem(256),
                left: pxToRem(93),
            },
        }),
    ),

    CreateAccountSubTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            width: pxToRem(555),
            height: { xs: pxToRem(50), sm: pxToRem(50), md: pxToRem(129), lg: pxToRem(129) },
            // height: pxToRem(129),
            top: { xs: pxToRem(20), sm: pxToRem(20), md: pxToRem(400), lg: pxToRem(574) },
            left: { xs: pxToRem(38), sm: pxToRem(38), md: pxToRem(400), lg: pxToRem(96) },
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { xs: pxToRem(20), sm: pxToRem(20), md: pxToRem(48), lg: pxToRem(48) },
            lineHeight: pxToRem(56.25),
            color: '#2E4665',
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                textAlign: '',
            },
        }),
    ),

    ComeBackToLoginBox: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: pxToRem(30),
        alignItems: 'baseline',
        justifyContent: 'center',
    })) as typeof Box,

    CreateAccountInformationSecondTab: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(1365)]: {
            flexDirection: 'row',
        },
        gap: pxToRem(10),
        margin: `${pxToRem(25)} ${pxToRem(50)}`,
    })) as typeof Box,

    CreateAccountText: styled(Typography)(() => ({
        width: pxToRem(128),
        height: pxToRem(41),
        top: pxToRem(798),
        left: pxToRem(239),
        fontWeight: 400,
        margin: '1rem 0',
        whiteSpace: 'pre-line',
        textAlign: 'right',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(18.75),
    })) as typeof Typography,

    CreateAccountCopyrightText: styled(Typography)(() => ({
        width: pxToRem(100),
        height: pxToRem(14),
        fontWeight: '400',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(14.06),
        letterSpacing: '0.4%',
        color: '#748498',
        margin: '2em',
    })) as typeof Typography,

    CreateAccountBottomText: styled(Typography)(() => ({
        width: pxToRem(685),
        height: pxToRem(228),
        fontWeight: '400',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(14.06),
        letterSpacing: '0.4%',
        margin: '3em 0em',
    })) as typeof Typography,

    CreateAccountFatcaText: styled(Typography)(() => ({
        fontSize: pxToRem(12),
        color: '#748498',
        letterSpacing: '0.4%',
        margin: '3em 0em',
        width: '70%',
        textAlign: 'center',
    })) as typeof Typography,
};
