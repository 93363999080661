import { TableCell, styled } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    TickerCell: styled(TableCell)(({ theme }) => ({
        color: theme.palette.primary.main,
        cursor: 'pointer',
    })),
    DescriptionCell: styled(TableCell)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: { xl: '100%', lg: pxToRem(100) },
            overflow: 'hidden',
        }),
    ),
    ActionCell: styled(TableCell)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: pxToRem(30),
        }),
    ),
    PercentageCell: styled(TableCell)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: pxToRem(60),
        }),
    ),
    ColorCell: styled(
        TableCell,
        transientOptions,
    )<{ $positive: boolean | null; $isPercentage?: boolean }>(
        ({ theme, $positive, $isPercentage }) =>
            theme.unstable_sx({
                maxWidth: $isPercentage ? pxToRem(60) : 'auto',
                color:
                    $positive !== null
                        ? $positive
                            ? theme.palette.custom.green
                            : theme.palette.custom.red2
                        : 'auto',
            }),
    ),
};
